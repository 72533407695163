import React, { FC, useCallback } from 'react';

import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ProUser } from '@travauxlib/shared/src/types/company';

export type ConfirmArchiveUserModalProps = {
  proUser: Omit<ProUser, 'id'>;
  handleClose: () => unknown;
  archiveUser: ({ user, slug }: { user: ProUser; slug?: string }) => Promise<unknown>;
  slug?: string;
};

export const ConfirmArchiveUserModal: FC<ConfirmArchiveUserModalProps> = ({
  proUser,
  handleClose,
  archiveUser,
  slug,
}) => {
  const onClick = useCallback(() => {
    archiveUser({ user: proUser, slug });
    handleClose();
  }, [archiveUser, proUser]);

  return (
    <ModalContent
      title={`Êtes-vous sur de vouloir archiver l'utilisateur ${proUser.fullName}?`}
      validateAction={{ label: 'Confirmer', onClick: onClick }}
      cancelAction={{ label: 'Annuler', onClick: handleClose }}
    />
  );
};
