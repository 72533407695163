import React from 'react';

import { Form } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { toast } from '@travauxlib/shared/src/components/Notifications';

import { useLogin, usePasswordLogin } from 'api/useAuth';
import { LoginButton } from 'components/Auth/login';

export const LoginPage: React.FC = () => {
  const login = useLogin();
  const passwordLogin = usePasswordLogin();
  return (
    <div className="container !mt-lg">
      <div className="flex flex-wrap -mx-md !mb-xxl">
        <div className="sm-desktop:w-6/12 sm-desktop:px-md relative !mx-auto !text-center">
          <h1>Connexion</h1>
          <LoginButton
            onLoginSuccess={login}
            onLoginFailure={(e: Error) => {
              toast.error(`Erreur durant l'authentification: ${e.message}`);
            }}
          />
        </div>
      </div>
      {!APP_CONFIG.hideLoginButton && (
        <Form<{ password: string }> onSubmit={values => passwordLogin(values)}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-wrap -mx-md">
                <div className="sm-desktop:w-6/12 sm-desktop:px-md relative !mx-auto">
                  <InputField
                    className="mb-md"
                    name="password"
                    type="password"
                    label="Connexion (uniquement pour tests)"
                    id="password"
                  />
                  <Button type="submit" disabled={submitting} loading={submitting}>
                    Valider
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Form>
      )}
    </div>
  );
};
