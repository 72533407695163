import React from 'react';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Tag, Variant } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { S3File } from '@travauxlib/shared/src/types';
import { Answer } from '@travauxlib/shared/src/types/onboarding';

import { Lead, ScoreLabel } from 'types';

type BadgeQuestionsId = 'budget';

const badgesQuestionsId: string[] = ['budget'];

const badgedQuestions: {
  [K in BadgeQuestionsId]: {
    getColor: (payload: string) => Variant;
  };
} = {
  budget: {
    getColor: value => (value === '0-10000' ? 'error' : 'success'),
  },
};

const scoresLabelsBadgeLevels: {
  [K in ScoreLabel]: Variant;
} = {
  top: 'success',
  bon: 'info',
  moyen: 'primary',
  mauvais: 'error',
};

const hiddenQuestionIds = ['firstName', 'lastName', 'phoneNumber', 'localisation'];

const multiLineQuestionIds = ['description'];

type DescriptionElement = {
  id: string;
  questionLabel: string;
  label: string | JSX.Element;
};

type BadgeElement = DescriptionElement & {
  getColor: (value: string) => Variant;
  value: string;
};

const Answers: React.FC<{ answers: Answer[] }> = ({ answers }) => {
  const badgedElements: BadgeElement[] = [];
  const descriptionElements: DescriptionElement[] = [];

  answers.forEach(({ id, questionLabel, content: { label, value, type } }) => {
    if (value) {
      const descriptionElement = {
        id,
        questionLabel,
        label,
      };

      if (badgesQuestionsId.includes(id) && typeof value === 'string') {
        badgedElements.push({
          ...descriptionElement,
          value,
          getColor: badgedQuestions[id as BadgeQuestionsId].getColor,
        });
      } else if (type === 'files') {
        descriptionElements.push({
          ...descriptionElement,
          label: (
            <>
              {(value as S3File[]).map(({ key, name }) => (
                <span key={key}>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${APP_CONFIG.apiURL}/client/lead/temporary-file/${key}`}
                  >
                    {name}
                  </Link>
                  <br />
                </span>
              ))}
            </>
          ),
        });
      } else if (!hiddenQuestionIds.includes(id)) {
        descriptionElements.push(descriptionElement);
      }
    }
  });

  return (
    <>
      {badgedElements.map(({ id, questionLabel, label, value, getColor }, index) => (
        <div key={`${id}-${index}`}>
          <span>{questionLabel} :&nbsp;</span>
          <Tag variant={getColor(value)} label={label as string} size="md" />
        </div>
      ))}
      {descriptionElements.map(({ id, questionLabel, label }, index) => (
        <div key={`${id}-${index}`}>
          {multiLineQuestionIds.includes(id) ? (
            <>
              {questionLabel}:
              <strong>
                <MultilineParagraph text={label as string} />
              </strong>
            </>
          ) : (
            <>
              {questionLabel} : <strong>{label}</strong>
            </>
          )}
        </div>
      ))}
    </>
  );
};

export const Description: React.FC<{ lead: Lead }> = ({ lead }) => (
  <>
    <h3>
      {lead.shortTitle}{' '}
      {lead.scoreLabel ? (
        <Tag
          size="md"
          variant={scoresLabelsBadgeLevels[lead.scoreLabel]}
          label={lead.scoreLabel}
          className="uppercase"
        />
      ) : (
        <Tag size="md" variant="primary" label="Score inconnu" className="uppercase" />
      )}
    </h3>
    <div className="mb-md">
      {lead.parrainPipedriveDealUrl && (
        <Tag size="md" variant="info" className="!mx-xxs !mb-xs" label="Parrainage" />
      )}
      {lead.isDemandeDeRappel && (
        <Tag size="md" variant="info" className="!mx-xxs !mb-xs" label="Demande de rappel" />
      )}
    </div>
    {lead.acquisitionLabel && <div>{lead.acquisitionLabel}</div>}
    {lead.metadata.conversionButton && <div>Besoin client : {lead.metadata.conversionButton}</div>}
    {lead.recallAnswers.length > 0 && <Answers answers={lead.recallAnswers} />}
    <br />
    <Answers answers={lead.onboardingAnswers} />
  </>
);
