import React from 'react';

import { Form } from 'react-final-form';

import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { CreateClientAccountFormFields } from './CreateClientAccountFormFields';

import { useCreateClientAccount } from '../api/useCreateClientAccount';

type FormProps = {
  firstName: string;
  lastName: string;
  email: string;
};

type Props = {
  children?: React.ReactNode;
  onValidate?: (createdClientAccountUuid: string | undefined) => void;
  onClose: () => void;
};

export const CreateClientAccount: React.FC<Props> = ({ children, onValidate, onClose }) => {
  const createAccount = useCreateClientAccount();
  const onSubmit = async (values: FormProps): Promise<void> => {
    const createdAccount = await createAccount(values);
    onValidate?.(createdAccount?.uuid);
    onClose();
  };

  return (
    <Form<FormProps> onSubmit={onSubmit}>
      {({ handleSubmit, submitting, invalid }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          validateAction={{
            label: 'Valider',
            type: 'submit',
            disabled: invalid || submitting,
            loading: submitting,
          }}
        >
          {children}
          <CreateClientAccountFormFields />
        </ModalContent>
      )}
    </Form>
  );
};
