import React from 'react';

import classNames from 'classnames';

import { DevisForComparator } from '@travauxlib/shared/src/features/DevisDisplay/types';
import { generateDevisComparison } from '@travauxlib/shared/src/features/DevisDisplay/utils/generateDevisComparison';

import { DevisComparatorTableHeader } from './DevisComparatorTableHeader';

import { DevisComparatorLotRow } from '../DevisComparatorLotRow';
import { DevisComparatorStats } from '../DevisComparatorStats';

type Props = {
  firstDevis: DevisForComparator;
  secondDevis: DevisForComparator;
  showStats: boolean;
};

export const DevisComparatorGrid: React.FC<Props> = ({ firstDevis, secondDevis, showStats }) => {
  const comparisons = generateDevisComparison(firstDevis, secondDevis);

  const firstDevisLines = firstDevis.lots.flatMap(lot =>
    lot.items.filter(item => item.type === 'ligne'),
  );

  const secondDevisLines = secondDevis.lots.flatMap(lot =>
    lot.items.filter(item => item.type === 'ligne'),
  );

  const maxLineNumber =
    firstDevisLines.length > secondDevisLines.length
      ? firstDevisLines.length
      : secondDevisLines.length;

  return (
    <>
      {showStats && (
        <div className="sm-desktop:px-xl mb-lg">
          <DevisComparatorStats
            basePrice={firstDevis.prixTotalHT}
            comparePrice={secondDevis.prixTotalHT}
            maxLineNumber={maxLineNumber}
            comparisons={comparisons}
          />
        </div>
      )}
      <div className={classNames('w-full', { 'mt-lg': !showStats })}>
        <DevisComparatorTableHeader firstDocument={firstDevis} secondDocument={secondDevis} />
        <div className="w-full grid grid-cols-1 sm-desktop:grid-cols-[1fr_1fr_170px]">
          {comparisons.map((comparison, index) => (
            <DevisComparatorLotRow key={index} comparison={comparison} />
          ))}
        </div>
      </div>
    </>
  );
};
