import { BaseDePrix, Configuration } from '@travauxlib/shared/src/features/Configurateur/types';

import { getTotalPriceConfiguration } from './getTotalPriceConfiguration';

export const getConfigurationPrixTTC = (
  baseDePrix: BaseDePrix,
  configuration: Configuration,
): number =>
  getTotalPriceConfiguration({
    baseDePrix,
    configuration,
    withTVA: true,
  });
