import React from 'react';

import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';

import { useToggleEnableSuiviRenforce } from '../api/useToggleSuiviFinancierRenforce';

type Props = {
  isOpen: boolean;
  chantier: Chantier;
  handleClose: () => void;
};
export const SuiviFinancierRenforceModal: React.FC<Props> = ({ isOpen, chantier, handleClose }) => {
  const { toggleEnableSuiviRenforce, isLoading } = useToggleEnableSuiviRenforce();
  const title = chantier.enableSuiviRenforce
    ? 'Désactivation du suivi financier renforcé'
    : 'Activation du suivi financier renforcé';
  const content = chantier.enableSuiviRenforce
    ? 'Êtes-vous sûr de vouloir désactiver le suivi financier renforcé sur ce chantier ? Si la commission n’a pas été intégralement captée, elle le sera automatiquement sur le ou les prochaines libérations de fonds.'
    : 'Êtes-vous sûr de vouloir activer le suivi financier renforcé sur ce chantier ? Toutes les libérations de fonds seront bloquées automatiquement et ne pourront être débloquées que par une action manuelle depuis l’admin.';
  return (
    <FeedbackMessages
      variant="warning"
      title={title}
      handleClose={handleClose}
      isOpen={isOpen}
      message={content}
      validateAction={{
        label: 'Confirmer',
        disabled: isLoading,
        onClick: () => !isLoading && toggleEnableSuiviRenforce(chantier).then(() => handleClose()),
      }}
      cancelAction={{ label: 'Annuler', onClick: () => handleClose() }}
    />
  );
};
