import { useQuery } from '@tanstack/react-query';

import { DevisForComparator } from '@travauxlib/shared/src/features/DevisDisplay/types';
import { request } from '@travauxlib/shared/src/utils/request';

export const useDevisForComparator = (
  token?: string,
): { devis: DevisForComparator | undefined; isLoading: boolean; error: unknown } => {
  const {
    data: devis,
    isLoading,
    fetchStatus,
    error,
  } = useQuery<DevisForComparator>({
    queryKey: ['devis', token],
    queryFn: () => request(`${APP_CONFIG.apiURL}/admin/devis/${token}`),
    enabled: !!token,
    retry: 0,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  return { devis, isLoading: isLoading && fetchStatus !== 'idle', error };
};
