import { formatInitialValues } from '@travauxlib/shared/src/features/SuiviChantier/hooks/useSuiviInitialValues';
import { SuiviChantierPro, SuiviLot } from '@travauxlib/shared/src/features/SuiviChantier/types';
import {
  getAvancementForLots,
  parseFormDataToGetLots,
} from '@travauxlib/shared/src/features/SuiviChantier/utils';
import { computeSuiviChantierAmounts } from '@travauxlib/shared/src/features/SuiviChantier/utils/computeSuiviChantierAmounts';
import { ChantierAdminListItemView } from '@travauxlib/shared/src/types/api/admin/ChantierAdminListItemView';

import { BaseStep, ExecuteParams } from '../BaseStep';

const fields = [
  {
    name: 'suiviFullyAdvanced',
    helperText: 'Avancer le chantier à 100%',
    fieldType: 'checkbox',
  },
] as const;

type Input = { chantier: ChantierAdminListItemView };

export class AdvanceSuiviChantier extends BaseStep<
  Input,
  { dealUuid: string },
  typeof fields,
  { chantier: ChantierAdminListItemView }
> {
  public name: string = 'Create Suivi Chantier';

  public fields = fields;

  async execute({
    apiURL,
    request,
    chantier,
    suiviFullyAdvanced,
  }: ExecuteParams<Input, typeof fields>): Promise<{ dealUuid: string } | undefined> {
    super.log('Creating Suivi chantier Pro...');

    const suiviInitial = await request<SuiviChantierPro>(
      `${apiURL}/pro/chantiers/${chantier.uuid}/suivi-chantier/init`,
    );

    super.log('Created!');
    super.log(`Advancing suivi ${!!suiviFullyAdvanced ? 'to 100%...' : 'between 85% and 95%...'}`);

    const initialValues = formatInitialValues({ orderedDevisList: suiviInitial.devisList });
    const lots = parseFormDataToGetLots(initialValues);

    const suiviAdvancedBetweenPVs = lots.map<SuiviLot>(lot => ({
      ...lot,
      lignes: lot.lignes.map(ligne => {
        if (
          !!suiviFullyAdvanced ||
          ligne.designation ===
            "Fourniture et pose d'isolation thermique par l'extérieur en polystyrène expansé collé de 100 mm d'épaisseur et  R = 2,6 m².K/W"
        ) {
          return { ...ligne, pourcentageAvancement: 100, hasFournitures: true };
        } else {
          return ligne;
        }
      }),
    }));

    const globalPourcentageAvancement = getAvancementForLots(suiviAdvancedBetweenPVs);
    const remisesMapByDevisToken = suiviInitial.devisList.reduce(
      (acc, devis) => ({
        ...acc,
        [devis.token]: devis.remises,
      }),
      {},
    );

    const compute = computeSuiviChantierAmounts({
      lots: suiviAdvancedBetweenPVs,
      remisesMapByDevisToken,
      montantRemise: suiviInitial.montantRemise,
      montantDejaPaye: suiviInitial.montantDejaPaye,
      totalMontantTVA: suiviInitial.totalMontantTVA,
      threshold: 85,
    });

    const suiviPayload = {
      montantALibererTTC: compute.montantALibererTTC,
      globalPourcentageAvancement: globalPourcentageAvancement,
      lots: suiviAdvancedBetweenPVs,
      initialMontantDemande: compute.montantALibererTTC + compute.montantGele,
    };

    const { suiviChantierUuid } = await request<{ suiviChantierUuid: string }>(
      `${apiURL}/pro/chantiers/${chantier.uuid}/suivi-chantier`,
      {
        method: 'POST',
        body: suiviPayload,
      },
    );

    super.log('Suivi Pro advanced !');

    super.log('Validating Suivi Client...');

    await request(
      `${apiURL}/client/chantiers/${chantier.uuid}/suivi-chantier/${suiviChantierUuid}`,
      {
        method: 'POST',
        body: suiviPayload,
      },
    );

    super.log('Suivi validated !');

    return;
  }
}
