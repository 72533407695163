import classNames from 'classnames';

type Props = {
  neutralColor?: boolean;
};

export const PrestationsSeparator: React.FC<Props> = ({ neutralColor }) => (
  <div
    className={classNames('relative -left-[2.5rem] w-[calc(100%_+_2.5rem)] h-[1px] mb-md', {
      'bg-primary': !neutralColor,
      'bg-neutral-300': neutralColor,
    })}
  />
);
