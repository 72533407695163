import React from 'react';

import { FieldRenderProps } from 'react-final-form';

import { ProCompanyInsurance } from '@travauxlib/shared/src/types';

import { Assurance } from './Assurance';

export type AssurancesListProps = {
  handleDelete: (insuranceId: string) => void;
} & FieldRenderProps<ProCompanyInsurance[]>;

export const AssurancesList: React.FC<AssurancesListProps> = ({
  handleDelete,
  input: { value = [] },
}) => (
  <div>
    {value.map((insurance, index) => (
      <Assurance
        key={insurance.id}
        handleDelete={handleDelete}
        index={index}
        insurance={insurance}
      />
    ))}
  </div>
);
