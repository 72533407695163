import React, { useRef } from 'react';

import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeft.svg?react';
import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { useScrollStatus } from '@travauxlib/shared/src/hooks/useScrollStatus';
import { scrollFirstChild } from '@travauxlib/shared/src/utils/dom';

import { useChantierAdvancedActionAuthorized } from 'components/Auth/api/useChantierAdvancedActionAuthorized';

import { DemarrerChantier } from './DemarrerChantier';
import { EncaisserPrestationTPCard } from './EncaisserPrestationTPCard';
import { EncaisserServiceHemea } from './EncaisserServiceHemea';
import { LibererFondsCard } from './LibererFondsCard';
import { ProKYC2Card } from './ProKYC2Card';
import { RattacherCompteLemonway } from './RattacherCompteLemonway';
import { ValiderAvancementCard } from './ValiderEtatAvancementCard';

type Props = {
  chantier: Chantier;
};

export const EventCards: React.FC<Props> = ({ chantier }) => {
  const { isLoading } = useChantierAdvancedActionAuthorized();
  const scrollingContainer = useRef<HTMLDivElement>(null);
  const { isScrollLeft, isScrollRight } = useScrollStatus(scrollingContainer);

  const onNextButtonClick = (): void => {
    if (!scrollingContainer?.current) {
      return;
    }
    scrollFirstChild(scrollingContainer.current, 'next');
  };

  const onPrevButtonClick = (): void => {
    if (!scrollingContainer?.current) {
      return;
    }
    scrollFirstChild(scrollingContainer.current, 'prev');
  };

  return (
    <div className="relative">
      {!isScrollLeft && (
        <IconButton
          className="absolute -left-md top-1/2 transform -translate-y-1/2 shadow-md z-20 !bg-neutral-0"
          type="button"
          dataTestId="prev-button"
          onClick={() => onPrevButtonClick()}
          size="lg"
        >
          <ChevronLeft />
        </IconButton>
      )}
      <div
        ref={scrollingContainer}
        className="relative z-10 flex flex-col overflow-x-auto sm-desktop:flex-row gap-md my-md no-scrollbar"
      >
        <input type="hidden" data-testid="loading-user-rights" data-loading={isLoading} />
        <DemarrerChantier chantier={chantier} />
        <RattacherCompteLemonway chantier={chantier} />
        <LibererFondsCard chantier={chantier} />
        <ValiderAvancementCard chantier={chantier} />
        <EncaisserPrestationTPCard chantier={chantier} />
        <EncaisserServiceHemea chantier={chantier} />
        <ProKYC2Card chantier={chantier} />
      </div>

      {!isScrollRight && (
        <IconButton
          className="absolute -right-md top-1/2 transform -translate-y-1/2 shadow-md z-20 !bg-neutral-0"
          type="button"
          dataTestId="next-button"
          onClick={() => onNextButtonClick()}
          size="lg"
        >
          <ChevronRight />
        </IconButton>
      )}
    </div>
  );
};
