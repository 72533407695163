import React, { useEffect } from 'react';

import { useAuth } from 'api/useAuth';
import { useUpdateOrganization } from 'features/Partners/api/mutations';
import { PartnerOrganization } from 'types';
import { PartenairesTracking } from 'utils/tracking/PartenairesTracking';

import { OrganizationForm } from './OrganizationForm';

import { usePartner } from '../hooks/usePartner';

export const EditOrganization: React.FC = () => {
  const { updateOrganization } = useUpdateOrganization();
  const { adminUserProfile } = useAuth();
  const partner = usePartner();

  useEffect(() => {
    if (partner) {
      PartenairesTracking.onOrganisationPartenaireViewed({
        'User Role': adminUserProfile.role,
        'Organisation Partenaire UUID': partner?.uuid,
      });
    }
  }, [partner]);

  return (
    <OrganizationForm
      onSubmit={(organisation: PartnerOrganization) => {
        PartenairesTracking.onOrganisationPartenaireEdited({
          'User Role': adminUserProfile.role,
          'Organisation Partenaire UUID': organisation.uuid,
        });
        return updateOrganization(organisation);
      }}
    />
  );
};
