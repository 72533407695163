import React from 'react';

import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';

type Props = {
  value: { isOption?: boolean };
  index: number;
  fields: {
    update: (index: number, payload: object) => void;
  };
};

export const OptionCheckbox: React.FC<Props> = ({ fields, value, index }) => (
  <Checkbox
    className="!mb-md"
    checked={!!value.isOption}
    onChange={() => fields.update(index, { ...value, isOption: !value.isOption })}
    label="En option"
  />
);
