import React from 'react';

import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { ArchiType } from '@travauxlib/shared/src/types/api/domain/procompany/ArchiType';

import { MainComptes } from './MainComptes';
import { PaymentStatus } from './PaymentStatus';
import { PaymentStatusDisabled } from './PaymentStatusDisabled';

type Props = {
  chantier: Chantier;
};

const PaymentStatusSection: React.FC<{
  isDisabled: boolean;
  disabledDescription: string;
  name: string;
  popoverTitle: string;
  tauxMontant: number;
  totalDejaEncaisse: number;
  montantTotal: number;
}> = ({
  isDisabled,
  name,
  popoverTitle,
  tauxMontant,
  totalDejaEncaisse,
  montantTotal,
  disabledDescription,
}) => {
  if (isDisabled) {
    return <PaymentStatusDisabled name={name} description={disabledDescription} />;
  }

  const statusProps = {
    name,
    popoverTitle,
    tauxMontant,
    totalDejaEncaisse,
    montantTotal,
  };

  return <PaymentStatus {...statusProps} />;
};

export const ChantierComptes: React.FC<Props> = ({ chantier }) => {
  const { proCompany } = chantier;
  const { archiType } = proCompany;

  const isArchiInterne = archiType === ArchiType.interne;

  return (
    <div className="flex mt-xl mb-md flex-col sm-desktop:flex-row">
      <MainComptes chantier={chantier} isArchiInterne={isArchiInterne} />
      {archiType !== ArchiType.interne && (
        <div className="flex flex-col gap-md mt-md sm-desktop:mt-0">
          <PaymentStatusSection
            isDisabled={chantier.montantServiceHemeaTTC === 0}
            disabledDescription="Le client n'a pas de service hemea sur son chantier"
            name="Service hemea"
            popoverTitle="Détails Service hemea"
            tauxMontant={chantier.tauxServiceHemea}
            totalDejaEncaisse={chantier.montantServiceHemeaEncaisseTTC}
            montantTotal={chantier.montantServiceHemeaTTC}
          />
          <PaymentStatusSection
            isDisabled={false}
            disabledDescription=""
            name="Commission hemea"
            popoverTitle="Détails Commission hemea"
            tauxMontant={chantier.tauxCommissionHemea}
            totalDejaEncaisse={chantier.montantCommissionPreleveeTTC}
            montantTotal={chantier.montantCommissionTTC}
          />
          <PaymentStatusSection
            isDisabled={chantier.montantTravauxPlannerTTC === 0}
            disabledDescription="Le client n'a pas de travaux planner sur son chantier"
            name="Service Travaux Planner"
            popoverTitle="Détails Travaux Planner"
            tauxMontant={chantier.tauxTravauxPlanner}
            totalDejaEncaisse={chantier.montantTPEncaisseTTC}
            montantTotal={chantier.montantTravauxPlannerTTC}
          />
        </div>
      )}
    </div>
  );
};
