import React from 'react';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

import { useAuth } from 'api/useAuth';

import { useTeams } from '../api/useTeams';

export const Teams: React.FC = () => {
  const { teams } = useTeams();
  const {
    adminUserProfile: { isAdmin },
  } = useAuth();

  return (
    <div className="container w-full sm-desktop:w-9/12 text-center">
      <h2 className="mb-md">Liste des équipes</h2>
      <div className="flex justify-around flex-wrap">
        {teams
          .filter(t => t.label !== 'Admin' || isAdmin)
          .map(({ uuid, label }) => (
            <div className="text-h3 p-md" key={uuid}>
              <Link to={`/trombinoscope/teams/${uuid}`}>{label}</Link>
            </div>
          ))}
      </div>
      <hr />
    </div>
  );
};
