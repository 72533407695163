import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from 'features/Project/api/useProjectContext';
import { ManualModeReason, ConsultationOffer } from 'types';

type CreateConsultationOfferPayload = {
  consultationInfoId: number;
  proUserId: number;
  manualModeReason?: ManualModeReason;
  merData?: object;
  dureeValiditeHeures: number;
  withRdv?: boolean;
  rank?: string;
  proCompanyUuidsNotTested: string[];
};

const handleCreateConsultationOffer = async (
  payload: CreateConsultationOfferPayload,
): Promise<ConsultationOffer> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/consultations/${payload.consultationInfoId}/consultation-offer`;
  return request(requestURL, {
    body: payload,
    method: 'POST',
  });
};

export const useCreateConsultationOffer = (): typeof handleCreateConsultationOffer => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: handleCreateConsultationOffer,
    onSuccess: consultationOffer => {
      if (consultationOffer.projectUuid) {
        queryClient.invalidateQueries({ queryKey: [PROJECT_KEY, consultationOffer.projectUuid] });
      }
      toast.success("Chantier proposé à l'entreprise avec succès.");
    },
  });

  return mutateAsync;
};
