import { UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from 'features/Project/api/useProjectContext';

type Payload = {
  projectUuid: string;
  token: string;
};

export const useArchiveDevis = (): UseMutateAsyncFunction<void, Error, Payload> => {
  const queryClient = useQueryClient();

  const { mutateAsync: archiveDevis } = useMutation({
    mutationFn: ({ projectUuid, token }: Payload) =>
      request(`${APP_CONFIG.apiURL}/admin/projects/${projectUuid}/devis/${token}`, {
        method: 'DELETE',
      }),
    onSuccess: (_, { projectUuid }) => {
      toast.success('Le devis a bien été supprimé');
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY, projectUuid] });
    },
    onError: () => {
      toast.error('Impossible de supprimer le devis');
    },
  });

  return archiveDevis;
};
