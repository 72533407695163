import React, { useState } from 'react';

import dayjs from 'dayjs';
import _capitalize from 'lodash/capitalize';

import InfoCircle from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircle.svg?react';
import { Variant } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';

import { BadgeList } from 'components/BadgeList';
import { ProCompany } from 'types';

import { EditableNote } from './EditableNote';

type Props = {
  proCompany: ProCompany;
};

export const Comment: React.FC<Props> = ({ proCompany }) => {
  const [isNoteOpen, setIsNoteOpen] = useState(false);

  const allBadges: Array<{
    label: string;
    badges: string[];
    level: Variant;
    hideForArchitecte?: boolean;
  }> = [
    {
      badges: proCompany.gammes.map(_capitalize),
      label: 'Gammes',
      level: 'info',
    },
    {
      badges: proCompany.additionalInformations,
      label: 'Infos compl.',
      level: 'error',
      hideForArchitecte: true,
    },
    {
      badges: proCompany.certifications,
      label: 'Certifications',
      level: 'success',
      hideForArchitecte: true,
    },
    {
      badges: proCompany.proProfileFlags,
      label: 'Pro profile options',
      level: 'success',
      hideForArchitecte: true,
    },
  ];
  return (
    <>
      <div className="flex flex-wrap -mx-md !mb-xxs">
        {allBadges
          .filter(item => !item.hideForArchitecte || !proCompany.isArchitecte)
          .map((item, index) => (
            <div key={index} className="md-desktop:w-6/12 md-desktop:px-md relative">
              {item.badges && item.badges.length ? <small>{item.label}</small> : null}
              <BadgeList badges={item.badges} level={item.level} />
            </div>
          ))}
      </div>
      <div className="flex flex-wrap -mx-md">
        <div className="w-1/12 px-md relative">
          <InfoCircle className="w-md h-md" />
        </div>
        <div className="w-11/12 px-md relative">
          {isNoteOpen ? (
            <EditableNote
              closeForm={() => setIsNoteOpen(false)}
              initialValues={proCompany}
              slug={proCompany.slug}
            />
          ) : (
            <div
              role="button"
              tabIndex={0}
              onKeyPress={() => {}}
              className="border-dashed border text-gray-600 hover:text-dark border-gray-300 hover:border-solid !p-sm !mb-sm"
              onClick={() => setIsNoteOpen(true)}
            >
              <MultilineParagraph text={proCompany.internalComment} />
            </div>
          )}
          <p className="!text-right !text-info !italic">
            {' '}
            CA y-1 :{' '}
            {proCompany.chiffresAffaires[dayjs().year() - 1] ? (
              <EURCurrency amount={proCompany.chiffresAffaires[dayjs().year() - 1]} />
            ) : (
              '?'
            )}
            <br />
            Taille chantier :&nbsp;
            {proCompany.tailleChantierMin && proCompany.tailleChantierMax ? (
              <>
                <EURCurrency amount={proCompany.tailleChantierMin} />
                &nbsp;-&nbsp;
                <EURCurrency amount={proCompany.tailleChantierMax} />
              </>
            ) : (
              <span className="!text-danger">NON RENSEIGNÉE</span>
            )}
          </p>
        </div>
      </div>
    </>
  );
};
