import React from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

import { useCheckStatuses } from 'features/Entreprises/api/useCheckStatuses';

export const CheckStatuses: React.FC = () => {
  const checkStatuses = useCheckStatuses();

  return (
    <div className="flex flex-col items-center mt-xl">
      <Button className="mb-sm" onClick={checkStatuses}>
        Vérifier le statut des entreprises labellisées
      </Button>
      <small>(ne pas abuser, ça coûte cher)</small>
    </div>
  );
};
