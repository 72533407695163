import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from './useProjectContext';

const handleTransferDevis = async ({
  projectUuid,
  token,
  newProjectUuid,
}: {
  projectUuid: string;
  newProjectUuid: string;
  token: string;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/projects/${projectUuid}/devis/${token}/transfer`;
  await request(requestURL, {
    method: 'POST',
    body: {
      newProjectUuid,
    },
  });
};

export const useTransferDevis = (): {
  transferDevis: typeof handleTransferDevis;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { isPending, mutateAsync: transferDevis } = useMutation({
    mutationFn: handleTransferDevis,
    onSuccess: (_, { projectUuid, newProjectUuid }) => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY, projectUuid] });
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY, newProjectUuid] });
      toast.success('Le devis a bien été transféré.', {
        to: `/projects/${newProjectUuid}/cadrage`,
        actionLabel: 'Voir le projet',
      });
    },
    onError: () => {
      toast.error("Le devis n'a pas pu être transféré.");
    },
  });
  return { isLoading: isPending, transferDevis };
};
