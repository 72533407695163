import { Action, Subject, Flag } from './types';

import { AbilityBuilder } from '../AbilityBuilder';

const teamLeadMarketingBuilder = new AbilityBuilder<Action, Subject, Flag>();

teamLeadMarketingBuilder.can('manage', 'Lead');
teamLeadMarketingBuilder.can('manageAttributionRules', 'Lead');
teamLeadMarketingBuilder.can('manageAttribution', 'Lead');

export const teamLeadMarketing = teamLeadMarketingBuilder.build();
