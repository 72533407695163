import React from 'react';

import { Form } from 'react-final-form';

import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { TeamsLabel } from '@travauxlib/shared/src/types';
import { chiffreAffairesDeclare, companyStatuses } from '@travauxlib/shared/src/utils/constants';
import {
  required,
  requiredValidEmail,
  requiredValidPhoneNumber,
} from '@travauxlib/shared/src/utils/form';

import { useAuth } from 'api/useAuth';
import { PageTitle, PageTitleEnum } from 'components/PageTitle';
import { Source } from 'features/DashboardCDP/components/Source';
import { useTeamMembers } from 'features/Trombinoscope/api/useTeams';
import { InscriptionsProTracking } from 'utils/tracking/InscriptionProTracking';

import { FormData, useCreateProLead } from './api/useCreateProLead';

export const CreateLead: React.FC = () => {
  const { createProLead } = useCreateProLead();
  const { adminUserProfile } = useAuth();

  const { teamMembers: cdpls } = useTeamMembers([TeamsLabel.CDPL]);

  const cdplOptions = cdpls.map(p => ({
    label: `${p.firstName} ${p.lastName}`,
    value: p.uuid,
  }));

  return (
    <>
      <PageTitle title={`${PageTitleEnum.inscriptionPro} - Nouveau lead`} />
      <Form<FormData>
        onSubmit={async values => {
          InscriptionsProTracking.onInscriptionProCreated({ 'User Role': adminUserProfile.role });
          await createProLead(values);
          return undefined;
        }}
      >
        {({ handleSubmit, submitting, invalid, values }) => (
          <form className="container pb-xl" onSubmit={handleSubmit}>
            <h1 className="mb-lg">Création d&apos;un nouveau lead pro</h1>
            <div className="grid grid-cols-12 gap-md">
              <div className="col-span-full mb-md">
                <InputField
                  label="Numéro de SIRET"
                  id="siret"
                  name="siret"
                  validate={required}
                  helperText={
                    <Link
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.pappers.fr"
                      icon={<HelpCircle />}
                    >
                      Lien vers Pappers
                    </Link>
                  }
                />
              </div>
              <div className="col-span-full sm-desktop:col-span-6 mb-md">
                <DropdownField
                  id="statutEntreprise"
                  name="statutEntreprise"
                  label="Statut (SARL, SAS, Auto-entrepreneur...)"
                  options={companyStatuses}
                  validate={required}
                />
              </div>
              <div className="col-span-full sm-desktop:col-span-6 mb-md">
                <DropdownField
                  id="companyType"
                  name="companyType"
                  label="Activité"
                  options={[
                    { label: 'Architecte', value: 'architecte' },
                    { label: 'BET', value: 'architecte' },
                    { label: 'Pro', value: 'pro' },
                  ]}
                  validate={required}
                />
              </div>
              <div className="col-span-full mb-md">
                <DropdownField
                  id="chiffreAffairesDeclare"
                  name="chiffreAffairesDeclare"
                  label="Chiffre d'affaire"
                  options={chiffreAffairesDeclare}
                  validate={required}
                />
              </div>
              <div className="col-span-full sm-desktop:col-span-6 mb-md">
                <InputField id="firstName" name="firstName" label="Prénom" validate={required} />
              </div>
              <div className="col-span-full sm-desktop:col-span-6 mb-md">
                <InputField id="lastName" name="lastName" label="Nom" validate={required} />
              </div>
              <div className="col-span-full sm-desktop:col-span-6 mb-md">
                <InputField id="email" name="email" label="Email" validate={requiredValidEmail} />
              </div>
              <div className="col-span-full sm-desktop:col-span-6 mb-md">
                <InputField
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Téléphone"
                  validate={requiredValidPhoneNumber}
                />
              </div>
              <div className="col-span-full sm-desktop:col-span-6 mb-md">
                <DropdownField
                  name="isDemandeChantier"
                  id="isDemandeChantier"
                  label="Intérêt du lead"
                  options={[
                    { label: 'Logiciel de devis', value: false },
                    { label: 'Demande de chantier', value: true },
                  ]}
                />
              </div>
              <Source source={values.source} campaign={values.campaign} isForClubProLead />
              {values.source === 'mandataire' && (
                <DropdownField
                  id="recruiterUuid"
                  name="recruiterUuid"
                  label="Recruteur"
                  className="col-span-full mt-md"
                  options={cdplOptions}
                />
              )}
            </div>
            <div className="text-center mt-lg">
              <Button type="submit" disabled={submitting || invalid} loading={submitting}>
                Créer le lead
              </Button>
            </div>
          </form>
        )}
      </Form>
    </>
  );
};
