import React from 'react';

import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';

import { adminUrls } from 'utils/urls';

const tabs = [
  {
    to: adminUrls.searchCompany,
    label: 'Recherche',
  },
  {
    to: adminUrls.searchClubProCompany,
    label: 'Recherche avancée',
  },
  {
    to: adminUrls.checkCompaniesStatus,
    label: 'Statuts',
  },
];

export const ListHeader: React.FC = () => (
  <div className="mb-md">
    <Tabs items={tabs} position="right" />
  </div>
);
