import React from 'react';

import { LotComparison } from '@travauxlib/shared/src/features/DevisDisplay/types';

import { DisplayStat } from './DisplayStat';

type Props = {
  maxLineNumber: number;
  comparisons: LotComparison[];
};
export const DisplaySimilarLines: React.FC<Props> = ({ comparisons, maxLineNumber }) => {
  const numberSimilarLines = comparisons
    .flatMap(lotComparison => lotComparison.lignes)
    .filter(value => value.base && value.compare).length;

  return (
    <div className="sm-desktop:w-1/3 sm-desktop:border-r-1 sm-desktop:ml-md">
      <DisplayStat
        percentage={Math.round((numberSimilarLines / maxLineNumber) * 100)}
        title="Lignes similaires"
        subtitle="Prestations identiques, mais avec une quantité ou un prix unitaire différents"
      />
    </div>
  );
};
