import { MutateOptions, useMutation, useQueryClient } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { UpdateDevisModalitesPayload } from './types';
import { DEVIS_MODALITES_KEY } from './useDevisModalites';

type UpdateDevisModalitesParams = {
  devisToken: string;
  targetKey: string;
  body: UpdateDevisModalitesPayload;
};

const devisModalitesUpdate = (params: UpdateDevisModalitesParams): Promise<any> =>
  request(`${APP_CONFIG.apiURL}/admin/devis/${params.devisToken}/modalites/${params.targetKey}`, {
    method: 'PATCH',
    body: params.body,
  });

export const useDevisModalitesPartialUpdate: () => (
  variables: UpdateDevisModalitesParams,
  options?: MutateOptions<any, any, any, any>,
) => Promise<any> = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: query } = useMutation({
    mutationFn: devisModalitesUpdate,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DEVIS_MODALITES_KEY] });
    },
  });
  return query;
};
