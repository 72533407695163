import { useMemo } from 'react';

import { ProCompany } from 'types';

const minMatchCharLength = 3;

export const useSearchConsultationCompanies = (
  proCompanies: ProCompany[],
  search: string = '',
): ProCompany[] =>
  useMemo(() => {
    if (!search || search.length < minMatchCharLength) {
      return proCompanies;
    }

    const lowerSearch = search.toLowerCase();

    return proCompanies.filter(
      proCompany =>
        proCompany.name.toLowerCase().includes(lowerSearch) ||
        proCompany.email.toLowerCase().includes(lowerSearch),
    );
  }, [proCompanies, search]);
