import React from 'react';

import { v4 as uuidV4 } from 'uuid';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { useFileDropzone } from '@travauxlib/shared/src/components/DesignSystem/components/FileDropzone/hook';
import { DownloadableFileWithTag } from '@travauxlib/shared/src/types';
import { DocumentTag } from '@travauxlib/shared/src/types/api/domain/client/DocumentTag';

import { useAuth } from 'api/useAuth';
import { FicheProjetTracking } from 'utils/tracking/FicheProjetTracking';

import { useRemoveProjectDocument } from '../api/useRemoveProjectDocument';
import { useUploadProjectDocument } from '../api/useUploadProjectDocument';

type Props = {
  projectUuid: string;
  files: DownloadableFileWithTag[];
};

export const UploadDocumentsCard: React.FC<Props> = ({ projectUuid, files }) => {
  const { uploadFiles } = useUploadProjectDocument();
  const { adminUserProfile } = useAuth();
  const { removeFile } = useRemoveProjectDocument();
  const [FileDropzone, onUpdate, onSuccess, onError] = useFileDropzone();

  const startUpload = async (files: File[]): Promise<{ files: File[] }> => {
    const filesWithMetadata = files.map(file => ({
      uuid: uuidV4(),
      name: file.name,
      type: file.type,
      body: file,
    }));

    FicheProjetTracking.onDocumentAdded({
      'Projet UUID': projectUuid,
      'User Role': adminUserProfile.role,
    });

    const result = await uploadFiles({
      projectUuid,
      tag: DocumentTag.Admin,
      files: filesWithMetadata,
      onUpdate,
      onSuccess,
      onError,
    });
    return {
      files: result,
    };
  };

  return (
    <Card className="!rounded-md" state="elevated">
      <div className="text-h4 font-bold">Ajouter des documents</div>
      <div className="text-b1 text-neutral-700 mb-md">
        Déposer ici les documents partagés par le client en dehors de son espace client
      </div>
      <FileDropzone
        multiple
        handleUploadFile={startUpload}
        files={files}
        onRemoveFile={file => removeFile({ projectUuid, fileUuid: file.uuid })}
        trackingDownloadProps={{
          'data-tracking-group': 'upload_documents',
          'data-tracking-item': 'view_uploaded_file',
        }}
      />
    </Card>
  );
};
