import { BaseStep, ExecuteParams } from '../BaseStep';

type Input = { token: string };

export class SetLeadAvailableStep extends BaseStep<Input> {
  public name: string = 'Set Lead Available';

  public fields = [];

  public async execute({ token, apiURL, request }: ExecuteParams<Input>): Promise<void> {
    await request(`${apiURL}/client/lead/auto-assign/${token}`, {
      method: 'POST',
    }).catch(e => {
      this.error(`Auto availability of lead failed because of error : ${e.getMessage()}`);
    });

    this.log('Lead was set available');
  }
}
