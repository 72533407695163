import { Action, Subject, Flag } from './types';

import { AbilityBuilder } from '../AbilityBuilder';

const responsableAcquisitionBuilder = new AbilityBuilder<Action, Subject, Flag>();

responsableAcquisitionBuilder.can('manage', 'Lead');
responsableAcquisitionBuilder.can('manageAttribution', 'Lead');

export const responsableAcquisition = responsableAcquisitionBuilder.build();
