import React from 'react';

import { useGoogleLogin } from '@react-oauth/google';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

type Props = {
  onLoginSuccess: (...args: any[]) => void;
  onLoginFailure: (...args: any[]) => void;
};

export const LoginButton: React.FC<Props> = ({
  onLoginSuccess = () => {},
  onLoginFailure = () => {},
}) => {
  const login = useGoogleLogin({
    onSuccess: onLoginSuccess,
    onError: onLoginFailure,
    hosted_domain: 'hemea.com',
  });

  return <Button onClick={() => login()}>Se connecter</Button>;
};
