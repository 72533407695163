import { LeadAdminView } from '@travauxlib/shared/src/types/api/admin/LeadAdminView';

import { BaseStep, ExecuteParams, StepField } from '../BaseStep';

type Input = { lead?: LeadAdminView };

export class SetLeadAdressable extends BaseStep<
  Input,
  { lead?: LeadAdminView },
  StepField[],
  { lead: LeadAdminView }
> {
  public name: string = 'Set LeadAdminView Adressable';

  public fields = [];

  async execute({
    lead,
    apiURL,
    adminURL,
    request,
  }: ExecuteParams<Input>): Promise<{ lead: LeadAdminView }> {
    if (!lead) {
      super.error('LeadAdminView is undefined. Cannot set adressable !');
      return Promise.reject();
    }

    const newLeadAdminView = { ...lead, status: 'Adressable' };

    super.log('Setting as adressable');

    const leadUpdated = await request<LeadAdminView>(`${apiURL}/admin/leads/${lead.id}/status`, {
      method: 'PATCH',
      body: newLeadAdminView,
    });

    super.log(`Pipedrive deal id: ${leadUpdated.pipedriveDealId}`);
    super.log(`Pipedrive URL : ${leadUpdated.pipedriveDealUrl}`);

    if (leadUpdated.project?.uuid) {
      super.log(`Page projet : ${adminURL}/projects/${leadUpdated.project.uuid}`);
    }

    super.setCreatedEntities({
      lead: leadUpdated,
    });

    return { lead: leadUpdated };
  }
}
