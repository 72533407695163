import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { ChantierAdminListItemView } from '@travauxlib/shared/src/types/api/admin/ChantierAdminListItemView';
import { ConsultationInfoView } from '@travauxlib/shared/src/types/api/admin/ConsultationInfoView';

import { BaseStep, ExecuteParams, StepField } from '../BaseStep';

type Input = { consultationInfo: ConsultationInfoView };

export class FindChantierFromPipedriveDealId extends BaseStep<
  Input,
  { chantier: ChantierAdminListItemView; detailledChantier: Chantier },
  StepField[],
  { chantier: ChantierAdminListItemView; detailledChantier: Chantier }
> {
  public name = 'Find Chantier from pipezgeg';

  public fields = [];

  async execute({ apiURL, adminURL, consultationInfo, request }: ExecuteParams<Input>): Promise<{
    chantier: ChantierAdminListItemView;
    detailledChantier: Chantier;
  }> {
    const allChantiers = await request<{ data: ChantierAdminListItemView[] }>(
      `${apiURL}/admin/chantiers`,
    ).then(allChantiers => allChantiers.data);

    const chantier = allChantiers.find(
      c => c.pipedriveDealId.toString(10) === consultationInfo.pipedriveDealId,
    );

    if (!chantier) {
      super.error('No chantier found !');
      return Promise.reject();
    }

    super.log(`Chantier trouvé : ${adminURL}/chantiers/${chantier.slug}`);

    const detailledChantier = await request<Chantier>(`${apiURL}/admin/chantiers/${chantier.slug}`);

    super.setCreatedEntities({ chantier: chantier, detailledChantier: detailledChantier });

    return { chantier, detailledChantier };
  }
}
