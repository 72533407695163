import React from 'react';

import { ProRegistration } from '@travauxlib/shared/src/types';

import { AddressableReasonsSection } from './AddressableReasonsSection';
import { DemandeChantierResults } from './DemandeChantierResults';
import { CompanyInfoSection } from './InfoSection';
import { LostReasonsSection } from './LostReasonsSection';

import { useUpdateProRegistration } from '../api/useUpdateProRegistration';

type Props = {
  proRegistration: ProRegistration;
};

export const ProRegistationComponent: React.FC<Props> = ({ proRegistration }) => {
  const { acquisitionLabel, deviceFirstPageView, lostReason, metadata, status, isDemandeChantier } =
    proRegistration;
  const { updateProRegistration, isUpdating } = useUpdateProRegistration();

  return (
    <tr data-testid="proRegistration">
      <td className="min-w-[10rem] max-w-[30rem]">
        <CompanyInfoSection proRegistration={proRegistration} />
      </td>
      <td className="min-w-[10rem] max-w-[30rem]">
        {status}
        <br />
        {lostReason}
        <br />
        <span className="break-words">
          {acquisitionLabel && <div>{acquisitionLabel}</div>}
          {metadata.conversionButton && <div>Besoin : {metadata.conversionButton}</div>}
          Première page : {deviceFirstPageView}
        </span>
        <br />
        {isDemandeChantier && <DemandeChantierResults {...proRegistration} />}
      </td>
      <td className="min-w-[10rem] max-w-[30rem]">
        <AddressableReasonsSection
          proRegistration={proRegistration}
          updateProRegistration={updateProRegistration}
          isUpdating={isUpdating}
        />
      </td>
      <td className="min-w-[10rem] max-w-[30rem]">
        <LostReasonsSection
          proRegistration={proRegistration}
          updateProRegistration={updateProRegistration}
          lostReason={lostReason}
          isUpdating={isUpdating}
        />
      </td>
    </tr>
  );
};
