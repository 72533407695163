import { Action, Subject, Flag } from './types';

import { AbilityBuilder } from '../AbilityBuilder';

const teamLeadPartenariatBuilder = new AbilityBuilder<Action, Subject, Flag>();

teamLeadPartenariatBuilder.can('manage', 'Lead');
teamLeadPartenariatBuilder.can('manageAttribution', 'Lead');
teamLeadPartenariatBuilder.can('manage', 'Facturation');

export const teamLeadPartenariat = teamLeadPartenariatBuilder.build();
