import React from 'react';

import dayjs from 'dayjs';
import _orderBy from 'lodash/orderBy';

import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Pagination } from '@travauxlib/shared/src/components/DesignSystem/components/Pagination';
import { ItemLink } from '@travauxlib/shared/src/components/ItemLink';
import { Chantier, ProFacture } from '@travauxlib/shared/src/features/Chantiers/types';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { usePagination } from '@travauxlib/shared/src/hooks/usePagination';
import {
  FactureItem,
  getChantierFacturesItems,
} from '@travauxlib/shared/src/utils/getChantierFacturesItems';
import { toCalendarFormat } from '@travauxlib/shared/src/utils/time';

type Props = {
  chantier: Chantier;
};

type PaginationItem = FactureItem | ProFacture;

function isProFacture(item: PaginationItem): item is ProFacture {
  return 'token' in item;
}

export const ChantierFactures: React.FC<Props> = ({ chantier }) => {
  const facturesItems = getChantierFacturesItems(chantier);
  const isTabletOrMobile = useIsTabletOrMobile();

  const { proFactures } = chantier;

  const orderedFactures = _orderBy([...facturesItems, ...proFactures], f => f.date);

  const pagination = usePagination<PaginationItem>(orderedFactures, 4, false);

  return (
    <Card
      state="outlined"
      className="min-h-[19.875rem] flex sm-desktop:w-1/2"
      bodyClassNames="grow flex flex-col justify-between"
    >
      <div>
        <div className="mb-md">
          <span className="text-h5 font-bold">Factures</span>
        </div>
        <div>
          {pagination.items.map(item => {
            if (isProFacture(item)) {
              return (
                <ItemLink
                  key={item.token}
                  title={item.title}
                  {...(item.paidAt && {
                    tag: {
                      label: 'Payée',
                      variant: 'success',
                    },
                  })}
                  label={
                    item.paidAt
                      ? `Acquittée ${toCalendarFormat(dayjs(item.paidAt))}`
                      : `Envoyée ${toCalendarFormat(dayjs(item.date))}`
                  }
                  price={item.prixTotalTTC}
                  href={`${APP_CONFIG.appURL}/factures/${item.token}`}
                  target="_blank"
                  rightIcon={isTabletOrMobile ? undefined : <ChevronRight />}
                />
              );
            } else {
              return (
                <ItemLink
                  key={item.axonautInvoiceId}
                  title={item.name}
                  label={`Acquittée ${toCalendarFormat(dayjs(item.date))}`}
                  //TODO: Uncomment this once we figure out to get the facture paid status
                  // tag={{
                  //   label: 'Payée',
                  //   variant: 'success',
                  // }}
                  price={item.montant}
                  href={`${APP_CONFIG.apiURL}/admin/accounting/invoices/${item.axonautInvoiceId}`}
                  target="_blank"
                  rightIcon={isTabletOrMobile ? undefined : <ChevronRight />}
                />
              );
            }
          })}
        </div>
      </div>
      <div className="flex justify-center">
        <Pagination showTotalRecords={false} {...pagination} />
      </div>
    </Card>
  );
};
