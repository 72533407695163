import { ProRegistrationAdminView } from '@travauxlib/shared/src/types/api/admin/ProRegistrationAdminView';

import { BaseStep, ExecuteParams } from '../BaseStep';

type Input = { proLead: ProRegistrationAdminView; proCompanyEmail?: string };

export class SearchProRegistration extends BaseStep<
  Input,
  { proRegistration: ProRegistrationAdminView }
> {
  public name: string = 'Search Pro Registration';

  public fields = [];

  async execute(
    params: ExecuteParams<Input>,
  ): Promise<{ proRegistration: ProRegistrationAdminView } | undefined> {
    const { proLead, proCompanyEmail, apiURL, request } = params;
    if (proCompanyEmail) {
      super.log(`Skipped! proCompanyEmail chosen`);
      return;
    }
    const allProRegistrations = await request<{ data: ProRegistrationAdminView[] }>(
      `${apiURL}/admin/pro-registrations`,
    );

    const targetProRegistration = allProRegistrations.data.find(
      (proRegistration: ProRegistrationAdminView) => proRegistration.email === proLead.email,
    );

    if (!targetProRegistration) {
      super.error(`No pro registration found for pro email ${proLead.email}`);
      return;
    }

    super.log(
      `Found pro registration : ${targetProRegistration.email} (${targetProRegistration.uuid} - ${targetProRegistration.companyName})`,
    );

    return { ...params, proRegistration: targetProRegistration };
  }
}
