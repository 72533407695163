import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { Lead } from 'types';

export const LEAD_CLIENTS_KEY = 'leads-clients';
const fetchLeadClients = ({
  queryKey: [, criteria],
}: QueryFunctionContext<[string, object]>): Promise<{ data: Lead[] }> => {
  const url = `${APP_CONFIG.apiURL}/admin/leads/client-search`;
  return request(url, { params: criteria, method: 'GET' });
};

export const useLeadClients = (criteria: object): { leads: Lead[]; isLoading: boolean } => {
  const { data, isLoading } = useQuery<{ data: Lead[] }>({
    queryKey: [LEAD_CLIENTS_KEY, criteria],
    queryFn: fetchLeadClients,
  });

  return { leads: data?.data || [], isLoading };
};
