import { Action, Subject, Flag } from './types';

import { AbilityBuilder } from '../AbilityBuilder';

const teamLeadCDPBuilder = new AbilityBuilder<Action, Subject, Flag>();

teamLeadCDPBuilder.cannot('manage', 'Company');
teamLeadCDPBuilder.can('manage', 'Company', ['isEmailVerified']);

teamLeadCDPBuilder.can('cancelAppelDePaiement', 'Chantier');
teamLeadCDPBuilder.can('cancelSignature', 'Chantier');
teamLeadCDPBuilder.can('changeAmountAppelDePaiement', 'Chantier');
teamLeadCDPBuilder.can('changeCommission', 'Chantier');
teamLeadCDPBuilder.can('toggleRelance', 'Chantier');
teamLeadCDPBuilder.can('validateAppelDePaiement', 'Chantier');

teamLeadCDPBuilder.can('manage', 'Lead');
teamLeadCDPBuilder.can('manageAttributionRules', 'Lead');
teamLeadCDPBuilder.can('manageAttribution', 'Lead');

export const teamLeadCDP = teamLeadCDPBuilder.build();
