import { useQuery } from '@tanstack/react-query';

import { ChantierListItem } from '@travauxlib/shared/src/features/Chantiers/types';
import { request } from '@travauxlib/shared/src/utils/request';

type RequestResult = {
  data: ChantierListItem[];
};

const fetchChantiers = (): Promise<RequestResult> =>
  request(`${APP_CONFIG.apiURL}/admin/chantiers`);

export const useChantiers = (): {
  isLoading: boolean;
  chantiers: ChantierListItem[];
} => {
  const { isLoading, data } = useQuery<RequestResult>({
    queryKey: ['chantiers'],
    queryFn: fetchChantiers,
  });
  return { isLoading, chantiers: data?.data || [] };
};
