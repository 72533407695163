import React from 'react';

import { Form } from 'react-final-form';

import { ButtonGroupField } from '@travauxlib/shared/src/components/DesignSystem/components/ButtonGroup/Field';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { DropdownMultiField } from '@travauxlib/shared/src/components/DesignSystem/components/DropdownMulti/Field';
import { FormLabel } from '@travauxlib/shared/src/components/FormLabel';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { LeadAssignmentConfig } from '@travauxlib/shared/src/types';
import { departmentsWithShortcuts } from '@travauxlib/shared/src/utils/departments';

import { departmentsNormalizer } from 'utils/departmentsNormalizer';

type Props = {
  config: LeadAssignmentConfig;
  displayName?: string;
  picture?: string;
  onSubmit: (config: LeadAssignmentConfig) => void;
};

const availabilitiesOptions = [
  {
    label: 'DISPO',
    value: true,
  },
  {
    label: 'PAS DISPO',
    value: false,
  },
];

const budgetOptions = [
  {
    label: '< 10 k€',
    value: '0-10000',
  },
  {
    label: '10 k€ - 30 k€',
    value: '10000-30000',
  },
  {
    label: '30 k€ - 50k€',
    value: '30000-50000',
  },
  {
    label: '50 k€ - 100 k€',
    value: '50000-100000',
  },
  {
    label: '100 k€ - 250 k€',
    value: '100000-250000',
  },
  {
    label: '> 250 k€',
    value: '+250000',
  },
];

export const EditAssignmentConfigForm: React.FC<Props> = props => {
  const { config, displayName, picture, onSubmit } = props;

  return (
    <Form onSubmit={onSubmit} initialValues={config}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <h4 className="mb-lg">
            <ImgLazy
              alt="main"
              url={picture || 'https://picsum.photos/300/300'}
              width={60}
              height={60}
              imgixParams={{
                fit: 'crop',
                crop: 'top',
              }}
            />{' '}
            {displayName}
          </h4>
          <div className="mb-sm">
            <FormLabel label="Disponibilité" />
            <ButtonGroupField name="availability" options={availabilitiesOptions} />
          </div>
          <div className="mb-sm">
            <DropdownMultiField
              id="departments"
              name="departments"
              label="Départements"
              options={departmentsWithShortcuts}
              parse={departments => departmentsNormalizer(departments, departmentsWithShortcuts)}
            />
          </div>
          <div className="mb-sm">
            <DropdownMultiField
              id="budgets"
              name="budgets"
              options={budgetOptions}
              label="Budgets"
            />
          </div>
          <div className="mb-sm">
            <DropdownMultiField
              id="cityManagerOf"
              name="cityManagerOf"
              label="City Manager des départements suivants"
              options={departmentsWithShortcuts}
              parse={departments => departmentsNormalizer(departments, departmentsWithShortcuts)}
            />
          </div>
          <CheckboxField
            name="acceptHemea"
            id={`acceptHemea${displayName}`}
            className="!block !mb-md"
            label={
              <>
                Leads <strong>hemea</strong>
              </>
            }
          />
          <CheckboxField
            name="acceptPaid"
            id={`acceptPaid${displayName}`}
            className="!block !mb-md"
            label={
              <>
                Leads <strong>payants</strong>
              </>
            }
          />
          <CheckboxField
            name="acceptBarnes"
            id={`acceptBarnes${displayName}`}
            className="!block !mb-md"
            label={
              <>
                Leads <strong>Barnes</strong>
              </>
            }
          />
          <CheckboxField
            name="acceptPartner"
            id={`acceptPartner${displayName}`}
            className="!block !mb-md"
            label={
              <>
                Leads <strong>partenaires</strong>
              </>
            }
          />
          <div className="!mt-xs">
            <Button type="submit" disabled={submitting} loading={submitting} size="sm">
              Valider
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
