import React from 'react';

import CrossCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircleOutline.svg?react';
import EditCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/EditCircleOutline.svg?react';
import PlusCircle from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircleOutline.svg?react';
import { ComparisonStatus } from '@travauxlib/shared/src/types';

export const getLineIcon = (comparisonStatus: ComparisonStatus): JSX.Element | null => {
  switch (comparisonStatus) {
    case 'added':
      return (
        <span className="text-success-800 max-h-md flex">
          <PlusCircle />
        </span>
      );
    case 'missing':
      return (
        <span className="text-error-800 max-h-md flex">
          <CrossCircle />
        </span>
      );
    case 'modified':
      return (
        <span className="text-info-800 max-h-md flex">
          <EditCircleOutline />
        </span>
      );
    default:
      return null;
  }
};
