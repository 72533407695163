import { useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

export type OriginType = 'batiprix';

const handleUploadBaseDePrix = ({
  slug,
  file,
  archiveExisting,
  origin,
}: {
  slug: string;
  file: File;
  archiveExisting?: boolean;
  origin?: OriginType;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/base-de-prix`;
  const formData = new FormData();
  formData.append('file', file);
  return request(requestURL, {
    method: 'POST',
    body: formData,
    params: { archiveExisting, origin },
  });
};

export const useUploadBaseDePrix = (): {
  uploadBaseDePrix: typeof handleUploadBaseDePrix;
  isLoading: boolean;
} => {
  const { mutateAsync: uploadBaseDePrix, isPending } = useMutation({
    mutationFn: handleUploadBaseDePrix,
    onSuccess: () => {
      toast.success('La base de prix a bien été importée');
    },
    onError: () => {
      toast.error("Erreur lors de l'upload");
    },
  });

  return {
    uploadBaseDePrix,
    isLoading: isPending,
  };
};
