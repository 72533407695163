import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

import { ClientAccount } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';
import { stringifyParams } from '@travauxlib/shared/src/utils/urls';

export const CLIENT_ACCOUNTS_KEY = 'client-accounts';

type RequestResult = {
  data: ClientAccount[];
};

export type UrlFilters = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

const fetchClientAccounts = ({
  queryKey: [, filters],
}: QueryFunctionContext<[string, UrlFilters]>): Promise<{ data: ClientAccount[] }> =>
  request(
    `${APP_CONFIG.apiURL}/admin/clients/search?searchType=contains&${stringifyParams(filters)}`,
  );

export const useClientAccounts = (
  filters: UrlFilters,
): { isLoading: boolean; clientAccounts: ClientAccount[] } => {
  const { isLoading, data } = useQuery<RequestResult>({
    queryKey: [CLIENT_ACCOUNTS_KEY, filters],
    queryFn: fetchClientAccounts,
  });
  return { isLoading, clientAccounts: data?.data || [] };
};
