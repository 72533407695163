import React from 'react';

import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { contractantGeneral } from '@travauxlib/shared/src/utils/constants';
import { extractDepartmentFromGoogleMapsAddress } from '@travauxlib/shared/src/utils/form';

import { ConsultationInfo, ProCompany } from 'types';

export const extractConsultationWarnings = ({
  consultationInfo,
  proCompany,
}: {
  consultationInfo: ConsultationInfo;
  proCompany: ProCompany;
}): { title: string; errors: string[] }[] => {
  if (proCompany.isArchitecte) {
    return [];
  } // no check for architectes yet

  const corpsEtatErrors = (consultationInfo.insuredFor || []).filter(
    corpsEtat =>
      !proCompany.corpsEtat.includes(contractantGeneral) &&
      !proCompany.corpsEtat.includes(corpsEtat),
  );
  const certificationErrors = (consultationInfo.certifications || []).filter(
    certification => !proCompany.certifications.includes(certification),
  );
  const typeTravauxErrors = (consultationInfo.typesTravaux || []).filter(
    typeTravaux =>
      proCompany.specializations.length !== 0 && !proCompany.specializations.includes(typeTravaux),
  );
  const gammeErrors = (consultationInfo.gamme ? [consultationInfo.gamme] : []).filter(
    gamme => proCompany.gammes.length !== 0 && !proCompany.gammes.includes(gamme),
  );
  const tailleChantierErrors =
    (proCompany.tailleChantierMax || 0) > 0 &&
    consultationInfo.value > (proCompany.tailleChantierMax || 0)
      ? [`Maximum non respecté (${proCompany.tailleChantierMax}€)`]
      : [];
  const consultationInfoDepartment = extractDepartmentFromGoogleMapsAddress(
    consultationInfo.googleMapsAddress,
  );
  const departmentErrors =
    consultationInfoDepartment && !proCompany.departements.includes(consultationInfoDepartment)
      ? [consultationInfoDepartment]
      : [];
  const autonomeErrors =
    consultationInfo.includeAutonomeCommercialementOnly && !proCompany.autonomeCommercialement
      ? ['Requis']
      : [];

  const allWarnings = [
    {
      title: "Corps d'état",
      errors: corpsEtatErrors,
    },
    {
      title: 'Certifications',
      errors: certificationErrors,
    },
    {
      title: 'Types travaux',
      errors: typeTravauxErrors,
    },
    {
      title: 'Gamme',
      errors: gammeErrors,
    },
    {
      title: 'Taille chantier',
      errors: tailleChantierErrors,
    },
    {
      title: 'Département',
      errors: departmentErrors,
    },
    {
      title: 'Autonome commercialement',
      errors: autonomeErrors,
    },
  ].filter(v => v.errors.length > 0);

  return allWarnings;
};

type Props = {
  consultationInfo: ConsultationInfo;
  proCompany: ProCompany;
};

const ConsultationWarningsList: React.FC<{ warnings: { title: string; errors: string[] }[] }> = ({
  warnings,
}) => (
  <div className="relative px-lg py-sm rounded bg-error-100 text-error !mb-0">
    <h4 className="!text-center">❌ Critères non respectés ❌</h4>
    {warnings.map(warning => (
      <li key={warning.title}>
        {warning.title} : {warning.errors.join(', ')}
      </li>
    ))}
  </div>
);

export const ConsultationWarnings: React.FC<Props> = ({ consultationInfo, proCompany }) => {
  const warnings = extractConsultationWarnings({ consultationInfo, proCompany });

  if (warnings.length === 0) {
    return null;
  }

  return <ConsultationWarningsList warnings={warnings} />;
};

export const ConsultationWarningsTooltip: React.FC<Props> = ({ consultationInfo, proCompany }) => {
  const warnings = extractConsultationWarnings({ consultationInfo, proCompany });

  if (warnings.length === 0) {
    return null;
  }

  return (
    <WithTooltip
      trigger={<div className="!text-center">❌ Critères consultation non respectés ❌</div>}
      position="left"
      width="22rem"
    >
      <ConsultationWarningsList warnings={warnings} />
    </WithTooltip>
  );
};
