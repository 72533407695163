import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { CheckboxGrouped as RawCheckboxGrouped, Props as CheckboxGroupedProps, Group } from '.';

const CheckboxGroupedWrapper: React.FC<
  FieldRenderProps<string[]> & {
    options: Group[];
  } & { label: string }
> = ({ input: { value, onChange }, options, label }) => (
  <RawCheckboxGrouped value={value} onChange={onChange} label={label} options={options} />
);
type Props = {
  name: string;
} & Omit<CheckboxGroupedProps, 'value' | 'onChange'> &
  UseFieldConfig<string[]>;

export const CheckboxGroupedField: React.FC<Props> = props => (
  <Field component={CheckboxGroupedWrapper} {...props} />
);
