import React from 'react';

import { Navigate } from 'react-router-dom';

import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';

import { AccountItem } from 'features/Partners/components/AccountItem';
import { usePartner } from 'features/Partners/hooks/usePartner';

import { PartnerDetailsContent } from './PartnerDetailsModal/PartnerDetailsContent';

export const AccountListing: React.FC = () => {
  const partner = usePartner();

  if (!partner) {
    return <Navigate to=".." />;
  }

  const accounts = partner.agencies.flatMap(agency => agency.accounts.map(account => account));
  const hasAccounts = accounts.length > 0;

  return (
    <PartnerDetailsContent>
      {hasAccounts ? (
        <div className="grid grid-cols-12 gap-md">
          {accounts.map(account => (
            <AccountItem
              key={account.email}
              account={account}
              allAgencies={partner.agencies}
              partner={partner}
              agency={partner.agencies[0]}
            />
          ))}
        </div>
      ) : (
        <EmptyState
          title="Aucun compte rattaché à ce partenaire"
          description="Pour ajouter un compte, veuillez d'abord sélectionner l'agence à laquelle le rattacher."
        />
      )}
    </PartnerDetailsContent>
  );
};
