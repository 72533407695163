import React from 'react';

import { Form } from 'react-final-form';
import { useOutletContext } from 'react-router-dom';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { CharteStatus } from '@travauxlib/shared/src/types/company';

import { StickySubmitButton } from 'components/StickySubmitButton';
import { useUpdateProCompany } from 'features/Entreprises/features/Entreprise/api/useUpdateProCompany';
import { ProCompany } from 'types';

import { FieldList } from './FieldList';

export const Settings: React.FC = () => {
  const { proCompany } = useOutletContext<{ proCompany: ProCompany }>();
  const { companyType, slug, status } = proCompany;
  const { update } = useUpdateProCompany();

  return (
    <Card>
      <Form<Pick<ProCompany, 'charteStatus' | 'archiType' | 'status' | 'isExclusive'>>
        initialValues={proCompany}
        onSubmit={async values => {
          const updateValues = {
            ...values,
            charteStatus: values.charteStatus ? CharteStatus.skipped : null,
          };
          await update({ settings: updateValues, slug });
        }}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <FieldList
              charteStatus={proCompany.charteStatus}
              companyType={companyType}
              status={status}
              chiffresAffaires={proCompany.chiffresAffaires}
              archiType={values.archiType}
              isExclusive={values.isExclusive}
            />
            <StickySubmitButton />
          </form>
        )}
      </Form>
    </Card>
  );
};
