import _uniqBy from 'lodash/uniqBy';

import { Person, TeamsLabel } from '@travauxlib/shared/src/types';

import { useTeamMembers } from 'features/Trombinoscope/api/useTeams';

type Response = {
  allMembers: { value: string; label: string }[];
  ccMembers: { value: string; label: string }[];
};

const formatPersons = (persons: Person[]): Array<{ label: string; value: string }> =>
  persons.map(person => ({
    label: `${person.firstName} ${person.lastName}`,
    value: person.uuid,
  }));

export const useSuiviByOptions = (): Response => {
  const { teamMembers: tpMembers } = useTeamMembers([TeamsLabel.TravauxPlanner]);
  const { teamMembers: ccMembers } = useTeamMembers([TeamsLabel.CustomerCare]);
  const { teamMembers: cdplMembers } = useTeamMembers([TeamsLabel.CDPL]);

  return {
    allMembers: _uniqBy(formatPersons([...ccMembers, ...tpMembers, ...cdplMembers]), 'value'),
    ccMembers: formatPersons(ccMembers),
  };
};
