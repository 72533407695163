import React, { useState } from 'react';

import { List } from './components/List';
import { Search, SearchPayload } from './components/Search';

export const ClubPro: React.FC = () => {
  const [search, setSearch] = useState<SearchPayload>({});

  return (
    <>
      <Search onSearchSubmit={setSearch} />
      <List search={search} />
    </>
  );
};
