import React from 'react';

import CrossCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircle.svg?react';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';

import { useChantierAdvancedActionAuthorized } from './api/useChantierAdvancedActionAuthorized';

type Props = { children: JSX.Element };

export const AuthorizedGuard: React.FC<Props> = ({ children }) => {
  const { isAuthorized, isLoading } = useChantierAdvancedActionAuthorized();

  if (isLoading) {
    return <LoaderWrapper />;
  }

  return isAuthorized ? (
    children
  ) : (
    <div className="px-lg py-sm rounded bg-error-50 text-center flex items-center gap-xs">
      <CrossCircle className="w-md h-md" />
      Accès interdit
      <CrossCircle className="w-md h-md" />
    </div>
  );
};
