import React from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate, useParams } from 'react-router-dom';

import LeftChevron from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeft.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';
import { usePerson } from 'features/Trombinoscope/api/usePersons';

import { HeaderCard } from './HeaderCard';
import { InformationCard } from './InformationCard';
import { PermissionsCard } from './PermissionsCard';

dayjs.extend(relativeTime);

export const Person: React.FC = () => {
  const { personUuid } = useParams();
  const { person, isLoading } = usePerson(personUuid!);
  const navigate = useNavigate();

  if (isLoading || !person) {
    return <LoaderWrapper />;
  }

  const bonusPicture = person.bonusPicture || 'https://picsum.photos/600/600';

  return (
    <>
      <PageTitle
        title={`${PageTitleEnum.trombinoscope} - ${person.firstName} ${person.lastName}`}
      />
      <div className="container mb-xl max-w-[1300px] p-0">
        <Button
          className="mb-md py-0"
          variant="tertiary"
          onClick={() => navigate(-1)}
          leftIcon={<LeftChevron />}
        >
          Retour
        </Button>
        <HeaderCard person={person} />
        <div className="grid grid-cols-12 gap-md mt-md">
          <div className="col-span-full md-desktop:col-span-4">
            <PermissionsCard person={person} />
          </div>
          <div className="col-span-full md-desktop:col-span-4">
            <InformationCard person={person} />
          </div>
          <div className="col-span-full md-desktop:col-span-4">
            <Card className="h-full" bodyClassNames="h-full flex flex-col justify-between">
              <div className="text-h4">Photo bonus</div>
              <ImgLazy
                width={350}
                height={350}
                alt="bonus"
                url={bonusPicture}
                imgixParams={{
                  fit: 'crop',
                  crop: 'top',
                }}
              />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
