import React from 'react';

import { AuthenticatedRoutes } from './authentified';
import { UnauthenticatedRoutes } from './unauthentified';

type Props = {
  adminEmail?: string;
};

export const AdminRoutes: React.FC<Props> = ({ adminEmail }) =>
  adminEmail ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />;
