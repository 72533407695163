import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

export const useCheckStatuses = () => async () => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/check-statuses-pro-companies`;
  try {
    await request(requestURL);
    toast.success('Le check des statuts a bien été lancé');
  } catch {
    toast.error('Erreur lors du lancement');
  }
};
