import { useQueryClient, useMutation } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from './useProjectContext';

const handleLinkClientAccount = async (payload: {
  projectUuid: string;
  clientAccountUuid: string;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/projects/${payload.projectUuid}/client-account/${payload.clientAccountUuid}/link`;

  await request(requestURL, { method: 'POST' });
};

export const useLinkClientAccount = (): typeof handleLinkClientAccount => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: handleLinkClientAccount,
    onSuccess: (_, { projectUuid }) => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY, projectUuid] });
    },
  });

  return mutateAsync;
};

const handleUnlinkClientAccount = async (payload: {
  projectUuid: string;
  clientAccountUuid: string;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/projects/${payload.projectUuid}/client-account/${payload.clientAccountUuid}/unlink`;

  await request(requestURL, { method: 'POST' });
};

export const useUnlinkClientAccount = (): typeof handleUnlinkClientAccount => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: handleUnlinkClientAccount,
    onSuccess: (_, { projectUuid }: { projectUuid: string }) => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY, projectUuid] });
    },
  });

  return mutateAsync;
};
