import React, { useEffect, useRef } from 'react';

import classNames from 'classnames';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { FloatingContent } from '@travauxlib/shared/src/features/DevisDisplay/components/Comments/FloatingContent';
import { useDevisDisplayContext } from '@travauxlib/shared/src/features/DevisDisplay/components/DevisDisplayContext';
import { useRevisionStore } from '@travauxlib/shared/src/features/DevisDisplay/hooks/useRevisionStore';
import { isDeletedThread } from '@travauxlib/shared/src/features/DevisDisplay/utils/isDeletedThread';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { Ligne as LigneType, LigneStatus } from '@travauxlib/shared/src/types';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';
import { units } from '@travauxlib/shared/src/utils/units';

import { Fournitures } from './Fournitures';
import { LeftIndicator } from './LeftIndicator';
import { LigneStatusIndicator } from './LigneStatusIndicator';
import { Location } from './Location';
import { Prices } from './Prices';
import { getAllLocationsToDisplay } from './utils/getAllLocationsToDisplay';

export type Props = {
  lotIndex: number;
  itemIndex: number;
  ligne: LigneType;
  lotFacturationPercentage?: number;
};

export const Ligne: React.FC<Props> = ({
  ligne,
  lotIndex,
  itemIndex,
  lotFacturationPercentage,
}) => {
  const {
    customization,
    showFournitures,
    hidePrices,
    devisLocations,
    isRGE,
    comments,
    isReviewed,
    isAuthor,
    lots,
  } = useDevisDisplayContext();

  const isTabletOrMobile = useIsTabletOrMobile();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const unit = units[ligne.unite];
  const uniteLabel = `/${unit.unitaire ? 'u' : unit.label}`;
  const locations = getAllLocationsToDisplay(ligne, devisLocations);

  const shouldCrossOut =
    ligne.comparisonStatus === 'missing' ||
    (ligne.status && [LigneStatus.Canceled, LigneStatus.Modified].includes(ligne.status));

  const commentsList = comments?.[ligne.threadUuid!] || [];
  const hasComments = !!commentsList.length;
  const { isRevising, openThread, activeThread } = useRevisionStore();

  const fournitureLignes = ligne.fournitureLignes || [];
  const hasChangedDesignation = ligne.compare && ligne.designation !== ligne.compare.designation;

  const isActive =
    activeThread?.ligneUuid === ligne.uuid ||
    (ligne.threadUuid && activeThread?.threadUuid === ligne.threadUuid);

  useEffect(() => {
    let timeoutId: number | undefined;

    if (isActive && isTabletOrMobile) {
      const target = containerRef.current;

      timeoutId = window.setTimeout(() => {
        target?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 100);
    }

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isActive]);

  const isValidated = commentsList[0] && commentsList[0].validated;
  const isDeleted = isDeletedThread(lots, ligne.threadUuid);
  const toReview =
    hasComments &&
    commentsList[commentsList.length - 1].senderType === 'pro' &&
    !commentsList[commentsList.length - 1].validated &&
    !isDeleted;

  const notSentYet = hasComments && !commentsList[commentsList.length - 1].sent;

  return (
    <>
      <div
        ref={containerRef}
        role="button"
        data-testid="ligne"
        onClick={() => {
          if (isAuthor || ligne.comparisonStatus === 'missing' || !comments) {
            return;
          }
          if (hasComments || !isReviewed) {
            openThread({
              ligneUuid: ligne.uuid,
              threadUuid: ligne.threadUuid,
            });
          }
        }}
        id={itemIndex === 0 && lotIndex === 0 ? 'ligne-devis' : `ligne-devis-${lotIndex}`}
        className={classNames(
          'p-xs mb-xs relative flex break-inside-avoid rounded-xxs ligne-devis',
          // this is used to be make sure the hover will properly work with add comment icon
          'before:-right-xl before:top-0 before:bottom-0 before:block before:w-xl sm-desktop:before:content-[""] before:absolute',
          {
            'line-through': shouldCrossOut,
            'hover:bg-neutral-100 [&:hover_.add-comment-icon]:flex cursor-pointer':
              comments && !isReviewed && !isAuthor && ligne.comparisonStatus !== 'missing',
            'border-2 border-dashed border-info-800':
              hasComments && isRevising && !isDeleted && (isActive || !isValidated),
            'border-neutral-600':
              notSentYet && isRevising && !isDeleted && (isActive || !isValidated),
            'border-2 border-dashed !border-success-800':
              hasComments && isRevising && isValidated && isActive,
            'bg-neutral-100 [&_.add-comment-icon]:flex': isActive,
            'border-2 border-dashed !border-warning-800': isRevising && toReview,
            '!bg-error-50': ligne.comparisonStatus === 'missing',
            '!bg-success-50': ligne.comparisonStatus === 'added',
            '!bg-info-50': ligne.comparisonStatus === 'modified',
          },
        )}
      >
        <FloatingContent targetUuid={ligne.uuid} threadUuid={ligne.threadUuid} />
        <LeftIndicator ligne={ligne} lotIndex={lotIndex} itemIndex={itemIndex} />
        <div className="grow">
          <div className="mb-xxs flex items-center">
            <div className="grow">
              {ligne.compare && hasChangedDesignation && (
                <div className="text-sm line-through text-neutral-800">
                  {ligne.compare?.designation}
                </div>
              )}
              <div
                className={classNames(
                  'grow mr-xxs',
                  customization?.bodyFontStyle,
                  hasChangedDesignation && 'font-bold text-info-800',
                )}
              >
                {ligne.designation}
              </div>
            </div>
            <LigneStatusIndicator ligne={ligne} />
          </div>
          <div className="flex">
            {lotFacturationPercentage !== undefined && (
              <div className="text-neutral-600 text-sm mb-xs">
                Avancement : {lotFacturationPercentage} %
              </div>
            )}
          </div>
          <Prices ligne={ligne} hidePrices={hidePrices} />
          <div className="flex">
            <div className="sm-desktop:ml-1/2 sm-desktop:w-1/4 text-center">
              {showFournitures && ligne.prixPublicFournitureHT !== 0 && (
                <div className="text-b2 text-neutral-600 mb-xs">
                  Dont fourniture&nbsp;: <EURCurrency amount={ligne.prixPublicFournitureHT} />
                  {uniteLabel}
                </div>
              )}
              {isRGE && ligne.prixMainOeuvreHT !== undefined && (
                <div className="text-b2 text-neutral-600 mb-xs">
                  Dont main d'oeuvre :&nbsp;
                  <EURCurrency
                    amount={roundToTwoDecimals(
                      ligne.prixMainOeuvreHT + (ligne.prixMainOeuvreHT * (ligne?.marge || 0)) / 100,
                    )}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={classNames(locations.length > 0 && 'mb-sm')}>
            <MultilineParagraph
              paragraphClassName="mb-0 italic text-neutral-600"
              text={ligne.description}
              hasLinksOpenedInNewWindow
            />
          </div>
          {locations.length > 0 && (
            <div className="flex flex-wrap justify-start gap-xs text-h5">
              {locations.map(location => (
                <Location key={location.uuid} location={location} ligne={ligne} />
              ))}
            </div>
          )}
        </div>
      </div>
      <Fournitures fournitureLignes={fournitureLignes} />
    </>
  );
};
