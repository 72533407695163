import React from 'react';

import { DatePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { companyStatuses } from '@travauxlib/shared/src/utils/constants';
import { parseDateInput } from '@travauxlib/shared/src/utils/time';

const startYear = 2015;
const currentYear = new Date().getFullYear();
const yearRange = Array.from({ length: currentYear - startYear }).map(
  (_, index) => `${index + startYear}`,
);

type Props = {
  siret?: string;
  companyType: string;
};

export const LegalInfos: React.FC<Props> = ({ siret, companyType }) => (
  <div className="!mb-sm">
    <h2>Infos légales</h2>
    <div className="grid grid-cols-1 sm-desktop:grid-cols-3 gap-xs">
      <div>
        <InputField
          label={
            <span>
              Numéro de TVA&nbsp;
              <small>
                <Link
                  href={`https://corporama.com/search?company=${siret}&amp;action_no_filter=GO&amp;include_policy=any&amp;hs=1`}
                  target="_blank"
                  rel="noopener noreferrer"
                  inline
                >
                  Trouver sur corporama
                </Link>
              </small>
            </span>
          }
          id="numeroTVA"
          name="numeroTVA"
        />
      </div>
      <div>
        <InputField
          label={
            <span>
              Siret *
              {siret && (
                <small>
                  <Link
                    href={`https://corporama.com/search?company=${siret}&amp;action_no_filter=GO&amp;include_policy=any&amp;hs=1`}
                    target="_blank"
                    rel="noopener noreferrer"
                    inline
                  >
                    Corporama
                  </Link>
                </small>
              )}
            </span>
          }
          id="siret"
          name="siret"
          required
        />
      </div>
      <div>
        <DropdownField
          id="statut"
          label="Statut (SARL, SAS, Auto-entrepreneur...)"
          placeholder="Statut"
          name="statutEntreprise"
          options={companyStatuses}
        />
      </div>
      <div>
        <DatePickerField
          parse={parseDateInput}
          id="companyCreationDate"
          name="companyCreationDate"
          label="Date de création de l'entreprise*"
        />
      </div>
      <div>
        <InputField label="Ville du RCS" id="RCS" name="RCS" />
      </div>
      <div>
        <NumberInputField name="capital" id="capital" label="Capital" />
      </div>
      {companyType === 'architecte' && (
        <div>
          <InputField
            label="Numéro d'inscription à l'Ordre des Architectes"
            placeholder="Numéro d'inscription à l'Ordre"
            id="orderOfArchitectsId"
            name="orderOfArchitectsId"
          />
        </div>
      )}

      {yearRange.map(year => (
        <div key={year}>
          <NumberInputField
            name={`chiffresAffaires.key-${year}`}
            id={`chiffre-affaires-${year}`}
            label={`Chiffre d'affaires en ${year}`}
          />
        </div>
      ))}
    </div>
  </div>
);
