import dayjs from 'dayjs';

import HandyMan from '@travauxlib/shared/src/components/DesignSystem/assets/HandyMan.svg?react';
import { AppelDePaiement, Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { toCalendarFormatForDateTime } from '@travauxlib/shared/src/utils/time';

import { AdminUserProfile } from 'api/useAuth';
import { appelDePaiementStatusThatCanBeUpdated } from 'features/Chantiers/types';
import { disabledMessageForPermissionLabel } from 'utils/constants';

import { FluxFinancierHistoriqueFilterType, HistoryItem, VariantHistotyItemCta } from '../types';

export const appelDePaiementToHistoryItem = ({
  appelDePaiement,
  chantier,
  handleOpenModalCancelAppelDePaiement,
  isLoading,
  adminUserProfile,
  handleOpenModifyAppelDePaiementModal,
  handleOpenValidateAppelDePaiementModal,
}: {
  appelDePaiement: AppelDePaiement;
  chantier: Chantier;
  handleOpenModalCancelAppelDePaiement: (
    appelDePaiement: AppelDePaiement,
    chantierSlug: string,
  ) => void;
  isLoading: boolean;
  adminUserProfile: Pick<AdminUserProfile, 'hasPermission' | 'role'>;
  handleOpenModifyAppelDePaiementModal: (
    appelDePaiement: AppelDePaiement,
    chantier: Chantier,
  ) => void;
  handleOpenValidateAppelDePaiementModal: (appelDePaiementUuid: string, chantier: Chantier) => void;
}): HistoryItem => {
  const { slug, soldeCompteSequestre } = chantier;

  return {
    key: appelDePaiement.uuid,
    date: appelDePaiement.createdAt,
    author: appelDePaiement.createdBy,
    amount: appelDePaiement.montant,
    label: 'Appel de paiement',
    filterType: FluxFinancierHistoriqueFilterType.Paiement,
    pendingInfo:
      appelDePaiement.status === 'pending'
        ? {
            label: 'En attente',
            tooltipMessage:
              "L'appel de paiement sera automatiquement envoyé au client dès que le compte séquestre sera suffisamment provisionné",
          }
        : undefined,
    cancellationInfo: appelDePaiement.deletedAt
      ? {
          date: toCalendarFormatForDateTime(dayjs(appelDePaiement.deletedAt)),
          author: appelDePaiement.deletedBy,
        }
      : undefined,

    validationInfo: appelDePaiement.validatedAt
      ? {
          date: toCalendarFormatForDateTime(dayjs(appelDePaiement.validatedAt)),
          author: appelDePaiement.validatedBy,
        }
      : undefined,
    Icon: HandyMan,
    variant: 'beige',
    actions: [
      ...(appelDePaiementStatusThatCanBeUpdated.includes(appelDePaiement.status)
        ? [
            {
              label: 'Modifier',
              variant: 'primary' as VariantHistotyItemCta,
              disabled: !chantier.enableSuiviRenforce,
              disabledMessage: 'Le suivi financier renforcé doit etre activé',
              onClick: () => handleOpenModifyAppelDePaiementModal(appelDePaiement, chantier),
            },
          ]
        : []),
      ...(appelDePaiement.status === 'initiated'
        ? [
            {
              label: 'Valider à la place du client',
              variant: 'primary' as VariantHistotyItemCta,
              disabled:
                isLoading ||
                soldeCompteSequestre < appelDePaiement.montant ||
                !adminUserProfile.hasPermission('VALIDATE_APPELS_DE_PAIEMENT'),
              disabledMessage: isLoading
                ? undefined
                : soldeCompteSequestre < appelDePaiement.montant
                  ? 'Le solde du compte séquestre est insuffisant.'
                  : disabledMessageForPermissionLabel('VALIDATE_APPELS_DE_PAIEMENT'),
              onClick: () => {
                handleOpenValidateAppelDePaiementModal(appelDePaiement.uuid, chantier);
              },
            },
          ]
        : []),
      ...(appelDePaiementStatusThatCanBeUpdated.includes(appelDePaiement.status)
        ? [
            {
              label: 'Annuler',
              onClick: () => handleOpenModalCancelAppelDePaiement(appelDePaiement, slug),
            },
          ]
        : []),
    ],
  };
};
