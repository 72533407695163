import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { CHANTIER_KEY } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';
import { ChantierAdmin } from 'types';

const fetchChantier = ({
  queryKey: [, slug],
}: QueryFunctionContext<[string, string]>): Promise<ChantierAdmin> =>
  request(`${APP_CONFIG.apiURL}/admin/chantiers/${slug}`);

export const useChantier = (
  slug: string,
): { isLoading: boolean; chantier: ChantierAdmin | undefined } => {
  const { isLoading, data, fetchStatus } = useQuery<ChantierAdmin>({
    queryKey: [CHANTIER_KEY, slug],
    queryFn: fetchChantier,
    staleTime: 10 * 1000,
  });

  return { isLoading: isLoading && fetchStatus !== 'idle', chantier: data };
};
