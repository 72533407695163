import React, { useMemo, useState } from 'react';

import classNames from 'classnames';

import { SwitchLotsLocations } from './components/SwitchLotsLocations';

import { Devis } from '../../types';
import { roundToTwoDecimals } from '../../utils/format';
import { switchToLotsByLocations } from '../../utils/switchToLotsByLocations';
import { DevisDisplay } from '../DevisDisplay';
import { LotsSommaire } from '../LDDToolbar/components/LotsSommaire';
import { SimpleTotal } from '../LDDToolbar/components/SimpleTotal';

type Props = {
  devis: Devis;
};

export const ProDevisDisplay: React.FC<Props> = ({ devis }) => {
  const [isViewByLocations, setIsViewByLocations] = useState(false);

  const lots = useMemo(() => {
    if (isViewByLocations) {
      return switchToLotsByLocations(devis.lots, devis.locations);
    } else {
      return devis.lots;
    }
  }, [devis, isViewByLocations]);

  return (
    <div className="flex flex-col h-full">
      <div className="p-sm flex h-[4.5rem] justify-between relative border-b">
        <div className="w-full flex items-center">
          <div className="px-md">
            <div className="font-bold">Titre</div>
            <div className="truncate">{devis.title}</div>
          </div>
        </div>
      </div>
      <div className="grow flex overflow-hidden">
        <div className="grow w-0 flex">
          <div className="bg-neutral-100 px-sm md-desktop:px-[3.5rem] py-[3.5rem] overflow-auto h-full">
            <div className="bg-neutral-0 shadow-lg">
              <div className="px-md tablet:px-xl mx-auto tablet:pt-[3.5rem] py-lg">
                <DevisDisplay
                  lots={devis.lots}
                  proCompanyName="hemea"
                  customerName="hemea"
                  locations={devis.locations}
                  showFournitures={devis.showFournitures}
                  isViewByLocations={isViewByLocations}
                  token={devis.token}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames('w-[20rem] shrink-0', {
            'hidden sm-desktop:block': true,
          })}
        >
          <div className="h-full flex flex-col shadow-sm fixed w-[20rem]">
            <div className="flex-shrink flex flex-col overflow-hidden pt-sm">
              {devis.locations.length > 0 && lots.length > 0 && (
                <SwitchLotsLocations
                  locations={devis.locations}
                  lots={lots}
                  isViewByLocations={isViewByLocations}
                  setIsViewByLocations={isViewByLocations => {
                    setIsViewByLocations(isViewByLocations);
                  }}
                />
              )}
              <LotsSommaire lots={lots} />
            </div>
            <div className="py-sm px-md border-y">
              <div className="mb-xxs">
                <SimpleTotal label="Total travaux HT" total={devis.prixTotalHT} />
              </div>
              <div className="mb-xxs">
                <SimpleTotal
                  label="Montant TVA"
                  total={roundToTwoDecimals(devis.prixTotalTTC - devis.prixTotalHT)}
                />
              </div>
              <SimpleTotal
                className="font-bold"
                label="Total travaux TTC"
                total={devis.prixTotalTTC}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
