import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';

import { BaseStep, ExecuteParams } from '../BaseStep';

type Input = { proCompany?: ProCompanyAdminView };

export class SignInAsPro extends BaseStep<Input> {
  public name = 'Sign in as pro';

  public fields = [];

  async execute({ apiURL, proCompany, request }: ExecuteParams<Input>): Promise<void> {
    const proUserId = proCompany?.proUsers[0]?.id;

    if (!proUserId) {
      super.error('No pro user id found ?');
      return Promise.reject('No pro user ID');
    }

    super.log(`Signin as pro`);

    await request(`${apiURL}/admin/pro-users/${proUserId}/sign-in`, { method: 'POST' });

    super.log('OK');
  }
}
