import { RowAction } from '@travauxlib/shared/src/components/DesignSystem/components/Table/types';
import { SuiviChantierCycleHistory } from '@travauxlib/shared/src/features/SuiviChantier/types';

import { PermissionType } from 'utils/constants';
import { adminUrls } from 'utils/urls';

export const getCycleActions = (
  cycle: SuiviChantierCycleHistory,
  chantierSlug: string,
  navigate: (url: string) => void,
  hasPermission: (permission: PermissionType) => boolean | undefined,
  handleOpenModalChangeTransactionVisibility: (uuid: string, chantierSlug: string) => void,
): RowAction[] => {
  const finalStatuses = ['agreed', 'canceled', 'disputed'];
  const isFinalStatus = finalStatuses.includes(cycle.status);

  return [
    {
      label: 'Voir',
      action: () => navigate(adminUrls.suiviChantierHistoryUrl(cycle.uuid)),
    },
    {
      label: 'Supprimer',
      disabled: !hasPermission('EDIT_FLUX_FINANCIERS') || isFinalStatus,
      action: () => handleOpenModalChangeTransactionVisibility(cycle.uuid, chantierSlug),
    },
  ];
};
