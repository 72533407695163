import { useEffect } from 'react';

import {
  SimpleModal,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

const YoutubeModal: React.FC = () => (
  <SimpleModal isOpen={true}>
    <div className="flex w-full">
      <div className="w-full">
        <iframe
          className="w-full h-[500px]"
          title="Logiciel de devis hemea"
          src="https://www.youtube.com/embed/rEq1Z0bjdwc?autoplay=1"
          allowFullScreen
        />
      </div>
    </div>
  </SimpleModal>
);

export const useObiwan = (): void => {
  const openModal = useOpenModal();
  const obiwanHelpUs: string = 'helpusobi 1';
  let buffer: string = '';

  useEffect(() => {
    const onKeyPress = (e: KeyboardEvent): void => {
      buffer = (buffer + e.key).slice(-obiwanHelpUs.length);
      if (buffer === obiwanHelpUs) {
        openModal(YoutubeModal, {
          closable: true,
        });
      }
    };
    document.addEventListener('keypress', onKeyPress);
    return () => document.removeEventListener('keypress', onKeyPress);
  }, []);
};
