import { useFormState } from 'react-final-form';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import {
  Chauffage,
  Plomberie,
} from '@travauxlib/shared/src/features/Configurateur/types/Plomberie';
import { TypeBien } from '@travauxlib/shared/src/types';

import { ChipPictoVerticalListFieldConfigurateur } from 'features/Configurateur/components/ChipPictoVerticalListFieldConfigurateur';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';
import { getLocationOptions } from 'features/Configurateur/utils/getLocationOptions';

import { LocalisationPacAInstaller } from './components/LocalisationPacAInstaller';

import { getAlertForTypeModifChauffage } from '../../utils/getAlertForTypeModif';

type Props = {
  configuration: Configuration;
  name: string;
  value: Chauffage;
};

export const ChauffageNonExistant: React.FC<Props> = ({ configuration, name, value }) => {
  const isMaison = configuration.typeLogement !== TypeBien.Appartement;
  const locationOptions = getLocationOptions(configuration);
  const { values } = useFormState<Plomberie>();

  return (
    <>
      <QuestionTitle
        prefix="2"
        title="Type de modifications ?"
        alert={getAlertForTypeModifChauffage(value, values)}
        tooltip={
          <div>
            Type d'équipement en fonction de la gamme :
            <br />- Toutes gammes confondues : Radiateurs à inertie fluide pour radiateurs
            électriques
            <br />- Primo & Confort : Radiateurs avec robinet thermostatique pour radiateurs à eau
            <br />- Premium & Exclusif : Radiateurs avec robinet thermostatique connecté et
            thermostat d'ambiance pour radiateurs à eau
          </div>
        }
      />
      <ChipListField
        name={`${name}.typeInstallation`}
        renderingOptions={{
          containerClassName: 'mb-md flex flex-wrap gap-xs',
        }}
        options={[
          {
            label: 'Installation chauffage au gaz',
            value: 'Gaz',
          },
          {
            label: 'Installation chauffage électrique',
            value: 'Electrique',
          },
          ...(isMaison
            ? [
                {
                  label: 'Installation chauffage PAC',
                  value: 'PAC',
                },
              ]
            : []),
        ]}
      />
      {value?.typeInstallation === 'Gaz' && (
        <>
          <QuestionTitle prefix="3" title="Localisation de la chaudière à installer ?" />
          <ChipPictoVerticalListFieldConfigurateur
            name={`${name}.poseChaudiereLocationUuid`}
            locationOptions={locationOptions}
          />
        </>
      )}
      {value?.typeInstallation === 'PAC' && (
        <LocalisationPacAInstaller configuration={configuration} prefix="3" name={name} />
      )}
    </>
  );
};
