import { request } from '@travauxlib/shared/src/utils/request';

import { PartnerAccount } from 'types';

export const useSignInToEstimateur = (account: PartnerAccount) => () => {
  request(
    `${APP_CONFIG.apiURL}/admin/partners/${account.organizationUuid}/accounts/${account.uuid}/sign-in`,
    { method: 'POST' },
  );
  window.open(`${APP_CONFIG.appURL}/estimateur`);
};
