import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { ProCompany } from 'types';

const PRO_COMPANIES_FOR_CONSULTATION_KEY = 'pro-companies-for-mer';

export const useProCompaniesForConsultation = (
  consultationInfoId: number,
): { proCompanies: ProCompany[]; nbResults: number; isFetching: boolean } => {
  const { data, isFetching } = useQuery<{
    proCompanies: ProCompany[];
    nbResults: number;
  }>({
    queryKey: [PRO_COMPANIES_FOR_CONSULTATION_KEY, consultationInfoId],
    queryFn: () =>
      request(`${APP_CONFIG.apiURL}/admin/consultations/${consultationInfoId}/pro-companies`),
    refetchOnWindowFocus: false,
  });

  return {
    proCompanies: data?.proCompanies || [],
    nbResults: data?.nbResults || 0,
    isFetching: isFetching,
  };
};
