import React from 'react';

import { DropdownMultiField } from '@travauxlib/shared/src/components/DesignSystem/components/DropdownMulti/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { architectAccreditations } from '@travauxlib/shared/src/utils/constants';

import { assurancesArchitecte, specializationsArchitects } from 'utils/constants';

export const FieldsArchitect: React.FC = () => (
  <>
    <div data-testid="archi" className="flex flex-wrap -mx-md mb-md">
      <div className="tablet:w-3/12 tablet:px-md relative">
        <DropdownMultiField
          id="architectAccreditations"
          label="Habilitation"
          name="architectAccreditations"
          options={architectAccreditations}
        />
      </div>
      <div className="sm-desktop:w-9/12 sm-desktop:px-md relative">
        <DropdownMultiField
          id="corpsEtat"
          label="Assurée pour"
          name="corpsEtat"
          options={assurancesArchitecte.map(value => ({ value, label: value }))}
        />
      </div>
    </div>
    <div className="mb-md">
      <DropdownMultiField
        id="specializations"
        label="N'envoyer que sur"
        name="specializations"
        options={specializationsArchitects.map(value => ({ value, label: value }))}
      />
    </div>
    <div className="flex flex-wrap -mx-md">
      <div className="sm-desktop:w-4/12 sm-desktop:px-md relative">
        <NumberInputField
          className="mb-md"
          id="tailleChantierMin"
          name="tailleChantierMin"
          label="Taille chantier min"
        />
      </div>
    </div>
  </>
);
