import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { PRO_COMPANIES_KEY } from 'features/Entreprises/api/useProCompanies';
import { PRO_COMPANY_KEY } from 'features/Entreprises/features/Entreprise/api/useProCompany';
import { apiUrlsBuilder } from 'utils/urls';

const handleToggleFreeze = async (slug: string): Promise<string> => {
  const requestURL = apiUrlsBuilder.proCompanies.toggleFreeze(slug);

  await request(requestURL, { method: 'PATCH' });

  return slug;
};

export const useToggleFreeze = (): {
  toggleFreeze: typeof handleToggleFreeze;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: handleToggleFreeze,
    retry: 0,
    onSuccess: slug => {
      queryClient.invalidateQueries({ queryKey: [PRO_COMPANY_KEY, slug] });
      queryClient.invalidateQueries({ queryKey: [PRO_COMPANIES_KEY] });
      toast.success("L'entreprise a bien été mise à jour.");
    },
    onError: () => {
      toast.error("Erreur lors de la mise à jour de l'entreprise");
    },
  });

  return { toggleFreeze: mutateAsync, isLoading: isPending };
};
