import { UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { Devis } from 'types';

import { DEVIS_KEY } from './useDevis';

export const useCreateDevis = (): {
  createDevis: UseMutateAsyncFunction<
    { token: string },
    Error,
    Partial<Devis> & { projectUuid: string }
  >;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: createDevis, isPending } = useMutation({
    mutationFn: (adminDevis: Devis) =>
      request(`${APP_CONFIG.apiURL}/admin/projects/${adminDevis.projectUuid}/devis`, {
        method: 'POST',
        body: JSON.stringify(adminDevis),
      }),
    onSuccess: (_, { projectUuid }) => {
      queryClient.invalidateQueries({ queryKey: [DEVIS_KEY, projectUuid] });
    },
    onError: () => {
      toast.error("Le devis n'a pas pu être créé");
    },
  });

  return { createDevis, isLoading: isPending };
};
