import React from 'react';

import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { required, requiredValidEmail } from '@travauxlib/shared/src/utils/form';

export const CreateClientAccountFormFields = (): JSX.Element => (
  <>
    <InputField
      className="mb-md"
      label="Prénom"
      name="firstName"
      id="firstName-modal"
      validate={required}
    />
    <InputField
      className="mb-md"
      label="Nom"
      name="lastName"
      id="lastName-modal"
      validate={required}
    />
    <InputField label="Email" name="email" id="email-modal" validate={requiredValidEmail} />
  </>
);
