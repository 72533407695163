import React, { useState } from 'react';

import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';

import { FilterCriteria, useProCompanies } from 'features/Entreprises/api/useProCompanies';
import { ConsultationInfo, ConsultationOffer, ProCompany } from 'types';

import { Search } from './components/Search';
import { TableComponent } from './components/Table';

type Props = {
  initialFormValues?: FilterCriteria;
  enableCompanyTypeAndStatus?: boolean;
  ActionsComponent?: React.ComponentType<{ proCompany: ProCompany }>;
  consultationInfo?: ConsultationInfo;
  onConsultationDone?: (createdConsultationOffers: ConsultationOffer[]) => void;
};

const defaultInitialFormValues: FilterCriteria = {
  status: ['Activées', 'Labellisées'],
  companyTypes: ['pro', 'architecte', 'mandataire'],
  acceptsProjects: 'indifferent',
  enPause: 'indifferent',
  validAssurance: 'indifferent',
  kyc2: 'indifferent',
};

export const SearchCompany: React.FC<Props> = ({
  initialFormValues,
  enableCompanyTypeAndStatus,
  ActionsComponent,
  ...rest
}) => {
  const initialValues = {
    ...defaultInitialFormValues,
    ...initialFormValues,
  };
  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>(initialValues);
  const { proCompanies, isLoading } = useProCompanies(filterCriteria);

  return (
    <>
      <Search
        initialFormValues={initialFormValues}
        enableCompanyTypeAndStatus={enableCompanyTypeAndStatus}
        setFilterCriteria={setFilterCriteria}
      />
      {isLoading ? (
        <LoaderWrapper />
      ) : (
        <TableComponent proCompanies={proCompanies} ActionsComponent={ActionsComponent} {...rest} />
      )}
    </>
  );
};
