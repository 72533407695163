import React from 'react';

import Bank from '@travauxlib/shared/src/components/DesignSystem/assets/Bank.svg?react';
import { ChantierPvWithDocuments } from '@travauxlib/shared/src/features/Chantiers/types';

import { PvStatusTag } from './PvStatusTag';

type Props = {
  pv?: ChantierPvWithDocuments;
  label: string;
  isThresholdReached: boolean;
};

export const PvInfo: React.FC<Props> = ({ pv, label, isThresholdReached }) => (
  <div
    className="flex items-center gap-xxs justify-between text-b2"
    aria-label={label}
    role="section"
  >
    <div className="text-neutral-600">{label}</div>
    {isThresholdReached || pv ? (
      <PvStatusTag pv={pv} />
    ) : (
      <div className="flex items-center gap-xxs">
        <Bank className="size-md" />
        <div className="text-neutral-700">Plafond non atteint</div>
      </div>
    )}
  </div>
);
