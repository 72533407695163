import { createLeadAndStartChantier } from './createLeadAndStartChantier';

import { ChantierEnableSuiviChantier } from '../gdm_steps/Chantier/ChantierEnableSuiviChantier';
import { ChantierEncaisserPrestationHemea } from '../gdm_steps/Chantier/ChantierEncaisserPrestationHemea';
import { ChantierTriggerADPScheduler } from '../gdm_steps/Chantier/ChantierTriggerADPScheduler';
import { ChantierValidateAppelDePaiement } from '../gdm_steps/Chantier/ChantierValidateAppelDePaiement';
import { FindChantierFromPipedriveDealId } from '../gdm_steps/Chantier/FindChantierFromPipedriveDealId';

// eslint-disable-next-line
export const createLeadAndSuiviChantier = () =>
  createLeadAndStartChantier()
    .next(new ChantierTriggerADPScheduler())
    .next(new ChantierEnableSuiviChantier())
    .next(
      new FindChantierFromPipedriveDealId(), // Refresh chantier to get ADPs
    )
    .next(new ChantierValidateAppelDePaiement())
    .next(new ChantierEncaisserPrestationHemea());
