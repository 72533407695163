import React from 'react';

import { Outlet } from 'react-router-dom';

import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';

export const ApporteurLayout: React.FC = () => (
  <>
    <PageTitle title={PageTitleEnum.gestionFacturation} />
    <div className="flex grow justify-between items-center">
      <div className="text-h3 font-bold">Facturation apporteur d'affaires</div>
    </div>
    <Tabs
      items={[
        {
          label: 'Commissions actives',
          to: 'commissions-actives',
        },
        {
          label: 'Commissions réglées',
          to: 'commissions-reglees',
        },
      ]}
    />
    <Outlet />
  </>
);
