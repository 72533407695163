import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { Devis } from 'types';

export const DEVIS_KEY = 'devis';

export const useDevis = ({
  projectUuid,
  token,
}: {
  projectUuid: string;
  token: string;
}): { devis?: Devis; isLoading: boolean } => {
  const { data, isLoading, fetchStatus } = useQuery<Devis | undefined>({
    queryKey: [DEVIS_KEY, projectUuid, token],
    queryFn: () => request(`${APP_CONFIG.apiURL}/admin/projects/${projectUuid}/devis/${token}`),
  });

  return { devis: data, isLoading: isLoading && fetchStatus !== 'idle' };
};
