import { Configuration, LocationOption } from '@travauxlib/shared/src/features/Configurateur/types';
import { getLocationLabel } from '@travauxlib/shared/src/features/Configurateur/utils/getLocationLabel';
import { locationsPicto } from '@travauxlib/shared/src/features/DevisDisplay/components/LocalisationIcon';

export const getLocationOptions = (configuration: Configuration): LocationOption[] =>
  configuration.locations.map(location => {
    const Picto = locationsPicto[location.typeLocation];
    return {
      value: location.uuid,
      label: getLocationLabel(configuration, location),
      centerIcon: <Picto height="32" width="32" className="" />,
      typeLocation: location.typeLocation,
    };
  });
