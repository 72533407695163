import { useQuery } from '@tanstack/react-query';

import { ProRegistration } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

const fetchProRegistrations = (): Promise<{ data: ProRegistration[] }> =>
  request(`${APP_CONFIG.apiURL}/admin/pro-registrations`);

export const PRO_REGISTRATION_KEY = 'pro-registrations';

export const useProRegistrations = (): ProRegistration[] | undefined => {
  const { data } = useQuery<{ data: ProRegistration[] }>({
    queryKey: [PRO_REGISTRATION_KEY],
    queryFn: fetchProRegistrations,
    refetchInterval: 4000,
  });
  return data?.data;
};
