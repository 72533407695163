import React, { useState } from 'react';

import dayjs from 'dayjs';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Variant } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { BadgeList } from 'components/BadgeList';
import { useSignInAsPro } from 'features/Entreprises/api/useSignInAsPro';
import { AvailabilityStatus } from 'features/Entreprises/components/AvailabilityStatus';
import { CompanyInfo } from 'features/Entreprises/components/CompanyInfo';
import { ConsultationList } from 'features/Entreprises/components/ConsultationList';
import { ProCompany } from 'types';

import { Comment } from './Comment';
import { LastDevis } from './LastDevis';

type Props = {
  proCompany: ProCompany;
  ActionsComponent?: React.ComponentType<{ proCompany: ProCompany }>;
};

export const Ligne: React.FC<Props> = ({ proCompany, ActionsComponent, ...rest }) => {
  const [isOpenConsultation, setIsOpenConsultation] = useState(false);

  const handleSignInAsPro = useSignInAsPro();

  const allBadges: Array<{
    badges: string[];
    level: Variant;
  }> = [
    { badges: proCompany.corpsEtat, level: 'primary' },
    { badges: proCompany.specializations, level: 'info' },
    { badges: proCompany.travailEnInterne, level: 'light' },
    { badges: proCompany.departements, level: 'light' },
  ];

  const bg = (() => {
    if (proCompany.frozenAt) {
      return 'table-info';
    }
    if (proCompany.pauseReason) {
      return 'table-warning';
    }
    return '';
  })();

  const { proUsers = [] } = proCompany;
  return (
    <>
      <tr className={bg} aria-labelledby={proCompany.uuid}>
        <td>
          <CompanyInfo withStats proCompany={proCompany} />
          <br />
          <Button
            type="button"
            onClick={() => setIsOpenConsultation(isOpenConsultation => !isOpenConsultation)}
            className="!mt-xs"
          >
            {isOpenConsultation ? 'Masquer consultations' : 'Voir dernières consultations'}
          </Button>
        </td>
        {allBadges.map((element, key) => (
          <td key={key}>
            <BadgeList badges={element.badges} level={element.level} />
          </td>
        ))}
        <td>
          <Comment proCompany={proCompany} />
        </td>
        {proCompany.hasValidInsurances ? (
          <td className="bg-success-100">
            {proCompany.validInsurances.map((insurance, insuranceKey) => (
              <div key={insuranceKey} className="!mb-xs">
                <Link href={insurance.fileUrl} target="_blank" rel="noopener noreferrer">
                  {insurance.label} {dayjs(insurance.dateFinValidite).format('L')}
                  <br />
                </Link>
              </div>
            ))}
          </td>
        ) : (
          <td className="bg-error-100">
            <span>Pas d&apos;assurance</span>
          </td>
        )}
        <td>
          {ActionsComponent && <ActionsComponent proCompany={proCompany} {...rest} />}
          {proCompany.frozenAt && (
            <span className="!text-info !font-bold">
              <br />
              ❄️ Gelée le {formatFrenchDate(proCompany.frozenAt)}
            </span>
          )}
          <AvailabilityStatus proCompany={proCompany} advanced={false} />
          <br />
          {proUsers
            .sort((a, b) => +b.isProCompanyAdmin - +a.isProCompanyAdmin)
            .map(user => (
              <div key={user.id} className="!mb-sm">
                {user.fullName}
                {user.isProCompanyAdmin && (
                  <small className="ml-sm uppercase bg-success text-white px-sm">Admin</small>
                )}
                <br />
                {user.email}
                <br />
                {user.phoneNumber}
                <Button
                  variant="tertiary"
                  type="button"
                  onClick={() => handleSignInAsPro(user.id!)}
                  size="sm"
                >
                  Se connecter à son compte
                </Button>
              </div>
            ))}
          <div className="!mb-md">
            <Link to={`/entreprises/${proCompany.slug}/users`}>
              <Button variant="tertiary" size="sm">
                Inviter un nouvel utilisateur
              </Button>
            </Link>
          </div>
          {proCompany.pageVitrineUrl ? (
            <Link href={proCompany.pageVitrineUrl} target="_blank" rel="noopener noreferrer">
              Voir page vitrine
            </Link>
          ) : (
            <div>Pas de page vitrine</div>
          )}
        </td>
      </tr>
      {isOpenConsultation && (
        <tr>
          <td colSpan={8}>
            <div className="flex flex-wrap -mx-md">
              <div className="w-6/12 px-md relative">
                <ConsultationList
                  proCompany={proCompany}
                  title="lots envoyés sur les 3 derniers mois ou encore ouverts"
                />
              </div>
              <div className="w-6/12 px-md relative">
                <LastDevis proCompany={proCompany} />
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};
