import { acceptConsultationOfferAndSignDevis } from './acceptConsultationOfferAndSignDevis';
import { createAdressableLead } from './createAdressableLead';
import { createConsultation } from './createConsultation';
import { Flow } from './Flow';

import { LogOutAsPro } from '../gdm_steps/Pro/LogOutAsPro';

const isPrechiffrage = true;

export const createLeadAndChantierProAndArchi = (): Flow<any, any> =>
  createAdressableLead()
    .nextFlow(createConsultation('architecte'))
    .nextFlow(acceptConsultationOfferAndSignDevis(isPrechiffrage))
    .next(new LogOutAsPro())
    .nextFlow(createConsultation())
    .nextFlow(acceptConsultationOfferAndSignDevis());
