import React from 'react';

import { SimpleModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { AuthorizedGuard } from 'components/Auth/AuthorizedGuard';
import { ProCompany } from 'types';

import { useToggleFreeze } from '../api/useToggleFreeze';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  slug: string;
  frozenAt: ProCompany['frozenAt'];
};

export const ToggleFreezeModal: React.FC<Props> = ({ isOpen, onClose, slug, frozenAt }) => {
  const { toggleFreeze, isLoading } = useToggleFreeze();

  return (
    <SimpleModal
      isOpen={isOpen}
      handleClose={onClose}
      title={frozenAt ? "Dégeler l'entreprise" : "Geler l'entreprise"}
      validateAction={{
        label: 'Valider',
        onClick: async () => {
          await toggleFreeze(slug);
          onClose();
        },
        disabled: isLoading,
        loading: isLoading,
      }}
      cancelAction={{ label: 'Annuler', onClick: onClose }}
    >
      <AuthorizedGuard>
        {frozenAt ? (
          <>
            Il sera de nouveau possible de lui envoyer des consultations.
            <br />
            <br />
            Tous les chantiers futurs seront en Suivi TP uniquement si le client choisit de
            souscrire à l'option "Travaux Planner" à la signature (fonctionnement normal).
          </>
        ) : (
          <>
            Il sera impossible de lui envoyer des consultations.
            <br />
            <br />
            Tous les chantiers passés, présents et futurs, basculeront en Suivi TP pour nous
            permettre de contrôler la libération des fonds (sauf s'ils sont déjà en Suivi Archi ou
            Suivi TP).
          </>
        )}
      </AuthorizedGuard>
    </SimpleModal>
  );
};
