import React from 'react';

import { useFormState } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

import { usePrompt } from 'hooks/usePrompt';

type Props = {
  disablePrompt?: boolean;
  label?: string;
  className?: string;
};

export const SubmitButton: React.FC<Props> = ({
  disablePrompt = false,
  label = 'Enregistrer',
  className,
}: Props) => {
  const { submitting, dirty, invalid } = useFormState();
  usePrompt({
    when: dirty && !disablePrompt,
    message: 'Attention, les modifications en cours ne seront pas sauvegardées !',
  });
  return (
    <>
      <Button disabled={submitting || !dirty || invalid} className={className} type="submit">
        {label}
      </Button>
    </>
  );
};
