import React, { useState } from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

import { ScoreSignature } from 'types';

type Props = {
  score: ScoreSignature;
};

export const ScoreSignatureWithDetails: React.FC<Props> = ({ score }) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <>
      <table className="table !bg-light">
        <tbody>
          <tr>
            <td>Score unifié</td>
            <td>{score.value}</td>
          </tr>
        </tbody>
        {showDetails && (
          <tbody>
            <tr>
              <td colSpan={2} className="!text-center">
                Score A
              </td>
            </tr>
            {Object.entries(score.scoreA).map(([key, value]) => (
              <tr key={key}>
                <td>{key}</td>
                <td>{value}</td>
              </tr>
            ))}
            <tr>
              <td colSpan={2} className="!text-center">
                Score B
              </td>
            </tr>
            {Object.entries(score.scoreB).map(([key, value]) => (
              <tr key={key}>
                <td>{key}</td>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      <div className="!text-center !mb-lg">
        <Button type="button" onClick={() => setShowDetails(showDetails => !showDetails)} size="sm">
          {showDetails ? 'Masquer' : 'Afficher'} les détails du score
        </Button>
      </div>
    </>
  );
};
