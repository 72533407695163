import React, { useState } from 'react';

import Envelope from '@travauxlib/shared/src/components/DesignSystem/assets/Envelope.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';

import { useUpdateProCompany } from 'features/Entreprises/features/Entreprise/api/useUpdateProCompany';

export const OptymeaButton: React.FC<{
  slug: string;
  optymeaCreationLink?: string;
}> = ({ slug, optymeaCreationLink }) => {
  const [hasBeenClicked, setHasBeenClicked] = useState(false);
  const [hasConfirmedOptymeaCreation, setHasConfirmedOptymeaCreation] = useState(false);
  const { activateOptymeaAccount } = useUpdateProCompany();

  if (!hasBeenClicked) {
    return (
      <Button
        data-testid="activate"
        size="sm"
        onClick={() => setHasBeenClicked(true)}
        leftIcon={<Envelope />}
      >
        Activer compte Optymea
      </Button>
    );
  }

  if (!optymeaCreationLink) {
    return (
      <div>
        &quot;Impossible de créer un compte Optymea (manque un user sur la proCompany ?)&quot;
      </div>
    );
  }

  return (
    <>
      <h3>
        Attention, si tu actives le compte optymea par erreur, tu vas te faire gronder par prolub!
      </h3>

      <LoaderWrapper />

      <div className="flex flex-wrap -mx-md !mb-lg">
        <Link href={optymeaCreationLink}>Créer un compte Optymea</Link>
      </div>

      <div className="flex flex-wrap -mx-md !mb-lg">
        <Checkbox
          id="hasOptymeaAccount"
          label="Je certifie sur l'honneur avoir créé un compte Optymea et vouloir l'activer"
          checked={hasConfirmedOptymeaCreation}
          onChange={() =>
            setHasConfirmedOptymeaCreation(
              hasConfirmedOptymeaCreation => !hasConfirmedOptymeaCreation,
            )
          }
        />
      </div>

      <div className="flex flex-wrap -mx-md !mb-lg">
        <div className="!flex !items-between">
          <Button
            variant="secondary"
            onClick={() => setHasBeenClicked(false)}
            type="button"
            className="!mx-sm"
          >
            Pas fait exprès, déso
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              activateOptymeaAccount({ slug });
              setHasBeenClicked(false);
            }}
            type="button"
            disabled={!hasConfirmedOptymeaCreation}
            disabledMessageTooltip="Compte Optymea non créé ou Compte Optymea non confirmé"
            className="!mx-sm"
          >
            Activer le compte
          </Button>
        </div>
      </div>
    </>
  );
};
