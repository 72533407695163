import { useQuery } from '@tanstack/react-query';

import { useFuseWorker } from '@travauxlib/shared/src/hooks/useFuseWorker';
import { LabellisationStatus } from '@travauxlib/shared/src/types/company';
import { request } from '@travauxlib/shared/src/utils/request';

import { CompanyType, ProCompany } from 'types';
import { labellisationStatusesById } from 'utils/constants';

import { useFilterAndSortResults } from './useFilterAndSortResults';

export type FilterCriteria = {
  status?: string[];
  companyTypes?: CompanyType[];
  contactSearch?: string;
  companyEmail?: string;
  companyName?: string;
  siret?: string;
  userEmail?: string;
  acceptsProjects?: 'indifferent' | 'barnes' | 'hemea';
  kyc2?: 'indifferent' | 'oui' | 'non';
  enPause?: 'indifferent' | 'oui' | 'non';
  validAssurance?: 'indifferent' | 'oui' | 'non';
  proProfileFlags?: 'BET intégré' | 'Travaille avec des architectes';
};

const searchOptions = {
  shouldSort: true,
  threshold: 0.3,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    'name',
    'contacts.name',
    'internalComment',
    'phoneNumber',
    'email',
    'city',
    'postalCode',
    'proProfileFlags',
  ],
};

export const PRO_COMPANIES_KEY = 'pro-companies';
export const useProCompanies = (
  filterCriteria: FilterCriteria,
  enabled: boolean = true,
): { isFetching: boolean; isLoading: boolean; proCompanies: ProCompany[] } => {
  const { status, companyTypes, companyEmail, userEmail, companyName, siret } = filterCriteria;
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      PRO_COMPANIES_KEY,
      status,
      companyTypes,
      companyEmail,
      userEmail,
      companyName,
      siret,
    ],
    queryFn: () => {
      const requestURL = `${APP_CONFIG.apiURL}/admin/pro-companies`;
      const statuses = Object.values(LabellisationStatus).filter(statusId =>
        status?.includes(labellisationStatusesById[statusId].label),
      );

      return request(requestURL, {
        params: {
          status: statuses,
          companyTypes: companyTypes || [],
          userEmail,
          companyEmail,
          companyName,
          siret,
        },
      });
    },
    enabled,
    refetchOnWindowFocus: false,
  });

  const search = filterCriteria.contactSearch;

  const filteredProCompanies = useFilterAndSortResults(data?.data || [], filterCriteria);
  const searchResults = useFuseWorker<ProCompany>(filteredProCompanies, searchOptions, search);
  const results = search ? searchResults : filteredProCompanies;

  return { isLoading: isLoading && isFetching, isFetching, proCompanies: results };
};
