import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AdminEventsProperties,
  AdminFeatures,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class InscriptionsProTrackingClass extends Tracker<AdminFeatures> {
  onInscriptionProCreated(properties: { [AdminEventsProperties.UserRole]?: string }): void {
    this.sendTracking({ event: TrackingEvents.Created, properties });
  }
}

export const InscriptionsProTracking = new InscriptionsProTrackingClass(
  AdminFeatures.InscriptionsPro,
);
