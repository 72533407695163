import dayjs from 'dayjs';

import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';
import { ProRegistrationAdminView } from '@travauxlib/shared/src/types/api/admin/ProRegistrationAdminView';

import { BaseStep, ExecuteParams, StepField } from '../BaseStep';

type Input = { proRegistration: ProRegistrationAdminView; proCompanyEmail?: string };

export class SetProCompanyAbleForHemeaProjects extends BaseStep<
  Input,
  { proCompany?: ProCompanyAdminView } | undefined,
  StepField[],
  { proCompany: ProCompanyAdminView }
> {
  public name: string = 'Set entreprise Hemeable';

  public fields = [];

  async execute({
    proRegistration,
    proCompanyEmail,
    apiURL,
    adminURL,
    request,
  }: ExecuteParams<Input>): Promise<{ proCompany?: ProCompanyAdminView } | undefined> {
    const proCompanies = await request<{ data: ProCompanyAdminView[] }>(
      `${apiURL}/admin/pro-companies?userEmail=&companyEmail=${encodeURI(
        proCompanyEmail || proRegistration.email,
      )}&companyName=&siret=`,
    );
    // should split this step in two: getting the company and then updating it

    super.log('Found pro companies : (I will pick the first)');
    super.log(proCompanies.data.map((pc: any) => pc.email).join('\n'));

    const proCompany = proCompanies.data[0];

    if (!proCompany) {
      super.error('No pro company found !');
      return;
    }

    super.log(`Page entreprise : ${adminURL}/entreprises/${proCompany.slug}/edit`);

    const validProRegistration = {
      ...proCompany,
      acceptsHemeaProjects: true,
      isBaseDePrixHemeaEnabled: true,
      isEmailVerified: true,
      isKycCompliant: true,
      status: 'ACTIVEE',
      charteStatus: 'accepted',
      corpsEtat: ['Contractant général - Entreprise générale'],
      gammes: ['primo', 'confort', 'premium', 'exclusif'],
      hasClubProForFree: true,
      trialPeriodEndDate: dayjs('01/01/2001'),
    };
    const updatedProCompany: ProCompanyAdminView = await request(
      `${apiURL}/admin/pro-companies/${proCompany.slug}`,
      {
        method: 'PATCH',
        body: {
          settings: validProRegistration,
        },
      },
    );

    super.setCreatedEntities({ proCompany: updatedProCompany });

    return { proCompany: updatedProCompany };
  }
}
