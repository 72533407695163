import React from 'react';

import { FormSpy, useForm } from 'react-final-form';

import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { required } from '@travauxlib/shared/src/utils/form';

import { normalizeAttributionName } from '../utils/normalizeAttributionName';

export const AttributionNameField: React.FC = () => {
  const form = useForm();
  const formState = form.getState();

  return (
    <>
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
          const shouldAutoComputeAttributionName =
            values.name && !values.uuid && !formState.visited?.attributionName;

          return (
            shouldAutoComputeAttributionName &&
            form.change('attributionName', normalizeAttributionName(values.name))
          );
        }}
      />

      <InputField
        id="attributionName"
        name="attributionName"
        validate={required}
        parse={normalizeAttributionName}
        disabled={formState.initialValues?.uuid}
        label={
          <div className="flex items-center">
            Nom d'attribution (campagne){' '}
            <WithTooltip
              trigger={
                <span className="!flex !items-center !ml-xs">
                  <HelpCircle className="w-md h-md" />
                </span>
              }
              width="22rem"
            >
              <div className="!p-sm">
                Le nom d'attribution ne sera plus modifiable après la création du partenaire.
                <br />
                La nomenclature du nom d'attribution est sans majuscule, avec des "_" et sans
                accent.{' '}
                <Link
                  href="https://www.notion.so/hemea/Formatage-admin-partenaires-10621345104543e6b405e71671173461#ebc59bbd31714ff9aea26a653f779449"
                  target="_blank"
                >
                  Plus d'info ici.
                </Link>
              </div>
            </WithTooltip>
          </div>
        }
      />
    </>
  );
};
