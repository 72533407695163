import React from 'react';

import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { Elec } from '@travauxlib/shared/src/features/Configurateur/types/Elec';

import { useConfiguration } from 'features/Configurateur/api/useConfiguration';
import { FormFooter } from 'features/Configurateur/components/FormFooter';
import { FormHeader } from 'features/Configurateur/components/FormHeader';

import { AjoutDappareillagesElectriques } from './components/AjoutDappareillagesElectriques';
import { MiseAuxNormesEelectriciteAfaire } from './components/MiseAuxNormesEelectriciteAfaire';

import { useCreateUpdateConfiguration } from '../../api/useCreateUpdateConfiguration';

export const ConfigurateurElec: React.FC = () => {
  const projectUuid = useParams().uuid!;
  const { configuration } = useConfiguration(projectUuid!);

  const createUpdateConfiguration = useCreateUpdateConfiguration();
  const navigate = useNavigate();

  if (!configuration) {
    return <Navigate to="../bien" />;
  }

  return (
    <Form<Elec>
      initialValues={configuration?.answers?.elec}
      mutators={{ ...arrayMutators }}
      destroyOnUnregister
      onSubmit={elec => {
        const updatedConfiguration = {
          ...configuration,
          answers: {
            ...configuration.answers,
            elec,
          },
        };
        createUpdateConfiguration(updatedConfiguration);
        navigate('..');
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormHeader questionId="elec" />
          <MiseAuxNormesEelectriciteAfaire configuration={configuration} />
          <AjoutDappareillagesElectriques configuration={configuration} />
          <FormFooter />
        </form>
      )}
    </Form>
  );
};
