import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { useChantierQuery } from '../../Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';

type Payload = {
  chantier: Partial<Chantier> & { slug: string };
  montantProvision: number;
};

const handleAddProvision = async (payload: Payload): Promise<void> => {
  const { slug } = payload.chantier;
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${slug}/debug/add-provision`;
  await request(requestURL, {
    method: 'POST',
    body: { montantProvision: payload.montantProvision },
  });
};

export const useAddProvision = (): {
  addProvision: typeof handleAddProvision;
  isLoading: boolean;
} => {
  const { query, isLoading } = useChantierQuery<Payload>(
    handleAddProvision,
    'de la mise à jour du chantier',
    'Félicitations, tu es riche !',
  );

  return { addProvision: query, isLoading };
};
