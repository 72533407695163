import React from 'react';

import classNames from 'classnames';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ProRegistration } from '@travauxlib/shared/src/types';

type Props = {
  proRegistration: ProRegistration;
  isUpdating: boolean;
  updateProRegistration: (payload: {
    proRegistration: ProRegistration;
    isClubPro?: boolean;
    lostReason?: string;
    status: ProRegistration['status'];
  }) => void;
};

export const AddressableReasonsSection: React.FC<Props> = ({
  proRegistration,
  updateProRegistration,
  isUpdating,
}) => {
  const { status } = proRegistration;
  return (
    <div>
      <Button
        data-testid="addressable"
        type="button"
        disabled={isUpdating}
        loading={isUpdating}
        onClick={() =>
          updateProRegistration({
            proRegistration,
            status: 'Adressable',
            isClubPro: false,
          })
        }
        className={classNames('!mb-sm !whitespace-nowrap')}
      >
        Labellisation
      </Button>
      <div>
        <Button
          data-testid="addressable"
          type="button"
          disabled={isUpdating}
          loading={isUpdating}
          onClick={() =>
            updateProRegistration({
              proRegistration,
              status: 'Adressable',
              isClubPro: true,
            })
          }
          className={classNames('!mb-xs !whitespace-nowrap')}
        >
          Club Pro uniquement
        </Button>
      </div>
      <div>
        <Button
          data-testid="addressable"
          type="button"
          disabled={isUpdating}
          loading={isUpdating}
          onClick={() =>
            updateProRegistration({
              proRegistration,
              status: 'Adressable',
            })
          }
          className={classNames('!mb-xs !mr-xs !whitespace-nowrap')}
        >
          Parrainage
        </Button>
      </div>
      {proRegistration.websites?.personalWebsite && (
        <div>
          <Button
            variant={status === 'Adressable' ? 'secondary' : 'primary'}
            data-testid="addressable"
            type="button"
            onClick={() =>
              updateProRegistration({
                proRegistration,
                status: 'Adressable',
              })
            }
            className={classNames('!mb-xs !mr-xs !whitespace-nowrap')}
          >
            Récupéré site web
          </Button>
        </div>
      )}
      {proRegistration.websites?.linkedin && (
        <div>
          <Button
            data-testid="addressable"
            type="button"
            onClick={() =>
              updateProRegistration({
                proRegistration,
                status: 'Adressable',
              })
            }
            className={classNames('!mb-xs !mr-xs !whitespace-nowrap')}
          >
            Récupéré Linkedin
          </Button>
        </div>
      )}
    </div>
  );
};
