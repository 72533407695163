import React from 'react';

import classNames from 'classnames';

import CrossCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircle.svg?react';
import PlusCircle from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircle.svg?react';
import { useDevisDisplayContext } from '@travauxlib/shared/src/features/DevisDisplay/components/DevisDisplayContext';
import { locationsPicto } from '@travauxlib/shared/src/features/DevisDisplay/components/LocalisationIcon';
import { QuantityLabel } from '@travauxlib/shared/src/features/DevisDisplay/components/QuantityLabel';
import { Ligne } from '@travauxlib/shared/src/types';

import { LocationToDisplay } from './utils/getAllLocationsToDisplay';

type Props = {
  ligne: Ligne;
  location: LocationToDisplay;
};

export const Location: React.FC<Props> = ({ location, ligne }) => {
  const { customization } = useDevisDisplayContext();

  const Picto = locationsPicto[location.typeLocation];

  return (
    <span
      className={classNames(
        `inline-flex items-center rounded-sm border text-b2 text-neutral-800 py-[1px] px-xs`,
        !!location.status && 'pl-xxs',
        location.status === 'added' && 'border-success-600',
        location.status === 'deleted' && 'border-error-600 line-through',
      )}
      style={{ backgroundColor: `${customization?.textColor}3F` }}
    >
      {location.status === 'added' && (
        <PlusCircle data-testid="added" height="12" width="12" className="text-success-600" />
      )}
      {location.status === 'deleted' && (
        <CrossCircle data-testid="deleted" height="12" width="12" className="text-error-600" />
      )}
      <Picto className="mr-xxs" height="20" width="20" />
      {location.label}
      &nbsp;:&nbsp;
      <QuantityLabel unite={ligne.unite} quantite={location.quantite} />
    </span>
  );
};
