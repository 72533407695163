// prettier-ignore
// eslint-disable-file
// I AM AUTO-GENERATED BY buildFromCsv.js
// A test will fail if you try to prevent this

export const ouvrages = [
    [
        "Installations et nettoyages",
        "Installations"
    ],
    [
        "Protection des parties communes",
        "Installations"
    ],
    [
        "Protection ascenseur",
        "Installations"
    ],
    [
        "Protection des sols",
        "Installations"
    ],
    [
        "Amenée et repli du matériel",
        "Installations"
    ],
    [
        "Nettoyage de chantier",
        "Installations"
    ],
    [
        "Échafaudage",
        "Installations"
    ],
    [
        "Déchetterie accès facile",
        "Installations"
    ],
    [
        "Déchetterie accès difficile",
        "Installations"
    ],
    [
        "Constat d'huissier",
        "Installations"
    ],
    [
        "Démolition salle d'eau",
        "Démolition & Dépose"
    ],
    [
        "Démolition salle de bain",
        "Démolition & Dépose"
    ],
    [
        "Dépose cuisine",
        "Démolition & Dépose"
    ],
    [
        "Démolition cuisine",
        "Démolition & Dépose"
    ],
    [
        "Ouverture/démolition de cloison",
        "Démolition & Dépose"
    ],
    [
        "Démolition cloison placo",
        "Démolition & Dépose"
    ],
    [
        "Démolition mur briques/parpaings",
        "Démolition & Dépose"
    ],
    [
        "Dépose de doublage",
        "Démolition & Dépose"
    ],
    [
        "Démolition faux plafond",
        "Démolition & Dépose"
    ],
    [
        "Démolition allège fenêtre",
        "Démolition & Dépose"
    ],
    [
        "Ouverture/démolition de mur porteur",
        "Démolition & Dépose"
    ],
    [
        "Démolition murs porteurs",
        "Démolition & Dépose"
    ],
    [
        "Démolition plancher",
        "Démolition & Dépose"
    ],
    [
        "Dépose toiture",
        "Démolition & Dépose"
    ],
    [
        "Dépose fenêtres & portes fenêtres",
        "Démolition & Dépose"
    ],
    [
        "Dépose de baies vitrées",
        "Démolition & Dépose"
    ],
    [
        "Dépose de porte d'entrée",
        "Démolition & Dépose"
    ],
    [
        "Dépose de persienne",
        "Démolition & Dépose"
    ],
    [
        "Dépose réseau d'eau",
        "Démolition & Dépose"
    ],
    [
        "Dépose canalisations gaz",
        "Démolition & Dépose"
    ],
    [
        "Dépose tableau électrique",
        "Démolition & Dépose"
    ],
    [
        "Dépose réseau électrique",
        "Démolition & Dépose"
    ],
    [
        "Purge réseau chauffage",
        "Démolition & Dépose"
    ],
    [
        "Dépose chauffe-eau électrique",
        "Démolition & Dépose"
    ],
    [
        "Dépose chaudière gaz",
        "Démolition & Dépose"
    ],
    [
        "Dépose chaudière fioul",
        "Démolition & Dépose"
    ],
    [
        "Dépose radiateur",
        "Démolition & Dépose"
    ],
    [
        "Dépose sèche-serviette",
        "Démolition & Dépose"
    ],
    [
        "Dépose radiateurs électriques",
        "Démolition & Dépose"
    ],
    [
        "Dépose de radiateurs à eau",
        "Démolition & Dépose"
    ],
    [
        "Dépose/repose radiateur à eau",
        "Démolition & Dépose"
    ],
    [
        "Dépose VMC",
        "Démolition & Dépose"
    ],
    [
        "Démolition cheminée",
        "Démolition & Dépose"
    ],
    [
        "Dépose baignoire",
        "Démolition & Dépose"
    ],
    [
        "Dépose douche",
        "Démolition & Dépose"
    ],
    [
        "Dépose lavabo ou vasque",
        "Démolition & Dépose"
    ],
    [
        "Dépose WC",
        "Démolition & Dépose"
    ],
    [
        "Dépose bidet",
        "Démolition & Dépose"
    ],
    [
        "Dépose évier",
        "Démolition & Dépose"
    ],
    [
        "Dépose de carrelage",
        "Démolition & Dépose"
    ],
    [
        "Dépose sol souple",
        "Démolition & Dépose"
    ],
    [
        "Dépose moquette/ vinyle/ sol souple",
        "Démolition & Dépose"
    ],
    [
        "Dépose jonc de mer",
        "Démolition & Dépose"
    ],
    [
        "Dépose de parquet",
        "Démolition & Dépose"
    ],
    [
        "Dépose parquet massif",
        "Démolition & Dépose"
    ],
    [
        "Dépose parquet flottant",
        "Démolition & Dépose"
    ],
    [
        "Dépose plinthes",
        "Démolition & Dépose"
    ],
    [
        "Dépose faïence",
        "Démolition & Dépose"
    ],
    [
        "Dépose crédence cuisine",
        "Démolition & Dépose"
    ],
    [
        "Dépose papier peint",
        "Démolition & Dépose"
    ],
    [
        "Dépose de moulures",
        "Démolition & Dépose"
    ],
    [
        "Dépose toile de verre",
        "Démolition & Dépose"
    ],
    [
        "Dépose lambris",
        "Démolition & Dépose"
    ],
    [
        "Dépose paille japonaise",
        "Démolition & Dépose"
    ],
    [
        "Dépose tissu mural",
        "Démolition & Dépose"
    ],
    [
        "Dépose menuiseries bois",
        "Démolition & Dépose"
    ],
    [
        "Dépose porte intérieure",
        "Démolition & Dépose"
    ],
    [
        "Dépose d'étagères",
        "Démolition & Dépose"
    ],
    [
        "Longrine",
        "Gros oeuvre & structure"
    ],
    [
        "Semelles filantes",
        "Gros oeuvre & structure"
    ],
    [
        "Semelles isolées",
        "Gros oeuvre & structure"
    ],
    [
        "Dalle béton",
        "Gros oeuvre & structure"
    ],
    [
        "Seuil maçonné",
        "Gros oeuvre & structure"
    ],
    [
        "Poteau béton",
        "Gros oeuvre & structure"
    ],
    [
        "Poutre béton",
        "Gros oeuvre & structure"
    ],
    [
        "Voile béton armé",
        "Gros oeuvre & structure"
    ],
    [
        "Mur blocs",
        "Gros oeuvre & structure"
    ],
    [
        "Mur brique",
        "Gros oeuvre & structure"
    ],
    [
        "Chaînages",
        "Gros oeuvre & structure"
    ],
    [
        "Linteaux",
        "Gros oeuvre & structure"
    ],
    [
        "Appuis de fenêtre",
        "Gros oeuvre & structure"
    ],
    [
        "Plancher béton armé",
        "Gros oeuvre & structure"
    ],
    [
        "Plancher poutrelles-hourdis",
        "Gros oeuvre & structure"
    ],
    [
        "Plancher collaborant à bac acier",
        "Gros oeuvre & structure"
    ],
    [
        "Plancher bois",
        "Gros oeuvre & structure"
    ],
    [
        "Chape allégée",
        "Gros oeuvre & structure"
    ],
    [
        "Chape anhydrite",
        "Gros oeuvre & structure"
    ],
    [
        "Chape sèche",
        "Gros oeuvre & structure"
    ],
    [
        "Ouverture trémie",
        "Gros oeuvre & structure"
    ],
    [
        "Rebouchage Trémie",
        "Gros oeuvre & structure"
    ],
    [
        "Escalier béton",
        "Gros oeuvre & structure"
    ],
    [
        "Bande d'arase",
        "Gros oeuvre & structure"
    ],
    [
        "Enduit d'imperméabilisation",
        "Gros oeuvre & structure"
    ],
    [
        "Drainage périphérique",
        "Gros oeuvre & structure"
    ],
    [
        "Charpente traditionnelle bois",
        "Gros oeuvre & structure"
    ],
    [
        "Charpente industrielle bois",
        "Gros oeuvre & structure"
    ],
    [
        "ITE avec enduit minéral",
        "Façade, Couverture & ITE"
    ],
    [
        "Enduits muraux",
        "Façade, Couverture & ITE"
    ],
    [
        "ITE toiture sarking",
        "Façade, Couverture & ITE"
    ],
    [
        "Couverture tuile",
        "Façade, Couverture & ITE"
    ],
    [
        "Couverture ardoise",
        "Façade, Couverture & ITE"
    ],
    [
        "Gouttières",
        "Façade, Couverture & ITE"
    ],
    [
        "Descente EP",
        "Façade, Couverture & ITE"
    ],
    [
        "Porte d'entrée",
        "Menuiseries extérieures"
    ],
    [
        "Blindage porte d'entrée",
        "Menuiseries extérieures"
    ],
    [
        "Remplacement porte d'entrée",
        "Menuiseries extérieures"
    ],
    [
        "Remplacement fenêtres - PVC",
        "Menuiseries extérieures"
    ],
    [
        "Remplacement porte-fenêtre - PVC",
        "Menuiseries extérieures"
    ],
    [
        "Remplacement Baie vitrée - PVC",
        "Menuiseries extérieures"
    ],
    [
        "Remplacement porte fenêtre - PVC",
        "Menuiseries extérieures"
    ],
    [
        "Remplacement fenêtres - Alu",
        "Menuiseries extérieures"
    ],
    [
        "Remplacement porte-fenêtre - Alu",
        "Menuiseries extérieures"
    ],
    [
        "Remplacement Baie vitrée - Alu",
        "Menuiseries extérieures"
    ],
    [
        "Remplacement porte fenêtre - Alu",
        "Menuiseries extérieures"
    ],
    [
        "Remplacement fenêtres - Bois",
        "Menuiseries extérieures"
    ],
    [
        "Remplacement porte-fenêtre - Bois",
        "Menuiseries extérieures"
    ],
    [
        "Remplacement Baie vitrée - Bois",
        "Menuiseries extérieures"
    ],
    [
        "Remplacement porte fenêtre - Bois",
        "Menuiseries extérieures"
    ],
    [
        "Fenêtre",
        "Menuiseries extérieures"
    ],
    [
        "Porte-fenêtre",
        "Menuiseries extérieures"
    ],
    [
        "Baie vitrée coulissante",
        "Menuiseries extérieures"
    ],
    [
        "Fenêtre de toit",
        "Menuiseries extérieures"
    ],
    [
        "Remplacement de persienne",
        "Menuiseries extérieures"
    ],
    [
        "Remplacement de volet roulant",
        "Menuiseries extérieures"
    ],
    [
        "Condamnation de porte placo",
        "Plâtrerie & ITI"
    ],
    [
        "Cloison placo",
        "Plâtrerie & ITI"
    ],
    [
        "Cloison carreaux de plâtre",
        "Plâtrerie & ITI"
    ],
    [
        "ITI murs",
        "Plâtrerie & ITI"
    ],
    [
        "Doublage isolant",
        "Plâtrerie & ITI"
    ],
    [
        "Doublage pour passage de réseau",
        "Plâtrerie & ITI"
    ],
    [
        "Doublage collé",
        "Plâtrerie & ITI"
    ],
    [
        "Doublage sur rail",
        "Plâtrerie & ITI"
    ],
    [
        "ITI faux plafonds",
        "Plâtrerie & ITI"
    ],
    [
        "Faux plafond isolant",
        "Plâtrerie & ITI"
    ],
    [
        "Faux plafond pour passage de réseaux",
        "Plâtrerie & ITI"
    ],
    [
        "Faux plafond plâtre",
        "Plâtrerie & ITI"
    ],
    [
        "Faux plafond dalles",
        "Plâtrerie & ITI"
    ],
    [
        "ITI combles perdus",
        "Plâtrerie & ITI"
    ],
    [
        "ITI combles sous rampants",
        "Plâtrerie & ITI"
    ],
    [
        "Isolation des combles perdus",
        "Plâtrerie & ITI"
    ],
    [
        "Isolation des combles sous rampants",
        "Plâtrerie & ITI"
    ],
    [
        "Réfection plomberie",
        "Plomberie & CVC"
    ],
    [
        "Réfection plomberie salle d'eau",
        "Plomberie & CVC"
    ],
    [
        "Réfection plomberie salle de bain",
        "Plomberie & CVC"
    ],
    [
        "Réfection plomberie WC",
        "Plomberie & CVC"
    ],
    [
        "Chaudière gaz",
        "Plomberie & CVC"
    ],
    [
        "Remplacement chaudière gaz",
        "Plomberie & CVC"
    ],
    [
        "Pompe à chaleur air/eau",
        "Plomberie & CVC"
    ],
    [
        "Chauffe-eau électrique",
        "Plomberie & CVC"
    ],
    [
        "Remplacement chauffe-eau électrique",
        "Plomberie & CVC"
    ],
    [
        "Chauffe-eau thermodynamique",
        "Plomberie & CVC"
    ],
    [
        "Attentes pour lave-linge et/ou lave-vaisselle",
        "Plomberie & CVC"
    ],
    [
        "Attentes et évacuations évier",
        "Plomberie & CVC"
    ],
    [
        "Attentes et évacuations lave-linge",
        "Plomberie & CVC"
    ],
    [
        "Réseau alimentation chauffage",
        "Plomberie & CVC"
    ],
    [
        "Réseau alimentation eau",
        "Plomberie & CVC"
    ],
    [
        "Radiateurs électriques",
        "Plomberie & CVC"
    ],
    [
        "Remplacement radiateurs électriques",
        "Plomberie & CVC"
    ],
    [
        "Ajout radiateurs électriques",
        "Plomberie & CVC"
    ],
    [
        "Radiateurs à eau",
        "Plomberie & CVC"
    ],
    [
        "Remplacement radiateur à eau",
        "Plomberie & CVC"
    ],
    [
        "Radiateurs à eau supplémentaires",
        "Plomberie & CVC"
    ],
    [
        "Vannes d'arrêts",
        "Plomberie & CVC"
    ],
    [
        "Vannes d'arrêt radiateur",
        "Plomberie & CVC"
    ],
    [
        "Remplacement robinets radiateurs",
        "Plomberie & CVC"
    ],
    [
        "Robinets thermostatiques",
        "Plomberie & CVC"
    ],
    [
        "Plancher chauffant électrique",
        "Plomberie & CVC"
    ],
    [
        "Plancher chauffant à eau",
        "Plomberie & CVC"
    ],
    [
        "Douche",
        "Plomberie & CVC"
    ],
    [
        "Baignoire",
        "Plomberie & CVC"
    ],
    [
        "Lavabo",
        "Plomberie & CVC"
    ],
    [
        "Lave-main",
        "Plomberie & CVC"
    ],
    [
        "Meuble vasque",
        "Plomberie & CVC"
    ],
    [
        "Meuble simple vasque",
        "Plomberie & CVC"
    ],
    [
        "Meuble double vasque",
        "Plomberie & CVC"
    ],
    [
        "WC",
        "Plomberie & CVC"
    ],
    [
        "WC à poser",
        "Plomberie & CVC"
    ],
    [
        "WC suspendu",
        "Plomberie & CVC"
    ],
    [
        "Évier",
        "Plomberie & CVC"
    ],
    [
        "Aérateur",
        "Plomberie & CVC"
    ],
    [
        "Remplacement de bouches VMC",
        "Plomberie & CVC"
    ],
    [
        "VMC",
        "Plomberie & CVC"
    ],
    [
        "VMC simple flux",
        "Plomberie & CVC"
    ],
    [
        "Monobloc réversible",
        "Plomberie & CVC"
    ],
    [
        "Monosplit réversible",
        "Plomberie & CVC"
    ],
    [
        "Trisplit réversible",
        "Plomberie & CVC"
    ],
    [
        "Plan électrique",
        "Électricité"
    ],
    [
        "Réfection électrique complète",
        "Électricité"
    ],
    [
        "Distribution réseau électrique en goulotte",
        "Électricité"
    ],
    [
        "Distribution réseau apparent",
        "Électricité"
    ],
    [
        "Pose tableau électrique",
        "Électricité"
    ],
    [
        "Distribution réseau",
        "Électricité"
    ],
    [
        "Pose tableau communication",
        "Électricité"
    ],
    [
        "Gaine Technique Logement (GTL)",
        "Électricité"
    ],
    [
        "Consuel électrique",
        "Électricité"
    ],
    [
        "Mise à la terre",
        "Électricité"
    ],
    [
        "Appareillages électriques",
        "Électricité"
    ],
    [
        "Éclairages",
        "Électricité"
    ],
    [
        "Carrelage",
        "Revêtement sol"
    ],
    [
        "Mosaïque sol",
        "Revêtement sol"
    ],
    [
        "Carreaux de ciment sol",
        "Revêtement sol"
    ],
    [
        "Parquet neuf",
        "Revêtement sol"
    ],
    [
        "Rénovation parquet",
        "Revêtement sol"
    ],
    [
        "Sol vinyle",
        "Revêtement sol"
    ],
    [
        "Moquette",
        "Revêtement sol"
    ],
    [
        "Béton ciré",
        "Revêtement sol"
    ],
    [
        "Rénovation tomettes",
        "Revêtement sol"
    ],
    [
        "Préparation murs - peinture par le client",
        "Revêtement mur & plafond"
    ],
    [
        "Peinture murs",
        "Revêtement mur & plafond"
    ],
    [
        "Préparation plafonds - peinture par le client",
        "Revêtement mur & plafond"
    ],
    [
        "Peinture plafonds",
        "Revêtement mur & plafond"
    ],
    [
        "Toile de verre",
        "Revêtement mur & plafond"
    ],
    [
        "Papier peint",
        "Revêtement mur & plafond"
    ],
    [
        "Peinture et rénovation de porte",
        "Revêtement mur & plafond"
    ],
    [
        "Peinture radiateur",
        "Revêtement mur & plafond"
    ],
    [
        "Peinture fenêtre",
        "Revêtement mur & plafond"
    ],
    [
        "Faïence",
        "Revêtement mur & plafond"
    ],
    [
        "Pose crédence cuisine",
        "Revêtement mur & plafond"
    ],
    [
        "Mosaïque murale",
        "Revêtement mur & plafond"
    ],
    [
        "Carreaux de ciment mural",
        "Revêtement mur & plafond"
    ],
    [
        "Carrelage niche douche ou bain",
        "Revêtement mur & plafond"
    ],
    [
        "Carrelage tablier de baignoire",
        "Revêtement mur & plafond"
    ],
    [
        "Carrelage coffrage",
        "Revêtement mur & plafond"
    ],
    [
        "Baguettes finition",
        "Revêtement mur & plafond"
    ],
    [
        "Bibliothèque",
        "Menuiseries intérieures"
    ],
    [
        "Bibliothèque du commerce à monter",
        "Menuiseries intérieures"
    ],
    [
        "Bibliothèque sur mesure",
        "Menuiseries intérieures"
    ],
    [
        "Dressing",
        "Menuiseries intérieures"
    ],
    [
        "Dressing du commerce à monter",
        "Menuiseries intérieures"
    ],
    [
        "Dressing sur mesure",
        "Menuiseries intérieures"
    ],
    [
        "Menuiserie complexe",
        "Menuiseries intérieures"
    ],
    [
        "Placard",
        "Menuiseries intérieures"
    ],
    [
        "Placards du commerce à monter",
        "Menuiseries intérieures"
    ],
    [
        "Placards sur mesure",
        "Menuiseries intérieures"
    ],
    [
        "Porte standard",
        "Menuiseries intérieures"
    ],
    [
        "Remplacement porte standard",
        "Menuiseries intérieures"
    ],
    [
        "Porte acoustique",
        "Menuiseries intérieures"
    ],
    [
        "Porte à galandage",
        "Menuiseries intérieures"
    ],
    [
        "Porte coulissante",
        "Menuiseries intérieures"
    ],
    [
        "Verrière sur mesure",
        "Menuiseries intérieures"
    ],
    [
        "Verrière sur mesure petite",
        "Menuiseries intérieures"
    ],
    [
        "Verrière sur mesure moyenne",
        "Menuiseries intérieures"
    ],
    [
        "Verrière sur mesure grande",
        "Menuiseries intérieures"
    ],
    [
        "Verrière sur mesure très grande",
        "Menuiseries intérieures"
    ],
    [
        "Verrière du commerce",
        "Menuiseries intérieures"
    ],
    [
        "Verrière du commerce petite",
        "Menuiseries intérieures"
    ],
    [
        "Verrière du commerce moyenne",
        "Menuiseries intérieures"
    ],
    [
        "Porte atelier verrière",
        "Menuiseries intérieures"
    ],
    [
        "Meubles",
        "Cuisine"
    ],
    [
        "Mobilier du commerce en kit à monter",
        "Cuisine"
    ],
    [
        "Pose mobilier par cuisiniste déjà choisi",
        "Cuisine"
    ],
    [
        "Fourniture & Pose mobilier prémonté",
        "Cuisine"
    ],
    [
        "Plan de travail",
        "Cuisine"
    ],
    [
        "Remplacement plan de travail",
        "Cuisine"
    ],
    [
        "Evier",
        "Cuisine"
    ],
    [
        "Remplacement évier",
        "Cuisine"
    ],
    [
        "Mitigeur évier",
        "Cuisine"
    ],
    [
        "Four",
        "Cuisine"
    ],
    [
        "Hotte",
        "Cuisine"
    ],
    [
        "Plaque de cuisson",
        "Cuisine"
    ],
    [
        "Réfrigérateur",
        "Cuisine"
    ],
    [
        "Lave-vaisselle",
        "Cuisine"
    ],
    [
        "Lave-linge",
        "Cuisine"
    ],
    [
        "Pleine masse",
        "VRD & Extérieurs"
    ],
    [
        "Semelles filantes ou isolées",
        "VRD & Extérieurs"
    ],
    [
        "Clôture et seuils de portail",
        "VRD & Extérieurs"
    ],
    [
        "Fouille en passe alternée",
        "VRD & Extérieurs"
    ],
    [
        "Remblais",
        "VRD & Extérieurs"
    ],
    [
        "Réseau eaux usées",
        "VRD & Extérieurs"
    ],
    [
        "Pompe de relevage",
        "VRD & Extérieurs"
    ],
    [
        "Réseau eaux pluviales",
        "VRD & Extérieurs"
    ],
    [
        "Puisard",
        "VRD & Extérieurs"
    ],
    [
        "Caniveau",
        "VRD & Extérieurs"
    ],
    [
        "Réseau eau potable",
        "VRD & Extérieurs"
    ],
    [
        "Réseau ERDF",
        "VRD & Extérieurs"
    ],
    [
        "Réseau Télécom",
        "VRD & Extérieurs"
    ],
    [
        "Portail",
        "VRD & Extérieurs"
    ],
    [
        "Portillon",
        "VRD & Extérieurs"
    ],
    [
        "Clôtures",
        "VRD & Extérieurs"
    ],
    [
        "Terrasse et Deck",
        "VRD & Extérieurs"
    ],
    [
        "Véhicules électriques",
        "VRD & Extérieurs"
    ]
] as const;

export const ouvragesDesignation = ouvrages.map(ouvrage => ouvrage[0]);

export type OuvrageIdentifier = (typeof ouvragesDesignation)[number];

type OuvrageLots = { [K in OuvrageIdentifier]: string };
export const ouvragesLots: OuvrageLots = Object.fromEntries(ouvrages) as OuvrageLots;
