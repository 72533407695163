import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { ProCompanyUsersComponentsProps } from '@travauxlib/shared/src/features/ProCompanyUsersControlPannel/types';
import { parametresBaseUrl } from '@travauxlib/shared/src/utils/urls';

import { ProCompanyUserCard } from './ProCompanyUserCard';

import { useOpenHandleProCompanyUserModal } from '../../hooks/useOpenAddOrUpdateProUserModal';
import { useOpenConfirmRestoreUserModal } from '../../hooks/useOpenConfirmRestoreUserModal';

export const ProCompanyUserCardList: FC<ProCompanyUsersComponentsProps> = ({
  currentUser,
  proUsers,
  onSubmit,
  archiveProUserAccount,
  updateProUser,
  handleSignInAsPro,
}) => {
  const navigate = useNavigate();
  const openHandleProCompanyUserModal = useOpenHandleProCompanyUserModal();
  const openConfirmRestoreUserModal = useOpenConfirmRestoreUserModal(updateProUser);
  return (
    <>
      {currentUser && (
        <ProCompanyUserCard
          user={currentUser}
          openEditUserModal={() => navigate(parametresBaseUrl)}
        />
      )}
      {proUsers.map(proUser => (
        <ProCompanyUserCard
          key={proUser.uuid}
          user={proUser}
          openEditUserModal={() => {
            proUser.archived
              ? openConfirmRestoreUserModal({ proUser, updateProUser })
              : openHandleProCompanyUserModal({
                  onSubmit,
                  proUser,
                  archiveUser: archiveProUserAccount,
                });
          }}
          handleSignInAsPro={handleSignInAsPro}
        />
      ))}
    </>
  );
};
