import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';

import { BaseStep, ExecuteParams } from '../BaseStep';

type Input = { proCompany?: ProCompanyAdminView };

export class LogOutAsPro extends BaseStep<Input> {
  public name = 'Logout as pro';

  public fields = [];

  async execute({ apiURL, proCompany, request }: ExecuteParams<Input>): Promise<void> {
    const proUserId = proCompany?.proUsers[0]?.id;

    if (!proUserId) {
      super.error('No pro user id found ?');
      return Promise.reject('No pro user ID');
    }

    super.log(`Logout as pro`);

    await request(`${apiURL}/pro/users/logout`, { method: 'POST' });

    super.log('OK');
  }
}
