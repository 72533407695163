import React from 'react';

import { Outlet } from 'react-router-dom';

import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeft.svg?react';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

export const GestionFacturationLayout: React.FC = () => (
  <div>
    <ButtonLink className="mb-md !px-0" variant="tertiary" leftIcon={<ChevronLeft />} to=".">
      Retour
    </ButtonLink>
    <Outlet />
  </div>
);
