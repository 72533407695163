import React from 'react';

import classNames from 'classnames';

import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';

type Props = {
  centerIcon: JSX.Element;
  label: string | JSX.Element;
  className?: string;
  disabled?: boolean;
  inline?: boolean;
  separator?: boolean;
  helperLocationText?: string;
  children: React.ReactNode;
};

export const LocationCard: React.FC<Props> = ({
  centerIcon,
  label,
  className,
  disabled,
  children,
  inline = true,
  separator = false,
  helperLocationText,
}) => (
  <div
    className={classNames(
      className,
      'relative group justify-start',
      'flex flex-col relative text-center',
      { 'pointer-events-none bg-neutral-100 text-neutral-300': disabled },
    )}
  >
    <div
      className={classNames('flex flex-wrap justify-between grow items-start', !inline && 'mb-md')}
    >
      <div
        className={classNames(
          'flex items-end min-w-[10rem]',
          inline && 'h-full py-sm',
          helperLocationText && '!py-0 !items-start',
        )}
      >
        <div className="w-lg h-lg text-primary">{centerIcon}</div>
        <div className="text-left pl-xs">
          <span className="text-b2">{label}</span>
          {helperLocationText && (
            <p className="text-sm mb-0 text-neutral-600">{helperLocationText}</p>
          )}
        </div>
      </div>
      {inline && children}
    </div>
    {!inline && children}
    {separator && <PrestationsSeparator neutralColor={true} />}
  </div>
);
