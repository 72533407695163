import { Flow } from './Flow';

import { CreateLeadStep } from '../gdm_steps/Client/CreateLead';
import { SearchLeadByEmail } from '../gdm_steps/Client/SearchLeadByEmail';
import { SetLeadAdressable } from '../gdm_steps/Client/SetLeadAdressable';
import { SetLeadAvailableStep } from '../gdm_steps/Client/SetLeadAvailable';
import { SignInAsClient } from '../gdm_steps/Client/SignInAsClient';

// eslint-disable-next-line
export const createAdressableLead = () =>
  new Flow()
    .next(new CreateLeadStep())
    .next(new SignInAsClient())
    .next(new SetLeadAvailableStep())
    .next(new SearchLeadByEmail())
    .next(new SetLeadAdressable());
