import React from 'react';

import { NavLink } from 'react-router-dom';

import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

import { useSignInAsClient } from 'api/useSignInAsClient';
import { Calendly } from 'components/Calendly';
import { ProjectFiles } from 'components/ProjectFiles';
import { Lead } from 'types';

import { useLinkClientAccountToLead } from './api/useLinkClientAccountToLead';
import { LinkClientAccountModal } from './components/LinkClientAccountModal';

type Props = {
  lead: Lead;
};

export const ExtraInformations: React.FC<Props> = ({ lead }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleSignIn = useSignInAsClient();
  const { clientUuid, estimations = [] } = lead;

  const { linkClientAccount, isPending } = useLinkClientAccountToLead();

  return (
    <>
      {lead.calendlyMeetings.length !== 0 && (
        <div className="!mb-sm">
          <strong>Calendly :</strong>
          <Calendly calendlyMeetings={lead.calendlyMeetings} />
        </div>
      )}
      <div className="!mb-sm">
        <NavLink className="!mr-xs" to={`/leads?email=${lead.email}`}>
          Autres leads avec cet email
        </NavLink>
        <br />
        {lead.phoneNumber && (
          <NavLink className="!mr-xs" to={`/leads?phoneNumber=${lead.phoneNumber}`}>
            Autres leads avec ce n° de tel
          </NavLink>
        )}
        {clientUuid ? (
          <Button
            type="button"
            className="mt-xs"
            onClick={() => handleSignIn(clientUuid, lead.project?.uuid)}
            fullwidth
            size="sm"
          >
            Se connecter à son compte
          </Button>
        ) : (
          <>
            <AlertMessage
              title="Ce lead n'est pas rattaché à un compte client, cliquer sur le bouton ci-dessous pour le créer."
              level="warning"
            />
            <Button
              onClick={() => setIsModalOpen(true)}
              className="my-xs"
              variant="secondary"
              fullwidth
              size="sm"
            >
              Créer un espace client
            </Button>
            <LinkClientAccountModal
              onClose={() => setIsModalOpen(false)}
              isModalOpen={isModalOpen}
              leadId={lead.id}
              hasExistingProject={!!lead.project}
            />
          </>
        )}
        {lead.project && (
          <ButtonLink
            className="my-xs"
            variant="secondary"
            to={`/projects/${lead.project.uuid}`}
            fullwidth
            size="sm"
          >
            Voir le projet
          </ButtonLink>
        )}
        {!lead.project && lead.clientUuid && (
          <>
            <AlertMessage
              title="Ce lead n'est pas rattaché à un projet, cliquer sur le bouton ci-dessous pour le créer."
              level="warning"
            />
            <Button
              onClick={() =>
                linkClientAccount({
                  leadId: lead.id,
                  clientAccountUuid: lead.clientUuid!,
                  hasExistingProject: false,
                })
              }
              disabled={isPending}
              className="my-xs"
              variant="secondary"
              fullwidth
              size="sm"
            >
              Créer un projet
            </Button>
          </>
        )}
        {!!lead.project?.files?.length && (
          <>
            <div className="!font-bold">Pièces jointes :</div>
            <ProjectFiles
              files={lead.project.files}
              zipName={`${lead.firstName}_${lead.lastName}`}
            />
          </>
        )}
      </div>
      {estimations.length ? (
        <Tag className="mb-sm" size="md" variant="info" label="Estimations" />
      ) : null}
      {estimations.map(estimation => (
        <div key={estimation.url} className="!mb-sm">
          <ButtonLink href={estimation.url} target="_blank" rel="noopener noreferrer">
            {estimation.token} : <EURCurrency amount={estimation.prixTotalTTC} /> TTC
          </ButtonLink>
        </div>
      ))}
    </>
  );
};
