import React from 'react';

import { Modal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { useCreatePartner } from 'features/Partners/api/mutations';

import { OrganizationForm } from './OrganizationForm';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export const NewPartnerModal: React.FC<Props> = ({ isOpen, closeModal }) => {
  const { createOrganization } = useCreatePartner();

  return (
    <Modal isOpen={isOpen} handleClose={closeModal} title="Créer un nouveau partenaire">
      <OrganizationForm
        onSubmit={async values => {
          try {
            await createOrganization(values);
            closeModal();
          } catch {}
        }}
      />
    </Modal>
  );
};
