import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { request } from '@travauxlib/shared/src/utils/request';
import { formatLocalDate } from '@travauxlib/shared/src/utils/time';

import { useChantierQuery } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';

const handleCloseChantier = async (chantier: Chantier): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${chantier.slug}/close`;

  await request(requestURL, {
    method: 'POST',
    body: { value: formatLocalDate(chantier.dateFinReelle) },
  });
};

export const useCloseChantier = (): { closeChantier: typeof handleCloseChantier } => {
  const { query } = useChantierQuery(
    handleCloseChantier,
    'de la clôture du chantier',
    'Le chantier a bien été mis à jour.',
  );

  return { closeChantier: query };
};
