import React, { useState } from 'react';

import AttachBreak from '@travauxlib/shared/src/components/DesignSystem/assets/AttachBreak.svg?react';
import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { SimpleModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { ClientAccount } from '@travauxlib/shared/src/types';

import { useSignInAsClient } from 'api/useSignInAsClient';
import { ClientAccountDropdown } from 'features/Chantiers/components/MainInfo/ClientAccountDropdown';
import {
  useLinkClientAccount,
  useUnlinkClientAccount,
} from 'features/Project/api/useLinkClientAccount';
import { ProjectContext } from 'features/Project/api/useProjectContext';

import { CreateClientAccountForm } from './CreateClientAccountForm';

type Props = {
  projectContext: ProjectContext;
  onClose: () => void;
  isOpen: boolean;
};

export const ClientAccountsModal: React.FC<Props> = ({ projectContext, onClose, isOpen }) => {
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const signInAsClient = useSignInAsClient();
  const [selectedAccount, setSelectedAccount] = useState<ClientAccount | undefined>(undefined);

  const linkClientAccount = useLinkClientAccount();
  const unlinkClientAccount = useUnlinkClientAccount();

  const validateAction = async (uuid: string): Promise<void> => {
    await linkClientAccount({
      projectUuid: projectContext.project.uuid,
      clientAccountUuid: uuid,
    });
    setSelectedAccount(undefined);
  };

  return (
    <SimpleModal
      isOpen={isOpen}
      handleClose={onClose}
      isScrollable
      size="lg"
      title={
        <div className="flex">
          Ajouter un nouveau contact{' '}
          <WithTooltip
            trigger={
              <span className="flex items-center ml-xs">
                <HelpCircle className="w-md h-md" />
              </span>
            }
            position="bottom"
            width="22rem"
          >
            <div className="!p-sm">
              <p>Les projets peuvent être attachés à un ou plusieurs comptes clients.</p>
              <div className="!text-right">
                <Link
                  href="https://www.notion.so/hemea/Espace-client-hemea-captures-d-cran-1b3874309bb64724bd3c240e03f35165"
                  className="font-bold"
                  target="_blank"
                >
                  Lire la documentation →
                </Link>
              </div>
            </div>
          </WithTooltip>
        </div>
      }
      validateAction={
        displayForm
          ? undefined
          : {
              label: 'Ajouter',
              onClick: () => validateAction(selectedAccount!.uuid),
              disabled: !selectedAccount,
            }
      }
    >
      <>
        <div className="text-right mb-xl">
          <Link
            href={projectContext.project.pipedriveDealUrl ?? '#'}
            target="_blank"
            rel="noopener noreferrer"
            disabled={!projectContext.project.pipedriveDealUrl}
            disabledMessageTooltip="Il n'y a pas de deal pipedrive associé au projet"
          >
            Voir le deal Pipedrive
          </Link>
        </div>
        <Table
          columnConfigs={[
            {
              name: 'Nom',
              columnKey: 'firstName',
            },
            {
              name: 'Email',
              columnKey: 'email',
            },
            {
              name: 'N° tél',
              columnKey: 'phoneNumber',
            },
            {
              name: 'Action',
              hideSort: true,
              renderValue: (_, account) => (
                <>
                  <Button
                    type="button"
                    onClick={() => signInAsClient(account.uuid, projectContext.project.uuid)}
                    size="sm"
                  >
                    Se connecter à son compte
                  </Button>{' '}
                  {projectContext.clientAccounts.length > 1 && (
                    <IconButton
                      type="button"
                      title="Détacher le compte du projet"
                      onClick={() =>
                        unlinkClientAccount({
                          projectUuid: projectContext.project.uuid,
                          clientAccountUuid: account.uuid,
                        })
                      }
                      size="sm"
                    >
                      <AttachBreak />
                    </IconButton>
                  )}
                </>
              ),
            },
          ]}
          items={projectContext.clientAccounts}
          defaultSort={{ columnKey: 'email', order: 'asc' }}
        />
        <div className="my-md">
          {!displayForm && (
            <ClientAccountDropdown
              onChange={clientAccount => setSelectedAccount(clientAccount)}
              onFilter={clientAccounts =>
                clientAccounts.filter(
                  foundAcc =>
                    !projectContext.clientAccounts.map(ca => ca.uuid).includes(foundAcc.uuid),
                )
              }
            />
          )}
        </div>
        <CreateClientAccountForm
          displayForm={displayForm}
          setDisplayForm={setDisplayForm}
          onClose={onClose}
          onValidate={validateAction}
        />
      </>
    </SimpleModal>
  );
};
