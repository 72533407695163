export const yesNoForm = {
  options: [
    {
      label: 'Oui',
      value: 'oui',
    },
    {
      label: 'Non',
      value: 'non',
    },
  ],
  parse: (value: string) => value === 'oui',
  format: (value: string) => {
    if (value === undefined) {
      return undefined;
    }
    return value ? 'oui' : 'non';
  },
};
