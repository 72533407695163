import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { request } from '@travauxlib/shared/src/utils/request';
import { formatLocalDate } from '@travauxlib/shared/src/utils/time';

import { useChantierQuery } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';

const handleLaunchChantier = async (chantier: Chantier): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${chantier.slug}/launch`;

  await request(requestURL, {
    method: 'POST',
    body: { value: formatLocalDate(chantier.dateDebutReelle) },
  });
};

export const useLaunchChantier = (): { launchChantier: typeof handleLaunchChantier } => {
  const { query } = useChantierQuery(
    handleLaunchChantier,
    'du démarrage du chantier',
    'Le chantier a bien été mis à jour.',
  );

  return { launchChantier: query };
};
