import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { CounterField } from '@travauxlib/shared/src/components/DesignSystem/components/Counter/Field';
import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { MenuiseriesInterieuresPorte } from '@travauxlib/shared/src/features/Configurateur/types/MenuiseriesInterieures';
import { getLocationLabel } from '@travauxlib/shared/src/features/Configurateur/utils/getLocationLabel';
import { locationsPicto } from '@travauxlib/shared/src/features/DevisDisplay/components/LocalisationIcon';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { LocationCard } from 'features/Configurateur/components/LocationCard';
import { LocationsCounters } from 'features/Configurateur/components/LocationsCounters';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

type Props = {
  configuration: Configuration;
};

const Depose: React.FC<{ name: string; configuration: Configuration }> = ({
  name,
  configuration,
}) => (
  <>
    <QuestionTitle prefix="2" title="Pièces concernées ?" />
    {configuration.locations.map(location => {
      const Picto = locationsPicto[location.typeLocation];
      return (
        <LocationCard
          key={location.uuid}
          className="mb-md"
          label={getLocationLabel(configuration, location)}
          centerIcon={<Picto />}
        >
          <div>
            <CounterField
              name={`${name}.locationQuantities.${location.uuid}.depose`}
              id={`${name}.locationQuantities.${location.uuid}.depose`}
            />
          </div>
        </LocationCard>
      );
    })}
  </>
);

const Remplace: React.FC<{ name: string; configuration: Configuration }> = ({
  name,
  configuration,
}) => (
  <>
    <QuestionTitle prefix="2" title="Pièces concernées ?" />
    {configuration.locations.map(location => {
      const Picto = locationsPicto[location.typeLocation];
      return (
        <LocationCard
          key={location.uuid}
          className="mb-md"
          label={getLocationLabel(configuration, location)}
          centerIcon={<Picto />}
        >
          <div>
            <CounterField
              name={`${name}.locationQuantities.${location.uuid}.remplace`}
              id={`${name}.locationQuantities.${location.uuid}.remplace`}
            />
          </div>
        </LocationCard>
      );
    })}
  </>
);

const PoseLocations: React.FC<{ name: string; configuration: Configuration }> = ({
  name,
  configuration,
}) => (
  <>
    <QuestionTitle
      prefix="2"
      title="Pièces concernées"
      tooltip={
        <div>
          L’installation d’une porte à galandage nécessite de démolir la cloison où on souhaite
          l’installer.
          <br />
          - Il faut une cloison de 10cm, plus épaisse que les cloisons standard afin de pouvoir y
          faire coulisser la porte.
          <br />
          - La longueur de mur pour rentrer la porte doit comprendre une marge, par exemple pour une
          porte de 60cm, il faut 60x2+10cm= 130cm de longueur totale. <br />- Attention pas de
          fixation possible sur la cloison galandage ni de passage de réseaux
        </div>
      }
    />
    <LocationsCounters
      configuration={configuration}
      locations={configuration.locations}
      getFields={locationUuid => [
        { label: 'Porte battante', name: `${name}.locationQuantities.${locationUuid}.battantes` },
        {
          label: 'Porte coulissante',
          name: `${name}.locationQuantities.${locationUuid}.coulissantes`,
        },
        {
          label: 'Porte à galandage',
          name: `${name}.locationQuantities.${locationUuid}.galandages`,
        },
      ]}
    />
  </>
);

export const Portes: React.FC<Props> = ({ configuration }) => (
  <Accordion
    className="mb-md"
    title="Porte à déposer, ajouter ou remplacer ?"
    prefix="1"
    defaultExpanded
  >
    <FieldArray<MenuiseriesInterieuresPorte> name="portes">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={index} className="mb-lg">
                <div className="flex justify-between">
                  <QuestionTitle
                    prefix="1"
                    title="Type de prestation ?"
                    tooltip={
                      <>
                        - Portes à déposer et ajouter s'utilisent dans le cas où l'existant et le
                        projet sont différents (ex : une porte battante qu'on veut changer par une
                        porte à galandage)
                        <br />- Portes à remplacer s'utilise dans le cas où on veut remplacer à
                        l'identique les portes
                        <br />
                        <br />
                        Type de porte battante selon les gammes :
                        <br />- Primo : porte à âme creuse
                        <br />- Confort : porte à âme pleine
                        <br />- Premium & Exclusif : Porte acoustique
                      </>
                    }
                  />
                  <PrestationDelete onDelete={() => fields.remove(index)} />
                </div>
                <OptionCheckbox fields={fields} value={value} index={index} />
                <ChipListField
                  name={`${name}.typePrestation`}
                  renderingOptions={{
                    containerClassName: 'mb-md flex flex-wrap gap-xs',
                  }}
                  options={[
                    {
                      label: 'Porte à déposer',
                      value: 'Dépose',
                    },
                    {
                      label: 'Porte à ajouter',
                      value: 'Pose',
                    },
                    {
                      label: 'Porte à remplacer',
                      value: 'Remplace',
                    },
                  ]}
                />
                {value.typePrestation === 'Dépose' && (
                  <Depose name={name} configuration={configuration} />
                )}
                {value.typePrestation === 'Pose' && (
                  <PoseLocations name={name} configuration={configuration} />
                )}
                {value.typePrestation === 'Remplace' && (
                  <Remplace name={name} configuration={configuration} />
                )}
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
