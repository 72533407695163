import React, { useEffect } from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { NumberInput } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput';
import { toast } from '@travauxlib/shared/src/components/Notifications';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { useAuth } from 'api/useAuth';
import { disabledMessageForPermissionLabel } from 'utils/constants';

import { useDevisModalitesPartialUpdate } from '../api/useDevisModalitesUpdate';

const calculateCommissionAmount = (
  basePrice: number,
  commissionRate: number,
  doApplyTva?: boolean,
): number => roundToTwoDecimals(basePrice * (commissionRate / 100) * (doApplyTva ? 1.2 : 1));

type Props = {
  devisToken: string;
  commissionKey: string;
  basePrice: number;
  customRate?: number;
  defaultRate: number;
  doApplyTva?: boolean;
  devisSigned: boolean;
};

export const CommissionCard: React.FC<Props> = ({
  basePrice,
  customRate,
  defaultRate,
  commissionKey,
  devisToken,
  doApplyTva,
  devisSigned,
}) => {
  const [commissionRate, setCommissionRate] = React.useState<number>(customRate ?? defaultRate);
  const [commissionAmountHT, setCommissionAmountHT] = React.useState<number>(
    calculateCommissionAmount(basePrice, commissionRate),
  );
  const [commissionAmountTTC, setCommissionAmountTTC] = React.useState<number>(
    calculateCommissionAmount(basePrice, commissionRate, doApplyTva),
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const updateCommission = useDevisModalitesPartialUpdate();
  const {
    adminUserProfile: { hasPermission },
  } = useAuth();

  useEffect(() => {
    setCommissionRate(customRate ?? defaultRate);
  }, [defaultRate]);

  const onClickUpdateCommission = (newValue: number | null): Promise<any> => {
    setIsLoading(true);
    return updateCommission({
      targetKey: commissionKey,
      devisToken,
      body: {
        newValue,
      },
    })
      .then(() => {
        toast.success(<span className="text-dark">Le taux a bien été mis a jour</span>);
      })
      .catch(() => {
        toast.error(
          <span className="text-dark">
            Une erreur est survenue lors de la mise a jour des taux
          </span>,
        );
      })
      .finally(() => setIsLoading(false));
  };

  const onChangeCommissionRate = (newCommissionRate: number | undefined): void => {
    if (newCommissionRate === undefined) {
      return;
    }
    setCommissionRate(newCommissionRate);
    setCommissionAmountHT(calculateCommissionAmount(basePrice, newCommissionRate));
    setCommissionAmountTTC(calculateCommissionAmount(basePrice, newCommissionRate, doApplyTva));
  };

  const onClickResetRate = (): void => {
    onChangeCommissionRate(defaultRate);
    onClickUpdateCommission(null);
  };

  const userCanSave = hasPermission('UPDATE_MODALITE_HEMEA') && !devisSigned;

  return (
    <>
      <div className="flex items-center space-around justify-center">
        <NumberInput
          id={`${commissionKey}_basePrice`}
          data-testid={`${commissionKey}_basePrice`}
          label="Montant"
          onChange={() => {}}
          value={basePrice}
          disabled
          suffix="€"
        />
        <div className="p-md">x</div>
        <NumberInput
          id={`${commissionKey}_commissionRate`}
          data-testid={`${commissionKey}_commissionRate`}
          label="Taux"
          onChange={onChangeCommissionRate}
          value={commissionRate}
          suffix="%"
        />
        <div className="p-md">=</div>
        {commissionKey === 'tauxCommissionHemea' && (
          <>
            <NumberInput
              id={`${commissionKey}_commissionAmount`}
              data-testid={`${commissionKey}_commissionAmount`}
              label="Montant HT"
              onChange={() => {}}
              disabled
              value={commissionAmountHT}
              suffix="€"
            />
            <div className="p-md">* 1.2</div>
          </>
        )}
        <NumberInput
          id={`${commissionKey}_commissionAmountTTC`}
          data-testid={`${commissionKey}_commissionAmountTTC`}
          label="Montant TTC"
          onChange={() => {}}
          disabled
          value={commissionAmountTTC}
          suffix="€"
        />
      </div>
      <div className="flex justify-between mt-md">
        <Button
          onClick={onClickResetRate}
          variant="secondary"
          disabled={!userCanSave || isLoading}
          data-testid={`${commissionKey}_reset`}
          disabledMessageTooltip={disabledMessageForPermissionLabel('UPDATE_MODALITE_HEMEA')}
        >
          Appliquer taux par defaut
        </Button>
        <Button
          onClick={() => onClickUpdateCommission(commissionRate)}
          disabled={!userCanSave || isLoading}
          data-testid={`${commissionKey}_save`}
          disabledMessageTooltip={disabledMessageForPermissionLabel('UPDATE_MODALITE_HEMEA')}
        >
          Sauvegarder
        </Button>
      </div>
    </>
  );
};
