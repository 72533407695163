import React from 'react';

import { DatePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { companyStatuses } from '@travauxlib/shared/src/utils/constants';
import { parseDateInput } from '@travauxlib/shared/src/utils/time';

export type Props = {
  siret?: string;
};

export const LegalInfosCDPL: React.FC<Props> = ({ siret }) => (
  <div className="mb-sm">
    <h4>Infos légales</h4>
    <div className="flex flex-col sm-desktop:flex-row gap-md">
      <InputField
        className="w-full"
        label={
          <span>
            Numéro de TVA&nbsp;
            {siret && (
              <small>
                <Link
                  href={`https://corporama.com/search?company=${siret}&amp;action_no_filter=GO&amp;include_policy=any&amp;hs=1`}
                  target="_blank"
                  rel="noopener noreferrer"
                  inline
                >
                  Trouver sur corporama
                </Link>
              </small>
            )}
          </span>
        }
        id="numeroTVA"
        name="cdplInformation.numeroTVA"
      />
      <InputField
        className="w-full"
        label={
          <span>
            Siret &nbsp;
            {siret && (
              <small>
                <Link
                  href={`https://corporama.com/search?company=${siret}&amp;action_no_filter=GO&amp;include_policy=any&amp;hs=1`}
                  target="_blank"
                  rel="noopener noreferrer"
                  inline
                >
                  Corporama
                </Link>
              </small>
            )}
          </span>
        }
        id="siret"
        name="cdplInformation.siret"
      />
      <DropdownField
        className="w-full"
        id="statut"
        label="Statut (SARL, SAS, Auto-entrepreneur...)"
        placeholder="Statut"
        name="cdplInformation.statutEntreprise"
        options={companyStatuses}
      />
    </div>
    <div className="flex flex-col sm-desktop:flex-row gap-md mt-md">
      <DatePickerField
        containerClassName="w-full"
        parse={parseDateInput}
        id="companyCreationDate"
        name="cdplInformation.companyCreationDate"
        label="Date de création de l'entreprise"
      />
      <InputField className="w-full" label="Ville du RCS" id="RCS" name="cdplInformation.RCS" />
      <NumberInputField
        className="w-full"
        name="cdplInformation.capital"
        id="capital"
        label="Capital"
      />
    </div>
  </div>
);
