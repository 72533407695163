import React from 'react';

import HelpCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircleOutline.svg?react';
import {
  IconButton,
  IconSizes,
} from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';

import { WithTooltip } from '.';

export type Props = {
  innerTooltipText: string;
  iconSize?: IconSizes;
};

export const QuestionMarkTooltip: React.FC<Props> = ({ innerTooltipText, iconSize }) => (
  <WithTooltip
    trigger={
      <IconButton size={iconSize} className="relative flex items-center ml-xs">
        <HelpCircleOutline className="text-neutral-600" />
      </IconButton>
    }
    position="bottom-end"
  >
    <div>{innerTooltipText}</div>
  </WithTooltip>
);
