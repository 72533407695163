import { SuiviChantierCycleHistory } from '@travauxlib/shared/src/features/SuiviChantier/types';

export const useLatestSuiviChantierAValider = (
  suiviChantierHistoryList?: SuiviChantierCycleHistory[],
): SuiviChantierCycleHistory | undefined =>
  suiviChantierHistoryList &&
  suiviChantierHistoryList.length > 0 &&
  !suiviChantierHistoryList[0].etapes.tp &&
  suiviChantierHistoryList[0].status === 'pending'
    ? suiviChantierHistoryList[0]
    : undefined;
