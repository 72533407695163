import React from 'react';

import classNames from 'classnames';
import { UseFieldConfig } from 'react-final-form';

import { CheckboxListField } from '@travauxlib/shared/src/components/DesignSystem/components/CheckboxList/Field';
import { RadioButtonsListField } from '@travauxlib/shared/src/components/DesignSystem/components/RadioButtonsList/Field';

import { PrestationDelete } from '../PrestationDelete';
import { QuestionTitle } from '../QuestionTitle';

type Option = {
  label: string;
  value: string;
};

type Props = {
  title?: string;
  optionCheckbox?: JSX.Element;
  description?: string;
  tooltip?: string | JSX.Element;
  options: Option[];
  multipleAnswers?: boolean;
  name: string;
  prefix?: string;
  className?: string;
  onDelete?: () => void;
} & UseFieldConfig<any>;

const optionClassName =
  'flex items-center mb-0 h-full pl-[10px] py-md w-full border-b border-neutral-300 last:border-b-0';

export const FormControl: React.FC<Props> = ({
  title,
  optionCheckbox,
  description,
  tooltip,
  multipleAnswers,
  options,
  name,
  prefix,
  className,
  onDelete,
  ...rest
}) => (
  <div className={classNames('flex flex-col max-w-[42.5rem]', className)}>
    <div className="flex justify-between">
      <QuestionTitle title={title} description={description} tooltip={tooltip} prefix={prefix} />
      {onDelete && <PrestationDelete onDelete={onDelete} />}
    </div>

    {optionCheckbox}
    <div>
      {multipleAnswers ? (
        <CheckboxListField
          renderingOptions={{
            optionClassName: optionClassName,
          }}
          name={name}
          options={options}
          {...rest}
        />
      ) : (
        <RadioButtonsListField
          {...rest}
          name={name}
          optionsClassName={optionClassName}
          options={options}
        />
      )}
    </div>
  </div>
);
