import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from 'features/Project/api/useProjectContext';

type RemoveFilePayload = {
  projectUuid: string;
  fileUuid: string;
};
const handleRemoveFile = ({ projectUuid, fileUuid }: RemoveFilePayload): Promise<void> => {
  const requestUrl = `${APP_CONFIG.apiURL}/admin/projects/${projectUuid}/files/${fileUuid}`;

  return request(requestUrl, {
    method: 'DELETE',
  });
};

export const useRemoveProjectDocument = (): {
  removeFile: typeof handleRemoveFile;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();

  const { mutateAsync: removeFile, isPending } = useMutation({
    mutationFn: handleRemoveFile,
    onSuccess: (_, { projectUuid }) => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY, projectUuid] });
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la suppression du fichier');
    },
  });

  return { removeFile, isLoading: isPending };
};
