import React from 'react';

import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { departments } from '@travauxlib/shared/src/utils/departments';

import { AffiliateFilter, LeadStatus } from 'types';

import { StatusTabs } from './StatusTabs';
import { Table } from './Table';

import { useLeads } from '../api/useLeads';

export type FormValues = {
  dealOwners?: string[];
  firstClickSources?: string[];
  departments?: string[];
  startDate: string;
  endDate: string;
  affiliateFilter: AffiliateFilter;
  status: LeadStatus[];
};

type Props = {
  values: FormValues;
  startDate?: string;
  endDate: string;
  affiliateFilter: AffiliateFilter;
  status: LeadStatus[];
};

const MAX_DISPLAYED = 200;

export const List: React.FC<Props> = ({
  startDate,
  endDate,
  status = [],
  affiliateFilter,
  values,
}) => {
  const { leads, isLoading } = useLeads({
    ...values,
    startDate,
    endDate,
    status,
    affiliateFilter,
    allDepartments: departments,
  });

  const warningMaxDisplayed = leads && leads.length > MAX_DISPLAYED && (
    <small className="!text-danger !text-center !font-bold">
      Attention, tous les leads ne sont pas affichés car il y en a plus de {MAX_DISPLAYED} pour ces
      critères.
    </small>
  );
  return (
    <>
      <div className="!mb-sm">
        <StatusTabs filteredLeadsCount={leads.length} loading={isLoading} />
      </div>
      {isLoading ? (
        <LoaderWrapper />
      ) : (
        <>
          {warningMaxDisplayed}
          <Table leads={leads.slice(0, MAX_DISPLAYED)} />
          {warningMaxDisplayed}
        </>
      )}
    </>
  );
};
