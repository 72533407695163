import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { useRedirectQuery } from '@travauxlib/shared/src/hooks/useRedirectQuery';

import { LoginPage } from '../features/LoginPage';

export const UnauthenticatedRoutes: React.FC = () => {
  const query = useRedirectQuery();
  const redirectUrl = `/login?${query}`;
  return (
    <div className="w-full mx-auto px-md sm-desktop:px-xl pt-md bg-neutral-100 h-full grow flex flex-col">
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="*" element={<Navigate replace to={redirectUrl} />} />
      </Routes>
    </div>
  );
};
