import { useQuery } from '@tanstack/react-query';

import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { request } from '@travauxlib/shared/src/utils/request';

export const CONFIGURATEUR_CONFIGURATION_KEY = 'configurateur_configuration';

export const useConfiguration = (
  projectUuid: string,
): {
  isLoading: boolean;
  isFetching: boolean;
  configuration?: Configuration;
} => {
  const { isLoading, isFetching, data } = useQuery<Configuration | null>({
    queryKey: [CONFIGURATEUR_CONFIGURATION_KEY, projectUuid],

    queryFn: () => {
      if (projectUuid) {
        const requestURL = `${APP_CONFIG.apiURL}/admin/configuration/${projectUuid}`;
        return request(requestURL);
      }
      return null;
    },
  });
  return { isLoading, isFetching, configuration: data || undefined };
};
