import React from 'react';

import { useDownloadAsZip } from '@travauxlib/shared/src/api/useDownloadAsZip';
import Document from '@travauxlib/shared/src/components/DesignSystem/assets/Document.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { DownloadableFileWithTag } from '@travauxlib/shared/src/types';
import { slugify } from '@travauxlib/shared/src/utils/slugify';
import { dateTimeFormat } from '@travauxlib/shared/src/utils/time';

type Props = {
  zipName: string;
  files: DownloadableFileWithTag[];
};

export const ProjectFiles: React.FC<Props> = ({ zipName, files }) => {
  const urls = files.map(file => file.downloadUrl);
  const { downloadAsZip, isLoading } = useDownloadAsZip();
  const parsedZipName = `${slugify(zipName)}.zip`;

  return (
    <>
      {files.length > 0 && (
        <Button
          variant="secondary"
          size="sm"
          className="!mb-xs"
          type="button"
          onClick={() =>
            downloadAsZip({
              zipName: parsedZipName,
              urls,
            })
          }
          disabled={isLoading}
          disabledMessageTooltip="En cours de téléchargement"
          loading={isLoading}
        >
          Tout télécharger
        </Button>
      )}
      {files.map(file => (
        <div key={file.uuid}>
          <Link
            href={file.downloadUrl}
            target="_blank"
            rel="noopener noreferrer"
            icon={<Document />}
          >
            {file.name}
          </Link>
          <span className="float-right">&nbsp;{dateTimeFormat(file.createdAt)}</span>
        </div>
      ))}
    </>
  );
};
