import React from 'react';

import { FileUpload } from '@travauxlib/shared/src/components/FileUpload';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { toast } from '@travauxlib/shared/src/components/Notifications';

import { UploadPicturePayload } from 'features/Trombinoscope/api/usePersons';

type Props = {
  onUpload: (payload: UploadPicturePayload) => void;
  personUuid?: string;
  imageUrl?: string;
  alt?: string;
  isOfficial?: boolean;
};

export const PictureComponent: React.FC<Props> = ({
  imageUrl,
  onUpload,
  personUuid,
  alt,
  isOfficial = false,
}) => (
  <div>
    <div className="w-full px-md mx-auto">
      <div className="flex flex-wrap -mx-md">
        <div className="tablet:w-6/12 tablet:px-md relative">
          {imageUrl && (
            <ImgLazy
              imgixParams={{ fit: 'crop', crop: 'top' }}
              width={220}
              height={300}
              url={imageUrl}
              alt={alt}
            />
          )}
        </div>
        <div className="tablet:w-2/12 tablet:px-md relative" />
        <div className="tablet:w-4/12 tablet:px-md relative !my-auto">
          <FileUpload
            accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }}
            onChange={data => personUuid && onUpload({ personUuid, data, isOfficial })}
            disabled={!personUuid}
            alreadyUploaded={!!imageUrl}
            onReject={rejectionMessage => toast.error(rejectionMessage)}
          />
        </div>
      </div>
    </div>
  </div>
);
