import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { CounterField } from '@travauxlib/shared/src/components/DesignSystem/components/Counter/Field';
import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { FenetresToit } from '@travauxlib/shared/src/features/Configurateur/types/MenuiseriesExterieures';
import { getLocationLabel } from '@travauxlib/shared/src/features/Configurateur/utils/getLocationLabel';
import { locationsPicto } from '@travauxlib/shared/src/features/DevisDisplay/components/LocalisationIcon';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { LocationCard } from 'features/Configurateur/components/LocationCard';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

type Props = {
  configuration: Configuration;
};

export const FenetresToits: React.FC<Props> = ({ configuration }) => (
  <Accordion
    className="mb-md"
    title="Fenêtre de toit à créer ?"
    prefix="3"
    tooltip="Pour créer une fenêtre de toit, il faut ouvrir un chevêtre en toiture et prévoir les raccordements d’étanchéité."
    defaultExpanded
  >
    <FieldArray<FenetresToit> name="fenetresToits">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={name}>
                <div className="mb-md relative">
                  <div className="flex justify-between">
                    <QuestionTitle prefix="1" title="Pièces concernées ?" />
                    <PrestationDelete onDelete={() => fields.remove(index)} />
                  </div>
                  <OptionCheckbox fields={fields} value={value} index={index} />
                  {configuration.locations.map(location => {
                    const Picto = locationsPicto[location.typeLocation];
                    return (
                      <LocationCard
                        key={location.uuid}
                        className="mb-md"
                        label={getLocationLabel(configuration, location)}
                        centerIcon={<Picto />}
                      >
                        <CounterField
                          name={`${name}.locationQuantities.${location.uuid}`}
                          id={`${name}.locationQuantities.${location.uuid}`}
                        />
                      </LocationCard>
                    );
                  })}
                </div>
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
