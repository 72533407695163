import React from 'react';

import { DropdownMultiField } from '@travauxlib/shared/src/components/DesignSystem/components/DropdownMulti/Field';
import { departments } from '@travauxlib/shared/src/utils/departments';

import { useSalesTeamMembers } from 'features/Trombinoscope/api/useTeams';
import { noDealOwner } from 'utils/constants';
import { firstClickSourcesOptions } from 'utils/constants/acquisition';

type Props = {
  isEditable: boolean;
};

export const Search: React.FC<Props> = ({ isEditable }) => {
  const { teamMembers: sales } = useSalesTeamMembers();
  const dealOwnerOptions = [
    { value: noDealOwner, label: 'Pas de deal owner' },
    ...sales.map(({ email, firstName, lastName }) => ({
      value: email,
      label: `${firstName} ${lastName}`,
    })),
  ];
  return (
    <div className="flex flex-wrap -mx-md">
      {isEditable && (
        <div className="mb-xs sm-desktop:mb-0 basis-full sm-desktop:basis-0 flex-grow px-md relative">
          <DropdownMultiField
            id="dealOwners"
            name="dealOwners"
            label="Deal owner"
            options={dealOwnerOptions}
          />
        </div>
      )}
      <div className="mb-xs sm-desktop:mb-0 basis-full sm-desktop:basis-0 flex-grow px-md relative">
        <DropdownMultiField
          id="departments"
          name="departments"
          label="Départements"
          options={departments}
        />
      </div>
      <div className="mb-xs sm-desktop:mb-0 basis-full sm-desktop:basis-0 flex-grow px-md relative">
        <DropdownMultiField
          label="Source"
          id="firstClickSources"
          name="firstClickSources"
          options={firstClickSourcesOptions}
        />
      </div>
    </div>
  );
};
