import React, { useState } from 'react';

import uniqBy from 'lodash/uniqBy';

import { DropdownOption } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Option';
import { DropdownMulti } from '@travauxlib/shared/src/components/DesignSystem/components/DropdownMulti';
import { Input } from '@travauxlib/shared/src/components/DesignSystem/components/Input';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { ColumnConfig } from '@travauxlib/shared/src/components/DesignSystem/components/Table/types';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { PartnerAccount, PartnerAgency, PartnerOrganization } from 'types';

import { FiltersContainer } from './FiltersContainer';

type Props = {
  partners: PartnerOrganization[];
  agencies: PartnerAgency[];
  accounts: PartnerAccount[];
  orgNameOptions: DropdownOption<string>[];
  iacOptions: DropdownOption<string | undefined>[];
  organisationsByUuid: { [k: string]: PartnerOrganization };
  agenciesGrouppedByUuid: { [k: string]: PartnerAgency };
};

const columns: ColumnConfig<EnrichedPartnerAccount>[] = [
  {
    name: 'Nom',
    columnKey: 'lastName',
  },
  {
    name: 'Prénom',
    columnKey: 'firstName',
  },
  {
    name: 'Email',
    columnKey: 'email',
  },
  {
    name: 'Téléphone',
    columnKey: 'phoneNumber',
  },
  {
    name: 'Organisation',
    columnKey: 'organisationName',
  },
  {
    name: 'Agence',
    columnKey: 'agencyName',
  },
  {
    name: 'Résponsable',
    columnKey: 'isOrganizationManager',
    renderValue: (v: boolean) => <>{v ? 'Oui' : 'Non'}</>,
  },
  {
    name: 'Crée le',
    columnKey: 'createdAt',
    renderValue: (d: string) => <>{formatFrenchDate(d)}</>,
  },
];

interface EnrichedPartnerAccount extends PartnerAccount {
  organisationName: string;
  agencyName: string;
  organisationUuid: string;
}

const enrichPartnerAccounts =
  (
    organisationsByUuid: { [k: string]: PartnerOrganization },
    agenciesByUuid: { [k: string]: PartnerAgency },
  ) =>
  (p: PartnerAccount) => ({
    ...p,
    organisationUuid: organisationsByUuid[agenciesByUuid[p.agencyUuid]?.organizationUuid]?.uuid,
    organisationName:
      organisationsByUuid[agenciesByUuid[p.agencyUuid]?.organizationUuid]?.name ?? 'Inconnu',
    agencyName: agenciesByUuid[p.agencyUuid]?.name ?? 'Inconnu',
  });

export const AccountsTable: React.FC<Props> = ({
  partners,
  agencies,
  accounts,
  organisationsByUuid,
  agenciesGrouppedByUuid,
  orgNameOptions,
}) => {
  const [nameFilter, setNameFilter] = useState<string[]>([]);
  const [agencyNameFilter, setAgencyNameFilter] = useState<string[]>([]);
  const [accountNameFilter, setAccountNameFilter] = useState<string>('');
  const [accountEmailFilter, setAccountEmailFilter] = useState<string>('');
  const [accountPhoneNumberFilter, setAccountPhoneNumberFilter] = useState<string>('');

  const agencyNameOptions = React.useMemo(
    () => uniqBy(agencies, 'name').map(a => ({ label: a.name, value: a.name })),
    [partners, accounts, agencies],
  );

  const fn = React.useCallback(enrichPartnerAccounts(organisationsByUuid, agenciesGrouppedByUuid), [
    organisationsByUuid,
    agenciesGrouppedByUuid,

    partners,
    accounts,
    agencies,
  ]);

  const enrichedAccounts = React.useMemo(
    () => accounts.map(fn),
    [partners, accounts, agencies, fn],
  );

  const filteredAccounts = React.useMemo(
    () =>
      enrichedAccounts.filter(
        a =>
          (nameFilter.length ? nameFilter.includes(a.organisationName) : true) &&
          (agencyNameFilter.length ? agencyNameFilter.includes(a.agencyName) : true) &&
          (accountNameFilter
            ? a.lastName.toLowerCase().startsWith(accountNameFilter.toLowerCase())
            : true) &&
          (accountEmailFilter ? a.email.startsWith(accountEmailFilter.toLowerCase()) : true) &&
          (accountPhoneNumberFilter
            ? !!a.phoneNumber
                ?.replace(' ', '')
                ?.startsWith(accountPhoneNumberFilter.replace(' ', ''))
            : true),
      ),
    [
      enrichedAccounts,
      nameFilter,
      agencyNameFilter,
      accountNameFilter,
      accountEmailFilter,
      accountPhoneNumberFilter,
    ],
  );

  return (
    <>
      <FiltersContainer>
        <DropdownMulti<string>
          id="nameFilter"
          options={orgNameOptions}
          onChange={setNameFilter}
          value={nameFilter}
          label="Nom organisation"
        />
        <DropdownMulti<string>
          id="agencyNameFilter"
          options={agencyNameOptions}
          onChange={setAgencyNameFilter}
          value={agencyNameFilter}
          label="Nom d'agence"
        />
        <Input
          id="accountNameFilter"
          value={accountNameFilter}
          onChange={setAccountNameFilter}
          label="Nom compte"
        />
        <Input
          id="accountEmailFilter"
          value={accountEmailFilter}
          onChange={setAccountEmailFilter}
          label="Email"
        />
        <Input
          id="accountPhoneNumberFilter"
          value={accountPhoneNumberFilter}
          onChange={setAccountPhoneNumberFilter}
          label="Téléphone"
        />
      </FiltersContainer>
      <Table<EnrichedPartnerAccount>
        columnConfigs={columns}
        items={filteredAccounts}
        itemsPerPage={50}
        onRowClick={({ agencyUuid, uuid, organisationUuid }) =>
          window.open(
            `/partners/${organisationUuid}/agencies/${agencyUuid}/accounts?accountUuid=${uuid}`,
          )
        }
      />
    </>
  );
};
