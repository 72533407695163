import { useMutation, useQueryClient } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { COMMISSIONS_KEY } from './useCommissions';

const handleUploadFactureCommission = ({
  commissionUuid,
  file,
}: {
  commissionUuid: string;
  file: File;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/partners/commissions/${commissionUuid}/upload`;
  const formData = new FormData();
  formData.append('file', file);
  return request(requestURL, {
    method: 'POST',
    body: formData,
  });
};

export const useUploadFactureCommission = (): typeof handleUploadFactureCommission => {
  const queryClient = useQueryClient();
  const { mutateAsync: uploadFactureCommission } = useMutation({
    mutationFn: handleUploadFactureCommission,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COMMISSIONS_KEY] });
    },
  });

  return uploadFactureCommission;
};
