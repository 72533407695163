import React, { useState } from 'react';

import { useOutletContext } from 'react-router-dom';

import PlusCircle from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircle.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

import { ProCompany } from 'types';

import { Review } from './Review';
import { ReviewsDetails } from './ReviewsDetails';

export const ReviewListing: React.FC = () => {
  const { proCompany } = useOutletContext<{ proCompany: ProCompany }>();
  const [showNew, setShowNew] = useState(false);
  const { slug, reviewsDetails, reviews = [] } = proCompany;

  return (
    <div className="container">
      <ReviewsDetails initialValues={reviewsDetails} slug={slug} />
      <hr />
      <div className="!m-sm">
        <div className="!flex !justify-center !items-center">
          <Button
            size="sm"
            leftIcon={<PlusCircle />}
            type="button"
            onClick={() => setShowNew(showNew => !showNew)}
          >
            Ajouter un avis
          </Button>
        </div>
      </div>
      {showNew && <Review slug={slug} />}
      {reviews.map(review => (
        <div key={review.reviewId} className="!mt-sm !pt-sm !pb-sm">
          <Review initialValues={review} slug={slug} reviewId={review.reviewId} />
        </div>
      ))}
    </div>
  );
};
