import { request } from '@travauxlib/shared/src/utils/request';

import { useChantierQuery } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';

const handleCancelSignature = async (signatureUuid: string): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/signatures/${signatureUuid}`;
  await request(requestURL, { method: 'DELETE' });
};

export const useCancelSignature = (): {
  isLoading: boolean;
  cancelSignature: typeof handleCancelSignature;
} => {
  const { isLoading, query } = useChantierQuery(
    handleCancelSignature,
    "de l'annulation de la signature",
    'La signature a bien été annulée.',
  );
  return { isLoading, cancelSignature: query };
};
