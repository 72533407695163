import React from 'react';

import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';

type Props = {
  onDelete: () => void;
};

export const PrestationDelete: React.FC<Props> = ({ onDelete }) => (
  <IconButton onClick={onDelete}>
    <Delete />
  </IconButton>
);
