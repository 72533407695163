import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from './useProjectContext';

const handleDuplicateDevis = async ({
  projectUuid,
  token,
}: {
  projectUuid: string;
  token: string;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/projects/${projectUuid}/devis/${token}/duplicate`;
  await request(requestURL, {
    method: 'POST',
  });
};

export const useDuplicateDevis = (): typeof handleDuplicateDevis => {
  const queryClient = useQueryClient();
  const { mutateAsync: duplicateDevis } = useMutation({
    mutationFn: handleDuplicateDevis,
    onSuccess: (_, { projectUuid }) => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY, projectUuid] });
    },
    onError: () => {
      toast.error("Le devis n'a pas pu être dupliqué.");
    },
  });
  return duplicateDevis;
};
