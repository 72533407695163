import React from 'react';

import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

import { useUpdateProCompany } from 'features/Entreprises/features/Entreprise/api/useUpdateProCompany';

type Props = {
  lemonwayAccountUrl: string;
  slug: string;
  isKycCompliant: boolean;
};

export const PaymentCreated: React.FC<Props> = ({ isKycCompliant, slug, lemonwayAccountUrl }) => {
  const { update } = useUpdateProCompany();
  const updateKyc = (isKycCompliant: boolean): void => {
    update({
      payment: {
        isKycCompliant,
      },
      slug,
    });
  };

  return (
    <>
      <div className="!text-center !my-lg">
        <ButtonLink href={lemonwayAccountUrl} target="_blank" rel="noopener noreferrer">
          Accéder au compte Lemonway
        </ButtonLink>
      </div>
      <Checkbox
        id="is-kyc-compliant"
        label="Entreprise validée KYC2 par Lemonway"
        checked={isKycCompliant}
        onChange={checked => updateKyc(checked)}
      />
    </>
  );
};
