import React, { useRef } from 'react';

import classNames from 'classnames';

import { LeftPart } from './LeftPart';
import { MiddlePart } from './MiddlePart';
import { RightPart } from './RightPart';
import { Left, Middle, Right } from './types';
import { containerClassNamesByState } from './utils';

import { Card, CardState } from '../Card';

export type Props = {
  state?: CardState;
  size?: 'md' | 'sm';
  disabled?: boolean;
  onChange?: (value: boolean | string) => void;
  value?: boolean | string;
  left?: Left;
  middle: Middle;
  children?: React.ReactNode;
  right?: Right;
  className?: string;
  bodyClassName?: string;
  disableBottomBorder?: boolean;
  shouldTruncate?: boolean;
};

export const ListItem: React.FC<Props> = ({
  state = 'elevated',
  size = 'md',
  className = '',
  bodyClassName = '',
  disabled,
  onChange,
  value,
  left,
  middle,
  children,
  right,
  shouldTruncate = true,
}) => {
  const isRightClickable =
    (right?.type === 'iconButton' || right?.type === 'button') && !!right.onClick;
  const rightElementRef = useRef<HTMLButtonElement>(null);
  const isContentAlignedOnOneLine =
    !middle.label && !children && !middle.iconButtons && !middle.buttons;

  return (
    <Card
      state={state}
      disabled={disabled}
      className={classNames('w-full text-b1', containerClassNamesByState[state], className)}
      bodyClassNames={classNames(
        'flex gap-xs',
        {
          'py-xs': size === 'sm',
          'items-center': isContentAlignedOnOneLine,
        },
        bodyClassName,
      )}
      onClick={event => {
        const doesRightHasBeenClicked =
          rightElementRef.current && rightElementRef.current.contains(event.target as Node);

        if (disabled) {
          return undefined;
        }
        if (isRightClickable && doesRightHasBeenClicked) {
          return right?.onClick?.();
        } // Right directly clicked
        if (left?.type === 'radio') {
          return onChange?.(left?.value);
        } // Radio
        if (right?.type === 'radio') {
          return onChange?.(right?.value);
        } // Radio
        if (onChange) {
          return onChange?.(!value);
        } // Checkbox
        if (isRightClickable) {
          return right.onClick?.();
        } // Right if no form control

        return undefined;
      }}
      role={onChange || isRightClickable ? 'button' : 'listitem'}
      aria-label={middle.title.toString()}
    >
      {left && <LeftPart left={left} value={value} disabled={disabled} />}
      <MiddlePart middle={middle} disabled={disabled} shouldTruncate={shouldTruncate}>
        {children}
      </MiddlePart>
      {right && <RightPart right={right} value={value} ref={rightElementRef} disabled={disabled} />}
    </Card>
  );
};
