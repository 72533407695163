import {
  Configuration,
  ConfigurationStatus,
} from '@travauxlib/shared/src/features/Configurateur/types';
import { LeadAdminView } from '@travauxlib/shared/src/types/api/admin/LeadAdminView';

import { BaseStep, ExecuteParams, StepField } from '../BaseStep';

type Input = { lead: LeadAdminView; configuration: Configuration };

export class ValidateConfigurateur extends BaseStep<
  Input,
  void,
  StepField[],
  { configuration: Configuration }
> {
  public name: string = 'Validate configurateur';

  public fields = [];

  async execute({ lead, configuration, apiURL, request }: ExecuteParams<Input>): Promise<void> {
    if (!lead) {
      super.error('Lead is undefined. Cannot validate a configurateur !');
      return Promise.reject();
    }

    super.log('Validating configurateur...');

    const projectUuid = lead.project?.uuid;

    if (!projectUuid) {
      super.error('Project is undefined. Cannot validate configurateur !');
      return Promise.reject();
    }

    const requestURL = `${apiURL}/admin/configuration/${projectUuid}`;
    const updatedConfiguration = {
      ...configuration,
      status: ConfigurationStatus.Sent,
    };

    await request(requestURL, {
      method: 'PUT',
      body: updatedConfiguration,
    });

    super.setCreatedEntities({ configuration: updatedConfiguration });

    super.log('Validated configurateur !');
  }
}
