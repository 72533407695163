import { ChantierAdminListItemView } from '@travauxlib/shared/src/types/api/admin/ChantierAdminListItemView';

import { BaseStep, ExecuteParams } from '../BaseStep';

type Input = { chantier: ChantierAdminListItemView };

export class ChantierEnableSuiviChantier extends BaseStep<Input> {
  public name = 'Chantier Enable Suivi Chantier';

  public fields = [];

  async execute({ chantier, apiURL, request }: ExecuteParams<Input>): Promise<void> {
    if (!chantier) {
      super.error('No chantier given');
      return Promise.reject();
    }

    await request(`${apiURL}/admin/chantiers/${chantier.slug}/switch-suivi-chantier/true`, {
      method: 'POST',
    });

    super.log('OK');
  }
}
