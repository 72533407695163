import React from 'react';

import _orderBy from 'lodash/orderBy';

import Person from '@travauxlib/shared/src/components/DesignSystem/assets/Person.svg?react';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';
import { useIsBelowBreakpoint } from '@travauxlib/shared/src/hooks/useIsBelowBreakpoint';

import { ProCompanyUserCardList } from './ProCompanyUsersCardList';
import { ProCompanyUserTable } from './ProCompanyUsersTable';

import { ProCompanyUsersControlPannelProps } from '../types';

export const ProCompanyUsers: React.FC<ProCompanyUsersControlPannelProps> = ({
  showArchived,
  proUsers,
  isFetchingUsers,
  isLoading,
  onSubmit,
  archiveProUserAccount,
  updateProUser,
  profile = undefined,
  handleSignInAsPro,
  slug,
}) => {
  const shouldDisplayMobile = useIsBelowBreakpoint('md-desktop');
  const orderedUsers = _orderBy(proUsers, 'isProCompanyAdmin', 'desc').filter(proUser =>
    profile
      ? proUser.uuid !== profile.uuid
      : (showArchived && proUser.archived) || (!showArchived && !proUser.archived),
  );
  const currentUser = showArchived && profile ? undefined : profile;

  if (!isFetchingUsers && !orderedUsers?.length && showArchived) {
    return (
      <div className="mt-xxl">
        <EmptyState
          className="mt-md"
          description="Vous n'avez aucun utilisateur archivé"
          icon={<Person />}
        />
      </div>
    );
  }

  return (
    <div>
      {shouldDisplayMobile ? (
        <ProCompanyUserCardList
          currentUser={currentUser}
          isCreatingUser={isLoading}
          onSubmit={onSubmit}
          proUsers={orderedUsers}
          archiveProUserAccount={archiveProUserAccount}
          updateProUser={updateProUser}
          handleSignInAsPro={handleSignInAsPro}
          slug={slug}
        />
      ) : (
        <ProCompanyUserTable
          currentUser={currentUser}
          onSubmit={onSubmit}
          proUsers={orderedUsers}
          archiveProUserAccount={archiveProUserAccount}
          updateProUser={updateProUser}
          handleSignInAsPro={handleSignInAsPro}
          slug={slug}
        />
      )}
    </div>
  );
};
