import { useMutation, useQueryClient } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { PARTNERS_KEY } from 'features/Partners/api/usePartners';

type HandleUploadLogoPartnerPayload = {
  formData: FormData;
  uuid: string;
};

const handleUploadLogoPartner = ({
  formData,
  uuid,
}: HandleUploadLogoPartnerPayload): Promise<void> =>
  request(`${APP_CONFIG.apiURL}/admin/partners/${uuid}/logo`, {
    method: 'POST',
    body: formData,
  });

export const useUploadLogoPartner = (): {
  isUploadingLogo: boolean;
  uploadLogoPartner: typeof handleUploadLogoPartner;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: uploadLogoPartner, isPending } = useMutation({
    mutationFn: handleUploadLogoPartner,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PARTNERS_KEY] });
    },
  });

  return { uploadLogoPartner, isUploadingLogo: isPending };
};
