import { Devis } from '@travauxlib/shared/src/types';

import { BaseStep, ExecuteParams } from '../BaseStep';

type Input = { dealUuid: string };

export class CreateDevisPrechiffrage extends BaseStep<
  Input,
  { devis: Devis },
  any,
  { devis: Devis }
> {
  public name = 'Create Devis prechiffrage';

  public fields = [];

  async execute({
    apiURL,
    dealUuid,
    seed,
    request,
  }: ExecuteParams<Input>): Promise<{ devis: Devis }> {
    if (!dealUuid) {
      this.error("Cannot create devis if I don't have a deal uuid");
      return Promise.reject();
    }

    super.log('Creating devis');
    let devisBody = {
      title: `${seed}-prechiffrage-godmode`,
      lots: [
        {
          label: 'Installation de chantier - Nettoyage',
          prixTotalHT: 208.72,
          prixTotalTTC: 229.59,
          uuid: 'b92ce0ae-1e45-4cca-b175-034ab8cd4b1f',
          items: [
            {
              description:
                "Mise en place de bâches de protection adhésives (résistantes et sécurisantes), couverture du palier et de l'escalier joignant l'étage inférieur.",
              designation: 'Mise en place de protections pour les parties communes',
              fournitureLignes: [],
              locations: [],
              lotUuid: 'b92ce0ae-1e45-4cca-b175-034ab8cd4b1f',
              marge: 30,
              montantTVA: 20.87,
              normalizedIdentifier: 'protection_parties_communes',
              order: 0,
              priority: 2,
              prixAchat: 160.55,
              prixHT: 208.72,
              prixMainOeuvreHT: 125,
              prixMateriauxHT: 35.55,
              prixPublicFournitureHT: 0,
              prixTTC: 229.59,
              prixUnitaireHT: 208.72,
              quantite: 1,
              tauxTVA: 10,
              type: 'ligne',
              unite: 'unitaire',
              uuid: '1d933edd-b703-4211-88a0-e4cd224a99f9',
            },
          ],
        },
      ],
      numero: '101',
      defaultTauxTVA: 10,
      defaultMargin: 30,
      projectTitle: `${seed}-prechiffrage-godmode`,
      isPreChiffrage: true,
    };

    const createdDevis: Devis = await request(`${apiURL}/pro/deals/${dealUuid}/devis`, {
      method: 'POST',
      body: devisBody,
    });

    super.setCreatedEntities({ devis: createdDevis });

    return { devis: createdDevis };
  }
}
