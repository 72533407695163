import React from 'react';

import {
  ModalContent,
  Props as ModalContentProps,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal/ModalContent';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';

import { useAgency } from 'features/Partners/hooks/useAgency';
import { usePartner } from 'features/Partners/hooks/usePartner';

import { PartnerTabs } from './PartnerTabs';

export const PartnerDetailsContent: React.FC<ModalContentProps> = ({ children, ...rest }) => {
  const partner = usePartner();
  const agency = useAgency();

  return (
    <ModalContent {...rest}>
      {partner && (
        <>
          <div className="mb-sm flex items-center">
            {partner.logoUrl && (
              <div className="mr-xs">
                <ImgLazy url={partner.logoUrl} width={50} />
              </div>
            )}
            <h4 className="mb-0">{partner.name}</h4>
          </div>
          <PartnerTabs agency={agency} />
        </>
      )}
      {children}
    </ModalContent>
  );
};
