import React from 'react';

import { Dropdown } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown';
import { Person } from '@travauxlib/shared/src/types';

export type Props = {
  onChange: (email?: string) => void;
  value?: string;
  persons?: Person[];
  label?: string;
  allowEmpty?: boolean;
  emptyLabel?: string;
  error?: string;
  id: string;
};

export const PeopleSelector: React.FC<Props> = ({
  onChange,
  persons = [],
  value,
  label = 'Chef de projet...',
  allowEmpty,
  emptyLabel = 'Indifférent',
  error,
  id,
  ...rest
}) => {
  const personsOptions = persons.map(p => ({
    label: `${p.firstName} ${p.lastName}`,
    value: p.email,
    imageUrl: p.officialPicture || 'https://picsum.photos/300/300',
  }));

  const options = allowEmpty
    ? [
        {
          label: emptyLabel,
          value: undefined,
        },
        ...personsOptions,
      ]
    : personsOptions;

  return (
    <Dropdown<string | undefined>
      onChange={onChange}
      error={error}
      label={label || ''}
      value={value}
      options={options}
      id={id}
      {...rest}
    />
  );
};
