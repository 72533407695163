import { useQuery } from '@tanstack/react-query';

import { ClientAction } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from 'features/Project/api/useProjectContext';

export const useActions = (
  projectUuid: string,
): { actions: ClientAction[]; isLoading: boolean } => {
  const { isLoading, data } = useQuery<{ data: ClientAction[] }>({
    queryKey: [PROJECT_KEY, projectUuid, 'actions'],
    queryFn: () => request(`${APP_CONFIG.apiURL}/admin/projects/${projectUuid}/actions`),
    refetchInterval: 5000,
  });

  return { actions: data?.data || [], isLoading };
};
