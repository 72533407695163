import { Devis } from '@travauxlib/shared/src/types';

import { BaseStep, ExecuteParams } from '../BaseStep';

type Input = { dealUuid: string };

export class GenerateDevisTS extends BaseStep<
  { dealUuid: string },
  { devisSup: Devis },
  any,
  { devisSup: Devis }
> {
  public name = 'Pro Create Devis TS';

  public fields = [];

  async execute({
    dealUuid,
    seed,
    apiURL,
    request,
  }: ExecuteParams<Input>): Promise<{ devisSup: Devis }> {
    if (!dealUuid) {
      this.error("Cannot create devis if I don't have a deal uuid");
      return Promise.reject();
    }

    const devis = {
      modalitesPaiement: [
        {
          label: 'Acompte à la signature',
          montant: 0,
          pourcentage: 40,
        },
        {
          label: 'Durant les travaux',
          montant: 0,
          pourcentage: 50,
        },
        {
          label: 'À la fin des travaux',
          montant: 0,
          pourcentage: 10,
        },
      ],
      typeContrat: 'sans_suivi',
      lots: [
        {
          label: 'Installation de chantier - Nettoyage',
          items: [
            {
              designation: "Protection des zones d'intervention",
              description: "Mise en place de bâches de protection des zones d'intervention.",
              tauxTVA: 10.0,
              prixUnitaireHT: 40000.0,
              quantite: 1,
              unite: 'unitaire',
              lot: 'Installation de chantier - Nettoyage',
              type: 'ligne',
            },
            {
              designation: "Nettoyage à l'avancement et fin de chantier",
              description: 'Enlèvement des protections, nettoyage des sols, poussière et résidus.',
              tauxTVA: 10.0,
              prixUnitaireHT: 200.0,
              quantite: 1,
              unite: 'unitaire',
              lot: 'Installation de chantier - Nettoyage',
              type: 'ligne',
            },
          ],
        },
      ],
      dateDebut: '2019-07-05',
      dateFin: '2019-11-05',
      title: `godmod-devis-ts-${seed}`,
      dureeValidite: {
        quantity: 1,
        unit: 'mois',
      },
      hasRemise: false,
      isTS: true,
    };

    super.log('Creating devis ts');

    const createdDevis = await request<Devis>(`${apiURL}/pro/deals/${dealUuid}/devis`, {
      method: 'POST',
      body: devis,
    });

    super.setCreatedEntities({ devisSup: createdDevis });

    return { devisSup: createdDevis };
  }
}
