import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { MenuiseriesInterieuresMenuiserieACreer } from '@travauxlib/shared/src/features/Configurateur/types/MenuiseriesInterieures';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { LocationsDimensions } from 'features/Configurateur/components/LocationsDimensions';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

type Props = {
  configuration: Configuration;
};

const MenuiseriesACreerLocation: React.FC<{
  name: string;
  configuration: Configuration;
  value: MenuiseriesInterieuresMenuiserieACreer;
}> = ({ configuration, name, value }) => (
  <>
    <QuestionTitle prefix="3" title="Pièces concernées ?" />
    <LocationsDimensions
      configuration={configuration}
      locations={configuration.locations}
      getFields={locationUuid => [
        {
          name: `${name}.locationQuantities.${locationUuid}.longueur`,
        },
        {
          name: `${name}.locationQuantities.${locationUuid}.hauteur`,
          defaultValue:
            value.typeFabrication === 'Du commerce' && value.typeMenuiserie !== 'Bibliothèque'
              ? 2.3
              : undefined,
        },
      ]}
    />
  </>
);

const MenuiseriesACreerFabrication: React.FC<{
  name: string;
  configuration: Configuration;
  value: MenuiseriesInterieuresMenuiserieACreer;
}> = ({ configuration, name, value }) => (
  <>
    <QuestionTitle prefix="2" title="Type de fabrication ?" />
    <ChipListField
      name={`${name}.typeFabrication`}
      renderingOptions={{
        containerClassName: 'mb-md flex flex-wrap gap-xs',
      }}
      options={[
        {
          label: 'Sur mesure',
          value: 'Sur mesure',
        },
        {
          label: 'Du commerce',
          value: 'Du commerce',
        },
      ]}
    />
    {!!value.typeFabrication && (
      <MenuiseriesACreerLocation configuration={configuration} name={name} value={value} />
    )}
  </>
);

export const MenuiseriesACreer: React.FC<Props> = ({ configuration }) => (
  <Accordion className="mb-md" title="Menuiseries à créer ?" prefix="3" defaultExpanded>
    <FieldArray<MenuiseriesInterieuresMenuiserieACreer> name="menuiseriesACreer">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={index} className="mb-lg">
                <div className="flex justify-between">
                  <QuestionTitle
                    prefix="1"
                    title="Type de menuiserie ?"
                    tooltip={
                      <div>
                        Type de menuiseries selon les gammes :
                        <br />
                        <br />
                        Menuiserie du commerce
                        <br />
                        Primo : Menuiserie commerce standard
                        <br />
                        Confort, Premium & Exclusif : Menuiserie du commerce avec habillage à
                        peindre
                        <br />
                        <br />
                        Menuiseries sur mesure
                        <br />
                        Primo & Confort : Menuiserie sur mesure à peindre
                        <br />
                        Premium & Exclusif : Menuiseries sur mesure avec MDF plaqué
                      </div>
                    }
                  />
                  <PrestationDelete onDelete={() => fields.remove(index)} />
                </div>
                <OptionCheckbox fields={fields} value={value} index={index} />
                <ChipListField
                  name={`${name}.typeMenuiserie`}
                  renderingOptions={{
                    containerClassName: 'mb-md flex flex-wrap gap-xs',
                  }}
                  options={[
                    {
                      label: 'Bibliothèque',
                      value: 'Bibliothèque',
                    },
                    {
                      label: 'Placard',
                      value: 'Placard',
                    },
                    {
                      label: 'Dressing',
                      value: 'Dressing',
                    },
                  ]}
                />
                {value.typeMenuiserie && (
                  <MenuiseriesACreerFabrication
                    configuration={configuration}
                    name={name}
                    value={value}
                  />
                )}
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
