import React from 'react';

import dayjs from 'dayjs';
import { useOutletContext } from 'react-router-dom';

import EventDuoTone from '@travauxlib/shared/src/components/DesignSystem/assets/EventDuoTone.svg?react';
import LocalisationDuoTone from '@travauxlib/shared/src/components/DesignSystem/assets/LocalisationDuoTone.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { EventCard } from '@travauxlib/shared/src/components/DesignSystem/components/EventCard';
import { Summary } from '@travauxlib/shared/src/features/Project/components/Summary';
import { DocumentTag } from '@travauxlib/shared/src/types/api/domain/client/DocumentTag';

import { ProjectContext } from 'features/Project/api/useProjectContext';
import { useUpdateProject } from 'features/Project/api/useUpdateProject';

import { DocumentsSection } from './components/DocumentsSection';
import { ProjectForm } from './components/ProjectForm';
import { SlotsRendezVous } from './components/SlotsRendezVous';
import { UploadDocumentsCard } from './components/UploadDocumentsCard';

export const ProjectInfos: React.FC = () => {
  const updateProject = useUpdateProject();

  const { project } = useOutletContext<ProjectContext>();

  const bookedSlots = project.bookedSlots;
  const documentsAddedByAdmin = project.files.filter(file => file.tag === DocumentTag.Admin);

  return (
    <div className="pt-md pb-lg">
      {!!bookedSlots.length && (
        <div className="flex mb-md flex-wrap gap-xs">
          {bookedSlots
            .filter(slot => dayjs(slot.start).isAfter(dayjs()))
            .map(slot => (
              <div className="w-[20.5rem] h-[6rem]" key={slot.start.toString()}>
                <EventCard
                  icon={slot.isCalendly ? <EventDuoTone /> : <LocalisationDuoTone />}
                  hideTitleDate
                  title={
                    slot.isCalendly
                      ? 'Rendez-vous Calendly à venir'
                      : 'Rendez-vous physique à venir'
                  }
                  dueDate={slot.start.toString()}
                  fullTimeFormat
                  variant="primary"
                  iconVariant="beige"
                >
                  {slot.label}
                </EventCard>
              </div>
            ))}
        </div>
      )}
      <ProjectForm project={project} updateProject={updateProject}>
        <>
          <Card className="!rounded-md mb-md !overflow-visible" state="elevated">
            <Summary
              project={project}
              updateProject={updateProject}
              googleApiKey={APP_CONFIG.googleApiKey}
            />
          </Card>
          <div className="grid grid-cols-1 sm-desktop:grid-cols-2 gap-md mb-md">
            <Card className="!rounded-md" state="elevated">
              <SlotsRendezVous bookedSlots={project.bookedSlots} />
            </Card>
            <DocumentsSection documents={project.files} />
          </div>
        </>
      </ProjectForm>
      <UploadDocumentsCard projectUuid={project.uuid} files={documentsAddedByAdmin} />
    </div>
  );
};
