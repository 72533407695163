import React from 'react';

import classNames from 'classnames';
import { Form } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import {
  required,
  requiredValidEmail,
  validatePhoneNumber,
} from '@travauxlib/shared/src/utils/form';

import { useAgency } from 'features/Partners/hooks/useAgency';
import { PartnerAccount, PartnerAgency } from 'types';

type Props = {
  onSubmit: (values: PartnerAccount) => Promise<unknown>;
  agencies: PartnerAgency[];
  initialValues?: Partial<PartnerAccount>;
  cancelEditing?: () => void;
};

export const AccountForm: React.FC<Props> = ({
  initialValues,
  cancelEditing,
  onSubmit,
  agencies,
}) => {
  const agency = useAgency();

  const agencyUuid = agencies.length === 1 ? agencies[0].uuid : agency?.uuid;

  return (
    <Form<PartnerAccount> initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, dirty, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-12 gap-md">
            <div className="col-span-6">
              <InputField id="firstName" label="Prénom" name="firstName" validate={required} />
            </div>
            <div className="col-span-6">
              <InputField id="lastName" label="Nom" name="lastName" validate={required} />
            </div>
            <div className="col-span-6">
              <InputField
                id="email"
                label="Email"
                name="email"
                type="email"
                validate={requiredValidEmail}
              />
            </div>
            <div className="col-span-6">
              <InputField
                id="password"
                label="Mot de passe"
                name="password"
                type="password"
                validate={!initialValues?.password ? required : undefined}
              />
            </div>
            <div className="col-span-6">
              <InputField
                id="phoneNumber"
                label="Numéro de téléphone"
                name="phoneNumber"
                validate={validatePhoneNumber}
              />
            </div>
            <div className="col-span-6">
              <DropdownField
                id="agencyUuid"
                label="Agence"
                name="agencyUuid"
                options={agencies.map(agency => ({
                  value: agency.uuid,
                  label: agency.name,
                }))}
                initialValue={agencyUuid}
                validate={required}
              />
            </div>
            <div className="col-span-full">
              <CheckboxField
                id="isArchived"
                label="Désactiver le compte"
                name="isArchived"
                className="!block !mb-md"
                defaultValue={false}
              />
              <CheckboxField
                id="isOrganizationManager"
                label="Manager du partenariat"
                name="isOrganizationManager"
                className="!block !mb-md"
                defaultValue={false}
              />
            </div>
          </div>
          <div className="!text-right">
            {cancelEditing && (
              <Button
                onClick={cancelEditing}
                variant="secondary"
                className={classNames('!mr-md')}
                type="button"
              >
                Annuler
              </Button>
            )}
            <Button type="submit" disabled={!dirty || submitting}>
              Enregistrer
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
