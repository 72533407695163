import React from 'react';

import { RadioButtonsListField } from '@travauxlib/shared/src/components/DesignSystem/components/RadioButtonsList/Field';

export const SpecificFields: React.FC = () => (
  <div className="flex flex-wrap sm-desktop:-mx-md">
    <div className="sm-desktop:basis-0 sm-desktop:flex-grow sm-desktop:px-md relative">
      <RadioButtonsListField
        label="KYC2"
        id="kyc2"
        name="kyc2"
        options={[
          { label: 'Indifférent', value: 'indifferent' },
          { label: 'Oui', value: 'oui' },
          { label: 'Non', value: 'non' },
        ]}
        initialValue="indifferent"
      />
    </div>
    <div className="sm-desktop:basis-0 sm-desktop:flex-grow sm-desktop:px-md relative">
      <RadioButtonsListField
        label="Projets"
        id="acceptsProjects"
        name="acceptsProjects"
        options={[
          { label: 'Indifférent', value: 'indifferent' },
          { label: 'Projets Barnes', value: 'barnes' },
          { label: 'Projets hemea', value: 'hemea' },
        ]}
      />
    </div>
    <div className="sm-desktop:basis-0 sm-desktop:flex-grow sm-desktop:px-md relative">
      <RadioButtonsListField
        label="En pause"
        id="enPause"
        name="enPause"
        options={[
          { label: 'Indifférent', value: 'indifferent' },
          { label: 'En pause', value: 'oui' },
          { label: 'Pas en pause', value: 'non' },
        ]}
      />
    </div>
    <div className="sm-desktop:basis-0 sm-desktop:flex-grow sm-desktop:px-md relative">
      <RadioButtonsListField
        label="Assurance valide"
        id="validAssurance"
        name="validAssurance"
        options={[
          { label: 'Indifférent', value: 'indifferent' },
          { label: 'Oui', value: 'oui' },
          { label: 'Non', value: 'non' },
        ]}
      />
    </div>
  </div>
);
