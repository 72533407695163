import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { LEAD_CLIENTS_KEY } from 'features/Leads/api/useLeadClients';

type Payload = {
  leadId: number;
  clientAccountUuid: string;
  hasExistingProject: boolean;
};

export const useLinkClientAccountToLead = (): {
  linkClientAccount: (payload: Payload) => Promise<void>;
  isPending: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: Payload) =>
      request(`${APP_CONFIG.apiURL}/admin/leads/${payload.leadId}/link-account`, {
        body: payload,
        method: 'POST',
      }),
    onSuccess: (_, { hasExistingProject }) => {
      queryClient.invalidateQueries({ queryKey: [LEAD_CLIENTS_KEY] });
      if (!hasExistingProject) {
        toast.success('Le projet a bien été créé avec le compte client.');
      }
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la création du projet.');
    },
  });

  return { linkClientAccount: mutateAsync, isPending };
};
