import React, { useState } from 'react';

import AlertCircle from '@travauxlib/shared/src/components/DesignSystem/assets/AlertCircle.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

const DEFAULT_SLICE_SIZE = 50;

type Props<T> = {
  items: T[];
  itemName: string;
  isFeminine?: boolean;
  limit?: number;
};

export const useItemsLimiter = <T extends object>({
  items,
  itemName,
  isFeminine = false,
  limit = DEFAULT_SLICE_SIZE,
}: Props<T>): {
  LimitInfo: () => JSX.Element;
  slicedItems: T[];
} => {
  const [nbItemsDisplayed, setNbItemsDisplayed] = useState(limit);
  const nbItemsNotDisplayed = items.length - nbItemsDisplayed;
  const slicedItems = items.slice(0, nbItemsDisplayed);
  const femininePrefix = isFeminine ? 'e' : '';

  const LimitInfo = (): JSX.Element => (
    <div className="!text-center !py-lg">
      {nbItemsNotDisplayed > 0 ? (
        <>
          <p>
            <AlertCircle className="w-md h-md text-warning" />
            <span className="!mx-xs">
              {slicedItems.length} {itemName} sur {items.length} sont affiché{femininePrefix}s.
            </span>
            <AlertCircle className="w-md h-md text-warning" />
          </p>
          <Button
            variant="tertiary"
            type="button"
            onClick={() => setNbItemsDisplayed(nbItemsDisplayed => nbItemsDisplayed + limit)}
          >
            {nbItemsNotDisplayed > limit
              ? `Afficher les ${limit} ${itemName} suivant${femininePrefix}s`
              : `Afficher les ${nbItemsNotDisplayed} ${itemName} restant${femininePrefix}s`}
          </Button>
        </>
      ) : (
        <p>
          Tou{isFeminine ? 'tes' : 's'} les {itemName} de la recherche sont affiché{femininePrefix}
          s.
        </p>
      )}
    </div>
  );

  return { LimitInfo, slicedItems };
};
