import React from 'react';

import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';
import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { useCancelSignature as useCancelSignatureApi } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useCancelSignature';

type OwnProps = {
  signatureToCancelUuid: string;
  hasTransactions: boolean;
};

type InjectedProps = {
  handleClose: () => void;
};

type Props = OwnProps & InjectedProps;

const posMessageText = "Confirmez-vous l'annulation de la signature ?";

const getWarningMessage = (hasTransactions: boolean): JSX.Element | string => {
  if (hasTransactions) {
    return (
      <div>
        Il y a déjà eu des mouvements d'argent sur ce chantier, annuler cette signature peut générer
        des incohérences à régler manuellement (notamment concernant la commission). <br />
        {posMessageText}
      </div>
    );
  }
  return posMessageText;
};

export const CancelSignatureModal: React.FC<Props> = ({
  signatureToCancelUuid,
  handleClose,
  hasTransactions,
}) => {
  const { cancelSignature, isLoading } = useCancelSignatureApi();

  const onConfirm = async (): Promise<void> => {
    await cancelSignature(signatureToCancelUuid);
    handleClose();
  };

  return (
    <FeedbackMessages
      variant="warning"
      title="Annuler la signature"
      handleClose={handleClose}
      isOpen
      validateAction={{
        label: 'Confirmer',
        onClick: onConfirm,
        disabled: isLoading,
        loading: isLoading,
      }}
      message={getWarningMessage(hasTransactions)}
      cancelAction={{ label: 'Non', onClick: handleClose }}
    />
  );
};

export const useCancelSignatureModal = (): ((
  signatureToCancelUuid: string,
  hasTransactions: boolean,
) => void) => {
  const openModal = useOpenModal();

  const open = async (signatureToCancelUuid: string, hasTransactions: boolean): Promise<void> => {
    openModal(CancelSignatureModal, {
      signatureToCancelUuid,
      hasTransactions,
    });
  };

  return open;
};
