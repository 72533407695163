import { Devis } from '@travauxlib/shared/src/types';

import { BaseStep, ExecuteParams, StepField } from '../BaseStep';

type Input = { devis: Devis; dealUuid: string; email?: string };

export class ProSendDevis extends BaseStep<Input, { devis: Devis }, StepField[], { devis: Devis }> {
  public name = 'Pro send devis';

  public fields = [];

  async execute({
    apiURL,
    email,
    dealUuid,
    devis,
    request,
    appURL,
  }: ExecuteParams<Input>): Promise<{ devis: Devis }> {
    const sendEmailBody = {
      customerEmail: email,
      emailSubject: 'Votre devis Novare Construction',
      emailContent: 'BOOP BOOP JE SUIS UN ROBOT AHAHAH',
      isPostponingChantierDates: false,
      saveIntroductionLetter: false,
      saveRelanceInterval: false,
      contractors: [],
    };

    // "Envoie mail" => tu mens. Envoie mail, update pipedriveDeal, enable yousign, update company config,
    // Update devis and envoie le mail enfin

    const updatedDevis = await request<Devis>(
      `${apiURL}/pro/deals/${dealUuid}/devis/${devis.token}/envoi-mail`,
      {
        method: 'POST',
        body: sendEmailBody,
      },
    );

    super.log(`Page devis client : ${appURL}/pro/devis/${updatedDevis.token}`);

    super.setCreatedEntities({ devis: updatedDevis });

    return { devis: updatedDevis };
  }
}
