import React from 'react';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

import { ProCompany } from 'types';

const buildFields = (proCompany: ProCompany): { label: string; value?: string }[] => [
  { label: "Nom de l'entreprise", value: proCompany.name },
  { label: 'Email', value: proCompany.email },
  { label: 'Prénom du gérant', value: proCompany.firstNameRepresentantLegal },
  {
    label: 'Nom de famille du gérant',
    value: proCompany.lastNameRepresentantLegal,
  },
  { label: 'Adresse', value: proCompany.address },
  { label: 'Ville', value: proCompany.city },
  { label: 'Code postal', value: proCompany.postalCode },
  { label: 'Téléphone', value: proCompany.phoneNumber },
  { label: 'SIRET/SIREN', value: proCompany.siret },
  { label: 'Propriétaire du compte', value: proCompany.bankAccountOwner },
  { label: 'IBAN', value: proCompany.iban },
  { label: 'BIC', value: proCompany.bic },
];

type Props = {
  proCompany: ProCompany;
};

export const PaymentInformation: React.FC<Props> = ({ proCompany }) => (
  <table className="!my-lg !w-full">
    <tbody>
      {buildFields(proCompany).map(field => (
        <tr key={field.label}>
          <td>{field.label}</td>
          <td>{field.value}</td>
        </tr>
      ))}
      <tr>
        <td>RIB</td>
        <td>
          {proCompany.ribUrl && (
            <Link href={proCompany.ribUrl} target="_blank" rel="noopener noreferrer">
              Voir
            </Link>
          )}
        </td>
      </tr>
    </tbody>
  </table>
);
