import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ClientActionType } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from 'features/Project/api/useProjectContext';

type Payload = {
  projectUuid: string;
  actionType: ClientActionType;
  entityIdentifier?: string;
};

const handleValidateAction = ({
  projectUuid,
  actionType,
  entityIdentifier,
}: Payload): Promise<void> => {
  const requestUrl = `${
    APP_CONFIG.apiURL
  }/admin/projects/${projectUuid}/actions/${actionType}/validate${
    entityIdentifier ? `?entityIdentifier=${entityIdentifier}` : ''
  }`;

  return request(requestUrl, { method: 'POST' });
};

export const useValidateAction = (): {
  validateAction: typeof handleValidateAction;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: validateAction, isPending } = useMutation({
    mutationFn: handleValidateAction,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY] });
    },
    onError: () => {
      toast.error('Un problème est survenu, veuillez réessayer');
    },
  });

  return { validateAction, isLoading: isPending };
};
