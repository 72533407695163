import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { Consultation } from 'types';

type LastConsultationsPayload = {
  dateSearch?: string;
  proCompanySlug: string;
};

export const useLastConsultations = ({
  dateSearch,
  proCompanySlug,
}: LastConsultationsPayload): Consultation[] | undefined => {
  const { data } = useQuery<{
    data: Consultation[];
  }>({
    queryKey: ['last-mers', proCompanySlug, dateSearch],
    queryFn: () => {
      const requestUrl = dateSearch
        ? `${APP_CONFIG.apiURL}/admin/consultation-offers/pro-companies/${proCompanySlug}/until-date/${dateSearch}`
        : `${APP_CONFIG.apiURL}/admin/consultation-offers/pro-companies/${proCompanySlug}`;
      return request(requestUrl);
    },
  });

  return data?.data;
};
