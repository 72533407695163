import { ChantierListItem } from '@travauxlib/shared/src/features/Chantiers/types';

import { CurrentTab, Filters, MoneyFilterValue } from '../types';

const getCommissionCaptee = (chantier: ChantierListItem): MoneyFilterValue => {
  if (!chantier.lemonwayAccountUrl || chantier.proCompany.archiType === 'interne') {
    return 'n/a';
  }

  if (chantier.montantCommissionPreleveeTTC >= chantier.montantCommissionTTC) {
    return 'en_totalite';
  }

  if (chantier.montantCommissionPreleveeTTC === 0) {
    return 'pas_du_tout';
  }

  return 'en_partie';
};

const getPrestationHemeaEncaissee = (chantier: ChantierListItem): MoneyFilterValue => {
  if (
    !chantier.lemonwayAccountUrl ||
    (chantier.montantTravauxPlannerTTC === 0 && chantier.proCompany.archiType !== 'interne')
  ) {
    return 'n/a';
  }

  if (chantier.montantTravauxPlannerTTC > 0) {
    if (chantier.montantTPEncaisseTTC >= chantier.montantTravauxPlannerTTC) {
      return 'en_totalite';
    }

    if (chantier.montantTPEncaisseTTC === 0) {
      return 'pas_du_tout';
    }

    return 'en_partie';
  }

  if (chantier.montantArchiInterneEncaisseTTC >= chantier.montantTotalTTC) {
    return 'en_totalite';
  }

  if (chantier.montantArchiInterneEncaisseTTC === 0) {
    return 'pas_du_tout';
  }

  return 'en_partie';
};

const getVersementALentreprise = (chantier: ChantierListItem): string => {
  if (!chantier.lemonwayAccountUrl || chantier.proCompany.archiType === 'interne') {
    return 'n/a';
  }

  return chantier.countLiberationDeFonds > 0 ? 'au_moins_un' : 'aucun';
};

const verifyTabCondition = (tab: CurrentTab, chantier: ChantierListItem): boolean => {
  if (tab === 'tous') {
    return true;
  }
  if (tab === 'en_cours') {
    return ['non_demarre', 'demarre'].includes(chantier.status);
  }
  if (tab === 'termines') {
    return ['termine', 'annule'].includes(chantier.status);
  }
  if (tab === 'provision') {
    return chantier.montantProvisionsAttendues > 1;
  }
  if (tab === 'validation') {
    return chantier.countAppelsDePaiementInitiated > 0;
  }

  return chantier.countAppelsDePaiementValidated > 0;
};

const verifyFilterValue = (filterValue: string | undefined, chantierValue?: string): boolean => {
  if ([undefined, 'tous', 'indifferent'].includes(filterValue)) {
    return true;
  }
  return chantierValue === filterValue;
};

const stringFilterContains = (filterValue: string | undefined, chantierValue: string): boolean => {
  if (!filterValue) {
    return true;
  }
  return chantierValue.toLowerCase().includes(filterValue.toLowerCase());
};

const verifyClientAccounts = (
  clientAccountFilter: string | undefined,
  chantier: ChantierListItem,
): boolean => {
  if (!clientAccountFilter) {
    return true;
  }
  return chantier.clientAccounts.some(
    ca =>
      clientAccountFilter
        .split(' ')
        .every(keyword =>
          (ca.firstName + ca.lastName).toLowerCase().includes(keyword.toLowerCase()),
        ) || stringFilterContains(clientAccountFilter, ca.email),
  );
};

const verifySuiviBy = (suiviBy: string | undefined, chantier: ChantierListItem): boolean => {
  if (!suiviBy || suiviBy === 'tous') {
    return true;
  }
  if (suiviBy === 'aucun') {
    return !chantier.responsableSuiviUuid;
  }
  return chantier.responsableSuiviUuid === suiviBy;
};

const filtersFn: {
  [Filter in keyof Required<Filters>]: (
    filter: Filters[Filter],
    chantier: ChantierListItem,
  ) => boolean;
} = {
  tab: verifyTabCondition,
  prestation: (prestation, chantier) =>
    verifyFilterValue(
      prestation,
      chantier.proCompany.companyType === 'architecte' ? 'conception' : 'travaux',
    ),
  suivi: (suivi, chantier) => verifyFilterValue(suivi, chantier.typeSuivi),
  demarre: (demarre, chantier) =>
    verifyFilterValue(demarre, chantier.dateDebutReelle ? 'oui' : 'non'),
  suiviBy: verifySuiviBy,
  pipedriveDealId: (pipedriveDealId, chantier) =>
    verifyFilterValue(pipedriveDealId, chantier.pipedriveDealId?.toString()),
  clientAccount: verifyClientAccounts,
  proCompanyName: (proCompanyName, chantier) =>
    stringFilterContains(proCompanyName, chantier.proCompany.name),
  commissionCaptee: (commissionCaptee, chantier) =>
    verifyFilterValue(commissionCaptee, getCommissionCaptee(chantier)),
  prestationHemeaEncaissee: (prestationHemeaEncaissee, chantier) =>
    verifyFilterValue(prestationHemeaEncaissee, getPrestationHemeaEncaissee(chantier)),
  versementALentreprise: (versementALentreprise, chantier) =>
    verifyFilterValue(versementALentreprise, getVersementALentreprise(chantier)),
} as const;

type FilterValue = Filters[keyof Filters];

export const applyFilters = (filters: Filters, chantier: ChantierListItem): boolean => {
  for (const rawFilter of Object.keys(filtersFn)) {
    // Object.keys return string[]
    // https://stackoverflow.com/a/52856805/4949918
    const filter = rawFilter as keyof Filters;

    const value: FilterValue = filters[filter];

    if (!value) {
      continue;
    }

    const filterFn: (value: FilterValue, chantier: ChantierListItem) => boolean = filtersFn[filter];

    const isValid = filterFn(value, chantier);

    if (!isValid) {
      return false;
    }
  }

  return true;
};

export const getFilteredChantiers = (
  chantiers: ChantierListItem[],
  filters: Filters,
): ChantierListItem[] => chantiers.filter(chantier => applyFilters(filters, chantier));
