import React from 'react';

import { Params, useParams } from 'react-router-dom';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';

import { CommissionCard } from './CommissionCard';
import { SeparationTag } from './ModalitesDevis';

import { useDevisModalites } from '../api/useDevisModalites';

export const ModalitesHemea: React.FC = () => {
  const { devisToken } = useParams<Params>();
  const { data: modalitesDevis } = useDevisModalites(devisToken!);

  return modalitesDevis ? (
    <>
      {!modalitesDevis.archiType && (
        <Card className="mb-md">
          <h3>
            Suivi Travaux Planner{' '}
            {modalitesDevis.hasServiceHemeaIncluded && 'avec le service hemea inclus'}
          </h3>
          <div className="flex mt-md justify-center">
            (<SeparationTag label="MontantDevisTTC" />
            &nbsp;x&nbsp;
            <SeparationTag label="(TauxTP / 100))" />
            &nbsp;=&nbsp;MontantTravauxPlanner
          </div>
          <CommissionCard
            devisToken={devisToken!}
            commissionKey="tauxTravauxPlanner"
            basePrice={modalitesDevis.prixTotalTTC}
            customRate={modalitesDevis.modalites?.tauxTravauxPlanner}
            defaultRate={modalitesDevis.defaultModalites.tauxTravauxPlanner}
            devisSigned={modalitesDevis.devisSigned}
          />
        </Card>
      )}
      {modalitesDevis.hasServiceHemeaIncluded && (
        <Card>
          <h3>Service hemea</h3>
          <div className="flex mt-md justify-center">
            (<SeparationTag label="MontantDevisTTC" />
            &nbsp;x&nbsp;
            <SeparationTag label="(TauxService / 100))" />
            &nbsp;=&nbsp;MontantServiceHemea
          </div>
          <CommissionCard
            devisToken={devisToken!}
            commissionKey="tauxServiceHemea"
            basePrice={modalitesDevis.prixTotalTTC}
            customRate={modalitesDevis.modalites?.tauxServiceHemea}
            defaultRate={modalitesDevis.defaultModalites.tauxServiceHemea}
            devisSigned={modalitesDevis.devisSigned}
          />
        </Card>
      )}
    </>
  ) : null;
};
