import { useMutation, useQueryClient } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { AUTH_KEY } from './useAuth';

const handleLogoutFromClientAccount = (): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/client/logout`;
  return request(requestURL, {
    method: 'POST',
  });
};

export const useLogoutFromClientAccount = (): typeof handleLogoutFromClientAccount => {
  const queryClient = useQueryClient();
  const { mutateAsync: logout } = useMutation({
    mutationFn: handleLogoutFromClientAccount,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [AUTH_KEY] });
    },
  });

  return logout;
};
