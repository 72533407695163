import React from 'react';

import {
  ButtonGroup,
  Option,
} from '@travauxlib/shared/src/components/DesignSystem/components/ButtonGroup';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { CircularProgress } from '@travauxlib/shared/src/components/DesignSystem/components/CircularProgress';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { CompteMontant } from './CompteMontant';

import { calculatePercentageAvancement } from '../../utils/math';

type Props = {
  progressAmount: number;
  totalAmount: number;
  variant: 'info' | 'success';
  buttonGroupActions: Option[];
  buttonActions?: {
    label: string;
    onClick: () => void;
    leftIcon?: JSX.Element;
    disabled?: boolean;
    disabledMessageTooltip?: string;
    variant?: 'secondary' | 'secondaryOutline';
  }[];
  amounts: { label: string; montant: number }[];
  label: string;
};

const ButtonsSection: React.FC<{
  buttonGroupActions: Option[];
  buttonActions?: Props['buttonActions'];
}> = ({ buttonGroupActions, buttonActions }) => {
  const isTabletOrmMobile = useIsTabletOrMobile();
  return (
    <div className="flex flex-col sm-desktop:flex-row items-center mt-md sm-desktop:mt-0">
      <div>
        <ButtonGroup
          options={buttonGroupActions}
          fullwidth={isTabletOrmMobile}
          variant="secondary"
          onChange={() => {}}
        />
      </div>
      <div className="sm-desktop:ml-xxs mt-md sm-desktop:mt-0">
        {buttonActions?.map(
          ({
            label,
            onClick,
            leftIcon,
            disabled,
            disabledMessageTooltip,
            variant = 'secondary',
          }) => (
            <Button
              key={label}
              variant={variant}
              size="sm"
              onClick={onClick}
              leftIcon={leftIcon}
              disabled={disabled}
              disabledMessageTooltip={disabledMessageTooltip}
            >
              {label}
            </Button>
          ),
        )}
      </div>
    </div>
  );
};

export const Compte: React.FC<Props> = ({
  progressAmount,
  totalAmount,
  variant,
  buttonGroupActions,
  buttonActions,
  amounts,
  label,
}) => {
  const isTabletOrmMobile = useIsTabletOrMobile();
  return (
    <div
      role="section"
      aria-label={label}
      className="bg-white shadow-xs rounded-md py-md pl-lg pr-md flex flex-col sm-desktop:flex-row items-center grow"
    >
      <div className="sm-desktop:pr-lg">
        <CircularProgress
          value={calculatePercentageAvancement(progressAmount, totalAmount)}
          variant={variant}
          size="lg"
        />
      </div>
      <div className="flex flex-col grow items-center sm-desktop:items-stretch">
        <div className="flex justify-between mb-md">
          <div className="text-h4 font-bold mr-xs">{label}</div>
          {!isTabletOrmMobile && (
            <ButtonsSection buttonGroupActions={buttonGroupActions} buttonActions={buttonActions} />
          )}
        </div>
        <div className="flex gap-md flex-col sm-desktop:flex-row sm-desktop:mr-md">
          {amounts.map(({ label, montant }) => (
            <CompteMontant key={label} label={label} montant={montant} />
          ))}
        </div>
      </div>
      {isTabletOrmMobile && (
        <ButtonsSection buttonGroupActions={buttonGroupActions} buttonActions={buttonActions} />
      )}
    </div>
  );
};
