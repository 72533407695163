import { Chantier, ChantierTransaction } from '@travauxlib/shared/src/features/Chantiers/types';

import {
  FluxFinancierHistoriqueFilterType,
  HistoryItem,
  Variant,
  VariantHistotyItemCta,
} from '../types';

export const createActionCreators =
  (
    handleOpenModalIgnoreTransaction: (
      transaction: ChantierTransaction,
      chantier: Chantier,
    ) => void,
    handleOpenModalChangeTransactionVisibility: (
      transaction: ChantierTransaction,
      chantier: Chantier,
    ) => void,
    chantier: Chantier,
    isTeamProduct: boolean,
  ) =>
  (transaction: ChantierTransaction): HistoryItem['actions'] => [
    ...(isTeamProduct
      ? [
          {
            variant: 'primary' as VariantHistotyItemCta,
            label: `${transaction.isIgnored ? 'Prendre en compte' : 'Ignorer'} la transaction`,
            onClick: () => handleOpenModalIgnoreTransaction(transaction, chantier),
          },
        ]
      : []),
    ...(isTeamProduct && transaction.isIgnored && transaction.isUserVisible !== undefined
      ? [
          {
            label: `${transaction.isUserVisible ? 'Cacher' : 'Afficher'} l'operation`,
            onClick: () => handleOpenModalChangeTransactionVisibility(transaction, chantier),
          },
        ]
      : []),
  ];

export const createBaseHistoryItem = (
  transaction: ChantierTransaction,
  label: string,
  filterType: FluxFinancierHistoriqueFilterType,
  Icon: React.FC,
  variant: Variant,
  createActions: (transaction: ChantierTransaction) => HistoryItem['actions'],
): HistoryItem => ({
  key: transaction.id.toString(),
  date: transaction.date,
  author: transaction.internalAuthor,
  amount: transaction.amount,
  label,
  filterType,
  Icon,
  variant,
  isIgnored: transaction.isIgnored,
  isUserVisible: transaction.isUserVisible,
  actions: createActions(transaction),
});
