import { Action, Subject, Flag } from './types';

import { AbilityBuilder } from '../AbilityBuilder';

const cdplBuilder = new AbilityBuilder<Action, Subject, Flag>();

cdplBuilder.can('open', 'Chantier');
cdplBuilder.can('close', 'Chantier');
cdplBuilder.can('manage', 'Lead', ['isAssignedTo']);

export const cdpl = cdplBuilder.build();
