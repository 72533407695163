import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { useChantierQuery } from '../../Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';

const handleToggleWithLemonwayMock = async (
  chantier: Partial<Chantier> & { slug: string },
): Promise<void> => {
  const { slug } = chantier;
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${slug}/debug/toggle-lemonway-mock`;
  await request(requestURL, { method: 'POST' });
};

export const useToggleWithLemonwayMock = (
  withMockedLemonway: boolean,
): {
  toggleWithLemonwayMock: typeof handleToggleWithLemonwayMock;
  isLoading: boolean;
} => {
  const { query, isLoading } = useChantierQuery(
    handleToggleWithLemonwayMock,
    'de la mise à jour du chantier',
    `Lemonway ${withMockedLemonway ? "n'est à présent plus mocked" : 'est maintenant mocked'}`,
  );

  return { toggleWithLemonwayMock: query, isLoading };
};
