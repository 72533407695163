import React from 'react';

import { CheckboxList } from '@travauxlib/shared/src/components/DesignSystem/components/CheckboxList';

export type Group = {
  options: string[];
};

export type Props = {
  value?: string[];
  label: string;
  options: Group[];
  onChange: (values: string[]) => void;
};

export const CheckboxGrouped: React.FC<Props> = ({ value = [], options, onChange, label }) => (
  <div className="mb-md">
    <label className="font-bold">{label}</label>
    <div className="flex flex-wrap">
      {options.map(({ options }) => (
        <div className="max-w-full basis-0 flex-grow" key={options[0]}>
          <CheckboxList
            options={options.map(label => ({ value: label, label }))}
            value={value}
            onChange={onChange}
          />
        </div>
      ))}
    </div>
  </div>
);
