import React from 'react';

import classNames from 'classnames';

import HelpCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircleOutline.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Popover } from '@travauxlib/shared/src/components/DesignSystem/components/Popover';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { ChantierPvWithDocuments, PvType } from '@travauxlib/shared/src/features/Chantiers/types';

import { PlafondPercentage } from './PlafondPercentage';
import { PvInfo } from './PvInfo';

type Props = {
  montantGele: number;
  receptionChantierThreshold?: number;
  leveeReservesThreshold?: number;
  pv: { [K in PvType]?: ChantierPvWithDocuments };
  currentThreshold: number;
  pourcentageAvancementGlobal: number;
  size?: 'md' | 'lg';
};

export const MontantGeleCard: React.FC<Props> = ({
  montantGele,
  receptionChantierThreshold,
  leveeReservesThreshold,
  pv,
  currentThreshold,
  pourcentageAvancementGlobal,
  size = 'md',
}) => {
  const hasReserves = pv.receptionChantier?.hasReserves;
  const isReceptionChantierValidatedWithoutReserves =
    pv.receptionChantier?.isValidated && !pv.receptionChantier?.hasReserves;
  const areThresholdsRaised =
    isReceptionChantierValidatedWithoutReserves || pv.leveeReserves?.isValidated;
  const isReceptionChantierThresholdReached =
    pourcentageAvancementGlobal >= (receptionChantierThreshold ?? 100);
  const isLeveeReservesThresholdReached =
    pourcentageAvancementGlobal >= (leveeReservesThreshold ?? 100);
  const pvWording = pv.receptionChantier?.isValidated
    ? 'levée des réserves'
    : 'réception de chantier';

  return (
    <Card
      className="flex-grow !rounded-md"
      bodyClassNames="!py-sm flex flex-col justify-between h-full"
      aria-labelledby="montantGele"
    >
      <div>
        <div className="flex items-center gap-xxs">
          <div
            className={classNames(
              'font-bold text-neutral-800',
              size === 'lg' ? 'text-h4' : 'text-b1',
            )}
            id="montantGele"
          >
            Montant gelé
          </div>
          <Popover
            trigger={(onClose, referenceProps) => (
              <IconButton onClick={onClose} size="xs" {...referenceProps}>
                <HelpCircleOutline className="text-neutral-700" />
              </IconButton>
            )}
            title="Montant gelé"
            content={`Vous avez déjà validé le versement de cette somme à l’entreprise. Elle est mise en attente tant que le procès-verbal de ${pvWording} n’a pas été transmis par l’entreprise et que vous ne l’avez pas validé dans votre espace ”Documents”.`}
            label="Fermer"
            position="bottom"
          />
        </div>
        {receptionChantierThreshold && (
          <PvInfo
            pv={pv.receptionChantier}
            label="Réception chantier"
            isThresholdReached={isReceptionChantierThresholdReached}
          />
        )}
        {hasReserves && (
          <PvInfo
            pv={pv.leveeReserves}
            label="Levée des réserves"
            isThresholdReached={isLeveeReservesThresholdReached}
          />
        )}
      </div>
      <div className="mt-xs flex items-center gap-xxs text-b2">
        <EURCurrency
          className={classNames(size === 'lg' ? 'text-h4 font-bold' : 'font-medium')}
          amount={montantGele}
        />
        <PlafondPercentage
          areThresholdsRaised={!!areThresholdsRaised}
          currentThreshold={currentThreshold}
          isLeveeReservesThresholdReached={isReceptionChantierThresholdReached}
          isReceptionChantierThresholdReached={isReceptionChantierThresholdReached}
        />
      </div>
    </Card>
  );
};
