import { PartnerAccount, PartnerAgency, PartnerOrganization } from 'types';

export const getDisabledReasons = (
  organization: PartnerOrganization,
  agency: PartnerAgency,
  account?: PartnerAccount,
): string[] => {
  const disabledReasons = [];
  if (account?.isArchived) {
    disabledReasons.push('Le compte est désactivé');
  }
  if (agency.isArchived) {
    disabledReasons.push(`L'agence ${agency.name} est désactivée`);
  }
  if (organization.isArchived) {
    disabledReasons.push(`Le partenaire ${organization.name} est désactivé`);
  }

  return disabledReasons;
};
