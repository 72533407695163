import { ChantierAdminListItemView } from '@travauxlib/shared/src/types/api/admin/ChantierAdminListItemView';
import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';

import { BaseStep, ExecuteParams } from '../BaseStep';

type Input = {
  chantier: ChantierAdminListItemView;
  proCompany?: ProCompanyAdminView;
};

export class ChantierMockLemonway extends BaseStep<Input> {
  public name = 'Mock Lemonway on Chantier';

  public fields = [];

  async execute({ chantier, proCompany, apiURL, request }: ExecuteParams<Input>): Promise<void> {
    if (!proCompany) {
      super.error(`No pro company passed for lemonway mocking of chantier ${chantier.slug}`);
      return;
    }

    await request(`${apiURL}/admin/chantiers/${chantier.slug}/debug/toggle-lemonway-mock`, {
      method: 'POST',
    });

    super.log('Mocked lemonway account');

    await request(`${apiURL}/admin/chantiers/${chantier.slug}/fix-lemonway-account`, {
      method: 'POST',
    });

    super.log('Fixed lemonway client account');

    await request(
      `${apiURL}/admin/pro-companies/${proCompany.slug}/createLemonwayWallet?withMockedLemonway=true`,
      { method: 'PUT' },
    );

    super.log('Created pro LW wallet');

    await request(`${apiURL}/admin/chantiers/${chantier.slug}/debug/add-provision`, {
      method: 'POST',
      body: {
        montantProvision: 50000000,
      },
    });

    super.log('Giving insane amount of money');
  }
}
