import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request, isConflict } from '@travauxlib/shared/src/utils/request';

import { CLIENT_ACCOUNTS_KEY } from './useClientAccounts';

const handleCreateClientAccount = async (payload: {
  firstName: string;
  lastName: string;
  email: string;
}): Promise<{ uuid: string } | undefined> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/clients`;

  try {
    return await request(requestURL, {
      method: 'POST',
      body: payload,
    });
  } catch (error) {
    if (error instanceof Error && isConflict(error)) {
      toast.error('Un compte client existe déjà avec cette adresse email.');
    } else {
      toast.error('Une erreur est survenue lors de la création du compte client.');
    }
  }
};

export const useCreateClientAccount = (): typeof handleCreateClientAccount => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: handleCreateClientAccount,
    retry: 0,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CLIENT_ACCOUNTS_KEY] });
      toast.success('Le compte client a bien été créé.');
    },
  });

  return mutateAsync;
};
