import React from 'react';

import { Modal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { CreateClientAccount } from './CreateClientAccount';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateClientAccountModal: React.FC<Props> = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} handleClose={onClose} title="Création compte client">
    <CreateClientAccount onClose={onClose} />
  </Modal>
);
