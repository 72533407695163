import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { SUIVI_CHANTIERS_KEY } from '@travauxlib/shared/src/features/SuiviChantier/api/createSuiviChantierHistoryList';
import { request } from '@travauxlib/shared/src/utils/request';

const handleCancelSuiviChantier = async ({
  chantierSlug,
  suiviChantierUuid,
}: {
  chantierSlug: string;
  suiviChantierUuid: string;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${chantierSlug}/suivi-chantier/${suiviChantierUuid}`;
  await request(requestURL, { method: 'DELETE' });
};

export const useCancelSuiviChantier = (): typeof handleCancelSuiviChantier => {
  const queryClient = useQueryClient();
  const { mutateAsync: cancelSuiviChantier } = useMutation({
    mutationFn: handleCancelSuiviChantier,
    onSuccess: (_, { chantierSlug }) => {
      queryClient.invalidateQueries({ queryKey: [SUIVI_CHANTIERS_KEY, chantierSlug] });
      toast.success('Le suivi de chantier a bien été annulé.');
    },
    onError: () => {
      toast.error("Une erreur est survenue lors de l'annulation du suivi de chantier");
    },
  });
  return cancelSuiviChantier;
};
