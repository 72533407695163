import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { CHANTIER_KEY } from './useChantierQuery';

const handleUpdateMontantAppelDePaiement = async ({
  chantier,
  appelDePaiementUuid,
  montant,
  runSchedulerAdp,
}: {
  chantier: Chantier;
  appelDePaiementUuid: string;
  montant: number;
  runSchedulerAdp: boolean;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${chantier.slug}/appels-de-paiement/${appelDePaiementUuid}`;
  await request(requestURL, {
    method: 'PATCH',
    body: { montant, runSchedulerAdp },
  });
};

export const useUpdateMontantAppelDePaiement = (): {
  updateMontantAppelDePaiement: typeof handleUpdateMontantAppelDePaiement;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateMontantAppelDePaiement, isPending } = useMutation({
    mutationFn: handleUpdateMontantAppelDePaiement,
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [CHANTIER_KEY] });
      toast.success("Le montant de l'appel de paiement a été modifié avec succès !");
    },
    onError: () => {
      toast.error('Une erreur est survenue');
    },
  });

  return {
    updateMontantAppelDePaiement,
    isLoading: isPending,
  };
};
