import React, { useState } from 'react';

import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';

import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';

import { useProjectContext } from 'features/Project/api/useProjectContext';
import { CompanyType } from 'types';

import { ConsultationManual } from './ConsultationManual';
import { ConsultationSemiAuto } from './ConsultationSemiAuto';

type Props = {
  manualCompanyTypes: CompanyType[];
};

export const ConsultationOffer: React.FC<Props> = ({ manualCompanyTypes }) => {
  const { uuid, consultationInfoId } = useParams<{ uuid: string; consultationInfoId: string }>();
  const { isLoading, projectContext } = useProjectContext(uuid!);
  const navigate = useNavigate();

  const [manualMode, setManualMode] = useState<boolean>(false);

  if (isLoading || !projectContext) {
    return <LoaderWrapper />;
  }

  const consultationInfo = projectContext.consultationInfoList.find(
    ficheChanter => ficheChanter.id === Number(consultationInfoId),
  );

  if (!consultationInfo) {
    return <Navigate to="../.." />;
  }

  const consultations = projectContext.consultations.filter(
    c => c.consultationInfo.id === consultationInfo.id,
  );

  const backLink = (
    <div className="!mb-md">
      <Link to="..">&lt; Retour vers le lot</Link>
    </div>
  );

  const onConsultationDone = (): void => navigate(`/projects/${uuid}/consultation`);

  if (manualCompanyTypes.includes('pro') && !manualMode) {
    return (
      <>
        {backLink}
        <ConsultationSemiAuto
          consultationInfo={consultationInfo}
          setManualMode={setManualMode}
          onConsultationDone={onConsultationDone}
          consultations={consultations}
        />
      </>
    );
  }

  return (
    <>
      {backLink}
      <ConsultationManual
        consultationInfo={consultationInfo}
        companyTypes={manualCompanyTypes}
        onConsultationDone={onConsultationDone}
        consultations={consultations}
      />
    </>
  );
};
