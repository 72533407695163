import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from 'features/Project/api/useProjectContext';

const handleFreeProFromConsultation = async ({
  consultationOfferUuid,
}: {
  consultationOfferUuid: string;
  projectUuid?: string;
}): Promise<string | undefined> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/consultation-offers/${consultationOfferUuid}/free-pro`;

  return request(requestURL, { method: 'PATCH' });
};

export const useFreeProFromConsultation = (): typeof handleFreeProFromConsultation => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: handleFreeProFromConsultation,
    onSuccess: (_, { projectUuid }) => {
      toast.success('Le pro a été libéré de cette consultation.');
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY, projectUuid] });
    },
  });

  return mutateAsync;
};
