import React from 'react';

import dayjs from 'dayjs';

import { Avatar } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar';
import { getInitials } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar/getInitials';
import { shortDateTimeFormat } from '@travauxlib/shared/src/utils/time';

type Props = {
  senderName: string;
  commentDate?: string;
  rightContent?: JSX.Element | null;
  toReview?: boolean;
};

export const CommentHeader: React.FC<Props> = ({
  senderName,
  commentDate,
  rightContent,
  toReview,
}) => {
  const displayedDate = shortDateTimeFormat(commentDate || dayjs());
  return (
    <div className="flex mb-xxs justify-between items-center">
      <div className="flex items-center relative py-xxs">
        {toReview && <div className="absolute bg-error-800 h-xs w-xs rounded-full -left-sm" />}

        <div className="mr-xs">
          <Avatar text={getInitials(senderName)} />
        </div>
        <div>
          <div className="text-b2 font-medium text-neutral-800 -mb-xxs">{senderName}</div>
          <div className="text-sm text-neutral-600">{displayedDate}</div>
        </div>
      </div>
      {rightContent}
    </div>
  );
};
