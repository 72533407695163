import React from 'react';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { toCalendarFormatForDateTime } from '@travauxlib/shared/src/utils/time';

import { ThresholdBadge } from 'components/ThresholdBadge';
import { ProCompanyStats } from 'types';

dayjs.extend(relativeTime);
dayjs.extend(duration);

type Props = {
  stats: ProCompanyStats;
};

export const Stats: React.FC<Props> = ({
  stats: {
    nbOfMer,
    nbOfMerAccepted,
    nbOfMerRejected,
    nbOfMerExpired,
    nbOfMerPending,
    nbOfWin,
    nbOfOpen,
    nbOfLost,
    reactivityMeanInSeconds,
    nbOfChantierEnCours,
    updatedAt,
  },
}) => (
  <div className="!p-xs border border-solid border-gray-300">
    <div className="!flex !justify-between !items-center">
      <div>
        # Consultations totales : <Tag size="md" variant="info" label={nbOfMer.toString()} />
      </div>
      <WithTooltip
        trigger={
          <span className="flex" data-testid="stats-tooltip">
            <HelpCircle className="w-md h-md" />
          </span>
        }
      >
        <div className="!p-sm">
          Ces statistiques ont été rafraichies {toCalendarFormatForDateTime(dayjs(updatedAt))}
        </div>
      </WithTooltip>
    </div>
    <div>
      # Consultations en attente :{' '}
      <Tag variant="info" size="md" label={nbOfMerPending.toString()} />
    </div>
    <div>
      # Consultations acceptées :{' '}
      <Tag variant="info" size="md" label={nbOfMerAccepted.toString()} />
    </div>
    <div>
      # Consultations refusées : <Tag variant="info" size="md" label={nbOfMerRejected.toString()} />
    </div>
    <div>
      # Consultations expirées :{' '}
      <ThresholdBadge errorThreshold={0} value={nbOfMerExpired} smallerIsBetter />
    </div>
    <div>
      # Chantiers en attente : <Tag variant="info" size="md" label={nbOfOpen.toString()} />
    </div>
    <div>
      # Chantiers gagnés :{' '}
      <ThresholdBadge errorThreshold={1} warningThreshold={1} value={nbOfWin} />
    </div>
    <div>
      # Chantiers perdus : <ThresholdBadge errorThreshold={0} value={nbOfLost} smallerIsBetter />
    </div>
    <div>
      # Chantiers en cours : <Tag variant="info" size="md" label={nbOfChantierEnCours.toString()} />
    </div>
    <div>
      Réactivité moy. consultation :{' '}
      {reactivityMeanInSeconds ? (
        <ThresholdBadge
          errorThreshold={10 * 60 * 60}
          warningThreshold={6 * 60 * 60}
          value={reactivityMeanInSeconds}
          label={dayjs.duration(reactivityMeanInSeconds, 'second').humanize()}
          smallerIsBetter
        />
      ) : (
        'N/A'
      )}
    </div>
  </div>
);
