import { useMutation, useQueryClient } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { PartnerCommission } from 'types';

import { COMMISSIONS_KEY } from './useCommissions';

const handleUpdateCommission = (commission: PartnerCommission): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/partners/commissions/${commission.uuid}`;
  return request(requestURL, {
    method: 'PUT',
    body: commission,
  });
};

export const useUpdateCommission = (): {
  updateCommission: typeof handleUpdateCommission;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateCommission, isPending } = useMutation({
    mutationFn: handleUpdateCommission,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COMMISSIONS_KEY] });
    },
  });

  return { updateCommission, isLoading: isPending };
};
