import { createLeadAndSendDevis } from './createLeadAndSendDevis';

import { FindChantierFromPipedriveDealId } from '../gdm_steps/Chantier/FindChantierFromPipedriveDealId';
import { ExploitWeakSignatureImplem } from '../gdm_steps/Client/ExploitWeakSignatureImplem';

// eslint-disable-next-line
export const createLeadAndSignDevis = () =>
  createLeadAndSendDevis()
    .next(new ExploitWeakSignatureImplem())
    .next(new FindChantierFromPipedriveDealId());
