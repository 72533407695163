import React, { FC } from 'react';

import classNames from 'classnames';

import Envelope from '@travauxlib/shared/src/components/DesignSystem/assets/Envelope.svg?react';
import Phone from '@travauxlib/shared/src/components/DesignSystem/assets/Phone.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { ProUser } from '@travauxlib/shared/src/types/company';

type ProCompanyUserLineProps = {
  user: ProUser;
  openEditUserModal?: () => void;
  handleSignInAsPro?: (userId: number) => void;
};

export const ProCompanyUserCard: FC<ProCompanyUserLineProps> = ({
  user,
  openEditUserModal,
  handleSignInAsPro,
}) => (
  <div
    onClick={openEditUserModal}
    role={openEditUserModal ? 'button' : undefined}
    className={classNames('pl-sm flex items-center my-xs rounded', {
      'bg-primarylighter': !openEditUserModal,
      'bg-lighter': openEditUserModal,
    })}
  >
    <div className="flex flex-col grow">
      <div className="flex items-center mb-xs">
        <h4 className="pt-xs font-bold">{user.fullName}</h4>
        <span className="text-sm">
          <Tag
            size="md"
            className="!ml-sm !mr-sm"
            variant={user.isProCompanyAdmin ? 'success' : 'light'}
            label={user.isProCompanyAdmin ? 'Administrateur' : 'Utilisateur'}
          />
        </span>
      </div>
      <div className="flex grow justify-between flex-wrap">
        <div className="flex items-center mb-xs text-sm">
          <ColoredCircle size="xxs">
            <Envelope />
          </ColoredCircle>
          <span className="ml-xs">{user.email}</span>
        </div>
        <div className="flex items-center mb-xs text-sm mr-md">
          <ColoredCircle size="xxs">
            <Phone />
          </ColoredCircle>
          <span className="ml-xs"> {user.phoneNumber}</span>
        </div>
      </div>
    </div>
    {handleSignInAsPro && (
      <div className="text-right">
        <Button
          size="sm"
          variant="secondary"
          className="mr-sm"
          onClick={event => {
            event.stopPropagation();
            handleSignInAsPro(user.id || 0);
          }}
        >
          Se connecter à son compte
        </Button>
      </div>
    )}
  </div>
);
