import React from 'react';

import { useParams } from 'react-router-dom';

import { EditTrombinoscope } from '../features/Edit';

export const EditPerson: React.FC = () => {
  const { personUuid } = useParams();
  return <EditTrombinoscope personUuid={personUuid} title="Modifier" />;
};
