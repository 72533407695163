import React, { useState } from 'react';

import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { Toggle } from '@travauxlib/shared/src/components/DesignSystem/components/Toggle';

import { ClientAccountDropdown } from 'features/Chantiers/components/MainInfo/ClientAccountDropdown';
import { CreateClientAccount } from 'features/ClientAccounts/components/CreateClientAccount';

import { useLinkClientAccountToLead } from '../api/useLinkClientAccountToLead';

type Props = {
  isModalOpen: boolean;
  onClose: () => void;
  leadId: number;
  hasExistingProject: boolean;
};

export const LinkClientAccountModal: React.FC<Props> = ({
  isModalOpen,
  onClose,
  leadId,
  hasExistingProject,
}) => {
  const { linkClientAccount } = useLinkClientAccountToLead();
  const [compteClientAlreadyExists, setCompteClientAlreadyExists] = useState(false);
  const [clientAccountFromExistingClientUuid, setClientAccountFromExistingClientUuid] = useState<
    string | undefined
  >(undefined);

  const toggleElement = (
    <div className="mb-md">
      <Toggle
        onChange={newValue => setCompteClientAlreadyExists(newValue)}
        checked={compteClientAlreadyExists}
        label="Rattacher le lead à un compte client existant ?"
      />
    </div>
  );

  return (
    <Modal
      isOpen={isModalOpen}
      handleClose={onClose}
      title="Créer ou rattacher un compte client au lead"
      isScrollable={false}
    >
      {compteClientAlreadyExists ? (
        <ModalContent
          validateAction={{
            label: 'Valider',
            onClick: async (): Promise<void> => {
              if (clientAccountFromExistingClientUuid === undefined) {
                return;
              }
              await linkClientAccount({
                leadId,
                clientAccountUuid: clientAccountFromExistingClientUuid,
                hasExistingProject,
              });
              onClose();
            },
            disabled: clientAccountFromExistingClientUuid === undefined,
          }}
        >
          {toggleElement}
          <ClientAccountDropdown
            onChange={clientAccount => setClientAccountFromExistingClientUuid(clientAccount?.uuid)}
          />
        </ModalContent>
      ) : (
        <CreateClientAccount
          onClose={onClose}
          onValidate={clientAccountUuid =>
            clientAccountUuid &&
            linkClientAccount({ leadId, clientAccountUuid, hasExistingProject })
          }
        >
          {toggleElement}
        </CreateClientAccount>
      )}
    </Modal>
  );
};
