import React from 'react';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ProCompanyInsurance } from '@travauxlib/shared/src/types';

type Props = {
  ribUrl?: string;
  kbisUrl?: string;
  insurances: ProCompanyInsurance[];
};

export const DownloadFiles: React.FC<Props> = ({ ribUrl, kbisUrl, insurances }) => (
  <div className="!p-sm">
    {ribUrl && (
      <div>
        <Link href={ribUrl} rel="noreferrer noopener" target="_blank">
          Télécharger RIB
        </Link>
      </div>
    )}
    {kbisUrl && (
      <div>
        <Link href={kbisUrl} rel="noreferrer noopener" target="_blank">
          Télécharger Kbis
        </Link>
      </div>
    )}
    {insurances
      .filter(({ isValid }) => isValid)
      .map((insurance, index) => (
        <div key={index}>
          <Link rel="noreferrer noopener" href={insurance.fileUrl} target="_blank">
            Télécharger {insurance.label}
          </Link>
        </div>
      ))}
  </div>
);
