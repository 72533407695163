import { AccountView } from '@travauxlib/shared/src/types/api/admin/AccountView';
import { AgencyView } from '@travauxlib/shared/src/types/api/admin/AgencyView';
import { PartnerView } from '@travauxlib/shared/src/types/api/admin/PartnerView';

import { BaseStep, ExecuteParams } from '../BaseStep';

const fields = [
  { name: 'deactivatedPartenaireEstimateur', fieldType: 'checkbox' },
  { name: 'showLogoInPDF', fieldType: 'checkbox' },
] as const;

export class CreatePartenaire extends BaseStep<
  {},
  { partner: PartnerView; agency: AgencyView; account: AccountView },
  typeof fields,
  { partner: PartnerView; agency: AgencyView; account: AccountView }
> {
  public name: string = 'Create partenaire';

  public fields = fields;

  async execute({
    seed,
    apiURL,
    deactivatedPartenaireEstimateur,
    showLogoInPDF,
    request,
  }: ExecuteParams<{}, typeof fields>): Promise<{
    partner: PartnerView;
    agency: AgencyView;
    account: AccountView;
  }> {
    super.log('Creating partenaire...');

    const partner = {
      name: `godmod_partner_${seed}`,
      attributionName: `godmod_partner_attribution_${seed}`,
      internalAccountManager: `${seed}@example.com`,
      isReseauIndependant: false,
      isArchived: false,
      estimateurConfig: {
        disabled: deactivatedPartenaireEstimateur || false,
        showLogoInPDF: showLogoInPDF || false,
      },
      isRenovationEnergetique: false,
    };

    const createdPartenaire = await request<PartnerView>(`${apiURL}/admin/partners`, {
      method: 'POST',
      body: partner,
    });

    if (!createdPartenaire) {
      throw new Error('Partenaire creation failed');
    }

    const createdAgency = await request<AgencyView>(
      `${apiURL}/admin/partners/${createdPartenaire.uuid}/agencies`,
      {
        method: 'POST',
        body: {
          name: `godmod_agency_${seed}`,
          attributionName: `godmod_agency_attribution_${seed}`,
          internalAccountManager: `${seed}@example.com`,
          source: 'partner_offline',
          organizationUuid: createdPartenaire.uuid,
          isArchived: false,
          estimateurConfig: {
            disabled: deactivatedPartenaireEstimateur || false,
            showLogoInPDF: showLogoInPDF || false,
          },
          disableRegistrationEmail: false,
        },
      },
    );

    if (!createdAgency) {
      throw new Error('Partenaire creation failed');
    }

    const createdAccount = await request<AccountView>(`${apiURL}/admin/partners/accounts`, {
      method: 'POST',
      body: {
        agencyUuid: createdAgency.uuid,
        password: 'yolo',
        firstName: 'yolo',
        lastName: 'zgeg',
        email: `${seed}-yolo-zgeg-${Math.floor(Math.random() * 100000000000)}@gmail.com`,

        phoneNumber: '0102030405',
        isArchived: false,
        isOrganizationManager: false,
      },
    });

    if (!createdAccount) {
      throw new Error('Partenaire creation failed');
    }

    super.setCreatedEntities({
      partner: createdPartenaire,
      agency: createdAgency,
      account: createdAccount,
    });

    super.log('OK');

    return { partner: createdPartenaire, agency: createdAgency, account: createdAccount };
  }
}
