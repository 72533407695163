import React from 'react';

import { Form } from 'react-final-form';
import { useParams, useNavigate } from 'react-router-dom';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { Toggle } from '@travauxlib/shared/src/components/DesignSystem/components/Toggle';
import { required } from '@travauxlib/shared/src/utils/form';

import { useAuth } from 'api/useAuth';
import { ComparateurTracking } from 'utils/tracking/ComparateurTracking';

type Props = {
  toggleShowStats: () => void;
  showStats: boolean;
  isLoading: boolean;
};

export const DevisComparatorInput: React.FC<Props> = ({
  toggleShowStats,
  showStats,
  isLoading,
}) => {
  const { token1, token2 } = useParams();
  const navigate = useNavigate();
  const { adminUserProfile } = useAuth();

  return (
    <Card className="flex flex-col gap-xs !rounded-xxs mb-sm">
      <h5 className="text-h5 font-bold">Comparez des devis hemea</h5>
      <div className="text-b2 text-neutral-700 mb-md break-words">
        Insérer les deux tokens que vous souhaitez comparer. Un token est un code au format suivant
        :<span className="italic">"a4nwQqTgOxmR4sZmWxicXkbOvcrcmC"</span>, vous le trouverez dans
        l'URL d'un devis, ou d’un configurateur
      </div>
      <Form<{ firstDevisToken: string; secondDevisToken: string }>
        initialValuesEqual={() => true}
        initialValues={{
          firstDevisToken: token1,
          secondDevisToken: token2,
        }}
        onSubmit={async values => {
          ComparateurTracking.onComparatorUsed({ 'User Role': adminUserProfile.role });
          navigate(`/comparateur/${values.firstDevisToken}/${values.secondDevisToken}`);
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col justify-center sm-desktop:flex-row sm-desktop:items-center">
              <div className="flex text-b2 items-center sm-desktop:w-1/4 sm-desktop:pr-md sm-desktop:flex-row-reverse border-r-1">
                <div className="mr-xs sm-desktop:mr-0">
                  Afficher les statistiques de comparaison
                </div>
                <Toggle
                  className="sm-desktop:mr-xs"
                  onChange={() => toggleShowStats()}
                  id="showStats"
                  checked={showStats}
                />
              </div>
              <hr className="my-xs w-full sm-desktop:hidden" />
              <div className="flex flex-col sm-desktop:flex-row sm-desktop:w-3/4 sm-desktop:items-center sm-desktop:ml-md">
                <InputField
                  className="w-full"
                  id="firstDevisToken"
                  name="firstDevisToken"
                  placeholder="Premier Token"
                  validate={required}
                  label="Token du premier devis"
                />
                <div className="text-b1 font-bold w-full text-center sm-desktop:w-auto sm-desktop:mx-md sm-desktop:text-h3">
                  VS
                </div>
                <InputField
                  className="w-full mb-xl sm-desktop:mb-0 sm-desktop:mr-xl"
                  id="secondDevisToken"
                  name="secondDevisToken"
                  placeholder="Second Token"
                  validate={required}
                  label="Token du second devis"
                />
                <Button data-testid="upload" type="submit" loading={isLoading} disabled={isLoading}>
                  Comparer
                </Button>
              </div>
            </div>
          </form>
        )}
      </Form>
    </Card>
  );
};
