import React from 'react';

import classNames from 'classnames';

import PlusCircle from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircle.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

export const NewAccountButton: React.FC<{ disabled: boolean }> = ({ disabled }) => (
  <Link
    disabled={disabled}
    disabledTriggerClassName="h-full w-full"
    disabledMessageTooltip="Cette agence appartient à un réseau d'indépendants. Elle ne peut avoir plusieurs comptes rattachés."
    positionTooltip="top-start"
    to="new"
    data-testid="add-account"
    className="!h-full w-full"
  >
    <Card
      state="outlined"
      className="!h-full !w-full"
      bodyClassNames="!flex !items-center !justify-center !h-full !w-full"
    >
      <PlusCircle
        className={classNames('w-xxl h-xxl text-primary', disabled && '!text-neutral-300')}
      />
    </Card>
  </Link>
);
