import React from 'react';

import _isEqual from 'lodash/isEqual';
import { useLocation, Location } from 'react-router-dom';

import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';
import { Tab } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs/types';
import { stringifyParams, getUrlParams } from '@travauxlib/shared/src/utils/urls';

import { LeadStatus } from 'types';
import { statusATraiter, statusAdressable, statusNonAdressable } from 'utils/constants';

const buildTab = (
  location: any,
  leadsCount: number,
  loading: boolean,
  statuses: LeadStatus[],
): Tab => {
  const params = new URLSearchParams(location.search);
  const currentStatus = params.getAll('status');

  const statusName = statuses[0] || 'Tous';

  const label =
    loading || !_isEqual(statuses.sort(), currentStatus.sort())
      ? statusName
      : `${statusName} (${leadsCount})`;

  return {
    label,
    to: {
      search: stringifyParams({ ...getUrlParams(location), status: statuses }),
    },
    isActive: (location: Location) => {
      const qsStatus = new URLSearchParams(location.search).getAll('status');
      return _isEqual(qsStatus.sort(), statuses.sort());
    },
  };
};

type Props = {
  loading: boolean;
  filteredLeadsCount: number;
};

export const StatusTabs: React.FC<Props> = ({ filteredLeadsCount, loading }) => {
  const location = useLocation();
  return (
    <Tabs
      buttonClassNames="bg-lighter"
      items={[[statusATraiter], [statusAdressable], [statusNonAdressable], []].map(statuses =>
        buildTab(location as any, filteredLeadsCount, loading, statuses),
      )}
    />
  );
};
