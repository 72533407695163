import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AdminEventsProperties,
  AdminFeatures,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class ComparateurTrackingClass extends Tracker<AdminFeatures> {
  onComparatorUsed(properties: { [AdminEventsProperties.UserRole]?: string }): void {
    this.sendTracking({ event: TrackingEvents.Used, properties });
  }
}

export const ComparateurTracking = new ComparateurTrackingClass(AdminFeatures.Comparateur);
