import { createConsultation } from './createConsultation';
import { createLeadAndCompleteConfigurateur } from './createLeadAndCompleteConfigurateur';

import { ValidateConfigurateur } from '../gdm_steps/Configurateur/ValidateConfigurateur';

// eslint-disable-next-line
export const createLeadAndCompleteConfigurateurAndConsultation = () =>
  createLeadAndCompleteConfigurateur()
    .nextFlow(createConsultation())
    .next(new ValidateConfigurateur());
