import React, { useEffect } from 'react';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';
import { NavigationTracking } from 'utils/tracking/NavigationTracking';

import { DevisComparatorLayout } from './components/DevisComparatorLayout';

const Index: React.FC = () => {
  useEffect(() => {
    NavigationTracking.onComparateurViewed();
  }, []);

  return (
    <>
      <PageTitle title={PageTitleEnum.comparator} />
      <DevisComparatorLayout />
    </>
  );
};

export const DevisComparator = {
  Index,
};
