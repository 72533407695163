import React from 'react';

import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { PlacesAutocompleteField } from '@travauxlib/shared/src/components/PlacesAutocomplete/Field';
import {
  required,
  requiredValidEmail,
  requiredValidPhoneNumber,
  validateGoogleMapAddress,
} from '@travauxlib/shared/src/utils/form';

type Props = {
  address?: string;
};

export const ContactInfos: React.ComponentType<Props> = ({ address = '' }) => (
  <div className="!mb-sm">
    <h2 className="text-center sm-desktop:text-left">Coordonnées</h2>
    <div className="grid grid-cols-1 sm-desktop:grid-cols-4 gap-xs">
      <div className="sm-desktop:col-span-2">
        <InputField id="name" name="name" label="Nom de l'entreprise*" validate={required} />
      </div>
      <div>
        <InputField
          label="Prénom du gérant*"
          id="firstNameRepresentantLegal"
          name="firstNameRepresentantLegal"
          validate={required}
        />
      </div>
      <div>
        <InputField
          id="lastNameRepresentantLegal"
          name="lastNameRepresentantLegal"
          label="Nom du gérant*"
          validate={required}
        />
      </div>
      <div>
        <InputField id="email" name="email" label="Email*" validate={requiredValidEmail} />
      </div>
      <div>
        <InputField
          className="sm-desktop:col-span-2 w-full"
          id="phoneNumber"
          name="phoneNumber"
          label="Numéro de téléphone*"
          validate={requiredValidPhoneNumber}
        />
      </div>
      <div className="sm-desktop:col-span-4">
        <PlacesAutocompleteField
          id="googleMapsAddress"
          name="googleMapsAddress"
          label="Adresse complète"
          initialInputValue={address}
          validate={validateGoogleMapAddress('postal_code')}
          resetOnBlur
          googleApiKey={APP_CONFIG.googleApiKey}
          onlyAllowFrance={false}
        />
      </div>
      <div className="sm-desktop:col-span-2">
        <InputField
          id="websites.personalWebsite"
          name="websites.personalWebsite"
          type="url"
          label="Site internet"
        />
      </div>
      <div className="sm-desktop:col-span-2">
        <InputField id="websites.pinterest" name="websites.pinterest" label="Compte pinterest" />
      </div>
      <div className="sm-desktop:col-span-2">
        <InputField id="websites.instagram" name="websites.instagram" label="Compte instagram" />
      </div>
      <div className="sm-desktop:col-span-2">
        <InputField label="Compte facebook" id="websites.facebook" name="websites.facebook" />
      </div>
    </div>
  </div>
);
