import _groupBy from 'lodash/groupBy';

import { Location, TypologieProject } from '@travauxlib/shared/src/types';

export const getLocationLabel = (
  typologieProject: TypologieProject,
  location: Location,
): string => {
  if (location.label) {
    return location.label;
  }
  const locationByTypes = _groupBy(typologieProject.locations, l => l.typeLocation);
  if (locationByTypes[location.typeLocation].length === 1) {
    return location.typeLocation;
  }
  return `${location.typeLocation} N°${
    locationByTypes[location.typeLocation].indexOf(location) + 1
  }`;
};
