import { useMutation, useQueryClient } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { DEVIS_MODALITES_KEY } from './useDevisModalites';

export const useToggleServiceHemea: (devisToken: string) => () => void = (devisToken: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync: toggleServiceHemea } = useMutation({
    mutationFn: () =>
      request(`${APP_CONFIG.apiURL}/admin/devis/${devisToken}/modalites/toggle-service-hemea`, {
        method: 'PATCH',
      }),
    onSuccess: () => queryClient.refetchQueries({ queryKey: [DEVIS_MODALITES_KEY] }),
  });

  return toggleServiceHemea;
};
