import dayjs from 'dayjs';

import { ChantierAdminListItemView } from '@travauxlib/shared/src/types/api/admin/ChantierAdminListItemView';

import { BaseStep, ExecuteParams } from '../BaseStep';

type Input = { chantier: ChantierAdminListItemView };

export class ChantierStart extends BaseStep<Input> {
  public name = 'Chantier Start';

  public fields = [];

  async execute({ apiURL, chantier, request }: ExecuteParams<Input>): Promise<void> {
    const bodyStart = {
      value: dayjs(Date.now()).format(`YYYY-MM-DD`),
    };

    await request(`${apiURL}/admin/chantiers/${chantier.slug}/launch`, {
      method: 'POST',
      body: bodyStart,
    });

    super.log('OK');
  }
}
