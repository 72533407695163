export type Contact = {
  name: string;
};

export type ChiffreAffaire = {
  year: string;
  value: number;
};

export enum CharteStatus {
  accepted = 'accepted',
  skipped = 'skipped',
}

export type Insurance = {
  file: string;
  label: string;
  isDeleted?: string;
};

export type CustomCertification = {
  uuid: string;
  name: string;
  imageUrl: string;
};

export type ArchiType = 'interne' | 'externe' | 'bet';

export type CompanyType = 'pro' | 'architecte' | 'mandataire';

export enum LabellisationStatus {
  activee = 'ACTIVEE',
  labellisee = 'LABELLISEE',
  labellisationEnCours = 'LABELLISATION_EN_COURS',
  nonLabellisee = 'NON_LABELLISEE',
  delabellisee = 'DELABELLISEE',
}

export type Company = {
  email: string;
  name: string;
  firstNameRepresentantLegal: string;
  lastNameRepresentantLegal: string;
  address: string;
  postalCode: number;
  city: string;
  phoneNumber: string;
  statutEntreprise: string;
  logoUrl: string;
  numeroTVA: string;
  isLabelliseeOrActivee: boolean;
  siret: string;
  RCS: string;
  capital: number;
  isArchitecte: boolean;
  insurances: Insurance[];
  iban?: string;
  bic?: string;
  ribUrl?: string;
  websites: ProCompanyWebsites;
  selfDeclaredCertifications: string[];
  customCertifications: CustomCertification[];
  activatedElectronicSignature: boolean;
  archiType?: ArchiType;
  stripeConnectAccountId?: string;
  slug: string;
};

export type Insurance2 = {
  label: string;
  fileUrl: string;
};

export type Photo = {
  url: string;
  title: string;
};

export type ProCompanyWebsites = {
  personalWebsite?: string;
};

export type Project = {
  photos: Photo[];
  title: string;
  budget: string;
  durationInDays: number;
};

export type Review = {
  customerImageUrl?: string;
  rating: number;
  customerName: string;
  dateTravaux: string;
  projectType?: string;
  budget?: string;
  description?: string;
};

export type Company2 = {
  slug: string;
  banner?: any;
  publicDescription: string;
  specializations: string[];
  bannerImageUrl?: string;
  hasVitrine?: boolean;
  contacts: Contact[];
  phoneNumber: string;
  email: string;
  address: string;
  postalCode: string;
  city: string;
  codeNAF?: string;
  capital?: number;
  departements: string[];
  RCS?: string;
  numeroTVA?: string;
  websites: {
    personalWebsite?: string;
  };
  validInsurances: Insurance2[];
  logoUrl?: string;
  logo?: any;
  name: string;
  companyCreationDate: string;
  chiffresAffaires: ChiffreAffaire[];
  isArchitecte: boolean;
  infoGreffeUrl: string;
  statut: string;
  siret: string;
  firstNameRepresentantLegal: string;
  lastNameRepresentantLegal: string;
  isMandataire?: boolean;
};

export type ProCompany = {
  slug: string;
  company: Company2;
  reviews: Review[];
  projects: Project[];
};

export type ProUser = {
  id?: number;
  uuid?: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  isProCompanyAdmin: boolean;
  archived?: boolean;
  firstName?: string;
  lastName?: string;
};
