import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

export const CHANTIER_KEY = 'chantier';

const handleUpdateChantier = async (payload: {
  slug: string;
  dateDebutReelle?: string;
  dateFinReelle?: string;
  dateAnnulation?: string;
  responsableSuiviUuid?: string;
  coachCareUuid?: string;
  typeSuivi?: string;
  blocNote?: string;
  dateDebutEstimeeInterne?: string;
  dureeEstimeeDaysInterne?: number;
  receptionChantierThreshold?: number;
  leveeReservesThreshold?: number;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${payload.slug}`;

  await request(requestURL, {
    method: 'PUT',
    body: payload,
  });
};

export const useUpdateChantier = (
  {
    hideNotification,
  }: {
    hideNotification?: boolean;
  } = {
    hideNotification: false,
  },
): { updateChantier: typeof handleUpdateChantier } => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateChantier } = useMutation({
    mutationFn: handleUpdateChantier,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CHANTIER_KEY] });
      if (hideNotification) {
        return;
      }
      toast.success('Le chantier a bien été mis à jour');
    },
    onError: () => {
      toast.error(`Une erreur est survenue lors de la mise à jour du chantier`);
    },
  });

  return {
    updateChantier,
  };
};
