import React from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

export const FormFooter: React.FC = () => (
  <div className="sm-desktop:w-[57rem] flex justify-end mx-auto mb-3xl">
    <Button type="submit" className="mt-md" variant="primary">
      Enregistrer
    </Button>
  </div>
);
