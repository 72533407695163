import { LeadAdminView } from '@travauxlib/shared/src/types/api/admin/LeadAdminView';

import { BaseStep, ExecuteParams } from '../BaseStep';

type Input = { lead?: LeadAdminView };

export class FillMandatoryPipedriveFields extends BaseStep<Input, { lead: LeadAdminView }> {
  public name: string = 'Fill mandatory Pipedrive Fields';

  public fields = [];

  public async execute({
    lead,
    apiURL,
    request,
  }: ExecuteParams<Input>): Promise<{ lead: LeadAdminView }> {
    if (!lead) {
      super.error('No lead given');
      return Promise.reject();
    }

    await request(`${apiURL}/admin/e2e/set-pipedrive/${lead.pipedriveDealId}`, {
      method: 'POST',
    });

    super.log('OK');

    return { lead: lead };
  }
}
