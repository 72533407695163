import React from 'react';

import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';

type Props = {
  disabledReasons: string[];
  label: string;
  labelInTooltip: string;
};

export const DisabledBadge: React.FC<Props> = ({ disabledReasons, label, labelInTooltip }) => (
  <WithTooltip
    trigger={
      <div>
        <Tag
          size="md"
          variant="error"
          label={
            <div className="flex items-center">
              {label}
              <HelpCircle className="w-md h-md ml-xs" />
            </div>
          }
        />
      </div>
    }
    position="bottom"
    width="22rem"
  >
    <div className="p-sm">
      {labelInTooltip}
      <ul className="pl-md mb-0">
        {disabledReasons.map(reason => (
          <li key={reason}>{reason}</li>
        ))}
      </ul>
    </div>
  </WithTooltip>
);
