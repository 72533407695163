import React from 'react';

import _groupBy from 'lodash/groupBy';
import _partition from 'lodash/partition';

import Box from '@travauxlib/shared/src/components/DesignSystem/assets/Box.svg?react';
import MedalOutline from '@travauxlib/shared/src/components/DesignSystem/assets/MedalOutline.svg?react';
import OpenTab from '@travauxlib/shared/src/components/DesignSystem/assets/OpenTab.svg?react';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { FournitureLigne } from '@travauxlib/shared/src/types';

type Props = {
  fournitureLignes: FournitureLigne[];
};

export const BlocFourniture: React.FC<{ fournitureLigne: FournitureLigne }> = ({
  fournitureLigne,
}) => (
  <div>
    <div className="mb-xs text-neutral-700 text-b2 pt-xxs">{fournitureLigne.designation}</div>
    <div className="flex gap-md flex-wrap mb-xxs">
      {fournitureLigne.images.map(image => (
        <a key={image} href={image} target="_blank" rel="noreferrer noopener">
          <ImgLazy
            className="rounded-xxs !w-[5rem] !h-[5rem] object-cover"
            width={175}
            url={image}
            alt=""
            eagerLoading
          />
        </a>
      ))}
    </div>
    <div className="mb-xxs">
      <MultilineParagraph
        paragraphClassName="text-neutral-600 !text-sm"
        text={fournitureLigne.description}
        hasLinksOpenedInNewWindow
      />
    </div>
    {fournitureLigne.url && fournitureLigne.showUrlOnDevis && (
      <div className="text-sm mb-xxs">
        <Link
          className="flex items-center"
          size="sm"
          href={fournitureLigne.url}
          target="_blank"
          rel="noreferrer noopener"
        >
          <span className="mr-xxs">Accéder au site fournisseur</span>
          <OpenTab className="w-md h-md" />
        </Link>
      </div>
    )}
  </div>
);

export const Fournitures: React.FC<Props> = ({ fournitureLignes }) => {
  const validFournitureLignes = fournitureLignes.filter(f => !!f.designation || !!f.images.length);
  if (!validFournitureLignes.length) {
    return null;
  }
  const [hemeaFournitures, otherFournitures] = _partition(
    validFournitureLignes,
    l => !!l.hemeaBrand,
  );

  const groupedHemeaFournitures = _groupBy(hemeaFournitures, l => l.hemeaBrand);

  return (
    <div className="flex flex-col gap-y-xs pl-xl grow">
      {Object.entries(groupedHemeaFournitures).map(([hemeaBrand, fournitures]) => (
        <div key={hemeaBrand} className="bg-white border rounded-md grow py-xs">
          <div className="px-xs flex items-center font-medium mb-xxs">
            <MedalOutline className="w-lg h-lg mr-xxs text-primary" />
            <div>hemea recommande {hemeaBrand}</div>
          </div>
          <div className="ml-xxs pl-xl pr-xs">
            {fournitures.map((fournitureLigne, index) => (
              <React.Fragment key={fournitureLigne.uuid}>
                <BlocFourniture fournitureLigne={fournitureLigne} />
                {index !== fournitures.length - 1 && (
                  <div className="my-xs h-[1px] bg-neutral-300" />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      ))}
      {otherFournitures.length !== 0 && (
        <div className="bg-white border rounded-md grow py-xs">
          <div className="px-xs flex items-center font-medium mb-xxs">
            <Box className="w-lg h-lg mr-xxs" />
            <div>Fournitures & équipements</div>
          </div>
          <div className="ml-xxs pl-xl pr-xs">
            {otherFournitures.map((fournitureLigne, index) => (
              <React.Fragment key={fournitureLigne.uuid}>
                <BlocFourniture fournitureLigne={fournitureLigne} />
                {index !== otherFournitures.length - 1 && (
                  <div className="my-xs h-[1px] bg-neutral-300" />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
