import React from 'react';

import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

import { ProjectListView } from 'types';

import { ProjectCardContainer } from './ProjectCardContainer';

type Props = {
  project: ProjectListView;
};

export const ProjectCard: React.FC<Props> = ({ project }) => {
  const { uuid, pipedriveDealUrl } = project;
  return (
    <ProjectCardContainer
      project={project}
      actionBlock={
        <div className="flex justify-end gap-md mt-md">
          {pipedriveDealUrl && (
            <ButtonLink href={pipedriveDealUrl} target="_blank" variant="secondary" size="sm">
              Accéder au Pipe
            </ButtonLink>
          )}
          <ButtonLink to={`../projects/${uuid}/information`} size="sm">
            Voir le projet
          </ButtonLink>
        </div>
      }
    />
  );
};
