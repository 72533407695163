import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { InsurancePayload } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { PRO_COMPANY_KEY } from './useProCompany';

const handleUploadInsurance = (insurance: InsurancePayload): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/pro-companies/${insurance.slug}/insurances`;
  return request(requestURL, {
    method: 'POST',
    body: insurance,
  });
};

export const useUploadInsurance = (): typeof handleUploadInsurance => {
  const queryClient = useQueryClient();

  const { mutateAsync: uploadInsurance } = useMutation({
    mutationFn: handleUploadInsurance,
    onSuccess: () => {
      toast.success('Assurance ajoutée');
      queryClient.invalidateQueries({ queryKey: [PRO_COMPANY_KEY] });
    },
    onError: () => {
      toast.error("Erreur lors de l'ajout de l'assurance");
    },
  });

  return uploadInsurance;
};
