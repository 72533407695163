import { Action, Subject, Flag } from './types';

import { AbilityBuilder } from '../AbilityBuilder';

const tpInterneBuilder = new AbilityBuilder<Action, Subject, Flag>();

tpInterneBuilder.can('validateAppelDePaiement', 'Chantier');
tpInterneBuilder.can('open', 'Chantier');
tpInterneBuilder.can('close', 'Chantier');
tpInterneBuilder.can('changeAmountAppelDePaiement', 'Chantier');

export const tpInterne = tpInterneBuilder.build();
