import { Navigate } from 'react-router-dom';

import { useAuth } from 'api/useAuth';
import { useCreatePartner } from 'features/Partners/api/mutations';
import { usePartner } from 'features/Partners/hooks/usePartner';
import { PartnerAccount } from 'types';
import { PartenairesTracking } from 'utils/tracking/PartenairesTracking';

import { AccountForm } from './AccountForm';
import { PartnerDetailsContent } from './PartnerDetailsModal/PartnerDetailsContent';

export const CreateAccount: React.FC = () => {
  const partner = usePartner();
  const { createAccount } = useCreatePartner(partner?.uuid);
  const { adminUserProfile } = useAuth();

  if (!partner) {
    return <Navigate to=".." />;
  }

  return (
    <PartnerDetailsContent>
      <h3 className="mb-md">Nouveau compte</h3>
      <AccountForm
        onSubmit={(account: PartnerAccount) => {
          PartenairesTracking.onComptePartenaireCreated({
            'User Role': adminUserProfile.role,
            'Compte Partenaire UUID': account.uuid,
            'Agence Partenaire UUID': account.agencyUuid,
            'Organisation Partenaire UUID': account.organizationUuid,
          });
          return createAccount(account);
        }}
        agencies={partner.agencies}
      />
    </PartnerDetailsContent>
  );
};
