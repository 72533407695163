import React from 'react';

import { Form } from 'react-final-form';
import { useOutletContext, Outlet } from 'react-router-dom';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';

import { useUpdateProCompany } from 'features/Entreprises/features/Entreprise/api/useUpdateProCompany';
import { ProCompany } from 'types';

import { Banner } from '../GlobalInfos/Banner';

const tabs = [
  {
    label: 'Projets',
    to: 'projects',
  },
  {
    label: 'Avis',
    to: 'reviews',
  },
];

export const SiteVitrine: React.FC = () => {
  const { proCompany } = useOutletContext<{ proCompany: ProCompany }>();
  const { slug, pageVitrineUrl } = proCompany;
  const { update } = useUpdateProCompany();

  return (
    <>
      <div className="p-lg border border-t-0 rounded bg-neutral-0 shadow-xs mb-lg">
        <div className="text-right">
          {pageVitrineUrl ? (
            <Link href={pageVitrineUrl} target="_blank" rel="noopener noreferrer">
              Voir la page vitrine
            </Link>
          ) : (
            'Pas de page vitrine'
          )}
        </div>
        <Form
          initialValues={proCompany}
          onSubmit={values =>
            update({
              globalInfos: {
                ...proCompany,
                publicDescription: values.publicDescription,
                bannerImageUrl: values.bannerImageUrl,
              },
              slug,
            })
          }
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="mb-xxl">
              <h2>Informations supplémentaires pour la page vitrine</h2>
              <Banner slug={slug} />
              <TextareaField
                id="publicDescription"
                rows={3}
                name="publicDescription"
                label="Description à destination des clients"
              />
              <div className="text-center">
                <Button type="submit">Valider</Button>
              </div>
            </form>
          )}
        </Form>
      </div>
      <Tabs items={tabs} />
      <div className="p-lg border border-t-0 rounded bg-neutral-0 shadow-xs">
        <Outlet context={{ proCompany }} />
      </div>
    </>
  );
};
