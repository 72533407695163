import React from 'react';

import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { MenuiseriesInterieures } from '@travauxlib/shared/src/features/Configurateur/types/MenuiseriesInterieures';

import { useConfiguration } from 'features/Configurateur/api/useConfiguration';
import { FormFooter } from 'features/Configurateur/components/FormFooter';
import { FormHeader } from 'features/Configurateur/components/FormHeader';

import { MenuiseriesACreer } from './components/MenuiseriesACreer';
import { MenuiseriesADeposer } from './components/MenuiseriesADeposer';
import { Portes } from './components/Portes';
import { VerrieresACreer } from './components/VerrieresACreer';

import { useCreateUpdateConfiguration } from '../../api/useCreateUpdateConfiguration';

export const ConfigurateurMenuiseriesInterieures: React.FC = () => {
  const projectUuid = useParams().uuid!;
  const { configuration } = useConfiguration(projectUuid);

  const createUpdateConfiguration = useCreateUpdateConfiguration();
  const navigate = useNavigate();

  if (!configuration) {
    return <Navigate to="../bien" />;
  }

  return (
    <Form<MenuiseriesInterieures>
      mutators={{ ...arrayMutators }}
      destroyOnUnregister
      initialValues={configuration?.answers?.menuiseriesInterieures}
      onSubmit={menuiseriesInterieures => {
        const updatedConfiguration = {
          ...configuration,
          answers: {
            ...configuration.answers,
            menuiseriesInterieures,
          },
        };
        createUpdateConfiguration(updatedConfiguration);
        navigate('..');
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormHeader questionId="menuiseriesInterieures" />
          <Portes configuration={configuration} />
          <MenuiseriesADeposer configuration={configuration} />
          <MenuiseriesACreer configuration={configuration} />
          <VerrieresACreer configuration={configuration} />
          <FormFooter />
        </form>
      )}
    </Form>
  );
};
