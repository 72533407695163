import React from 'react';

import { LotComparison } from '@travauxlib/shared/src/features/DevisDisplay/types';

import { DisplayStat } from './DisplayStat';

type Props = {
  maxLineNumber: number;
  comparisons: LotComparison[];
};

export const DisplayIdenticalLines: React.FC<Props> = ({ comparisons, maxLineNumber }) => {
  const numberSimilarLines = comparisons
    .flatMap(lotComparison => lotComparison.lignes)
    .filter(ligneComparison => ligneComparison.status === 'unchanged').length;

  return (
    <div className="sm-desktop:w-1/3 sm-desktop:border-r sm-desktop:pr-xs">
      <DisplayStat
        percentage={Math.round((numberSimilarLines / maxLineNumber) * 100)}
        title="Lignes identiques"
        subtitle="Prestations, prix et quantités identiques"
      />
    </div>
  );
};
