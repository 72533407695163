import React, { useState } from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import {
  SimpleModal,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { SearchBar } from '@travauxlib/shared/src/components/DesignSystem/components/SearchBar';
import { useDebouncedValue } from '@travauxlib/shared/src/hooks/useDebouncedValue';

import { useLatestProjects } from 'features/Project/api/useLatestProjects';
import { useTransferDevis } from 'features/Project/api/useTransferDevis';
import { ProjectCardContainer } from 'features/Project/features/ProjectListing/components/ProjectCardContainer';

type OwnProps = {
  projectUuid: string;
  token: string;
};

type Props = OwnProps & {
  handleClose: () => void;
};

const TransferPreChiffrageModal: React.FC<Props> = ({ projectUuid, token, handleClose }) => {
  const [clientSearch, setClientSearch] = useState('');
  const debouncedClientSearch = useDebouncedValue(clientSearch, 500);
  const { isLoading, projects } = useLatestProjects(debouncedClientSearch);

  const { isLoading: isTransferring, transferDevis } = useTransferDevis();

  return (
    <SimpleModal
      handleClose={handleClose}
      title="Transférer le devis"
      isOpen
      cancelAction={{
        label: 'Annuler',
        onClick: handleClose,
      }}
    >
      <div className="px-xl h-full overflow-auto flex flex-col">
        <SearchBar
          value={clientSearch}
          onChange={setClientSearch}
          className="w-full h-xxl mb-md"
          placeholder="Nom du client"
        />
        <div className="overflow-auto h-[75rem] flex-grow px-xs">
          {isLoading ? (
            <div className="h-full py-xl">
              <LoaderWrapper />
            </div>
          ) : (
            projects.map(project => (
              <div className="border mb-xs" key={project.uuid}>
                <ProjectCardContainer
                  project={project}
                  actionBlock={
                    <div className="flex justify-end gap-md mt-md">
                      <Button
                        type="button"
                        loading={isTransferring}
                        disabled={isTransferring}
                        onClick={async () => {
                          await transferDevis({
                            projectUuid,
                            token,
                            newProjectUuid: project.uuid,
                          });
                          handleClose();
                        }}
                      >
                        Transférer le devis
                      </Button>
                    </div>
                  }
                />
              </div>
            ))
          )}
        </div>
      </div>
    </SimpleModal>
  );
};

export const useOpenTransferPreChiffrageModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();

  return ({ projectUuid, token }: OwnProps) => {
    openModal(TransferPreChiffrageModal, {
      projectUuid,
      token,
      closable: true,
      isScrollable: false,
    });
  };
};
