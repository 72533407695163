import React from 'react';

import classNames from 'classnames';

import { FormControlListProps } from '../FormControl/commons';
import { InlineAlert } from '../InlineAlert';

import { Chip } from '.';

export type Props = FormControlListProps<string[], string>;

export const ChipMultipleList: React.FC<Props> = ({
  options,
  value,
  onChange,
  id,
  disabled,
  renderingOptions,
  error,
}) => (
  <>
    <div className={classNames(renderingOptions?.containerClassName)} id={id}>
      {options.map(option => (
        <div key={option.label} className={classNames(renderingOptions?.elementClassName)}>
          <Chip
            data-testid={`chiplist-select-${option.value}`}
            onClick={() =>
              value.includes(option.value)
                ? onChange(value.filter(val => val !== option.value))
                : onChange([...value, option.value])
            }
            label={option.label!}
            disabled={disabled || option.disabled}
            selected={value.includes(option.value)}
          />
        </div>
      ))}
    </div>
    {error && (
      <div className="mt-xxs">
        <InlineAlert level="error">{error}</InlineAlert>
      </div>
    )}
  </>
);
