export type Deferred<T> = {
  promise: Promise<T>;
  resolve: (v: T) => void;
  reject: (err: Error) => void;
};

// thin wrapper around a Promise that
// allows to resolve or reject from outside
export function deferred<T>(): Deferred<T> {
  let resolve;
  let reject;
  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return {
    promise,
    resolve: resolve!,
    reject: reject!,
  };
}

export function resolvedDeferred<T>(value: T): Deferred<T> {
  const def = deferred<T>();
  def.resolve(value);
  return def;
}
