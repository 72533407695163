import React from 'react';

import { useFormState } from 'react-final-form';

import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { required } from '@travauxlib/shared/src/utils/form';

import { PartnerAccount, PartnerAgency } from 'types';
import { partnerOffline } from 'utils/constants/acquisition';

type Props = {
  partnerSubCampaigns: PartnerAgency[];
};

const SubCampaignAccounts: React.FC<{ accounts: PartnerAccount[]; shouldBeRequired: boolean }> = ({
  accounts,
  shouldBeRequired,
}) => (
  <div className="col-span-full sm-desktop:col-span-6">
    <DropdownField
      id="emailCommercial"
      label="Agent"
      name="emailCommercial"
      options={accounts.map(account => ({
        label: `${account.firstName} ${account.lastName}`,
        value: account.email,
      }))}
      validate={shouldBeRequired ? required : undefined}
    />
  </div>
);

export const SubCampaign: React.FC<Props> = ({ partnerSubCampaigns }) => {
  const { values } = useFormState();
  const selectedSubCampaign = values?.subcampaign;

  return (
    <>
      <div className="col-span-full sm-desktop:col-span-6">
        <DropdownField
          id="subcampaign"
          label="Agence du partenaire"
          name="subcampaign"
          options={partnerSubCampaigns.map(({ attributionName, internalAccountManager }) => ({
            label: attributionName,
            value: { attributionName, internalAccountManager },
          }))}
          validate={required}
        />
      </div>
      {selectedSubCampaign && (
        <SubCampaignAccounts
          accounts={
            partnerSubCampaigns.find(
              ({ attributionName }) => attributionName === selectedSubCampaign.attributionName,
            )?.accounts || []
          }
          shouldBeRequired={values?.source === partnerOffline.value}
        />
      )}
    </>
  );
};
