import dayjs from 'dayjs';

import { Devis } from '@travauxlib/shared/src/types';

import { BaseStep, ExecuteParams, StepField } from '../BaseStep';

type Input = { devis: Devis; dealUuid: string; email?: string };

export class ProUpdateDevis extends BaseStep<
  Input,
  { devis: Devis },
  StepField[],
  { devis: Devis }
> {
  public name = 'Pro update devis';

  public fields = [];

  async execute({
    apiURL,
    dealUuid,
    devis,
    request,
  }: ExecuteParams<Input>): Promise<{ devis: Devis }> {
    const dn = dayjs(Date.now());
    const de = dayjs(dn).add(1, 'month');

    const updatedDevisRoute = {
      ...devis,
      dateFin: `${de.format(`YYYY-MM-DD`)}`,
      dateDebut: `${dn.format(`YYYY-MM-DD`)}`,
      dureeChantier: {
        // Ca sert a que dalle nan ?
        quantity: 1,
        unit: 'mois',
      },
      dureeValidite: {
        quantity: 1,
        unit: 'mois',
      },
      emailContent:
        'Lmao mais ca va dans tous les sens ces routes devis ou quoi ? Elle sert a quoi envoyer mail ptdr',
      introductionLetter: 'Blablabla',
    };

    // On s'en fous de son retour "Envoyer mail" renvoie un devis. Oui c'est succulent haja le top
    const devisUpdatedWithoutEmailSent = await request<Devis>(
      `${apiURL}/pro/deals/${dealUuid}/devis/${devis.token}/v2`,
      {
        method: 'PUT',
        body: updatedDevisRoute,
      },
    );

    super.log('OK');

    super.setCreatedEntities({
      devis: devisUpdatedWithoutEmailSent,
    });

    return { devis: devisUpdatedWithoutEmailSent };
  }
}
