import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { Fenetre } from '@travauxlib/shared/src/features/Configurateur/types/MenuiseriesExterieures';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { LocationsCounters } from 'features/Configurateur/components/LocationsCounters';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

type Props = {
  configuration: Configuration;
};

const Locations: React.FC<{ name: string; configuration: Configuration }> = ({
  configuration,
  name,
}) => (
  <>
    <QuestionTitle prefix="2" title="Pièces concernées ?" />
    <LocationsCounters
      configuration={configuration}
      locations={configuration.locations}
      getFields={locationUuid => [
        { label: 'Fenêtre', name: `${name}.locationQuantities.${locationUuid}.fenetres` },
        {
          label: 'Porte fenêtre',
          name: `${name}.locationQuantities.${locationUuid}.porteFenetres`,
        },
        { label: 'Baie vitrée', name: `${name}.locationQuantities.${locationUuid}.baieVitrees` },
      ]}
    />
  </>
);

const Materiau: React.FC<{
  name: string;
  configuration: Configuration;
  value: Fenetre;
  fields: {
    update: (index: number, payload: object) => void;
  };
  index: number;
  onDelete: () => void;
}> = ({ configuration, name, value, onDelete, fields, index }) => (
  <>
    <div className="flex justify-between">
      <QuestionTitle prefix="1" title="Matériau souhaité ?" />
      <PrestationDelete onDelete={onDelete} />
    </div>
    <OptionCheckbox fields={fields} value={value} index={index} />
    <ChipListField
      name={`${name}.typeMateriau`}
      renderingOptions={{
        containerClassName: 'mb-md flex flex-wrap gap-xs',
      }}
      options={[
        {
          label: 'PVC',
          value: 'pvc',
        },
        {
          label: 'Alu',
          value: 'alu',
        },
        {
          label: 'Bois',
          value: 'bois',
        },
      ]}
    />
    {value.typeMateriau && <Locations configuration={configuration} name={name} />}
  </>
);

export const Fenetres: React.FC<Props> = ({ configuration }) => (
  <Accordion className="mb-md" title="Fenêtres à remplacer ?" prefix="2" defaultExpanded>
    <FieldArray<Fenetre> name="fenetres">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={index} className="mb-md">
                <Materiau
                  configuration={configuration}
                  name={name}
                  value={value}
                  onDelete={() => fields.remove(index)}
                  fields={fields}
                  index={index}
                />
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
