import { AccountView } from '@travauxlib/shared/src/types/api/admin/AccountView';
import { PartnerView } from '@travauxlib/shared/src/types/api/admin/PartnerView';

import { BaseStep, ExecuteParams } from '../BaseStep';

type Input = { partner: PartnerView; account: AccountView };

export class SignInAsPartner extends BaseStep<Input> {
  public name = 'Sign in as partner';

  public fields = [];

  async execute({ apiURL, partner, account, request }: ExecuteParams<Input>): Promise<void> {
    super.log(`Sign in as partner`);

    await request(`${apiURL}/admin/partners/${partner.uuid}/accounts/${account.uuid}/sign-in`, {
      method: 'POST',
    });

    super.log('OK');
  }
}
