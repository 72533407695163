import React from 'react';

import { useItemsLimiter } from 'hooks/useItemsLimiter';
import { ConsultationOffer, ProCompany } from 'types';

import { TableCompanyDescription } from './TableCompanyDescription';

type Props = {
  proCompanies: ProCompany[];
  ActionsComponent?: React.ComponentType<{ proCompany: ProCompany }>;
  onConsultationDone?: (createdConsultationOffers: ConsultationOffer[]) => void;
};

export const TableComponent: React.FC<Props> = ({ proCompanies, ActionsComponent, ...rest }) => {
  const { LimitInfo, slicedItems: slicedProCompanies } = useItemsLimiter<ProCompany>({
    items: proCompanies,
    itemName: 'entreprises',
    isFeminine: true,
    limit: 10,
  });

  return (
    <>
      <h1>
        {proCompanies.length} Entreprises ({slicedProCompanies.length} affichées)
      </h1>

      <TableCompanyDescription
        proCompanies={slicedProCompanies}
        ActionsComponent={ActionsComponent}
        {...rest}
      />
      <LimitInfo />
    </>
  );
};
