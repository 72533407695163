import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { LotComparison } from '@travauxlib/shared/src/features/DevisDisplay/types';

import { DisplayIdenticalLines } from './DisplayIdenticalLines';
import { DisplayPriceDifference } from './DisplayPriceDifference';
import { DisplaySimilarLines } from './DisplaySimilarLines';

type Props = {
  comparisons: LotComparison[];
  maxLineNumber: number;
  basePrice: number;
  comparePrice: number;
};

export const DevisComparatorStats: React.FC<Props> = ({
  comparisons,
  maxLineNumber,
  basePrice,
  comparePrice,
}) => (
  <Card className="flex flex-col gap-xs !rounded-xxs">
    <h5 className="text-h5 font-bold mb-md">Résultat du comparateur de devis</h5>
    <div className="flex flex-col sm-desktop:flex-row gap-md sm-desktop:gap-0">
      <DisplayIdenticalLines comparisons={comparisons} maxLineNumber={maxLineNumber} />
      <DisplaySimilarLines comparisons={comparisons} maxLineNumber={maxLineNumber} />
      <DisplayPriceDifference firstDevisTotalHT={basePrice} secondDevisTotalHT={comparePrice} />
    </div>
  </Card>
);
