import React from 'react';

import { Tag, Variant } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';

type Props = {
  warningThreshold?: number;
  errorThreshold: number;
  value?: number;
  label?: string;
  smallerIsBetter?: boolean;
};

export const computeLevel = ({
  value,
  errorThreshold,
  warningThreshold,
  smallerIsBetter,
}: Props & { value: number }): Variant => {
  if (value > errorThreshold && smallerIsBetter) {
    return 'error';
  }
  if (warningThreshold !== undefined && value > warningThreshold && smallerIsBetter) {
    return 'primary';
  }
  if (smallerIsBetter) {
    return 'success';
  }
  if (value < errorThreshold) {
    return 'error';
  }
  if (warningThreshold !== undefined && value < warningThreshold) {
    return 'primary';
  }
  return 'success';
};

export const ThresholdBadge: React.FC<Props> = props => {
  const { label, value } = props;
  if (value === undefined) {
    return null;
  }

  return (
    <Tag size="md" variant={computeLevel({ ...props, value })} label={label || value.toString()} />
  );
};
