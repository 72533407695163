import { useMutation, useQueryClient } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { COMMISSIONS_KEY } from './useCommissions';

const handleAdvanceStatusCommissions = (commissionUuids: string[]): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/partners/commissions/advance-status`;
  return request(requestURL, {
    method: 'POST',
    body: {
      commissionUuids,
    },
  });
};

export const useAdvanceStatusCommissions = (): typeof handleAdvanceStatusCommissions => {
  const queryClient = useQueryClient();
  const { mutateAsync: advanceStatusCommissions } = useMutation({
    mutationFn: handleAdvanceStatusCommissions,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COMMISSIONS_KEY] });
    },
  });

  return advanceStatusCommissions;
};
