import React, { useMemo } from 'react';

import { Field, Form } from 'react-final-form';
import { useOutletContext } from 'react-router-dom';

import { StickySubmitButton } from 'components/StickySubmitButton';
import { useUpdateProCompany } from 'features/Entreprises/features/Entreprise/api/useUpdateProCompany';
import { ProCompany } from 'types';

import { ContactInfos } from './ContactInfos';
import { Contacts } from './Contacts';
import { LegalInfos } from './LegalInfos';

export const GlobalInfos: React.FC = () => {
  const { proCompany } = useOutletContext<{ proCompany: ProCompany }>();
  const { slug, siret, companyType } = proCompany;

  // final-form dont want numerics keys for objects ...
  const chiffresAffaires: { [K: string]: number } = useMemo(
    () =>
      Object.keys(proCompany.chiffresAffaires).reduce(
        (acc, key) => ({
          ...acc,
          [`key-${key}`]: proCompany.chiffresAffaires[key],
        }),
        {},
      ),
    [proCompany.chiffresAffaires],
  );
  const { update } = useUpdateProCompany();

  const onSubmit = (globalInfos: ProCompany): void => {
    const chiffresAffaires: { [K: string]: number } = Object.keys(
      globalInfos.chiffresAffaires,
    ).reduce(
      (acc, key) => ({
        ...acc,
        [key.replace('key-', '')]: globalInfos.chiffresAffaires[key],
      }),
      {},
    );
    update({ globalInfos: { ...globalInfos, chiffresAffaires }, slug });
  };

  return (
    <div className="py-lg px-md border border-solid border-gray-300 border-t-0 !rounded !bg-white !shadow-xs">
      <Form<ProCompany>
        initialValues={{ ...proCompany, chiffresAffaires }}
        onSubmit={values => {
          onSubmit(values);
        }}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <ContactInfos address={values.googleMapsAddress?.formatted_address} />
            <LegalInfos siret={siret} companyType={companyType} />
            <Field name="contacts" component={Contacts} />
            <StickySubmitButton />
          </form>
        )}
      </Form>
    </div>
  );
};
