import React from 'react';

import { Form } from 'react-final-form';

import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

type Props = {
  isOpen: boolean;
  onSubmit: (reason: { reason: string; typeReason: string }) => void;
  onClose: () => void;
};

export const ManualModeModal: React.FC<Props> = ({ isOpen, onSubmit, onClose }) => {
  const onFormSubmit = (values: { reason: string }): void => {
    onSubmit({ typeReason: 'other', reason: values.reason });
  };

  return (
    <Modal isOpen={isOpen} handleClose={onClose} title="Pourquoi ?">
      <Form onSubmit={onFormSubmit}>
        {({ handleSubmit, submitting, invalid }) => (
          <ModalContent
            title="Sélectionnez une entreprise manuellement"
            handleSubmit={handleSubmit}
            validateAction={{
              label: 'Valider',
              type: 'submit',
              disabled: invalid || submitting,
              loading: submitting,
            }}
            cancelAction={{ label: 'Annuler', onClick: onClose }}
          >
            <div className="flex flex-col">
              Attention, vous allez passer en consultation manuelle, plus aucun critère ne sera
              appliqué. Veillez à ce que l’entreprise sélectionnée corresponde bien aux critères de
              votre projet : taille du chantier, gamme, type de projet, assurance et certifications
              RGE.
            </div>
          </ModalContent>
        )}
      </Form>
    </Modal>
  );
};
