import React from 'react';

import classNames from 'classnames';

import Autorenew from '@travauxlib/shared/src/components/DesignSystem/assets/Autorenew.svg?react';
import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbol.svg?react';
import Gift from '@travauxlib/shared/src/components/DesignSystem/assets/Gift.svg?react';
import PlusCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircleOutline.svg?react';
import { Ligne, LigneStatus } from '@travauxlib/shared/src/types';

type Props = {
  ligne: Ligne;
};

const statusMapper: {
  [K in LigneStatus]?: { label: string; Icon: React.ComponentType<{ className: string }> };
} = {
  [LigneStatus.Canceled]: {
    label: 'ANNULÉ',
    Icon: CrossSymbol,
  },
  [LigneStatus.Modified]: {
    label: 'REMPLACÉ',
    Icon: Autorenew,
  },
  [LigneStatus.Free]: {
    label: 'OFFERT',
    Icon: Gift,
  },
  [LigneStatus.Option]: {
    label: 'OPTION',
    Icon: PlusCircleOutline,
  },
};

export const LigneStatusIndicator: React.FC<Props> = ({ ligne }) => {
  const status = ligne.status || ligne.compare?.status;
  const statusData = status && statusMapper[status];

  if (!statusData) {
    return null;
  }

  const { Icon, label } = statusData;

  return (
    <div
      className={classNames('text-primary flex text-sm font-bold items-center', {
        'line-through': ligne.compare?.status && !ligne.status,
      })}
    >
      <Icon className="shrink-0 w-md h-md mr-xxs" />
      <span>{label}</span>
    </div>
  );
};
