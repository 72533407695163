import React from 'react';

import dayjs from 'dayjs';

import InfoCircle from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircle.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';

import { TableFactureEnCours } from './components/TableFactureEnCours';
import { TableFactureReglees } from './components/TableFactureReglees';
import { useInformationsFacturation } from './hooks/useInformationsFacturation';
import { CDPLFacture, CDPLFactureStatus } from './types';

export type Props = {};

const mockFactures: CDPLFacture[] = Array.from({ length: 30 }, (_, index) => ({
  createdAt: dayjs().add(index, 'day').toISOString(),
  numero: `${index + 1}`,
  designation: `Facture ${index + 1}`,
  chantierTitle: `chantier ${index + 1}`,
  chantierSlug: '36-rue-severine-pluribatiment-plurielec-douud',
  token: 'UklysrSV2LNMQmf2nSi5FGv8hNCm6g',
  montantTTC: 1000,
  status: index % 2 === 0 ? CDPLFactureStatus.Draft : CDPLFactureStatus.Paid,
}));
export const CdplFacturation: React.FC<Props> = () => {
  const handleOpenInformationsFacturations = useInformationsFacturation();

  const isFacturesEnCours = location.search.includes('status=encours') || !location.search;

  const facturesEnCours = mockFactures.filter(
    ({ status }) => status === CDPLFactureStatus.Draft || status === CDPLFactureStatus.Sent,
  );

  const facturesReglees = mockFactures.filter(({ status }) => status === CDPLFactureStatus.Paid);

  return (
    <>
      <PageTitle title={PageTitleEnum.cdplFacturation} />
      <div className="flex grow justify-between items-center">
        <div className="text-h3 font-bold">Ma facturation</div>
        <Button
          leftIcon={<InfoCircle />}
          onClick={handleOpenInformationsFacturations}
          variant="secondary"
          size="md"
        >
          Informations facturations
        </Button>
      </div>
      <Tabs
        items={[
          {
            label: 'Factures en cours',
            to: { search: 'status=encours' },
            isActive: () => isFacturesEnCours,
          },
          {
            label: 'Factures réglées',
            to: { search: 'status=reglees' },
            isActive: location => location.search.includes('reglees'),
          },
        ]}
      />
      {isFacturesEnCours ? (
        <TableFactureEnCours factures={facturesEnCours} />
      ) : (
        <TableFactureReglees factures={facturesReglees} />
      )}
    </>
  );
};
