import React from 'react';

import classNames from 'classnames';

import AutorenewCircle from '@travauxlib/shared/src/components/DesignSystem/assets/AutorenewCircle.svg?react';
import CheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';
import CrossCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircle.svg?react';
import Living from '@travauxlib/shared/src/components/DesignSystem/assets/LivingDuoTone.svg?react';
import Workman from '@travauxlib/shared/src/components/DesignSystem/assets/Workman.svg?react';
import { ChantierStatus as ChantierStatusType } from '@travauxlib/shared/src/features/Chantiers/types';

const ICON_CLASS = 'w-xmd h-xmd pr-xxs';
const INDICATOR_ICON_CLASS = 'w-sm h-sm mr-xxs';
export const ChantierStatusOption: {
  [K in ChantierStatusType]: {
    label: string;
    style: string;
    icon: JSX.Element;
    indicatorIcon?: JSX.Element;
  };
} = {
  non_demarre: {
    label: 'Non démarré',
    style: 'bg-neutral-0',
    icon: <Workman className={ICON_CLASS} />,
  },
  demarre: {
    label: 'Démarré',
    style: 'bg-info-50',
    icon: <Workman className={ICON_CLASS} />,
    indicatorIcon: <AutorenewCircle className={`${INDICATOR_ICON_CLASS} text-info-600`} />,
  },
  termine: {
    label: 'Cloturé',
    style: 'bg-success-50',
    icon: <Living className={ICON_CLASS} />,
    indicatorIcon: <CheckCircle className={`${INDICATOR_ICON_CLASS} text-success-600`} />,
  },
  annule: {
    label: 'Annulé',
    style: 'bg-warning-50',
    icon: <Living className={ICON_CLASS} />,
    indicatorIcon: <CrossCircle className={`${INDICATOR_ICON_CLASS} text-warning-600 `} />,
  },
};

type Props = {
  status: ChantierStatusType;
};

export const ChantierStatus: React.FC<Props> = ({ status }) => {
  const { icon, indicatorIcon, label, style } = ChantierStatusOption[status];

  return (
    <span
      className={classNames(
        'text-b2 b rounded-sm py-3xs px-xs text-gray-700 border-1 border-neutral-300 items-center flex',
        style,
      )}
    >
      {indicatorIcon} {icon}
      Chantier : {label}
    </span>
  );
};
