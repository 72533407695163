import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AdminEventsProperties,
  AdminFeatures,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class LeadsTrackingClass extends Tracker<AdminFeatures> {
  onLeadCreated(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.LeadSource]?: string;
    [AdminEventsProperties.ClientEmail]: string;
    [AdminEventsProperties.ClientTelephoneNumber]: string;
    [AdminEventsProperties.ClientFirstname]: string;
    [AdminEventsProperties.ClientLastname]: string;
  }): void {
    this.sendTracking({ event: TrackingEvents.Created, properties });
  }
}

export const LeadsTracking = new LeadsTrackingClass(AdminFeatures.Leads);
