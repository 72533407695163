import React from 'react';

import { useNavigate } from 'react-router-dom';

import DocumentCheck from '@travauxlib/shared/src/components/DesignSystem/assets/DocumentCheck.svg?react';
import { EventCard } from '@travauxlib/shared/src/components/DesignSystem/components/EventCard';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { TypeSuivi } from '@travauxlib/shared/src/types';

import { useSuiviChantierHistoryList } from 'api/useSuiviChantierHistoryList';

import { eventCardClassName } from './constants';

import { useLatestSuiviChantierAValider } from '../../hooks/useLatestSuiviChantierAValider';

type Props = {
  chantier: Chantier;
};

export const ValiderAvancementCard: React.FC<Props> = ({ chantier }) => {
  const navigate = useNavigate();
  const { suiviChantierHistoryList } = useSuiviChantierHistoryList(chantier.slug);

  const latestSuiviChantierAValider = useLatestSuiviChantierAValider(suiviChantierHistoryList);

  return chantier.enableSuiviChantier &&
    !!latestSuiviChantierAValider &&
    [TypeSuivi.TravauxPlanner, TypeSuivi.TravauxPlannerWithService].includes(chantier.typeSuivi) ? (
    <EventCard
      className={eventCardClassName}
      icon={<DocumentCheck />}
      variant="beige"
      title="Valider l’état d’avancement"
      action={{
        label: 'Accéder au suivi d’avancement',
        onClick: () => navigate(`./suivi-chantier/${latestSuiviChantierAValider?.uuid}`),
        hideIcon: true,
      }}
    >
      <></>
    </EventCard>
  ) : (
    <></>
  );
};
