import React from 'react';

import { useOutletContext } from 'react-router-dom';

import { ProCompany } from 'types';

import { Project } from './Project';

export const ProjectListing: React.FC = () => {
  const { proCompany } = useOutletContext<{ proCompany: ProCompany }>();
  const { slug, projects } = proCompany;
  return (
    <>
      <Project initialValues={{ photos: [] }} slug={slug} />
      {projects.map((project, index) => (
        <Project
          key={project.id}
          index={index}
          initialValues={project}
          slug={slug}
          projectId={project.id}
        />
      ))}
    </>
  );
};
