import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { ConfirmArchiveUserModal, ConfirmArchiveUserModalProps } from '../ConfirmArchiveUserModal';

export const useOpenConfirmDeleteUserModal = (): ((
  props: Omit<ConfirmArchiveUserModalProps, 'handleClose'>,
) => void) => {
  const openModal = useOpenModal();

  return (props: ConfirmArchiveUserModalProps) =>
    openModal(ConfirmArchiveUserModal, {
      ...props,
      closable: true,
    });
};
