import React from 'react';

import { Form } from 'react-final-form';

import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';
import { ButtonGroupField } from '@travauxlib/shared/src/components/DesignSystem/components/ButtonGroup/Field';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { FormLabel } from '@travauxlib/shared/src/components/FormLabel';
import { toast } from '@travauxlib/shared/src/components/Notifications';
import { PlacesAutocompleteField } from '@travauxlib/shared/src/components/PlacesAutocomplete/Field';
import {
  required,
  requiredValidEmail,
  requiredValidPhoneNumber,
  validateGoogleMapAddress,
} from '@travauxlib/shared/src/utils/form';

import { useAuth } from 'api/useAuth';
import { PeopleSelectorField } from 'components/PeopleSelector/Field';
import { usePersons } from 'features/Trombinoscope/api/usePersons';
import { chaleursLead } from 'utils/constants';
import { recommandationClient, recommandationInterne } from 'utils/constants/acquisition';
import { LeadsTracking } from 'utils/tracking/LeadsTracking';

import { Source } from './Source';

import { FormData, useCreateLead } from '../api/useCreateLead';

export const CreateLead: React.FC = () => {
  const createLead = useCreateLead();
  const { persons } = usePersons();
  const { adminUserProfile } = useAuth();

  return (
    <Form<FormData>
      onSubmit={values => {
        if (
          [recommandationClient.value, recommandationInterne.value].includes(values.source!) &&
          !values.parrainPipedriveDealId &&
          !values.campaign
        ) {
          toast.error(
            'Au moins un parmi "Copain de qui" ou "ID du deal pipedrive du parrain" doit être renseigné',
          );
          return undefined;
        }
        LeadsTracking.onLeadCreated({
          'User Role': adminUserProfile.role,
          'Lead Source': values.source,
          'Client Email': values.email,
          'Client Firstname': values.firstName,
          'Client Lastname': values.lastName,
          'Client Telephone Number': values.phoneNumber,
        });

        return createLead(values);
      }}
      initialValues={{ shouldAttributeLeadManually: false, shouldSendCreationEmail: true }}
    >
      {({ handleSubmit, submitting, values }) => (
        <form className="container pb-xl" onSubmit={handleSubmit}>
          <h1 className="mb-lg">Création d&apos;un nouveau lead</h1>
          <div className="grid grid-cols-12 gap-md mb-lg">
            <div className="col-span-full sm-desktop:col-span-6">
              <InputField label="Prénom" id="firstName" name="firstName" validate={required} />
            </div>
            <div className="col-span-full sm-desktop:col-span-6">
              <InputField
                id="lastName"
                label="Nom de famille"
                name="lastName"
                validate={required}
              />
            </div>
            <div className="col-span-full sm-desktop:col-span-6">
              <InputField id="email" name="email" label="Email" validate={requiredValidEmail} />
            </div>
            <div className="col-span-full sm-desktop:col-span-6">
              <InputField
                id="phoneNumber"
                label="Téléphone"
                name="phoneNumber"
                validate={requiredValidPhoneNumber}
              />
            </div>
            <div className="col-span-full sm-desktop:col-span-6">
              <PlacesAutocompleteField
                label="Adresse (optionnel)"
                validate={validateGoogleMapAddress('postal_code')}
                id="googleMapsAddress"
                name="googleMapsAddress"
                googleApiKey={APP_CONFIG.googleApiKey}
              />
            </div>
            <div className="col-span-full sm-desktop:col-span-6">
              <InputField
                label={
                  <>
                    Titre de la demande <small>(optionnel)</small>
                  </>
                }
                id="title"
                name="title"
              />
            </div>
            <Source
              source={values.source}
              campaign={
                typeof values.campaign === 'string'
                  ? values.campaign
                  : values.campaign?.attributionName
              }
            />
            {values.source === 'recommandation_client' && (
              <>
                <div className="col-span-full sm-desktop:col-span-6">
                  <NumberInputField
                    name="parrainPipedriveDealId"
                    label="ID du deal pipedrive du parrain"
                    id="parrainPipedriveDealId"
                  />
                </div>
                <div className="col-span-full sm-desktop:col-span-6">
                  <PeopleSelectorField
                    name="parrainOwnerEmail"
                    label="Parrainage récupéré par (chez hemea)"
                    id="parrainOwnerEmail"
                    persons={persons}
                    validate={required}
                  />
                </div>
              </>
            )}

            <div className="col-span-full sm-desktop:col-start-1 sm-desktop:col-end-7">
              <NumberInputField name="budget" label="Budget (optionnel)" id="budget" suffix="€" />
            </div>
            <div className="col-span-full sm-desktop:col-span-6">
              <NumberInputField
                name="surface"
                label="Surface (optionnel)"
                id="surface"
                suffix="m²"
              />
            </div>
            <div className="col-span-full">
              <TextareaField
                label="Description du projet (optionnel)"
                id="description"
                name="description"
              />
            </div>
            <div className="col-span-full">
              <CheckboxField
                name="shouldAttributeLeadManually"
                id="shouldAttributeLeadManually"
                label="Passer par l'attribution manuelle du lead"
              />
              {values.shouldAttributeLeadManually && (
                <AlertMessage
                  level="warning"
                  className="mt-xs"
                  title="À utiliser seulement dans un cas exceptionnel. Ce lead ne pourra être attribué que par une personne disposant du droit d'attribution des leads."
                />
              )}
            </div>
            <div className="col-span-full">
              <CheckboxField
                name="shouldSendCreationEmail"
                id="shouldSendCreationEmail"
                label="Envoyer automatiquement un email au client pour l'inviter à choisir son mdp"
              />
            </div>
            <div className="col-span-full">
              <FormLabel
                label={
                  <>
                    Chaleur du deal <small>(optionnel)</small>
                  </>
                }
              />
              <ButtonGroupField
                name="chaleurLead"
                options={chaleursLead.map(chaleurLead => ({
                  label: chaleurLead,
                  value: chaleurLead,
                }))}
              />
            </div>
          </div>
          <div className="text-center">
            <Button type="submit" disabled={submitting} loading={submitting}>
              Créer le lead
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
