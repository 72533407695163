import { BaseStep, ExecuteParams } from '../BaseStep';

export class ChantierTriggerADPScheduler extends BaseStep {
  public name = 'Chantier trigger ADP Scheduler';

  public fields = [];

  async execute({ apiURL, request }: ExecuteParams): Promise<void> {
    await request(`${apiURL}/admin/send-adp-scheduler`);
    super.log('OK');
  }
}
