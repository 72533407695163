export enum ModeCalculCommission {
  commission_18_degressive = 'commission_18_degressive',
  commission_15_degressive = 'commission_15_degressive',
  commission_15_flat_rate = 'commission_15_flat_rate',
  commission_100_flat_rate = 'commission_100_flat_rate',
}

export const modeCalculCommissionById: {
  [key in ModeCalculCommission]: {
    label: string;
  };
} = {
  commission_18_degressive: {
    label: 'Commission 18% si non usage du LDD, dégressive',
  },
  commission_15_degressive: {
    label: 'Commission 15% dégressive',
  },
  commission_15_flat_rate: {
    label: 'Commission 15% fixe',
  },
  commission_100_flat_rate: {
    label: 'Commission archi interne',
  },
};
