import React from 'react';

import { Form } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';

type Props = {
  onSearchSubmit: (v: SearchPayload) => void;
};

export type SearchPayload = {
  companyEmail?: string;
  userEmail?: string;
  companyName?: string;
  siret?: string;
};

export const Search: React.FC<Props> = ({ onSearchSubmit }) => (
  <Form onSubmit={onSearchSubmit}>
    {({ dirty, handleSubmit, form }) => (
      <form onSubmit={handleSubmit} className="flex flex-wrap -mx-md mb-md">
        <div className="md-desktop:w-3/12 md-desktop:px-md">
          <InputField
            id="companyName"
            name="companyName"
            label="Nom d'entreprise"
            autoFocus
            placeholder="hemea, bat, ..."
          />
        </div>
        <div className="md-desktop:w-3/12 md-desktop:px-md">
          <InputField
            id="companyEmail"
            name="companyEmail"
            label="Email de l'entreprise"
            placeholder="contact@hemea.com"
          />
        </div>
        <div className="md-desktop:w-3/12 md-desktop:px-md">
          <InputField
            id="userEmail"
            name="userEmail"
            label="Email de l'utilisateur"
            placeholder="m.burin@hemea.com"
          />
        </div>
        <div className="mb-md md-desktop:w-2/12 md-desktop:px-md">
          <InputField id="siret" name="siret" label="Siret de l'entreprise" />
        </div>
        <div className="flex justify-end w-full md-desktop:px-md">
          <Button disabled={!dirty} className="mr-md" type="submit">
            Chercher
          </Button>
          <Button
            variant="secondary"
            type="button"
            onClick={() => {
              form.reset();
            }}
          >
            Reset
          </Button>
        </div>
      </form>
    )}
  </Form>
);
