import dayjs from 'dayjs';

import { PvType } from '@travauxlib/shared/src/features/Chantiers/types';
import { S3File } from '@travauxlib/shared/src/types';
import { ChantierAdminListItemView } from '@travauxlib/shared/src/types/api/admin/ChantierAdminListItemView';
import { formatLocalDate } from '@travauxlib/shared/src/utils/time';

import { BaseStep, ExecuteParams } from '../BaseStep';

const fields = [
  {
    name: 'hasReserves',
    helperText: 'Est ce que le PV a des réserves?',
    fieldType: 'checkbox',
    initialValue: true,
  },
] as const;

type Input = { chantier: ChantierAdminListItemView };

export class UploadChantierPV extends BaseStep<
  Input,
  { dealUuid: string },
  typeof fields,
  { chantier: ChantierAdminListItemView }
> {
  public name: string = 'Create PV chantier';

  public fields = fields;

  async execute({
    apiURL,
    request,
    chantier,
    hasReserves,
  }: ExecuteParams<Input, typeof fields>): Promise<{ dealUuid: string } | undefined> {
    super.log('Uploading PV...');

    const fileContent = new Blob(['yolo'], { type: 'text/plain' });

    const formData = new FormData();
    formData.append('file', fileContent, 'filename.txt');

    const s3File = await request<S3File>(`${apiURL}/pro/temporary-file?filename=yolo.txt`, {
      method: 'POST',
      body: formData,
    });

    const pv = {
      pvType: PvType.ReceptionChantier,
      documents: [s3File],
    };

    const { pvUuid } = await request<{ pvUuid: string }>(
      `${apiURL}/pro/chantiers/${chantier.uuid}/upload-pv`,
      {
        method: 'POST',
        body: pv,
      },
    );

    super.log('PV uploaded !');

    super.log('Validating PV...');

    const validatePvPayload = {
      pvUuid,
      pvType: pv.pvType,
      hasReserves,
      dateSignature: formatLocalDate(dayjs()),
    };

    await request(`${apiURL}/client/chantiers/${chantier.uuid}/validate-pv`, {
      method: 'POST',
      body: validatePvPayload,
    });

    super.log('PV validated !');
    return;
  }
}
