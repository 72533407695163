import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { UploadFilesPayload } from '@travauxlib/shared/src/types';
import { DocumentTag } from '@travauxlib/shared/src/types/api/domain/client/DocumentTag';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from 'features/Project/api/useProjectContext';

const handleUploadFiles = async ({
  projectUuid,
  tag,
  files,
  onUpdate,
  onSuccess,
  onError,
}: UploadFilesPayload & { tag: DocumentTag }): Promise<File[]> =>
  Promise.all(
    files.map((file, i) => {
      const requestUrl = `${APP_CONFIG.apiURL}/admin/projects/${projectUuid}/files/${file.uuid}?filename=${file.name}&tag=${tag}`;

      return request(requestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': file.type,
        },
        body: file.body,
        onUploadProgress: (event: ProgressEvent) => onUpdate(event, i),
      })
        .then(p => {
          onSuccess(i);
          return Promise.resolve(p);
        })
        .catch(() => {
          onError(i);
          return Promise.resolve();
        });
    }),
  );

export const useUploadProjectDocument = (): {
  uploadFiles: typeof handleUploadFiles;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: uploadFiles, isPending } = useMutation({
    mutationFn: handleUploadFiles,
    onSuccess: (_, { projectUuid }) => {
      toast.success('Les documents ont bien été ajoutés');
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY, projectUuid] });
    },
    onError: () => {
      toast.error("Une erreur est survenue lors de l'envoi de votre fichier");
    },
  });

  return { uploadFiles, isLoading: isPending };
};
