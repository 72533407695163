import React from 'react';

import { Form } from 'react-final-form';
import { useOutletContext } from 'react-router-dom';

import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { DateTimePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DateTimePicker/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';

import { useAuth } from 'api/useAuth';
import { StickySubmitButton } from 'components/StickySubmitButton';
import { useUpdateProCompany } from 'features/Entreprises/features/Entreprise/api/useUpdateProCompany';
import { ProCompany } from 'types';
import { planTypeOptions } from 'utils/constants';

import { BaseDePrix } from './BaseDePrix';
import { OptymeaButton } from './OptymeaButton';

export const ClubPro: React.FC = () => {
  const { proCompany } = useOutletContext<{ proCompany: ProCompany }>();
  const { slug } = proCompany;
  const {
    adminUserProfile: { isClubPro },
  } = useAuth();
  const { update } = useUpdateProCompany();
  return (
    <div className="!p-lg border border-solid border-gray-300 border-t-0 !rounded !bg-white !shadow-xs">
      <h3 className="!mb-lg">
        <Tag size="md" variant="info" label={proCompany.clubProStatus} />{' '}
        {proCompany.isOptymeaAccountActivated && (
          <Tag size="md" variant="success" label="Compte Optymea activé" />
        )}
        {!proCompany.isOptymeaAccountActivated && isClubPro && (
          <OptymeaButton slug={slug} optymeaCreationLink={proCompany.optymeaCreationLink} />
        )}
      </h3>
      <Form
        initialValues={proCompany}
        onSubmit={async settings => {
          await update({ settings, slug });
        }}
      >
        {({ handleSubmit, values: { clubProPlanType } }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap -mx-md !mb-sm">
              <div className="sm-desktop:w-4/12 sm-desktop:px-md relative">
                <CheckboxField
                  disabled={!isClubPro}
                  label="Gratuit illimité"
                  name="hasClubProForFree"
                />
              </div>
              <div className="sm-desktop:w-4/12 sm-desktop:px-md relative">
                <CheckboxField
                  disabled={!isClubPro}
                  label="Prélèvement automatique activé"
                  name="hasOngoingSubscription"
                />
                {proCompany.stripeCustomerId && (
                  <span>Compte client stripe : {proCompany.stripeCustomerId}</span>
                )}
              </div>
            </div>
            <div className="flex flex-wrap -mx-md !mb-sm">
              <div className="sm-desktop:w-4/12 sm-desktop:px-md relative">
                <DateTimePickerField
                  disabled={!isClubPro}
                  label="Date de fin de période d'essai"
                  id="trialPeriodEndDate"
                  name="trialPeriodEndDate"
                />
              </div>
              <div className="sm-desktop:w-4/12 sm-desktop:px-md relative">
                <DropdownField
                  disabled={!isClubPro}
                  label="Dernier plan"
                  id="clubProPlanType"
                  name="clubProPlanType"
                  options={planTypeOptions}
                />
              </div>
              {clubProPlanType && (
                <div className="sm-desktop:w-4/12 sm-desktop:px-md relative">
                  <DateTimePickerField
                    disabled={!isClubPro}
                    label="Date de fin de plan"
                    id="clubProEndDate"
                    name="clubProEndDate"
                  />
                </div>
              )}
            </div>
            <div className="flex flex-wrap -mx-md !mb-sm">
              <div className="sm-desktop:w-2/12 sm-desktop:px-md relative">
                <CheckboxField
                  disabled={!isClubPro}
                  label="Peut payer par RIB"
                  name="canPayByRib"
                />
              </div>
              <div className="sm-desktop:w-2/12 sm-desktop:px-md relative">
                <CheckboxField disabled={!isClubPro} label="Peut payer par CB" name="canPayByCb" />
              </div>
              <div className="sm-desktop:w-2/12 sm-desktop:px-md relative">
                <CheckboxField
                  disabled={!isClubPro}
                  label="Site vitrine activé"
                  name="hasVitrine"
                />
              </div>
              <div className="sm-desktop:w-4/12 sm-desktop:px-md relative">
                <CheckboxField
                  disabled={!isClubPro}
                  label="Désactiver la numérotation automatique"
                  name="disabledFactureAutomaticNumbering"
                />
              </div>
              <div className="sm-desktop:w-4/12 sm-desktop:px-md relative">
                <CheckboxField
                  disabled={!isClubPro}
                  label="Email vérifié (débloquage du compte)"
                  name="isEmailVerified"
                />
              </div>
              <div className="sm-desktop:w-4/12 sm-desktop:px-md relative">
                <CheckboxField
                  disabled={!isClubPro}
                  label="Activer la base de prix Hemea"
                  name="isBaseDePrixHemeaEnabled"
                />
              </div>
            </div>
            <StickySubmitButton />
          </form>
        )}
      </Form>
      <BaseDePrix slug={slug} />
    </div>
  );
};
