import { ComparisonStatus } from '@travauxlib/shared/src/types';

export const getBGColorFromComparisonStatus = (comparisonStatus?: ComparisonStatus): string => {
  switch (comparisonStatus) {
    case 'missing':
      return 'bg-error-50';
    case 'modified':
      return 'bg-info-50';
    case 'added':
      return 'bg-success-50';
    default:
      return '';
  }
};
