import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { AddOrUpdateUser, AddOrUpdateUserProps } from '../components/AddOrUpdateUser';

export const useOpenHandleProCompanyUserModal = (): ((
  props: Omit<AddOrUpdateUserProps, 'handleClose'>,
) => void) => {
  const openModal = useOpenModal();

  return ({
    proUser,
    proCompanyName,
    onSubmit,
    archiveUser,
    slug,
  }: Omit<AddOrUpdateUserProps, 'handleClose'>) =>
    openModal(AddOrUpdateUser, {
      archiveUser,
      proUser,
      proCompanyName,
      onSubmit,
      closable: true,
      slug,
    });
};
