import React, { Dispatch, SetStateAction } from 'react';

import Lightbox from 'react-image-lightbox';

import { DownloadableFileWithTag } from '@travauxlib/shared/src/types';
import { buildImgixURL } from '@travauxlib/shared/src/utils/urls';

type Props = {
  index?: number;
  setIndex: Dispatch<SetStateAction<number | undefined>>;
  images: DownloadableFileWithTag[];
};

const lightboxImgixParams = {
  w: 1800,
  fit: 'max',
};

export const ImagesViewer: React.FC<Props> = ({ index, setIndex, images }) =>
  index !== undefined ? (
    <Lightbox
      imageTitle={images[index].name}
      mainSrc={buildImgixURL(images[index].imgixUrl!, lightboxImgixParams)}
      nextSrc={buildImgixURL(images[(index + 1) % images.length].imgixUrl!, lightboxImgixParams)}
      prevSrc={buildImgixURL(
        images[(index + images.length - 1) % images.length].imgixUrl!,
        lightboxImgixParams,
      )}
      onMoveNextRequest={() => setIndex(index => (index! + 1) % images.length)}
      onMovePrevRequest={() => setIndex(index => (index! + images.length - 1) % images.length)}
      onCloseRequest={() => setIndex(undefined)}
      reactModalProps={{ overlayClassName: 'fixed !z-50 inset-0' }}
    />
  ) : null;
