import React from 'react';

import { useNavigate } from 'react-router-dom';

import { useDownloadAsZip } from '@travauxlib/shared/src/api/useDownloadAsZip';
import DotsMenu from '@travauxlib/shared/src/components/DesignSystem/assets/DotsMenu.svg?react';
import Download from '@travauxlib/shared/src/components/DesignSystem/assets/Download.svg?react';
import PaperSearch from '@travauxlib/shared/src/components/DesignSystem/assets/PaperSearch.svg?react';
import { ActionList } from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { DocumentTag } from '@travauxlib/shared/src/types/api/domain/client/DocumentTag';
import { slugify } from '@travauxlib/shared/src/utils/slugify';

type Props = {
  tag: DocumentTag;
  urlsToDownload: string[];
  disabled: boolean;
};

export const DocumentMenu: React.FC<Props> = ({ tag, urlsToDownload, disabled }) => {
  const navigate = useNavigate();
  const { downloadAsZip, isLoading } = useDownloadAsZip();
  const parsedZipName = `${slugify(tag)}.zip`;

  return (
    <ActionList
      items={[
        {
          type: 'action',
          icon: <PaperSearch />,
          label: 'Consulter',
          onClick: () => navigate(`../documents/${tag}`),
          disabled: isLoading || disabled,
        },
        {
          type: 'action',
          icon: <Download />,
          label: 'Télécharger',
          onClick: () =>
            downloadAsZip({
              zipName: parsedZipName,
              urls: urlsToDownload,
            }),
          disabled: isLoading || disabled,
        },
      ]}
      trigger={(toggle, referenceProps) => (
        <IconButton
          onClick={toggle}
          {...referenceProps}
          className="-mr-xxs"
          dataTestId="document-actions"
          disabled={disabled}
        >
          <DotsMenu />
        </IconButton>
      )}
    />
  );
};
