import { ChipPictoVerticalListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { LocationOption } from '@travauxlib/shared/src/features/Configurateur/types';

type Props = {
  name: string;
  locationOptions: LocationOption[];
};

export const ChipPictoVerticalListFieldConfigurateur: React.FC<Props> = ({
  name,
  locationOptions,
}) => (
  <ChipPictoVerticalListField
    className="flex flex-wrap gap-sm mb-md"
    wrapperClassName="w-[7.75rem] h-[7.75rem]"
    size="xmd"
    options={locationOptions}
    name={name}
  />
);
