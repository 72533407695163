import React from 'react';

import { Navigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { AccordionItem } from '@travauxlib/shared/src/components/DesignSystem/components/Accordion';
import { Banner } from '@travauxlib/shared/src/components/DesignSystem/components/Banner';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { ScrollToHashElement } from '@travauxlib/shared/src/components/ScrollToHashElement';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';
import { useChantier } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantier';

import { ChantierComptes } from './components/ChantierComptes';
import { ChantierDevis } from './components/ChantierDevis';
import { ChantierFactures } from './components/ChantierFactures';
import { ChantierFluxFinanciers } from './components/ChantierFluxFinancier';
import { ChantierHeader } from './components/ChantierHeader';
import { ChantierInformations } from './components/ChantierInformations';
import { ChantierBlocNote } from './components/ChantierInformations/ChantierBlocNote';
import { ChantierCompany } from './components/ChantierInformations/ChantierCompany';
import { EventCards } from './components/EventCards';
import { FinDeChantier } from './components/FinDeChantier';

type AccordionState = {
  information: boolean;
  devisFactures: boolean;
  receptionChantier: boolean;
};

type ChantierAccordionState = {
  [slug: string]: AccordionState;
};

type AccordionStateKey = keyof AccordionState;

const DEFAULT_ACCORDION_STATE: AccordionState = {
  information: true,
  devisFactures: true,
  receptionChantier: true,
};

export const ProjectChantier: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { isLoading, chantier } = useChantier(slug!);

  const [accordionStates, setAccordionStates] = useLocalStorage<ChantierAccordionState>(
    'page-chantier-accordions',
  );

  if (isLoading) {
    return <LoaderWrapper />;
  }

  if (!chantier) {
    return <Navigate replace to=".." />;
  }

  const updateAccordionState = (key: AccordionStateKey, isExpanded: boolean): void => {
    setAccordionStates((prevStates: ChantierAccordionState) => ({
      ...prevStates,
      [chantier.slug]: {
        ...(prevStates?.[chantier.slug] || DEFAULT_ACCORDION_STATE),
        [key]: isExpanded,
      },
    }));
  };

  const currentAccordionState = accordionStates?.[chantier.slug] || DEFAULT_ACCORDION_STATE;

  return (
    <div>
      <ScrollToHashElement />
      <PageTitle title={`${PageTitleEnum.chantiers} - ${chantier.customerName}`} />
      {chantier.enableSuiviRenforce && (
        <Banner
          level="warning"
          className="mb-md sm-desktop:-mx-xl -mx-md"
          title="Le suivi financier renforcé est activé sur ce chantier : toutes les libérations de fonds
        doivent être validées manuellement."
        />
      )}
      <ChantierHeader chantier={chantier} />
      <EventCards chantier={chantier} />
      <AccordionItem
        className="!rounded-md my-md"
        defaultExpanded={currentAccordionState.information}
        title="Information du chantier"
        labelClassName="!text-h4"
        onChange={isExpanded => updateAccordionState('information', isExpanded)}
      >
        <div className="flex flex-col sm-desktop:flex-row gap-md">
          <ChantierInformations chantier={chantier} />
          <ChantierCompany chantier={chantier} />
        </div>
        <ChantierBlocNote chantier={chantier} />
      </AccordionItem>
      <AccordionItem
        className="!rounded-md mb-md"
        title="Devis & Factures"
        labelClassName="!text-h4"
        defaultExpanded={currentAccordionState.devisFactures}
        onChange={isExpanded => updateAccordionState('devisFactures', isExpanded)}
      >
        <div className="flex flex-col sm-desktop:flex-row gap-md">
          <ChantierDevis devis={chantier.devis} />
          <ChantierFactures chantier={chantier} />
        </div>
      </AccordionItem>
      <AccordionItem
        className="!rounded-md"
        title="Documents de fin de chantier"
        labelClassName="!text-h4"
        defaultExpanded={currentAccordionState.receptionChantier}
        onChange={isExpanded => updateAccordionState('receptionChantier', isExpanded)}
      >
        <FinDeChantier chantier={chantier} />
      </AccordionItem>
      <ChantierComptes chantier={chantier} />
      <ChantierFluxFinanciers chantier={chantier} />
    </div>
  );
};
