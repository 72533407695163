import React from 'react';

import { useNavigate } from 'react-router-dom';

import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import {
  Props as TagProps,
  Tag,
} from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

type Props = {
  leftIcon?: React.ReactNode;
  title: string;
  label: string;
  price: number;
  tag?: TagProps;
  complementary?: string;
  rightIcon?: React.ReactNode;
  rightLink?: string;
  to?: string;
  href?: string;
  target?: string;
};

// TODO Mhh faire évoluer l'ItemList du DS pour qu'il se comporte un peu de cette manière, ça sera plus doux
export const ItemLink: React.FC<Props> = ({
  leftIcon,
  title,
  label,
  price,
  tag,
  complementary,
  rightIcon,
  rightLink,
  to,
  href,
  target,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="flex items-center gap-xxs px-md py-xxs border-b last-of-type:border-0 text-b2 hover:bg-neutral-100 active:bg-neutral-200"
      key={title}
      onClick={() => (to ? navigate(to) : window.open(href, target ?? '_self'))}
      role="button"
    >
      {leftIcon && (
        <ColoredCircle variant="beige" size="xs">
          {leftIcon}
        </ColoredCircle>
      )}
      <div className="grow py-3xs truncate">
        {title}
        <div className="text-sm text-neutral-700 truncate">{label}</div>
      </div>
      <div className="flex flex-col">
        {(complementary || tag) && (
          <div className="flex self-end">
            {complementary && (
              <div className="text-sm text-neutral-600 mr-xxs">{complementary}</div>
            )}
            {tag && <Tag className="self-end" size="sm" {...tag} />}
          </div>
        )}
        <EURCurrency className="font-medium self-end" amount={price} />
      </div>
      {rightIcon && (
        <IconButton
          className="shrink-0"
          onClick={event => {
            if (!rightLink) {
              return;
            }

            event.stopPropagation();
            return window.open(rightLink, '_self');
          }}
        >
          {rightIcon}
        </IconButton>
      )}
    </div>
  );
};
