import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { PeopleSelector as RawPeopleSelector, Props as PeopleSelectorProps } from '.';

type WrapperProps = FieldRenderProps<string> & Omit<PeopleSelectorProps, 'onChange' | 'value'>;

export const PeopleSelectorWrapper: React.FC<WrapperProps> = ({
  input: { value, onChange },
  meta,
  ...props
}) => {
  const { touched } = meta;
  const error = meta.error || (!meta.dirtySinceLastSubmit && meta.submitError);
  return (
    <RawPeopleSelector value={value} onChange={onChange} {...props} error={touched && error} />
  );
};

type Props = {
  name: string;
} & Omit<PeopleSelectorProps, 'value' | 'onChange'> &
  UseFieldConfig<string>;

export const PeopleSelectorField: React.FC<Props> = props => (
  <Field component={PeopleSelectorWrapper} {...props} />
);
