import React from 'react';

import { Navigate } from 'react-router-dom';

import { useAuth } from 'api/useAuth';
import { useCreatePartner } from 'features/Partners/api/mutations';
import { usePartner } from 'features/Partners/hooks/usePartner';
import { PartenairesTracking } from 'utils/tracking/PartenairesTracking';

import { AgencyForm } from './AgencyForm';

export const CreateAgency: React.FC = () => {
  const partner = usePartner();
  const { createAgency } = useCreatePartner(partner?.uuid);
  const { adminUserProfile } = useAuth();
  if (!partner) {
    return <Navigate to=".." />;
  }

  return (
    <AgencyForm
      onSubmit={values => {
        PartenairesTracking.onAgencePartenaireCreated({
          'User Role': adminUserProfile.role,
          'Agence Partenaire UUID': values.uuid,
          'Organisation Partenaire UUID': partner.uuid,
        });

        return createAgency({
          ...values,
          organizationUuid: partner.uuid,
        });
      }}
      initialValues={{
        internalAccountManager: partner.internalAccountManager,
        estimateurConfig: partner.estimateurConfig,
      }}
    />
  );
};
