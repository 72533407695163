import { ProjectStatus } from 'types';

export const statusFilterOptions: { label: string; value: ProjectStatus }[] = [
  { label: 'Nouveau', value: 'new' },
  { label: 'Étude de projet', value: 'etude_projet' },
  { label: 'Consultation archi envoyée', value: 'consultation_archi_sent' },
  { label: 'Consultation archi acceptée', value: 'consultation_archi_validated' },
  { label: 'Consultation pro envoyée', value: 'consultation_pro_sent' },
  { label: 'Consultation pro acceptée', value: 'consultation_pro_validated' },
  { label: 'Proposition archi envoyée', value: 'prestation_archi_sent' },
  { label: 'Prestation archi validée', value: 'prestation_archi_validated' },
  { label: 'Devis pro envoyé', value: 'devis_pro_sent' },
  { label: 'Deal gagné', value: 'deal_won' },
  { label: 'Deal perdu', value: 'deal_lost' },
];
