import { useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

export const handleDeleteDocument = ({
  documentType,
  personUuid,
}: {
  documentType: string;
  personUuid: string;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/employees/persons/${personUuid}/document-cdpl`;
  return request(requestURL, {
    method: 'DELETE',
    params: {
      documentType,
    },
  });
};

export const useDeleteDocumentCdpl = (): typeof handleDeleteDocument => {
  const { mutateAsync: deleteDocument } = useMutation({
    mutationFn: handleDeleteDocument,
    onError: () => {
      toast.error("Une erreur est survenue, le fichier n'a pas pu être supprimé.");
    },
  });
  return deleteDocument;
};
