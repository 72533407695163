import React from 'react';

import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';
import { Tab } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs/types';

const partnerTabs: Tab[] = [
  {
    label: 'Organisations',
    to: '/partners',
    isActive: location => location.pathname === '/partners',
  },
  {
    label: 'Agences',
    to: '/partners/agencies',
  },
  {
    label: 'Comptes',
    to: '/partners/accounts',
  },
];

export const PartnersTabs: React.FC = () => (
  <div className="-mx-md sm-desktop:-mx-xl -mt-md bg-white px-xl pt-sm shadow-sm">
    <div className="text-h3 font-bold mb-0 text-neutral-800">Partenaires</div>
    <Tabs items={partnerTabs} hideContainerBottomBorder />
  </div>
);
