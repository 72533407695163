import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { SlotRDV } from '@travauxlib/shared/src/types';

import { AgendaSlots as RawAgendaSlots, Props as AgendaSlotsProps } from '.';

type WrapperProps = FieldRenderProps<SlotRDV[]> & Omit<AgendaSlotsProps, 'value' | 'onChange'>;

export const AgendaSlotsWrapper: React.FC<WrapperProps> = ({
  input: { value, onChange },
  ...props
}) => <RawAgendaSlots value={value} onChange={onChange} {...props} />;

type Props = {
  name: string;
} & Omit<AgendaSlotsProps, 'value' | 'onChange'> &
  UseFieldConfig<SlotRDV[]>;

export const AgendaSlotsField: React.FC<Props> = props => (
  <Field component={AgendaSlotsWrapper} {...props} />
);
