import React from 'react';

import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';

import { Consultation } from 'types';

export const ConsultationStatus: React.FC<{ consultation: Consultation }> = ({ consultation }) => {
  const { deal, consultationOffer } = consultation;

  if (deal?.status === 'won') {
    return <Tag variant="success" label="Deal gagné" />;
  }

  if (deal?.status === 'lost') {
    return <Tag variant="warning" label="Deal perdu" />;
  }

  if (consultationOffer.isProFreedFromConsultation) {
    return <Tag variant="info" label="Pro libéré" />;
  }

  if (consultationOffer.isPending) {
    return <Tag variant="info" label="Envoyée" />;
  }

  if (consultationOffer.isExpired) {
    return <Tag variant="warning" label="Expirée" />;
  }

  if (consultationOffer.isAccepted) {
    return <Tag variant="success" label="Acceptée" />;
  }

  if (consultationOffer.isRejected) {
    return <Tag variant="error" label="Rejetée" />;
  }

  return <Tag variant="info" label="Envoyée" />;
};
