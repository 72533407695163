import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { ProjectListView } from 'types';

import { PROJECT_KEY } from './useProjectContext';

export const useLatestProjects = (
  clientSearch?: string,
  dealOwnerFilter?: string,
  offset?: number,
  statuses?: string[],
): {
  isLoading: boolean;
  projects: ProjectListView[];
} => {
  const { isLoading, data } = useQuery<{ data: ProjectListView[] }>({
    queryKey: [PROJECT_KEY, clientSearch, dealOwnerFilter, offset, statuses],
    queryFn: () =>
      request(`${APP_CONFIG.apiURL}/admin/projects`, {
        params: {
          clientSearch,
          dealOwnerFilter,
          offset,
          statuses,
        },
      }),
  });

  return { isLoading, projects: data?.data || [] };
};
