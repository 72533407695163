import { ConsultationInfoView } from '@travauxlib/shared/src/types/api/admin/ConsultationInfoView';
import { LeadAdminView } from '@travauxlib/shared/src/types/api/admin/LeadAdminView';

import { BaseStep, ExecuteParams, StepField } from '../BaseStep';

type Input = { lead: LeadAdminView };

export class CreateAndFillFicheChantier extends BaseStep<
  Input,
  { consultationInfo: ConsultationInfoView },
  StepField[],
  { consultationInfo: ConsultationInfoView }
> {
  public name: string = 'Create and fill fiche chantier';

  public fields = [];

  public async execute(
    params: ExecuteParams<Input>,
  ): Promise<{ consultationInfo: ConsultationInfoView }> {
    const { apiURL, adminURL, request, lead, seed } = params;

    const projectUuid = await request(`${apiURL}/admin/e2e/get-project-uuid/${lead.id}`);

    const filledChantier = {
      title: `${seed}-godmode`,
      typesTravaux: ['Rénovation'],
      insuredFor: ['Contractant général - Entreprise générale'],
      googleMapsAddress: {
        address_components: [
          {
            long_name: '75015',
            short_name: '75015',
            types: ['postal_code'],
          },
          {
            long_name: 'Paris',
            short_name: 'Paris',
            types: ['locality', 'political'],
          },
          {
            long_name: 'Département de Paris',
            short_name: 'Département de Paris',
            types: ['administrative_area_level_2', 'political'],
          },
          {
            long_name: 'Île-de-France',
            short_name: 'IDF',
            types: ['administrative_area_level_1', 'political'],
          },
          {
            long_name: 'France',
            short_name: 'FR',
            types: ['country', 'political'],
          },
        ],
        formatted_address: '75015 Paris, France',
        geometry: {
          bounds: {
            south: 48.8252116,
            west: 2.2629819,
            north: 48.8582221,
            east: 2.3246822,
          },
          location: {
            lat: 48.8421616,
            lng: 2.2927665,
          },
          location_type: 'APPROXIMATE',
          viewport: {
            south: 48.8252116,
            west: 2.2629819,
            north: 48.8582221,
            east: 2.3246822,
          },
        },
        place_id: 'ChIJ9WBuGRRw5kcR4FHY4caCCxw',
        types: ['postal_code'],
      },
    };

    const createdFicheChantier: ConsultationInfoView = await request(
      `${apiURL}/admin/projects/${projectUuid}/consultation-info`,
      {
        method: 'POST',
        body: filledChantier,
      },
    );

    super.log(
      `Fiche chantier id : ${createdFicheChantier.id} (title: ${createdFicheChantier.title})`,
    );

    super.log(
      `Page consultation : ${adminURL}/projects/${projectUuid}/consultation/${createdFicheChantier.id}`,
    );

    super.setCreatedEntities({ consultationInfo: createdFicheChantier });

    return { ...params, consultationInfo: createdFicheChantier };
  }
}
