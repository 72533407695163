import React, { useState } from 'react';

import uniqBy from 'lodash/uniqBy';

import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { DropdownOption } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Option';
import { DropdownMulti } from '@travauxlib/shared/src/components/DesignSystem/components/DropdownMulti';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { ColumnConfig } from '@travauxlib/shared/src/components/DesignSystem/components/Table/types';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { PartnerAccount, PartnerAgency, PartnerOrganization } from 'types';

import { FiltersContainer } from './FiltersContainer';

import { PartnerAcquisitionSource } from '../../types';

const columns: ColumnConfig<EnrichedPartnerAgency>[] = [
  {
    name: 'Organisation',
    columnKey: 'organisationName',
  },
  {
    name: 'Agence',
    columnKey: 'name',
  },
  {
    name: 'Account Manager',
    columnKey: 'internalAccountManager',
  },
  {
    name: 'Comptes',
    columnKey: 'comptes',
  },
  {
    name: 'Source',
    columnKey: 'source',
  },
  {
    name: 'Crée le',
    columnKey: 'createdAt',
    renderValue: (d: string) => <>{formatFrenchDate(d)}</>,
  },
];

const sourceOptions = [
  {
    label: 'Offline',
    value: 'partner_offline' as PartnerAcquisitionSource,
  },
  {
    label: 'Online',
    value: 'partner_online' as PartnerAcquisitionSource,
  },
];

type Props = {
  partners: PartnerOrganization[];
  agencies: PartnerAgency[];
  accounts: PartnerAccount[];
  orgNameOptions: DropdownOption<string>[];
  iacOptions: DropdownOption<string | undefined>[];
  accountsByAgencyUUID: { [k: string]: PartnerAccount[] };
  organisationsByUuid: { [k: string]: PartnerOrganization };
};

interface EnrichedPartnerAgency extends PartnerAgency {
  organisationName: string;
  orgActive: boolean;
  comptes: number;
}

const enrichPartnerAgency =
  (
    orgsByUuid: { [k: string]: PartnerOrganization },
    accountsByAgencyUUid: { [k: string]: PartnerAccount[] },
  ) =>
  (input: PartnerAgency): EnrichedPartnerAgency => ({
    ...input,
    organisationName: orgsByUuid[input.organizationUuid]?.name ?? 'Inconnu :O',
    orgActive: !orgsByUuid[input.organizationUuid]?.isArchived,
    comptes: accountsByAgencyUUid[input.uuid]?.length ?? 0,
  });

export const AgencyTable: React.FC<Props> = ({
  partners,
  agencies,
  accounts,
  accountsByAgencyUUID,
  organisationsByUuid,
  orgNameOptions,
  iacOptions,
}) => {
  const [orgNameFilter, setOrgNameFilter] = useState<string[]>([]);
  const [agencyNameFilter, setAgencyNameFilter] = useState<string[]>([]);
  const [iacFilter, setIacFilter] = useState<(string | undefined)[]>([]);
  const [sourceFilter, setSourceFilter] = useState<PartnerAcquisitionSource[]>([
    'partner_online',
    'partner_offline',
  ]);
  const [orgActiveFilter, setOrgActiveFilter] = useState<boolean>(true);
  const [agenceActiveFilter, setAgenceActiveFilter] = useState<boolean>(true);

  const nameOptions = orgNameOptions;

  const agencyNameOptions = React.useMemo(
    () => uniqBy(agencies, 'name').map(a => ({ label: a.name, value: a.name })),
    [partners, accounts, agencies],
  );

  const fn = React.useCallback(enrichPartnerAgency(organisationsByUuid, accountsByAgencyUUID), [
    organisationsByUuid,
    accountsByAgencyUUID,
    partners,
    accounts,
    agencies,
  ]);
  const enrichedAgencies = React.useMemo(
    () => agencies.map(fn),
    [partners, accounts, agencies, fn],
  );

  const filteredAgencies: EnrichedPartnerAgency[] = React.useMemo(
    () =>
      enrichedAgencies.length > 0
        ? enrichedAgencies.filter(
            a =>
              (orgActiveFilter === undefined ? true : a.orgActive === orgActiveFilter) &&
              (agencyNameFilter === undefined ? true : a.isArchived !== agenceActiveFilter) &&
              (orgNameFilter.length ? orgNameFilter.includes(a.organisationName) : true) &&
              (agencyNameFilter.length ? agencyNameFilter.includes(a.name) : true) &&
              (iacFilter.length ? iacFilter.includes(a.internalAccountManager) : true) &&
              (sourceFilter.length ? sourceFilter.includes(a.source) : true),
          )
        : [],
    [
      partners,
      accounts,
      agencies,
      orgNameFilter,
      agencyNameFilter,
      iacFilter,
      sourceFilter,
      orgActiveFilter,
      agenceActiveFilter,
      enrichedAgencies,
    ],
  );

  return (
    <div>
      <FiltersContainer>
        <DropdownMulti<string>
          id="nameFilter"
          options={nameOptions}
          onChange={setOrgNameFilter}
          value={orgNameFilter}
          label="Nom organisation"
        />
        <DropdownMulti<string>
          id="agencyNameFilter"
          options={agencyNameOptions}
          onChange={setAgencyNameFilter}
          value={agencyNameFilter}
          label="Nom d'agence"
        />
        <DropdownMulti<string | undefined>
          id="iacFilter"
          options={iacOptions}
          onChange={setIacFilter}
          value={iacFilter}
          label="Account Manager"
        />
        <DropdownMulti<PartnerAcquisitionSource>
          id="sourceFilter"
          options={sourceOptions}
          onChange={setSourceFilter}
          value={sourceFilter}
          label="Source"
        />

        <Checkbox
          indeterminate={orgActiveFilter === undefined}
          checked={!!orgActiveFilter}
          onChange={setOrgActiveFilter}
          label="Organisation active ?"
          data-testid="active"
          className="w-fit"
        />
        <Checkbox
          indeterminate={agenceActiveFilter === undefined}
          checked={!!agenceActiveFilter}
          onChange={setAgenceActiveFilter}
          label="Agence active ?"
          data-testid="agency-active"
          className="w-fit"
        />
      </FiltersContainer>
      <Table<EnrichedPartnerAgency>
        items={filteredAgencies}
        columnConfigs={columns}
        itemsPerPage={50}
        defaultSort={{ columnKey: 'organisationName', order: 'asc' }}
        onRowClick={({ organizationUuid, uuid }) =>
          window.open(`/partners/${organizationUuid}/agencies/${uuid}`)
        }
      />
    </div>
  );
};
