import { ConsultationInfoView } from '@travauxlib/shared/src/types/api/admin/ConsultationInfoView';

import { Flow } from './Flow';

import { ExploitWeakSignatureImplem } from '../gdm_steps/Client/ExploitWeakSignatureImplem';
import { CreateDevisPrechiffrage } from '../gdm_steps/Pro/CreateDevisPrechiffrage';
import { ProAcceptConsultationOffer } from '../gdm_steps/Pro/ProAcceptConsultationOffer';
import { ProCreateDevis } from '../gdm_steps/Pro/ProCreateDevis';
import { ProSendDevis } from '../gdm_steps/Pro/ProSendDevis';
import { ProUpdateDevis } from '../gdm_steps/Pro/ProUpdateDevis';
import { SignInAsPro } from '../gdm_steps/Pro/SignInAsPro';

export const acceptConsultationOfferAndSignDevis = (
  isPrechiffrage: boolean = false,
): Flow<any, any> =>
  new Flow<{ consultationInfo: ConsultationInfoView }, {}>()
    .next(new SignInAsPro())
    .next(new ProAcceptConsultationOffer())
    .next((isPrechiffrage ? new CreateDevisPrechiffrage() : new ProCreateDevis()) as ProCreateDevis)
    .next(new ProUpdateDevis())
    .next(new ProSendDevis())
    .next(new ExploitWeakSignatureImplem());
