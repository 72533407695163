import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { DevisMarketplace } from 'types';

export const DEVIS_LIST_KEY = 'devis_marketplace_list';

export const useDevisMarketplaceList = ({
  slug,
}: {
  slug: string;
}): { devisList: DevisMarketplace[]; isLoaded: boolean } => {
  const { data: devisList = [], isLoading } = useQuery<DevisMarketplace[]>({
    queryKey: [DEVIS_LIST_KEY, slug],
    queryFn: async () => {
      const requestURL = `${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/devis-marketplace`;
      const { data } = await request(requestURL);
      return data;
    },
  });

  return {
    devisList: devisList,
    isLoaded: !isLoading,
  };
};
