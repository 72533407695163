import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ProUser } from '@travauxlib/shared/src/types/company';

import {
  ConfirmRestoreUserModal,
  ConfirmRestoreUserModalProps,
} from '../components/ConfirmRestoreUserModal';

export const useOpenConfirmRestoreUserModal = (
  updateProUser: ({ updatedProUser }: { updatedProUser: ProUser }) => Promise<unknown>,
): ((props: Omit<ConfirmRestoreUserModalProps, 'handleClose'>) => void) => {
  const openModal = useOpenModal();

  return ({ proUser, slug }: Omit<ConfirmRestoreUserModalProps, 'handleClose'>) =>
    openModal(ConfirmRestoreUserModal, {
      proUser,
      closable: true,
      updateProUser,
      slug,
    });
};
