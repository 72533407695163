import { request } from '@travauxlib/shared/src/utils/request';

import { useChantierQuery } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';

const handleSubmitPaiement = async (payload: {
  chantierSlug: string;
  montant: number;
}): Promise<void> => {
  const { chantierSlug, montant } = payload;
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${chantierSlug}/appels-de-paiement`;
  await request(requestURL, {
    method: 'POST',
    body: { value: montant },
  });
};

export const useSubmitPaiement = (): {
  submitPaiement: typeof handleSubmitPaiement;
  isLoading: boolean;
} => {
  const { query, isLoading } = useChantierQuery(
    handleSubmitPaiement,
    "de la création de l'appel de paiement",
    "L'appel de paiement a bien été créé.",
  );
  return { submitPaiement: query, isLoading };
};
