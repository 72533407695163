import { useEffect } from 'react';

import { Outlet } from 'react-router-dom';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';
import { NavigationTracking } from 'utils/tracking/NavigationTracking';

import { LeadListing } from './components/LeadListing';
import { CreateLead } from './features/CreateLead';

const Index: React.FC = () => {
  useEffect(() => {
    NavigationTracking.onInscriptionsProViewed();
  }, []);

  return (
    <>
      <PageTitle title={PageTitleEnum.inscriptionPro} />
      <Outlet />
    </>
  );
};

export const ProRegistrations = {
  Index,
  LeadListing,
  CreateLead,
};
