import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import { AdminFeatures, TrackingEvents } from '@travauxlib/shared/src/utils/tracking';

class NavigationtrackingClass extends Tracker<AdminFeatures> {
  onChantiersViewed(): void {
    this.sendTracking({ subFeature: 'Chantiers', event: TrackingEvents.Viewed });
  }

  onComparateurViewed(): void {
    this.sendTracking({ subFeature: 'Comparateur', event: TrackingEvents.Viewed });
  }

  onComptesClientsViewed(): void {
    this.sendTracking({ subFeature: 'Comptes Client', event: TrackingEvents.Viewed });
  }

  onDashboardCPDViewed(): void {
    this.sendTracking({ subFeature: 'Dashboard CDP', event: TrackingEvents.Viewed });
  }

  onEntreprisesViewed(): void {
    this.sendTracking({ subFeature: 'Entreprises', event: TrackingEvents.Viewed });
  }

  onInscriptionsProViewed(): void {
    this.sendTracking({ subFeature: 'Inscriptions Pro', event: TrackingEvents.Viewed });
  }

  onLeadsViewed(): void {
    this.sendTracking({ subFeature: 'Leads', event: TrackingEvents.Viewed });
  }

  onPartenairesViewed(): void {
    this.sendTracking({ subFeature: 'Partenaires', event: TrackingEvents.Viewed });
  }

  onProjetViewed(): void {
    this.sendTracking({ subFeature: 'Projets', event: TrackingEvents.Viewed });
  }
}

export const NavigationTracking = new NavigationtrackingClass(AdminFeatures.Navigation);
