type Rule<Action, Subject, Flag> = {
  not?: boolean;
  action: Action;
  subject: Subject;
  flags: Flag[];
};

export class Ability<Action, Subject, Flag> {
  rules: Array<Rule<Action, Subject, Flag>>;

  constructor(rules: Array<Rule<Action, Subject, Flag>>) {
    this.rules = rules;
  }

  can = (action: Action, subject: Subject, flags: Flag[] = []): boolean => {
    let result = false;
    for (let rule of this.rules) {
      if (rule.action === action && rule.subject === subject) {
        if (flags.length === rule.flags.length && flags.every(f => rule.flags.includes(f))) {
          return !rule.not;
        }
        if (flags.length !== 0 && rule.flags.length === 0) {
          // this will be the result unless there's a rule perfectly matching all the flags
          result = !rule.not;
        }
      }
    }
    return result;
  };
}

export class AbilityBuilder<Action, Subject, Flag> {
  rules: Array<Rule<Action, Subject, Flag>> = [];

  can = (action: Action, subject: Subject, flags: Flag[] = []): void => {
    this.rules.push({
      action,
      subject,
      flags,
    });
  };

  cannot = (action: Action, subject: Subject, flags: Flag[] = []): void => {
    this.rules.push({
      action,
      subject,
      flags,
      not: true,
    });
  };

  build = (): Ability<Action, Subject, Flag> => new Ability<Action, Subject, Flag>(this.rules);
}
