import { UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from 'features/Project/api/useProjectContext';

const handleCreateConsultationInfo = (projectUuid: string): Promise<{ id: string }> =>
  request(`${APP_CONFIG.apiURL}/admin/projects/${projectUuid}/consultation-info`, {
    method: 'POST',
  });

export const useCreateConsultationInfo = (): {
  createConsultationInfo: UseMutateAsyncFunction<{ id: string }, unknown, string>;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: createConsultationInfo, isPending } = useMutation({
    mutationFn: handleCreateConsultationInfo,
    onSuccess: (_, projectUuid) =>
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY, projectUuid] }),
  });

  return { createConsultationInfo, isLoading: isPending };
};
