import { request } from '@travauxlib/shared/src/utils/request';

import { useChantierQuery } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';

const handleCancelAppelDePaiement = async ({
  chantierSlug,
  appelDePaiementUuid,
}: {
  chantierSlug: string;
  appelDePaiementUuid: string;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${chantierSlug}/appels-de-paiement/${appelDePaiementUuid}`;
  await request(requestURL, { method: 'DELETE' });
};

export const useCancelAppelDePaiement = (): {
  cancelAppelDePaiement: typeof handleCancelAppelDePaiement;
  isLoading: boolean;
} => {
  const { query, isLoading } = useChantierQuery(
    handleCancelAppelDePaiement,
    "de l'annulation de l'appel de paiement'",
    "L'appel de paiement a bien été annulé.",
  );
  return { cancelAppelDePaiement: query, isLoading };
};
