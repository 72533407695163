import React from 'react';

import Eco from '@travauxlib/shared/src/components/DesignSystem/assets/Eco.svg?react';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { QuestionId } from '@travauxlib/shared/src/features/Configurateur/types';
import { formData } from '@travauxlib/shared/src/features/Configurateur/utils/formData';

type Props = {
  questionId: QuestionId;
  isRge?: boolean;
};

export const FormHeader: React.FC<Props> = ({ questionId, isRge }) => {
  const { Icon, title } = formData[questionId];
  return (
    <div className="sm-desktop:w-[57rem] mx-auto">
      <div className="flex items-center mb-lg">
        <ColoredCircle size="lg" className="mr-xs relative" variant="beige">
          <Icon />
          {isRge && (
            <WithTooltip
              trigger={
                <span className="absolute -bottom-3xs -right-3xs">
                  <Eco className="w-[1.25rem] h-[1.25rem] text-success-600" />
                </span>
              }
              width="12.875rem"
              position="bottom-start"
            >
              <div className="text-b2 font-normal p-0 m-0">
                Contient des prestations de rénovation énergétique
              </div>
            </WithTooltip>
          )}
        </ColoredCircle>
        <div className="text-h3 font-bold">{title}</div>
      </div>
    </div>
  );
};
