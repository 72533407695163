import { ProRegistrationAdminView } from '@travauxlib/shared/src/types/api/admin/ProRegistrationAdminView';

import { BaseStep, ExecuteParams, StepField } from '../BaseStep';

type Input = { proRegistration: ProRegistrationAdminView; proCompanyEmail?: string };

export class SetProRegistrationLabellisee extends BaseStep<
  Input,
  { proRegistration: ProRegistrationAdminView },
  StepField[],
  { proRegistration: ProRegistrationAdminView }
> {
  public name: string = 'Set Pro Registration labellisee';

  public fields = [];

  async execute({
    proRegistration,
    proCompanyEmail,
    apiURL,
    request,
  }: ExecuteParams<Input>): Promise<{ proRegistration: ProRegistrationAdminView } | undefined> {
    if (proCompanyEmail) {
      super.log(`Skipped! proCompanyEmail chosen`);
      return;
    }
    const newProRegistration = {
      proRegistration,
      isClubPro: false,
      status: 'Adressable',
    };

    const updatedProRegistration: ProRegistrationAdminView = await request(
      `${apiURL}/admin/pro-registrations/${proRegistration.uuid}/status`,
      {
        method: 'PATCH',
        body: newProRegistration,
      },
    );

    super.log('OK');
    super.setCreatedEntities({
      proRegistration: updatedProRegistration,
    });

    return { proRegistration: updatedProRegistration };
  }
}
