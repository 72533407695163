import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { CounterField } from '@travauxlib/shared/src/components/DesignSystem/components/Counter/Field';
import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { Volet } from '@travauxlib/shared/src/features/Configurateur/types/MenuiseriesExterieures';
import { getLocationLabel } from '@travauxlib/shared/src/features/Configurateur/utils/getLocationLabel';
import { locationsPicto } from '@travauxlib/shared/src/features/DevisDisplay/components/LocalisationIcon';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { LocationCard } from 'features/Configurateur/components/LocationCard';
import { LocationsCounters } from 'features/Configurateur/components/LocationsCounters';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

type Props = {
  configuration: Configuration;
};

const LocationsPersienne: React.FC<{ name: string; configuration: Configuration }> = ({
  name,
  configuration,
}) => (
  <>
    <QuestionTitle prefix="2" title="Pièces concernées ?" />
    {configuration.locations.map(location => {
      const Picto = locationsPicto[location.typeLocation];
      return (
        <LocationCard
          key={location.uuid}
          className="mb-md"
          label={getLocationLabel(configuration, location)}
          centerIcon={<Picto />}
        >
          <div>
            <CounterField
              name={`${name}.locationQuantities.${location.uuid}.persiennes`}
              id={`${name}.locationQuantities.${location.uuid}.persiennes`}
            />
          </div>
        </LocationCard>
      );
    })}
  </>
);

const LocationsVolet: React.FC<{ name: string; configuration: Configuration }> = ({
  name,
  configuration,
}) => (
  <>
    <QuestionTitle prefix="2" title="Pièces concernées ?" />
    <LocationsCounters
      configuration={configuration}
      locations={configuration.locations}
      getFields={locationUuid => [
        {
          label: 'Fenêtre / porte fenêtre',
          name: `${name}.locationQuantities.${locationUuid}.fenetres`,
        },
        { label: 'Baie vitrée', name: `${name}.locationQuantities.${locationUuid}.baiesVitrees` },
      ]}
    />
  </>
);

export const Volets: React.FC<Props> = ({ configuration }) => (
  <Accordion className="mb-md" title="Remplacement de volets ?" prefix="4" defaultExpanded>
    <FieldArray<Volet> name="volets">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={index} className="mb-lg">
                <div className="flex justify-between">
                  <QuestionTitle prefix="1" title="Type de volet existant ?" />
                  <PrestationDelete onDelete={() => fields.remove(index)} />
                </div>
                <OptionCheckbox fields={fields} value={value} index={index} />
                <ChipListField
                  name={`${name}.typeVoletExistant`}
                  renderingOptions={{
                    containerClassName: 'mb-md flex flex-wrap gap-xs',
                  }}
                  options={[
                    {
                      label: 'Persienne',
                      value: 'Persienne',
                    },
                    {
                      label: 'Volet roulant',
                      value: 'Volet roulant',
                    },
                  ]}
                />
                {value.typeVoletExistant === 'Volet roulant' && (
                  <LocationsVolet name={name} configuration={configuration} />
                )}
                {value.typeVoletExistant === 'Persienne' && (
                  <LocationsPersienne name={name} configuration={configuration} />
                )}
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
