import { ComponentType } from 'react';

import Assistance from '@travauxlib/shared/src/components/DesignSystem/assets/Assistance.svg?react';
import Calendar from '@travauxlib/shared/src/components/DesignSystem/assets/Calendar.svg?react';
import CalendarClock from '@travauxlib/shared/src/components/DesignSystem/assets/CalendarClock.svg?react';
import Clock from '@travauxlib/shared/src/components/DesignSystem/assets/Clock.svg?react';
import Coach from '@travauxlib/shared/src/components/DesignSystem/assets/Coach.svg?react';
import Location from '@travauxlib/shared/src/components/DesignSystem/assets/Location.svg?react';
import Person from '@travauxlib/shared/src/components/DesignSystem/assets/Person.svg?react';
import Phone from '@travauxlib/shared/src/components/DesignSystem/assets/Phone.svg?react';
import Quote from '@travauxlib/shared/src/components/DesignSystem/assets/Quote.svg?react';
import RealEstate from '@travauxlib/shared/src/components/DesignSystem/assets/RealEstate.svg?react';
import Store from '@travauxlib/shared/src/components/DesignSystem/assets/Store.svg?react';

type IconName =
  | 'company'
  | 'phone'
  | 'name'
  | 'coachCare'
  | 'responsableSuivi'
  | 'dateDebut'
  | 'tempsEstime'
  | 'dateFin'
  | 'dateSignature'
  | 'travaux'
  | 'address';

type IconChantierInfoLineOptions = {
  [key in IconName]: ComponentType<{ className?: string }>;
};

const infoPicto: IconChantierInfoLineOptions = {
  company: Store,
  phone: Phone,
  name: Person,
  coachCare: Coach,
  responsableSuivi: Assistance,
  dateDebut: Calendar,
  tempsEstime: Clock,
  dateFin: CalendarClock,
  dateSignature: Quote,
  travaux: RealEstate,
  address: Location,
};

export const ChantierInfoLine: React.FC<{ icon: IconName; title: string; value?: string }> = ({
  icon,
  title,
  value,
}) => {
  const Picto = infoPicto[icon];
  return (
    <div className="flex">
      <Picto className="w-lg h-lg text-primary" />
      <div className="pl-xxs">
        <div data-testid={title} className="text-b1 font-medium text-neutral-800">
          {title}
        </div>
        {value && <div className="text-b2 text-neutral-700">{value}</div>}
      </div>
    </div>
  );
};
