import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { adminUrls } from 'utils/urls';

export type FormData = {
  siret: string;
  statutEntreprise: string;
  companyType: string;
  chiffreAffairesDeclare: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  source: string;
  campaign?: string;
  subcampaign?: string;
  isDemandeChantier: boolean;
};

const handleCreateProLead = (payload: FormData): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/pro-registrations`;

  return request(requestURL, {
    method: 'POST',
    body: payload,
  });
};

export const useCreateProLead = (): {
  createProLead: typeof handleCreateProLead;
  isLoading: boolean;
} => {
  const navigate = useNavigate();

  const { mutateAsync: createProLead, isPending } = useMutation({
    mutationFn: handleCreateProLead,
    onSuccess: () => {
      toast.success('Un lead pro a bien été créé');
      navigate(adminUrls.proRegistrations);
    },
    onError: (error: AxiosError) => {
      toast.error(`Une erreur est survenue : ${error.response?.data}`);
    },
  });

  return { createProLead, isLoading: isPending };
};
