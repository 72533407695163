import { useMutation, useQueryClient } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { PartnerCommission } from 'types';

import { COMMISSIONS_KEY } from './useCommissions';

const handleCreateCommission = (commission: PartnerCommission): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/partners/commissions`;
  return request(requestURL, {
    method: 'POST',
    body: commission,
  });
};

export const useCreateCommission = (): {
  createCommission: typeof handleCreateCommission;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: createCommission, isPending } = useMutation({
    mutationFn: handleCreateCommission,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COMMISSIONS_KEY] });
    },
  });

  return { createCommission, isLoading: isPending };
};
