import React from 'react';

import Calendar from '@travauxlib/shared/src/components/DesignSystem/assets/Calendar.svg?react';
import Document from '@travauxlib/shared/src/components/DesignSystem/assets/Document.svg?react';
import Envelope from '@travauxlib/shared/src/components/DesignSystem/assets/Envelope.svg?react';
import Euro from '@travauxlib/shared/src/components/DesignSystem/assets/Euro.svg?react';
import Location from '@travauxlib/shared/src/components/DesignSystem/assets/Location.svg?react';
import Person from '@travauxlib/shared/src/components/DesignSystem/assets/Person.svg?react';
import Phone from '@travauxlib/shared/src/components/DesignSystem/assets/Phone.svg?react';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { ProRegistration } from '@travauxlib/shared/src/types';
import { chiffreAffairesDeclare } from '@travauxlib/shared/src/utils/constants';
import { dateTimeFormat } from '@travauxlib/shared/src/utils/time';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { adminUrls } from 'utils/urls';

type Props = {
  proRegistration: ProRegistration;
};

type InfoRowProps = {
  label?: string;
  logo: JSX.Element;
  value?: string | number | JSX.Element;
  hidden?: boolean;
};

const InfoRow: React.FC<InfoRowProps> = ({ label, logo, value, hidden }) =>
  value && !hidden ? (
    <div>
      <b>
        {logo} {label}
      </b>
      &nbsp;
      {value}
    </div>
  ) : null;

export const getChiffreAffairesDeclareLabel = (value: string): string =>
  chiffreAffairesDeclare.find(ca => ca.value === value)?.label || 'NA';

export const CompanyInfoSection: React.FC<Props> = ({
  proRegistration: {
    proCompanySlug,
    companyName,
    isEmailVerified,
    statutEntreprise,
    siret,
    isLead,
    capital,
    chiffreAffairesDeclare,
    address,
    email,
    firstNameRepresentantLegal,
    fullName,
    lastNameRepresentantLegal,
    numeroTVA,
    rcs,
    calendly,
    createdAt,
    creationDate,
    city,
    phoneNumber,
    postalCode,
    status,
    pipedriveDealUrl,
    financials,
    internalComment,
    activity,
  },
}) => (
  <div>
    {pipedriveDealUrl && (
      <a href={pipedriveDealUrl} target="_blank" rel="noopener noreferrer">
        <Tag size="md" variant="error" label="Fiche pipedrive déjà existante" />
      </a>
    )}
    <h3>
      {proCompanySlug && <Link to={adminUrls.editCompanyUrl(proCompanySlug)}>{companyName}</Link>}
      {status !== 'A traiter' && (
        <Tag size="md" variant="error" className="!ml-sm" label="Déjà traité" />
      )}
    </h3>
    <div className="!mb-md">
      {isLead ? (
        <Tag size="md" variant="success" label="LEAD" />
      ) : (
        <>
          <Tag
            size="md"
            variant={isEmailVerified ? 'success' : 'error'}
            label="Email"
            className="!mr-md"
          />
          <Tag size="md" variant={siret ? 'success' : 'error'} label="SIRET" />
        </>
      )}
      {activity && <Tag size="md" variant="success" label="CHANTIER HEMEA" className="ml-xs" />}
    </div>
    {!isLead && <h3>Entreprise</h3>}
    <InfoRow
      logo={<Document className="w-md h-md" />}
      label="Statut de l'entreprise :"
      value={statutEntreprise}
    />
    <InfoRow
      logo={<Document className="w-md h-md" />}
      label="SIRET :"
      value={
        <Link
          href={`https://corporama.com/search?company=${siret}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {siret}
        </Link>
      }
      hidden={!siret}
    />
    <InfoRow
      logo={<Euro className="w-md h-md" />}
      label="Capital :"
      value={<EURCurrency amount={capital} />}
      hidden={capital === undefined}
    />
    <InfoRow
      logo={<Euro className="w-md h-md" />}
      label="CA déclaré :"
      value={getChiffreAffairesDeclareLabel(chiffreAffairesDeclare)}
      hidden={isLead}
    />
    <InfoRow logo={<Euro className="w-md h-md" />} label="Numéro TVA :" value={numeroTVA} />
    <InfoRow
      logo={<Person className="w-md h-md" />}
      label="Représentant légal :"
      value={`${firstNameRepresentantLegal} ${lastNameRepresentantLegal}`}
      hidden={!firstNameRepresentantLegal && !lastNameRepresentantLegal}
    />
    <InfoRow
      logo={<Calendar className="w-md h-md" />}
      label="Date de création :"
      value={creationDate}
    />
    <InfoRow logo={<Location className="w-md h-md" />} label="RCS :" value={rcs} />
    <InfoRow
      logo={<Location className="w-md h-md" />}
      label="Adresse :"
      value={
        <>
          {address}
          <br />
          {postalCode} {city}
        </>
      }
      hidden={!address && !postalCode && !city}
    />
    <InfoRow
      logo={<Document className="w-md h-md" />}
      label={`Activité de l'entreprise (Via societe.com au ${formatFrenchDate(createdAt)}) :`}
      value={internalComment}
    />
    <InfoRow
      logo={<Person className="w-md h-md" />}
      label={`Nombre de salariés (Via societe.com au ${formatFrenchDate(createdAt)}) :`}
      value={financials?.lastStaff}
    />
    {financials?.lastSales && (
      <InfoRow
        logo={<Euro className="w-md h-md" />}
        label={`CA (Via societe.com au ${formatFrenchDate(createdAt)}) :`}
        value={<EURCurrency amount={financials?.lastSales} />}
      />
    )}
    <h3 className="!mt-md">Contact</h3>
    <InfoRow logo={<Person className="w-md h-md" />} value={fullName} />
    <InfoRow logo={<Envelope className="w-md h-md" />} value={email} />
    <InfoRow logo={<Phone className="w-md h-md" />} value={phoneNumber} />
    <InfoRow
      logo={<Calendar className="w-md h-md" />}
      label="Date d'inscription :"
      value={dateTimeFormat(createdAt)}
    />
    {calendly && (
      <InfoRow
        logo={<Calendar className="w-md h-md" />}
        label="Rdv Calendly :"
        value={`${dateTimeFormat(calendly.startAt)} avec ${calendly.inviter}`}
      />
    )}
  </div>
);
