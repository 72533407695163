import React from 'react';

import { SubmitButton } from '../SubmitButton';

type Props = {
  disablePrompt?: boolean;
  label?: string;
};

export const StickySubmitButton: React.FC<Props> = ({ disablePrompt, label }: Props) => (
  <div className="pb-sm text-center sticky bottom-0 z-20">
    <SubmitButton disablePrompt={disablePrompt} label={label} />
  </div>
);
