import React from 'react';

import { Accept } from 'react-dropzone';

import { DocumentType } from '@travauxlib/shared/src/api/types';
import CrossCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircle.svg?react';
import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { FileLink } from '@travauxlib/shared/src/components/FileLink';
import { FileUpload } from '@travauxlib/shared/src/components/FileUpload';
import { toast } from '@travauxlib/shared/src/components/Notifications';

export type Props = {
  title?: string;
  id: string;
  label?: string | JSX.Element;
  accept?: Accept;
  documentType: DocumentType;
  alignLeft?: boolean;
  value?: string;
  uploadAction: (payload: { file: File; documentType: DocumentType }) => Promise<unknown>;
  deleteAction?: (payload: { documentType: DocumentType }) => Promise<unknown>;
  isLoading?: boolean;
};

export const Image: React.FC<{ value?: string }> = ({ value }) => (
  <>
    {value ? (
      <img className="max-h-[80px] max-w-full" src={value} alt="logo-img" role="presentation" />
    ) : (
      <>
        <CrossCircle data-testid="danger" className="w-md h-md text-danger mr-md" />
        <small>Aucun fichier ajouté.</small>
      </>
    )}
  </>
);

type ImageOrFileLinkProps = {
  documentType: string;
  value?: string;
};
const Document: React.FC<ImageOrFileLinkProps> = ({ documentType, value }) =>
  documentType === 'logo' ? <Image value={value} /> : <FileLink value={value} showIcon />;

export const UploadField: React.FC<Props> = ({
  title,
  id,
  label,
  uploadAction,
  deleteAction,
  documentType,
  value,
  alignLeft,
  isLoading,
  accept,
}) => {
  const completed = value !== undefined && value !== '';

  return (
    <>
      {title && <h5 className="font-bold mb-xs">{title}</h5>}
      <div className="flex items-center flex-wrap">
        <div
          className="md-desktop:whitespace-nowrap sm-desktop:mr-md mb-md sm-desktop:mb-0"
          id={id}
        >
          {label}
        </div>
        <div className="flex justify-between w-full items-center">
          <div>
            {isLoading ? <LoaderWrapper /> : <Document documentType={documentType} value={value} />}
          </div>
          <div className="flex items-center">
            {completed && deleteAction && (
              <IconButton className="mr-xs" onClick={() => deleteAction({ documentType })}>
                <Delete />
              </IconButton>
            )}
            <FileUpload
              accept={accept}
              onChange={file => uploadAction({ file, documentType })}
              alreadyUploaded={completed}
              alignLeft={alignLeft}
              onReject={rejectionMessage => toast.error(rejectionMessage)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
