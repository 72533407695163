import React from 'react';

import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { Meeting } from '@travauxlib/shared/src/types';
import { dateTimeFormat } from '@travauxlib/shared/src/utils/time';

type Props = {
  calendlyMeetings: Meeting[];
};

export const Calendly: React.FC<Props> = ({ calendlyMeetings }) => (
  <>
    {calendlyMeetings.map(({ uuid, startAt, inviter, canceledAt }) => (
      <div key={uuid}>
        {canceledAt && <Tag size="md" variant="error" className="!mr-xs" label="Annulé" />}
        {dateTimeFormat(startAt)} avec {inviter}
      </div>
    ))}
  </>
);
