import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { PartnerAccount, PartnerAgency, PartnerOrganization } from 'types';
import { adminUrls } from 'utils/urls';

import { PARTNERS_KEY } from './usePartners';

const handleCreateOrganization = (payload: PartnerOrganization): Promise<PartnerOrganization> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/partners`;

  return request(requestURL, {
    method: 'POST',
    body: payload,
  });
};

const handleCreateAgency = (payload: PartnerAgency): Promise<PartnerAgency> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/partners/${payload.organizationUuid}/agencies`;

  return request(requestURL, {
    method: 'POST',
    body: payload,
  });
};

const handleCreateAccount = (payload: PartnerAccount): Promise<PartnerAccount> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/partners/accounts`;

  return request(requestURL, {
    method: 'POST',
    body: payload,
  });
};

export const useCreatePartner = (
  organizationUuid?: string,
): {
  createOrganization: typeof handleCreateOrganization;
  createAgency: typeof handleCreateAgency;
  createAccount: typeof handleCreateAccount;
} => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: createOrganization } = useMutation({
    mutationFn: handleCreateOrganization,
    onSuccess: async (createdOrganization: PartnerOrganization) => {
      await queryClient.refetchQueries({ queryKey: [PARTNERS_KEY] });
      toast.success('Le partenaire a bien été créé !');
      navigate(`${adminUrls.partnerUrl}/${createdOrganization.uuid}`);
    },
    onError: (error: AxiosError) => {
      toast.error(error.response?.data as string);
    },
  });

  const { mutateAsync: createAgency } = useMutation({
    mutationFn: handleCreateAgency,
    onSuccess: async (createdAgency: PartnerAgency) => {
      await queryClient.refetchQueries({ queryKey: [PARTNERS_KEY] });
      toast.success("L'agence a bien été créée !");
      navigate(
        `${adminUrls.partnerUrl}/${createdAgency.organizationUuid}/agencies/${createdAgency.uuid}`,
      );
    },
    onError: (error: AxiosError) => {
      toast.error(error.response?.data as string);
    },
  });

  const { mutateAsync: createAccount } = useMutation({
    mutationFn: handleCreateAccount,
    onSuccess: async (createdAccount: PartnerAccount) => {
      await queryClient.refetchQueries({ queryKey: [PARTNERS_KEY] });
      toast.success('Le compte a bien été créé !');
      navigate(
        `${adminUrls.partnerUrl}/${organizationUuid}/agencies/${createdAccount.agencyUuid}/accounts`,
      );
    },
    onError: (error: AxiosError) => {
      toast.error(error.response?.data as string);
    },
  });

  return { createOrganization, createAgency, createAccount };
};

const handleUpdateOrganization = (payload: PartnerOrganization): Promise<PartnerOrganization> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/partners/${payload.uuid}`;

  return request(requestURL, {
    method: 'PUT',
    body: payload,
  });
};

export const useUpdateOrganization = (): {
  updateOrganization: typeof handleUpdateOrganization;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateOrganization } = useMutation({
    mutationFn: handleUpdateOrganization,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [PARTNERS_KEY] });
      toast.success('Les modifications ont bien été prises en compte !');
    },
  });

  return { updateOrganization };
};

const handleUpdateAgency = (payload: PartnerAgency): Promise<PartnerAgency> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/partners/${payload.organizationUuid}/agencies/${payload.uuid}`;

  return request(requestURL, {
    method: 'PUT',
    body: payload,
  });
};

export const useUpdateAgency = (): { updateAgency: typeof handleUpdateAgency } => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateAgency } = useMutation({
    mutationFn: handleUpdateAgency,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [PARTNERS_KEY] }).then(() => {
        toast.success('Les modifications ont bien été prises en compte !');
      });
    },
  });

  return { updateAgency };
};

const handleUpdateAccount = (payload: PartnerAccount): Promise<PartnerAccount> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/partners/${payload.organizationUuid}/accounts/${payload.uuid}`;

  return request(requestURL, {
    method: 'PUT',
    body: payload,
  });
};

export const useUpdateAccount = (): { updateAccount: typeof handleUpdateAccount } => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateAccount } = useMutation({
    mutationFn: handleUpdateAccount,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [PARTNERS_KEY] });
      toast.success('Les modifications ont bien été prises en compte !');
    },
  });

  return { updateAccount };
};
