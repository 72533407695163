import React, { FC, useCallback } from 'react';

import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ProUser } from '@travauxlib/shared/src/types/company';

export type ConfirmRestoreUserModalProps = {
  proUser: ProUser;
  handleClose: () => unknown;
  updateProUser: ({
    updatedProUser,
    slug,
  }: {
    updatedProUser: ProUser;
    slug?: string;
  }) => Promise<unknown>;
  slug?: string;
};

export const ConfirmRestoreUserModal: FC<ConfirmRestoreUserModalProps> = ({
  proUser,
  handleClose,
  updateProUser,
  slug,
}) => {
  const onClick = useCallback(() => {
    updateProUser({ updatedProUser: { ...proUser, archived: false }, slug });
    handleClose();
  }, [proUser]);

  return (
    <ModalContent
      title={`Êtes-vous sur de vouloir restaurer l'utilisateur ${proUser.fullName}?`}
      validateAction={{ label: 'Confirmer', onClick: onClick }}
      cancelAction={{ label: 'Annuler', onClick: handleClose }}
    />
  );
};
