import React from 'react';

import { useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import {
  Chauffage as ChauffageType,
  Plomberie,
} from '@travauxlib/shared/src/features/Configurateur/types/Plomberie';
import { TypeBien } from '@travauxlib/shared/src/types';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

import { ChauffageNonExistant } from './ChauffageNonExistant';
import { Collectif } from './Collectif';
import { Electrique } from './Electrique';
import { Fioul } from './Fioul';
import { Gaz } from './Gaz';

import { getAlertForTypeEnergieChauffage } from '../../utils/getAlertForTypeEnergie';

type Props = {
  configuration: Configuration;
};

const typeEnergieMaison = [
  { label: 'Électrique', value: 'Électrique' },
  { label: 'Gaz', value: 'Gaz' },
  { label: 'Fioul', value: 'Fioul' },
  { label: 'Pas de chauffage existant', value: 'Aucune' },
  { label: 'Ne sait pas', value: 'Ne sait pas' },
];

const typeEnergieAppart = [
  { label: 'Électrique', value: 'Électrique' },
  { label: 'Gaz', value: 'Gaz' },
  { label: 'Collectif', value: 'Collectif' },
  { label: 'Pas de chauffage existant', value: 'Aucune' },
  { label: 'Ne sait pas', value: 'Ne sait pas' },
];

export const Chauffage: React.FC<Props> = ({ configuration }) => {
  const { values } = useFormState<Plomberie>();
  const isMaison = configuration.typeLogement !== TypeBien.Appartement;
  return (
    <Accordion
      className="mb-md"
      title="Chauffage à modifier, supprimer ou ajouter ?"
      prefix="1"
      defaultExpanded
    >
      <FieldArray<ChauffageType> name="chauffages">
        {({ fields }) => (
          <div>
            {fields.map((name, index) => {
              const value = fields.value[index];
              return (
                <div key={name}>
                  <div className="flex justify-between">
                    <QuestionTitle
                      prefix="1"
                      alert={getAlertForTypeEnergieChauffage(value, values)}
                      title="Énergie utilisée pour le chauffage ?"
                      tooltip={
                        <div>
                          Remplacer du chauffage au gaz (avec radiateurs à eau) par de l’électrique
                          nécessite de vérifier aussi l’installation électrique et s’il y a la place
                          suffisante au tableau électrique.
                          <br /> <br />
                          S’il est déjà prévu une réfection complète de l’électricité, cela sera
                          pris en compte par défaut.
                          {isMaison && (
                            <>
                              <br /> <br />
                              PAC = Pompe à chaleur Air/eau. La PAC peut faire du chauffage seul ou
                              également du chauffage avec de l'eau chaude sanitaire intégrée. Se
                              référer à la question sur l'ECS le cas échéant.
                            </>
                          )}
                        </div>
                      }
                    />
                    <PrestationDelete onDelete={() => fields.remove(index)} />
                  </div>
                  <OptionCheckbox fields={fields} value={value} index={index} />
                  <ChipListField
                    name={`${name}.typeEnergie`}
                    renderingOptions={{
                      containerClassName: 'mb-md flex gap-md',
                    }}
                    options={isMaison ? typeEnergieMaison : typeEnergieAppart}
                  />
                  {value?.typeEnergie === 'Aucune' && (
                    <ChauffageNonExistant configuration={configuration} name={name} value={value} />
                  )}
                  {value?.typeEnergie === 'Électrique' && (
                    <Electrique configuration={configuration} name={name} value={value} />
                  )}
                  {value?.typeEnergie === 'Gaz' && (
                    <Gaz configuration={configuration} name={name} value={value} />
                  )}
                  {value?.typeEnergie === 'Collectif' && (
                    <Collectif configuration={configuration} name={name} value={value} />
                  )}
                  {value?.typeEnergie === 'Fioul' && (
                    <Fioul configuration={configuration} name={name} value={value} />
                  )}
                  <PrestationsSeparator />
                </div>
              );
            })}
            <AddPrestationButton fields={fields} />
          </div>
        )}
      </FieldArray>
    </Accordion>
  );
};
