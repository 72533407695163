import { PartnerCommission } from 'types';

export const shouldDisableTableRow = (item: PartnerCommission): boolean => {
  if (item.archived) {
    return true;
  }
  if (item.status === 'to_send' && item.typeCommission === 'pourcentage' && !item.billingEmail) {
    return true;
  }
  if (item.status === 'to_bill' && item.typeCommission === 'pourcentage' && !item.factureUrl) {
    return true;
  }
  return false;
};
