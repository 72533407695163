import React from 'react';

import Euro from '@travauxlib/shared/src/components/DesignSystem/assets/Euro.svg?react';
import { EventCard } from '@travauxlib/shared/src/components/DesignSystem/components/EventCard';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';

import { useChantierAdvancedActionAuthorized } from 'components/Auth/api/useChantierAdvancedActionAuthorized';

import { eventCardClassName } from './constants';
import { EncaisserPrestationInterneModal } from './EncaisserPrestationInterneModal';

import { TypePrestationEncaissement } from '../../api/useEncaisserPrestationInterne';

type Props = {
  chantier: Chantier;
};

export const EncaisserServiceHemea: React.FC<Props> = ({ chantier }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { isAuthorized, isLoading } = useChantierAdvancedActionAuthorized();
  return chantier.montantServiceHemeaRestantTTC > 0 && chantier.soldeCompteSequestre > 0 ? (
    <>
      {isModalOpen && (
        <EncaisserPrestationInterneModal
          chantier={chantier}
          typePrestation={TypePrestationEncaissement.ServiceHemea}
          labelPrestation="Service Hemea"
          montantTTCPrestation={chantier.montantServiceHemeaTTC}
          montantTTCEncaissePrestation={chantier.montantServiceHemeaEncaisseTTC}
          handleClose={() => setIsModalOpen(false)}
        />
      )}
      <EventCard
        className={eventCardClassName}
        icon={<Euro />}
        variant="beige"
        title="Encaisser le service hemea"
        action={{
          label: 'Encaisser la prestation',
          onClick: () => setIsModalOpen(true),
          disabled: isLoading || !isAuthorized,
          testId: 'btn-encaisser-service-hemea',
          hideIcon: true,
        }}
      >
        <></>
      </EventCard>
    </>
  ) : (
    <></>
  );
};
