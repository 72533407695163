import { DropdownOption } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Option';

export const noSource = 'Pas de source';

export const partnerOffline = {
  label: 'Partenaire offline',
  value: 'partner_offline',
};
export const partnerOnline = {
  label: 'Partenaire online',
  value: 'partner_online',
};
export const brand = {
  label: 'Brand',
  value: 'brand',
};
const telephone = {
  label: 'Téléphone',
  value: 'telephone',
};
const recommandationPro = {
  label: 'Recommandation Pro',
  value: 'recommandation_pro',
};
const recommandationArchi = {
  label: 'Recommandation Archi',
  value: 'recommandation_archi',
};
export const recommandationInterne = {
  label: 'Recommandation Interne',
  value: 'recommandation_interne',
};
export const recommandationClient = {
  label: 'Recommandation Client',
  value: 'recommandation_client',
};
export const boucheAOreille = {
  label: 'Bouche à oreille',
  value: 'bouche_a_oreille',
};
export const emailEntrant = {
  label: 'Email entrant',
  value: 'email_entrant',
};
const packMarketing = {
  label: 'Pack marketing',
  value: 'pack_marketing',
};
const repeat = {
  label: 'Repeat',
  value: 'repeat',
};
const displayFbAds = {
  label: 'Display fbads',
  value: 'display_fbads',
};
const displayInstaAds = {
  label: 'Display instaads',
  value: 'display_instaads',
};
const displayDiscovery = {
  label: 'Display discovery',
  value: 'display_discovery',
};
const display = {
  label: 'Display',
  value: 'display',
};
const pinterestAds = {
  label: 'Pinterest Ads',
  value: 'pinterest_ads',
};
const native = {
  label: 'Native',
  value: 'native',
};
const seo = {
  label: 'SEO',
  value: 'seo',
};
const retargeting = {
  label: 'Retargeting',
  value: 'retargeting',
};
const retargetingFbAds = {
  label: 'Retargeting fbads',
  value: 'retargeting_fbads',
};
const bing = {
  label: 'Bing',
  value: 'bing',
};
const criteo = {
  label: 'Criteo',
  value: 'criteo',
};
const referal = {
  label: 'Referral',
  value: 'referral',
};
const socialMedia = {
  label: 'Social media',
  value: 'social_media',
};
const youtube = {
  label: 'Youtube',
  value: 'youtube',
};
const accesDirect = {
  label: 'Accès direct',
  value: 'acces_direct',
};
const adwords = {
  label: 'Adwords',
  value: 'adwords',
};
const brandAdwords = {
  label: 'Brand Adwords',
  value: 'brand_adwords',
};
const brandBingAds = {
  label: 'Brand Bingads',
  value: 'brand_bingads',
};
const nonIdentifie = {
  label: 'Non identifié',
  value: 'non_identifie',
};
const trustpilot = {
  label: 'Trustpilot',
  value: 'trustpilot',
};
export const salon = {
  label: 'Salon',
  value: 'salon',
};
const consentDenied = {
  label: 'Consent Denied',
  value: 'consent_denied',
};
const scrapping = {
  label: 'Scrapping',
  value: 'scrapping',
};
const mandataire = {
  label: 'CDPL',
  value: 'mandataire',
};

export const salonOptions: Array<DropdownOption<string>> = [
  {
    label: 'Salon de la Rénovation',
    value: 'salon_renovation',
  },
  {
    label: 'Batimat',
    value: 'batimat',
  },
];

type SourceOption = {
  label: string;
  value: string;
  options?: Array<{
    label: string;
    value: string;
  }>;
};

const commonSourceOptions: SourceOption[] = [
  accesDirect,
  adwords,
  boucheAOreille,
  bing,
  brand,
  brandAdwords,
  brandBingAds,
  criteo,
  consentDenied,
  display,
  displayDiscovery,
  displayFbAds,
  emailEntrant,
  nonIdentifie,
  packMarketing,
  partnerOffline,
  partnerOnline,
  referal,
  salon,
  seo,
  socialMedia,
  telephone,
  trustpilot,
  youtube,
  recommandationClient,
];

export const clubProSourceOptions: SourceOption[] = [...commonSourceOptions, scrapping, mandataire];

export const marketplaceSourceOptions: SourceOption[] = [
  ...commonSourceOptions,
  displayInstaAds,
  native,
  pinterestAds,
  retargeting,
  retargetingFbAds,
  recommandationArchi,
  recommandationInterne,
  recommandationPro,
  repeat,
];

export const firstClickSourcesOptions: { value: string; label: string }[] = marketplaceSourceOptions
  .reduce(
    (res, { value, options }) => {
      if (Array.isArray(options)) {
        return [...res, ...options.map(({ value }) => value)];
      }
      if (value) {
        return [...res, value];
      }
      return res;
    },
    [noSource],
  )
  .map(value => ({ value, label: value }))
  .sort();
