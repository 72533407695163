import React from 'react';

import classNames from 'classnames';

import ChevronDown from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronDown.svg?react';
import ChevronUp from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronUp.svg?react';
import HelpCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircleOutline.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { useIsBelowBreakpoint } from '@travauxlib/shared/src/hooks/useIsBelowBreakpoint';

type Props = {
  title: string;
  className?: string;
  defaultExpanded?: boolean;
  prefix?: string;
  tooltip?: string;
  children: React.ReactNode;
};

export const Accordion: React.FC<Props> = ({
  title,
  className,
  prefix,
  tooltip,
  defaultExpanded = false,
  children,
}) => {
  const isBelowTablet = useIsBelowBreakpoint('sm-desktop');
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  return (
    <div
      role="section"
      aria-label={title}
      className={classNames(
        'w-full sm-desktop:w-[57rem] bg-neutral-0 sm-desktop:rounded-md shadow-xs mx-auto',
        className,
      )}
    >
      <div
        className={classNames(
          'flex justify-between items-center group pr-sm sm-desktop:pr-lg pl-xs sm-desktop:pl-[4.75rem]',
        )}
        onClick={() => setExpanded(expanded => !expanded)}
      >
        <div className="flex items-center py-xs sm-desktop:py-lg">
          <div className="flex sm-desktop:items-center">
            {prefix && (
              <ColoredCircle variant="primary" size={isBelowTablet ? 'xs' : 'sm'} className="mr-xs">
                {prefix}
              </ColoredCircle>
            )}
            <div className="text-b1 sm-desktop:text-h4 font-bold mr-xxs">{title}</div>
          </div>
          {tooltip && (
            <WithTooltip
              trigger={
                <span className="mr-sm">
                  <HelpCircleOutline className="text-neutral-600 w-lg h-lg" />
                </span>
              }
              position="top-end"
            >
              <div>{tooltip}</div>
            </WithTooltip>
          )}
        </div>
        <IconButton data-testid="toggle-accordion" size="xl">
          {expanded ? <ChevronUp /> : <ChevronDown />}
        </IconButton>
      </div>
      <div
        className={classNames(
          'w-full pl-xxl pr-md sm-desktop:pl-[7.25rem] sm-desktop:pr-[7.25rem] pb-xs sm-desktop:pb-md',
          { hidden: !expanded },
        )}
      >
        {children}
      </div>
    </div>
  );
};
