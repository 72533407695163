import React from 'react';

import { useNavigate, useOutletContext } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';

import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/Edit.svg?react';
import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbol.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { Toggles } from '@travauxlib/shared/src/features/Configurateur/components/Toggles';
import { ConfigurationStatus } from '@travauxlib/shared/src/features/Configurateur/types';
import { getDevisFromProject } from '@travauxlib/shared/src/utils/getDevisFromProject';

import { useAuth } from 'api/useAuth';
import { useBaseDePrix } from 'api/useBaseDePrix';
import { useConfiguration } from 'features/Configurateur/api/useConfiguration';
import { useCreateUpdateConfiguration } from 'features/Configurateur/api/useCreateUpdateConfiguration';
import { useCreateDevis } from 'features/LogicielDeDevis/api/useCreateDevis';
import { ProjectContext } from 'features/Project/api/useProjectContext';
import { FicheProjetTracking } from 'utils/tracking/FicheProjetTracking';

import { EmptyStateCadrage } from './components/EmptyStateCadrage';
import { PrechiffragesBlock } from './components/PrechiffragesBlock';

export const ProjectCadrage: React.FC = () => {
  const { prechiffrages, project } = useOutletContext<ProjectContext>();

  const { baseDePrix } = useBaseDePrix();
  const { createDevis } = useCreateDevis();
  const { adminUserProfile } = useAuth();
  const navigate = useNavigate();
  const { configuration, isLoading: configurationIsLoading } = useConfiguration(project.uuid);
  const createConfiguration = useCreateUpdateConfiguration();

  if (configurationIsLoading) {
    return <LoaderWrapper />;
  }

  const hasNoPrechiffrages = !prechiffrages?.length;

  const handleCreatePrechiffrage = (): void => {
    const prechiffrage = getDevisFromProject({ typologieProject: project });
    FicheProjetTracking.onPrechiffrageCreated({
      'User Role': adminUserProfile.role,
      'Projet UUID': project.uuid,
    });
    createDevis({
      ...prechiffrage,
      projectUuid: project.uuid,
    }).then(({ token }) => navigate(`../devis/${token}`));
  };

  const handleCreateConfiguration = async (): Promise<void> => {
    FicheProjetTracking.onConfigurationCreated({
      'User Role': adminUserProfile.role,
      'Projet UUID': project.uuid,
    });
    await createConfiguration({
      uuid: uuidV4(),
      projectUuid: project.uuid,
      status: ConfigurationStatus.Draft,
    });
    navigate('../configurateur');
  };

  if (!configuration && hasNoPrechiffrages) {
    return (
      <EmptyStateCadrage
        createPreChiffrage={handleCreatePrechiffrage}
        createConfiguration={handleCreateConfiguration}
      />
    );
  }

  return (
    <div className="py-lg flex flex-col gap-md">
      <div className="flex justify-end gap-[1px]">
        <Button onClick={handleCreatePrechiffrage} leftIcon={<PlusSymbol />}>
          Créer un préchiffrage
        </Button>
        {configuration ? (
          <ButtonLink to="../configurateur" leftIcon={<Edit />}>
            Éditer la configuration
          </ButtonLink>
        ) : (
          <Button onClick={handleCreateConfiguration} leftIcon={<PlusSymbol />}>
            Créer la configuration
          </Button>
        )}
      </div>
      <div>
        {configuration && (
          <Card className="!rounded-md">
            <Toggles readOnly baseDePrix={baseDePrix} configuration={configuration} />
          </Card>
        )}
        {!hasNoPrechiffrages && <PrechiffragesBlock prechiffrages={prechiffrages} />}
      </div>
      <div />
    </div>
  );
};
