import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { Person } from '@travauxlib/shared/src/types';

type Props = {
  person: Person;
  to: string;
};

export const PersonCard: React.FC<Props> = ({ person, to }) => {
  const { firstName, lastName, jobTitle } = person;

  const officialPicture = person.officialPicture || 'https://picsum.photos/224/224';

  const bonusPicture = person.bonusPicture || officialPicture;
  return (
    <Card data-testid="person-card" to={to} state="elevated" className="w-[16rem] h-[320px]">
      <div className="bg-transparent p-0 w-[14rem] [perspective]-[1000px] [&:hover>*]:[transform:rotateY(180deg)]">
        <div className="relative w-[14rem] h-[224px] duration-[800ms] [transform-style:preserve-3d]">
          <div className="absolute [backface-visibility:hidden]">
            <ImgLazy
              alt="main"
              url={officialPicture}
              width={224}
              height={224}
              imgixParams={{
                fit: 'crop',
                crop: 'top',
              }}
            />
          </div>
          <div className="absolute [backface-visibility:hidden] [transform:rotateY(180deg)]">
            <ImgLazy
              alt="main"
              url={bonusPicture}
              width={224}
              height={224}
              imgixParams={{
                fit: 'crop',
                crop: 'top',
              }}
            />
          </div>
        </div>
      </div>
      <div className="text-left mt-md">
        <div className="text-b1 font-bold truncate">
          {firstName} {lastName}
        </div>
        <div className="text-b1 truncate text-neutral-600">{jobTitle}</div>
      </div>
    </Card>
  );
};
