import React from 'react';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';

import { Consultation } from 'types';
import { adminUrls } from 'utils/urls';

import { ConsultationFeedback } from './ConsultationFeedback';
import { ConsultationMeeting } from './ConsultationMeeting';
import { ConsultationSentAt } from './ConsultationSentAt';
import { ConsultationStatus } from './ConsultationStatus';

import { useGetConsultationActions } from '../../hooks/useGetConsultationActions';

type Props = {
  consultations: Consultation[];
};

export const ConsultationTable: React.FC<Props> = ({ consultations }) => {
  const getConsultationActions = useGetConsultationActions();

  return (
    <Table
      columnConfigs={[
        {
          name: 'Nom du professionnel',
          hideSort: true,
          renderValue: (_, consultation) => (
            <Link
              target="_blank"
              className="text-neutral-800"
              variant="secondary"
              to={adminUrls.editCompanyUrl(consultation.proCompany.slug)}
            >
              {consultation.proCompany.name}
            </Link>
          ),
        },
        {
          name: 'Statut',
          hideSort: true,
          renderValue: (_, consultation) => <ConsultationStatus consultation={consultation} />,
        },
        {
          name: 'Envoyé le',
          hideSort: true,
          renderValue: (_, consultation) => <ConsultationSentAt consultation={consultation} />,
        },
        {
          name: 'Rendez-vous',
          hideSort: true,
          renderValue: (_, consultation) => <ConsultationMeeting consultation={consultation} />,
        },
        {
          name: 'Feedback',
          hideSort: true,
          renderValue: (_, consultation) => <ConsultationFeedback consultation={consultation} />,
        },
      ]}
      items={consultations}
      rowActions={getConsultationActions}
    />
  );
};
