import React, { useEffect } from 'react';

import { useAuth } from 'api/useAuth';
import { useUpdateAgency } from 'features/Partners/api/mutations';
import { useAgency } from 'features/Partners/hooks/useAgency';
import { PartnerAgency } from 'types';
import { PartenairesTracking } from 'utils/tracking/PartenairesTracking';

import { AgencyForm } from './AgencyForm';

export const EditAgency: React.FC = () => {
  const agency = useAgency();
  const { updateAgency } = useUpdateAgency();
  const { adminUserProfile } = useAuth();

  useEffect(() => {
    if (agency) {
      PartenairesTracking.onAgencePartenaireViewed({
        'User Role': adminUserProfile.role,
        'Agence Partenaire UUID': agency.uuid,
        'Organisation Partenaire UUID': agency.organizationUuid,
      });
    }
  }, [agency]);

  if (!agency) {
    return null;
  }

  return (
    <AgencyForm
      onSubmit={(agency: PartnerAgency) => {
        PartenairesTracking.onAgencePartenaireCreated({
          'User Role': adminUserProfile.role,
          'Agence Partenaire UUID': agency.uuid,
          'Organisation Partenaire UUID': agency.organizationUuid,
        });

        return updateAgency(agency);
      }}
      initialValues={agency}
    />
  );
};
