import { useFormState } from 'react-final-form';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import {
  Chauffage,
  Plomberie,
} from '@travauxlib/shared/src/features/Configurateur/types/Plomberie';

import { ChipPictoVerticalListFieldConfigurateur } from 'features/Configurateur/components/ChipPictoVerticalListFieldConfigurateur';
import { FormControl } from 'features/Configurateur/components/FormControl';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';
import { yesNoForm } from 'features/Configurateur/utils/forms';
import { getLocationOptions } from 'features/Configurateur/utils/getLocationOptions';

import { DeposeRadiateursEaux } from './components/DeposeRadiateursEaux';

import { getAlertForTypeModifChauffage } from '../../utils/getAlertForTypeModif';

type Props = {
  configuration: Configuration;
  name: string;
  value: Chauffage;
};

const ChaudiereFioulADeposer: React.FC<Props> = ({ configuration, name }) => {
  const locationOptions = getLocationOptions(configuration);

  return (
    <>
      <QuestionTitle prefix="3" title="Localisation de la chaudière à déposer ?" />
      <ChipPictoVerticalListFieldConfigurateur
        name={`${name}.deposeChaudiereFioulLocationUuid`}
        locationOptions={locationOptions}
      />
    </>
  );
};
const CuveFioulADeposer: React.FC<Props> = ({ name }) => (
  <>
    <QuestionTitle prefix="4" title="Cuve à fioul à déposer ?" />
    <FormControl name={`${name}.cuveFioulADeposer`} {...yesNoForm} />
  </>
);

export const Fioul: React.FC<Props> = ({ configuration, name, value }) => {
  const { values } = useFormState<Plomberie>();
  return (
    <>
      <QuestionTitle
        prefix="2"
        title="Type de modifications ?"
        alert={getAlertForTypeModifChauffage(value, values)}
      />
      <ChipListField
        name={`${name}.typeModifFioul`}
        renderingOptions={{
          containerClassName: 'mb-md flex flex-wrap gap-xs',
        }}
        options={[
          {
            label: 'Changement complet pour chauffage électrique',
            value: 'Electrique',
          },
          {
            label: 'Changement pour chauffage gaz',
            value: 'Gaz',
          },
          {
            label: 'Changement pour chauffage PAC',
            value: 'PAC',
          },
        ]}
      />
      {value?.typeModifFioul === 'Electrique' && (
        <>
          <ChaudiereFioulADeposer configuration={configuration} name={name} value={value} />
          {value?.deposeChaudiereFioulLocationUuid && (
            <CuveFioulADeposer configuration={configuration} name={name} value={value} />
          )}
          {value?.cuveFioulADeposer !== undefined && (
            <DeposeRadiateursEaux configuration={configuration} prefix="5" name={name} />
          )}
        </>
      )}
      {value?.typeModifFioul === 'Gaz' && (
        <>
          <ChaudiereFioulADeposer configuration={configuration} name={name} value={value} />
          {value?.deposeChaudiereFioulLocationUuid && (
            <CuveFioulADeposer configuration={configuration} name={name} value={value} />
          )}
        </>
      )}
      {value?.typeModifFioul === 'PAC' && (
        <>
          <ChaudiereFioulADeposer configuration={configuration} name={name} value={value} />
          {value?.deposeChaudiereFioulLocationUuid && (
            <CuveFioulADeposer configuration={configuration} name={name} value={value} />
          )}
        </>
      )}
    </>
  );
};
