import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { DevisModalites } from './types';

export const DEVIS_MODALITES_KEY = 'devis-modalites';

const fetchAdminDevis = (devisToken: string): Promise<DevisModalites> =>
  request(`${APP_CONFIG.apiURL}/admin/devis/${devisToken}/modalites`);

export const useDevisModalites = (
  devisToken: string,
): { data?: DevisModalites; isLoading: boolean } => {
  const { data, isLoading } = useQuery({
    queryKey: [DEVIS_MODALITES_KEY, devisToken],
    queryFn: () => fetchAdminDevis(devisToken),
  });

  return { data, isLoading };
};
