import React from 'react';

import { DropdownMultiField } from '@travauxlib/shared/src/components/DesignSystem/components/DropdownMulti/Field';
import { architectAccreditations } from '@travauxlib/shared/src/utils/constants';

import { assurancesArchitecte, specializationsArchitects } from 'utils/constants';

export const ArchitectFields: React.FC = () => (
  <div className="!py-xs !bg-lighter">
    <h4>Filtres archi</h4>
    <div className="flex flex-wrap sm-desktop:-mx-md">
      <div className="w-full mb-xs sm-desktop:mb-0 sm-desktop:w-3/12 sm-desktop:px-md relative">
        <DropdownMultiField
          id="architectAccreditations"
          name="architectAccreditations"
          label="Habilitation"
          options={architectAccreditations}
        />
      </div>
      <div className="w-full mb-xs sm-desktop:mb-0 sm-desktop:w-3/12 sm-desktop:px-md relative">
        <DropdownMultiField
          id="specializations"
          name="specializations"
          label="N'envoyer que sur"
          options={specializationsArchitects.map(value => ({ value, label: value }))}
        />
      </div>
      <div className="w-full mb-xs sm-desktop:mb-0 sm-desktop:w-3/12 sm-desktop:px-md relative">
        <DropdownMultiField
          id="corps-etat"
          placeholder="Aucun"
          label="Assurée pour"
          name="corpsEtat"
          options={assurancesArchitecte.map(value => ({ value, label: value }))}
        />
      </div>
    </div>
  </div>
);
