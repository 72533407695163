import React from 'react';

import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';

import { ProCompany } from 'types';
import { adminUrls } from 'utils/urls';

type Props = {
  proCompany: ProCompany;
  proUserId?: number;
};

export const CompanySummary: React.FC<Props> = ({
  proCompany: {
    name,
    slug,
    contacts = [],
    proUsers,
    specializations,
    corpsEtat,
    gammes,
    certifications,
    departements,
    tailleChantierMin,
    tailleChantierMax,
    autonomeCommercialement,
    pauseReason,
  },
  proUserId,
}) => {
  const proUser = proUsers.find(({ id }) => id === proUserId);
  return (
    <div>
      <h4>
        <strong>
          👷{' '}
          <Link to={adminUrls.editCompanyUrl(slug)} target="_blank">
            {name}
          </Link>
          <WithTooltip
            trigger={
              <span className="ml-xs">
                <HelpCircle className="w-md h-md" />
              </span>
            }
            position="right"
            width="40rem"
          >
            <div className="!p-sm">
              <ul>
                <li>
                  <b>Taille chantier :</b> {tailleChantierMin} {'<= value <='} {tailleChantierMax}
                </li>
                <li>
                  <b>Départements :</b> {(departements || []).sort().join(', ')}, +départements de
                  la même zone géographique
                </li>
                <li>
                  <b>Corps d'état :</b> {(corpsEtat || []).sort().join(', ')}
                </li>
                <li>
                  <b>Types travaux :</b> {(specializations || []).sort().join(', ')}
                </li>
                <li>
                  <b>Gammes :</b> {(gammes || []).sort().join(', ')}
                </li>
                <li>
                  <b>Certifications :</b> {(certifications || []).sort().join(', ')}
                </li>
                <li>
                  <b>Autonome Commercialement :</b> {autonomeCommercialement ? 'oui' : 'non'}
                </li>
              </ul>
            </div>
          </WithTooltip>
        </strong>
      </h4>
      {pauseReason && <Tag variant="info" label="En pause" className="my-xxs" />}
      {proUser ? (
        <div key={`${proUser.fullName}-${proUser.email}-${proUser.phoneNumber}`}>
          {proUser.fullName}
        </div>
      ) : (
        <>
          {contacts.map(contact => (
            <div key={`${contact.name}-${contact.email}-${contact.phoneNumber}`}>
              {contact.name}
            </div>
          ))}
        </>
      )}
    </div>
  );
};
