import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { UpdateDevisModalitesPaymentPayload } from './types';

type UpdateDevisModalitesParams = {
  devisToken: string;
  body: UpdateDevisModalitesPaymentPayload;
};

const devisModalitesPaymentUpdate = (params: UpdateDevisModalitesParams): Promise<void> =>
  request(`${APP_CONFIG.apiURL}/admin/devis/${params.devisToken}/modalites-payment`, {
    method: 'PATCH',
    body: params.body,
  });

export const useDevisModalitesPaymentUpdate: () => (
  variables: UpdateDevisModalitesParams,
) => Promise<void> = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: query } = useMutation({
    mutationFn: devisModalitesPaymentUpdate,
    onSuccess: (_, variables) => {
      toast.success('Les modalités de paiement ont été mises à jour');
      queryClient.invalidateQueries({ queryKey: ['DEVIS_MODALITES_KEY', variables.devisToken] });
    },
    onError: () => toast.error('Erreur lors de la mise à jour des modalités de paiement'),
  });
  return query;
};
