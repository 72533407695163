import { v4 } from 'uuid';

import { DropdownOption } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Option';
import { Options } from '@travauxlib/shared/src/utils/request';

export type StepField = {
  name: string;
  initialValue?: any;
  required?: boolean;
  fieldType?: 'input' | 'checkbox' | 'dropdown';
  hide?: (params: any) => boolean;
  helperText?: string;
  options?: DropdownOption<any>[];
};

export type ExtractTypeFromField<Fields extends readonly StepField[]> = {
  [Field in Fields[number] as Field['name']]: string;
};

export type ExecuteParams<Input = {}, Fields extends readonly StepField[] = []> = Input & {
  seed: string;
  apiURL: string;
  adminURL: string;
  appURL: string;
  request: <T extends {}>(url: string, options?: Options) => Promise<T>;
} & ExtractTypeFromField<Fields>;

export abstract class BaseStep<
  Input = {},
  Output = void,
  Fields extends readonly StepField[] = readonly StepField[],
  CreatedEntities = {},
> {
  public name: string = 'Base step';

  public seed: string;

  protected stderr: string = '';

  protected stdout: string = '';

  public createdEntities?: CreatedEntities;

  public abstract fields: Fields;

  public constructor(seed?: string) {
    this.seed = seed ?? `${Date.now().toString(10)}-${v4()}`;
  }

  abstract execute(params: ExecuteParams<Input, Fields>): Promise<Output | undefined>;

  public log(content: string): void {
    this.stdout += `\n${content}`;
  }

  public error(content: string): void {
    this.stderr += `\n${content}`;
  }

  public setCreatedEntities(stack: CreatedEntities): void {
    this.createdEntities = stack;
  }

  public getCreatedEntities(): CreatedEntities | undefined {
    return this.createdEntities;
  }

  public getOutputs(): [string, string] {
    return [this.stdout, this.stderr];
  }

  public getName(): string {
    return this.name;
  }
}
