import React, { useEffect, useState } from 'react';

import { Form } from 'react-final-form';
import { useOutletContext } from 'react-router-dom';

import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';

import { DevisMarketplace, ProCompany } from 'types';

import { useDevisMarketplaceList } from './api/useDevisMarketplaceList';
import { DevisList } from './DevisList';

export const Devis: React.FC = () => {
  const { proCompany } = useOutletContext<{ proCompany: ProCompany }>();
  const { devisList, isLoaded } = useDevisMarketplaceList({
    slug: proCompany.slug,
  });
  const [filter, setFilter] = useState<string>();
  const [filteredDevis, setFilteredDevis] = useState<DevisMarketplace[]>([]);

  useEffect(() => {
    if (filter) {
      setFilteredDevis(devisList.filter(devis => devis.customerName === filter));
    }
  }, [devisList, filter]);

  if (!isLoaded) {
    return <LoaderWrapper />;
  }

  return (
    <div className="p-lg">
      <h2 className="mb-md">Devis sur deals hemea</h2>
      <Form onSubmit={() => {}}>
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} className="mb-md">
            <DropdownField
              id="customerName"
              name="customerName"
              placeholder="Sélectionner un client..."
              onChange={(value: string) => {
                setFilter(value);
                form.change('customerName', value);
              }}
              options={Array.from(new Set(devisList.map(devis => devis.customerName)))
                .sort()
                .map(customerName => ({
                  label: customerName,
                  value: customerName,
                }))}
            />
          </form>
        )}
      </Form>

      <DevisList devisList={filteredDevis.length > 0 ? filteredDevis : devisList} />
    </div>
  );
};
