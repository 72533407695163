import React from 'react';

import dayjs from 'dayjs';

import Location from '@travauxlib/shared/src/components/DesignSystem/assets/Location.svg?react';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { LabellisationStatus } from '@travauxlib/shared/src/types/company';
import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

import { ProCompany } from 'types';
import { adminUrls } from 'utils/urls';

import { Stats } from './Stats';

type Props = {
  proCompany: ProCompany;
  withStats?: boolean;
};

export const CompanyInfo: React.FC<Props> = ({ proCompany, withStats }) => {
  const googleMapsUri = `https://maps.google.com/?q=${[
    proCompany.address,
    proCompany.postalCode,
    'France',
  ].join(' ')}`;
  return (
    <div>
      <Link to={adminUrls.editCompanyUrl(proCompany.slug)}>{proCompany.name}</Link>
      <br />
      <small>{proCompany.siret}</small>
      <div className="!flex !items-center">
        <small className="!mr-sm">{proCompany.statutEntreprise}</small>
        <Tag
          size="md"
          label={proCompany.status}
          variant={proCompany.status === LabellisationStatus.activee ? 'success' : 'info'}
        />
        {proCompany.isKycCompliant && (
          <img
            className="!ml-sm"
            src={`${baseImgixUrl}/static/logo-lemonway.png?h=30`}
            alt="isKycCompliant"
            height="30"
          />
        )}
      </div>
      {proCompany.pageVitrineUrl ? (
        <Link href={proCompany.pageVitrineUrl} target="_blank" rel="noopener noreferrer">
          Voir page vitrine
        </Link>
      ) : (
        <div>Pas de page vitrine</div>
      )}
      {proCompany.contacts &&
        proCompany.contacts.map((contact, key) => (
          <div key={`${key}-${contact.email}`}>
            <i>{contact.name}</i>
          </div>
        ))}
      {proCompany.logoUrl && (
        <div>
          <img
            className="h-[50px] max-w-full"
            src={proCompany.logoUrl}
            alt={`Logo ${proCompany.name}`}
          />
        </div>
      )}
      <br />
      <span id={proCompany.uuid}>{proCompany.email}</span>
      <br />
      {proCompany.phoneNumber}
      <br />
      Date d&apos;inscription :&nbsp;
      {dayjs(proCompany.createdAt).format('L')}
      <br />
      {proCompany.labelliseeAt && (
        <>
          Date de labellisation :&nbsp;
          {dayjs(proCompany.labelliseeAt).format('L')}
        </>
      )}
      {withStats && proCompany.stats && <Stats stats={proCompany.stats} />}
      <br />
      <Link href={googleMapsUri} target="_blank" rel="noopener noreferrer" icon={<Location />}>
        {proCompany.address} {proCompany.postalCode} {proCompany.city}
      </Link>
    </div>
  );
};
