import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';

import { ChipPictoVerticalListFieldConfigurateur } from 'features/Configurateur/components/ChipPictoVerticalListFieldConfigurateur';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';
import { getLocationOptions } from 'features/Configurateur/utils/getLocationOptions';

type Props = {
  configuration: Configuration;
};

export const LocalisationChauffeEauAInstaller: React.FC<Props> = ({ configuration }) => {
  const locationOptions = getLocationOptions(configuration);
  return (
    <>
      <QuestionTitle prefix="3" title="Localisation du chauffe-eau à installer ?" />
      <ChipPictoVerticalListFieldConfigurateur
        name="eauChaude.poseChauffeEauLocationUuid"
        locationOptions={locationOptions}
      />
    </>
  );
};
