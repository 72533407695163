import React from 'react';

import { Link } from 'react-router-dom';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { CDPLFacture } from '../types';

export type Props = {
  factures: CDPLFacture[];
};

export const TableFactureEnCours: React.FC<Props> = ({ factures }) => (
  <Card state="filled" className="my-md">
    <Table<CDPLFacture>
      itemsPerPage={14}
      columnConfigs={[
        {
          name: 'Numéro',
          columnKey: 'numero',
        },
        {
          name: 'Désignation',
          columnKey: 'designation',
        },
        {
          name: 'Chantier',
          renderValue: (_, facture: CDPLFacture) => (
            <Link to={`/chantiers/${facture.chantierSlug}`}>{facture.chantierTitle}</Link>
          ),
        },
        {
          name: 'Date de demande de paiement',
          columnKey: 'createdAt',
          renderValue: (createdAt: string) => <div>{formatFrenchDate(createdAt)}</div>,
        },
        {
          name: 'Montant TTC',
          columnKey: 'montantTTC',
          renderValue: (montantTTC: number) => <EURCurrency amount={montantTTC} />,
        },
      ]}
      rowActions={({ token }) => [
        {
          label: 'Voir',
          action: () => window.open(`${APP_CONFIG.appURL}/factures/${token}`),
        },
      ]}
      items={factures}
      defaultSort={{ columnKey: 'createdAt', order: 'desc' }}
    />
  </Card>
);
