import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { toast } from '@travauxlib/shared/src/components/Notifications';

export const CHANTIER_KEY = 'chantier';

export const useChantierQuery = <T>(
  request: (params: T) => Promise<void>,
  errorContext: string,
  successMessage: string,
): { query: (params: T) => Promise<void>; isLoading: boolean } => {
  const queryClient = useQueryClient();
  const { mutateAsync: query, isPending } = useMutation({
    mutationFn: request,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CHANTIER_KEY] });
      toast.success(successMessage);
    },
    onError: (error: AxiosError) => {
      queryClient.invalidateQueries({ queryKey: [CHANTIER_KEY] });
      const detail = error?.response?.data || 'Ne touche plus à rien et contacte la team product !';
      toast.error(`Une erreur est survenue lors ${errorContext}. ${detail}`, {
        toastOptions: { autoClose: false },
      });
    },
  });

  return { query, isLoading: isPending };
};
