import React from 'react';

import { Person } from '@travauxlib/shared/src/types';

import { useAuth } from 'api/useAuth';
import { PeopleSelector } from 'components/PeopleSelector';
import { LeadHistory } from 'features/DashboardCDP/components/CDPDashboardLine/LeadHistory';
import { useSalesTeamMembers } from 'features/Trombinoscope/api/useTeams';
import { Lead } from 'types';

import { LeadUpdater } from './LeadUpdater';

import { useUpdateLead } from '../api/useUpdateLead';

type Props = {
  lead: Lead;
};

export const LeadAction: React.FC<Props> = ({ lead }) => {
  const { teamMembers: sales, isLoading } = useSalesTeamMembers();
  const {
    adminUserProfile: { hasPermission },
  } = useAuth();
  const { close, updateStatus, setDealOwner, updateAdressable, isUpdating } = useUpdateLead();

  // When a non-sale (ex. admin) closes the deal, he's auto assigned.
  // Since he's not in the list, the hack is to show him as an additional option,
  // otherwise the re-render triggers an unwanted deal owner change

  if (isLoading) {
    return null;
  }

  const isDealOwnerInSalesTeam = sales.some(s => s.email === lead.dealOwner);
  const additionalOption: Person[] =
    lead.dealOwner && !isDealOwnerInSalesTeam
      ? [{ email: lead.dealOwner, firstName: 'CDP Non Ops', lastName: lead.dealOwner } as Person]
      : [];

  return (
    <>
      <div className="mb-xs">
        {hasPermission('UPDATE_ATTRIBUTION_LEAD') && (
          <PeopleSelector
            id={`dealOwner-${lead.token}`}
            persons={additionalOption.concat(sales)}
            value={lead.dealOwner}
            allowEmpty={!lead.dealOwner}
            emptyLabel="Aucun"
            onChange={dealOwner => dealOwner && setDealOwner({ lead, dealOwner })}
          />
        )}
      </div>
      <LeadUpdater
        lead={lead}
        handleCloseDemande={close}
        handleUpdateAdressable={updateAdressable}
        handleUpdateStatus={updateStatus}
        isUpdating={isUpdating}
      />
      <LeadHistory lead={lead} />
    </>
  );
};
