import React from 'react';

import classNames from 'classnames';

import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';

import { ProjectStatus } from 'types';

const projectStatusConfig: { [key in ProjectStatus]: { label: string; className: string } } = {
  etude_projet: { label: 'Étude de projet', className: '!text-neutral-800 !bg-warning-100' },
  consultation_archi_sent: {
    label: 'Consultation archi envoyée',
    className: '!text-neutral-800 !bg-info-100',
  },
  consultation_archi_validated: {
    label: 'Consultation archi acceptée',
    className: '!text-neutral-800 !bg-[#DCCFDD]',
  },
  consultation_pro_sent: {
    label: 'Consultation pro envoyée',
    className: '!text-neutral-800 !bg-[#BEDEEF]',
  },

  consultation_pro_validated: {
    label: 'Consultation pro acceptée',
    className: '!text-neutral-800 !bg-[#FFFBC6]',
  },
  prestation_archi_sent: {
    label: 'Proposition archi envoyée',
    className: '!text-neutral-800 !bg-[#CBF0C7]',
  },
  prestation_archi_validated: {
    label: 'Prestation archi validée',
    className: '!text-neutral-800 !bg-[#BEEFE1]',
  },
  devis_pro_sent: { label: 'Devis pro envoyé', className: '!text-neutral-800 !bg-[#FEE4D6]' },
  deal_won: { label: 'Deal gagné', className: '!text-neutral-800 !bg-success-100' },
  deal_lost: { label: 'Deal perdu', className: '!text-neutral-800 !bg-neutral-100' },
  new: { label: 'Nouveau', className: '!text-neutral-800 !bg-error-100' },
};

export const ProjectCardTag: React.FC<{ projectStatus: ProjectStatus }> = ({ projectStatus }) => (
  <Tag
    label={projectStatusConfig[projectStatus].label}
    className={classNames('h-xmd', projectStatusConfig[projectStatus].className)}
    variant="info"
  />
);
