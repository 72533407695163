import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AdminEventsProperties,
  AdminFeatures,
  AdminPartenairesSubfeatures,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class PartenairesTrackingClass extends Tracker<AdminFeatures> {
  onOrganisationPartenaireViewed(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.OrganisationPartenaireUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: AdminPartenairesSubfeatures.Organisation,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onOrganisationPartenaireEdited(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.OrganisationPartenaireUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: AdminPartenairesSubfeatures.Organisation,
      event: TrackingEvents.Edited,
      properties,
    });
  }

  onAgencePartenaireViewed(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.AgencePartenaireUUID]: string;
    [AdminEventsProperties.OrganisationPartenaireUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: AdminPartenairesSubfeatures.Agence,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onAgencePartenaireEdited(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.AgencePartenaireUUID]: string;
    [AdminEventsProperties.OrganisationPartenaireUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: AdminPartenairesSubfeatures.Agence,
      event: TrackingEvents.Edited,
      properties,
    });
  }

  onAgencePartenaireCreated(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.AgencePartenaireUUID]: string;
    [AdminEventsProperties.OrganisationPartenaireUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: AdminPartenairesSubfeatures.Agence,
      event: TrackingEvents.Edited,
      properties,
    });
  }

  onComptePartenaireEdited(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.ComptePartenaireUUID]: string;
    [AdminEventsProperties.AgencePartenaireUUID]: string;
    [AdminEventsProperties.OrganisationPartenaireUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: AdminPartenairesSubfeatures.Compte,
      event: TrackingEvents.Edited,
      properties,
    });
  }

  onComptePartenaireCreated(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.ComptePartenaireUUID]: string;
    [AdminEventsProperties.AgencePartenaireUUID]: string;
    [AdminEventsProperties.OrganisationPartenaireUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: AdminPartenairesSubfeatures.Compte,
      event: TrackingEvents.Created,
      properties,
    });
  }

  onComptePartenaireViewed(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.ComptePartenaireUUID]: string | null;
    [AdminEventsProperties.AgencePartenaireUUID]: string;
    [AdminEventsProperties.OrganisationPartenaireUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: AdminPartenairesSubfeatures.Compte,
      event: TrackingEvents.Viewed,
      properties,
    });
  }
}

export const PartenairesTracking = new PartenairesTrackingClass(AdminFeatures.Partenaires);
