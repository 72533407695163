import React from 'react';

import { ConsultationBlock } from 'features/Project/features/ProjectPage/features/ProjectConsultation/components/ConsultationBlock';
import { ProCompany } from 'types';

import { useLastConsultations } from '../api/useLastConsultations';

type Props = {
  proCompany: ProCompany;
  title: string;
  dateSearch?: string;
};

export const ConsultationList: React.FC<Props> = ({ proCompany, title, dateSearch }) => {
  const consultations = useLastConsultations({ dateSearch, proCompanySlug: proCompany.slug });
  if (!consultations) {
    return null;
  }

  return (
    <>
      <h3>
        {consultations.length} {title}
      </h3>
      {consultations.map(consultation => (
        <ConsultationBlock
          key={consultation.consultationInfo.id}
          consultationInfo={consultation.consultationInfo}
          consultations={[consultation]}
          showNewCompanyLink={false}
          ficheEntrepriseMode
        />
      ))}
    </>
  );
};
