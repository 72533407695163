import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { MenuiseriesInterieuresMenuiserieADeposer } from '@travauxlib/shared/src/features/Configurateur/types/MenuiseriesInterieures';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { LocationsDimensions } from 'features/Configurateur/components/LocationsDimensions';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

type Props = {
  configuration: Configuration;
};

export const MenuiseriesADeposer: React.FC<Props> = ({ configuration }) => (
  <Accordion
    className="mb-md"
    title="Menuiseries à déposer ?"
    prefix="2"
    tooltip="Les menuiseries peuvent être des placards, bibliothèques, dressing et autres menuiseries sur mesure."
    defaultExpanded
  >
    <FieldArray<MenuiseriesInterieuresMenuiserieADeposer> name="menuiseriesADeposer">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];

            return (
              <div key={name}>
                <div className="flex justify-between">
                  <QuestionTitle prefix="1" title="Pièces concernées ?" />
                  <PrestationDelete onDelete={() => fields.remove(index)} />
                </div>
                <OptionCheckbox fields={fields} value={value} index={index} />
                <LocationsDimensions
                  configuration={configuration}
                  locations={configuration.locations}
                  getFields={locationUuid => [
                    {
                      name: `${name}.locationQuantities.${locationUuid}.longueur`,
                    },
                    {
                      name: `${name}.locationQuantities.${locationUuid}.hauteur`,
                    },
                  ]}
                />
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
