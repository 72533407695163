import React from 'react';

import { CopyToClipboardButton } from '@travauxlib/shared/src/components/CopyToClipboardButton';
import Location from '@travauxlib/shared/src/components/DesignSystem/assets/Location.svg?react';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { dateTimeFormat } from '@travauxlib/shared/src/utils/time';

import { Lead } from 'types';

type Props = {
  lead: Lead;
};

export const Contact: React.FC<Props> = ({ lead }) => (
  <>
    {(lead.firstName || lead.lastName) && (
      <div className="!mb-xs">
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.google.fr/search?q=${lead.firstName || ''} ${lead.lastName || ''}`}
        >
          {lead.firstName} {lead.lastName}
        </Link>
        <br />
        {lead.isNewProjectFromKnownClient && (
          <Tag size="md" variant="error" label="Nouveau projet d'un client déjà connu" />
        )}
      </div>
    )}
    {lead.email && (
      <div className="!mb-xs">
        <span data-testid="email" className="select-all break-all mr-xxs">
          {lead.email}
        </span>
        <CopyToClipboardButton valueToCopy={lead.email} />
      </div>
    )}
    <div className="!mb-xs">
      {lead.phoneNumber && (
        <>
          <span className="select-all break-all" data-testid="phoneNumber">
            {lead.phoneNumber}
          </span>
          <CopyToClipboardButton valueToCopy={lead.phoneNumber} />{' '}
        </>
      )}
    </div>
    <div>
      Date :&nbsp;
      {dateTimeFormat(lead.createdAt)}{' '}
    </div>
    <hr />
    {lead.googleMapsUrl && (
      <p>
        <Link
          href={lead.googleMapsUrl}
          target="_blank"
          rel="noopener noreferrer"
          icon={<Location />}
        >
          {lead.address}
        </Link>
      </p>
    )}
    {!lead.googleMapsUrl && (
      <p>
        {lead.address}
        <br />
        <Link
          href={`https://ipinfo.io/${lead.metadata.ip}`}
          target="_blank"
          rel="noopener noreferrer"
          icon={<Location />}
        >
          Localisation de l&apos;ip
        </Link>
      </p>
    )}
    {lead.pipedriveDealUrl && (
      <p>
        <Link href={lead.pipedriveDealUrl} target="_blank" rel="noopener noreferrer">
          Deal pipedrive
        </Link>
      </p>
    )}
    {lead.parrainPipedriveDealUrl && (
      <Link
        className="mb-md"
        href={lead.parrainPipedriveDealUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        Deal pipedrive du parrain
      </Link>
    )}
  </>
);
