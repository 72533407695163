import Payment from '@travauxlib/shared/src/components/DesignSystem/assets/Payment.svg?react';
import { Chantier, ChantierTransaction } from '@travauxlib/shared/src/features/Chantiers/types';

import { createActionCreators, createBaseHistoryItem } from './baseHistoryItem';

import { FluxFinancierHistoriqueFilterType, HistoryItem } from '../types';

export const getLabelTransaction = (transaction: ChantierTransaction): string => {
  if (transaction.transactionType === 'encaissement_tp') {
    return 'Encaissement Travaux Planner';
  }
  if (transaction.transactionType === 'encaissement_service_hemea') {
    return 'Encaissement Service hemea';
  }
  if (transaction.transactionType === 'encaissement_archi_interne') {
    return 'Encaissement Archi Interne';
  }
  return `Paiement vers ${transaction.creditedAccountName}`;
};

export const paiementToHistoryItem =
  (
    handleOpenModalIgnoreTransaction: (
      transaction: ChantierTransaction,
      chantier: Chantier,
    ) => void,
    handleOpenModalChangeTransactionVisibility: (
      transaction: ChantierTransaction,
      chantier: Chantier,
    ) => void,
    chantier: Chantier,
    isTeamProduct: boolean,
  ) =>
  (transaction: ChantierTransaction): HistoryItem =>
    createBaseHistoryItem(
      transaction,
      getLabelTransaction(transaction),
      FluxFinancierHistoriqueFilterType.Paiement,
      Payment,
      'success',
      createActionCreators(
        handleOpenModalIgnoreTransaction,
        handleOpenModalChangeTransactionVisibility,
        chantier,
        isTeamProduct,
      ),
    );
