import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { CounterField } from '@travauxlib/shared/src/components/DesignSystem/components/Counter/Field';
import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import {
  DeposeMur,
  TypeRevetement,
} from '@travauxlib/shared/src/features/Configurateur/types/Murs';
import { getLocationLabel } from '@travauxlib/shared/src/features/Configurateur/utils/getLocationLabel';
import { locationsPicto } from '@travauxlib/shared/src/features/DevisDisplay/components/LocalisationIcon';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { LocationCard } from 'features/Configurateur/components/LocationCard';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

type Props = {
  configuration: Configuration;
};

const DeposeMursLocation: React.FC<{ name: string; configuration: Configuration }> = ({
  name,
  configuration,
}) => (
  <>
    <QuestionTitle prefix="2" title="Pièces concernées ?" />

    {configuration.locations.map(location => {
      const Picto = locationsPicto[location.typeLocation];
      return (
        <LocationCard
          className="mb-md"
          key={location.uuid}
          label={getLocationLabel(configuration, location)}
          centerIcon={<Picto />}
        >
          <CounterField
            name={`${name}.locationQuantities.${location.uuid}`}
            id={`${name}.locationQuantities.${location.uuid}`}
            label="Nombre de murs"
            className="py-sm !mb-0"
          />
        </LocationCard>
      );
    })}
  </>
);

export const DeposeMurs: React.FC<Props> = ({ configuration }) => (
  <Accordion className="mb-md" title="Revêtements de mur à déposer ?" prefix="1" defaultExpanded>
    <FieldArray<DeposeMur> name="deposeMurs">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={name} className="mb-lg">
                <div className="flex justify-between">
                  <QuestionTitle
                    prefix="1"
                    title="Type de revêtement ?"
                    tooltip="Faïence murale : La dépose de faïence peut abîmer le mur et nécessiter une reprise particulière."
                  />
                  <PrestationDelete onDelete={() => fields.remove(index)} />
                </div>
                <OptionCheckbox fields={fields} value={value} index={index} />
                <ChipListField
                  name={`${name}.typeDepose`}
                  renderingOptions={{
                    containerClassName: 'mb-md flex flex-wrap gap-xs',
                  }}
                  options={[
                    {
                      label: 'Faïence murale',
                      value: TypeRevetement.FaienceMurale,
                    },
                    {
                      label: 'Papier peint',
                      value: TypeRevetement.PapierPeint,
                    },
                    {
                      label: 'Toile de verre',
                      value: TypeRevetement.ToileDeVerre,
                    },
                    {
                      label: 'Crédence cuisine',
                      value: TypeRevetement.CredenceCuisine,
                    },
                  ]}
                />
                {value.typeDepose && value.typeDepose !== TypeRevetement.CredenceCuisine && (
                  <DeposeMursLocation name={name} configuration={configuration} />
                )}
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
