import React from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

import { useUpdateProCompany } from 'features/Entreprises/features/Entreprise/api/useUpdateProCompany';

type Props = {
  slug: string;
};

export const PaymentToCreate: React.FC<Props> = ({ slug }) => {
  const { createWallet, isCreatingWallet } = useUpdateProCompany();
  return (
    <>
      <h3 className="!text-center !mb-lg">
        Vérifiez que les champs ci-dessus sont correctement remplis
      </h3>
      <div className="!text-center">
        <Button
          type="button"
          disabled={isCreatingWallet}
          disabledMessageTooltip="Compte lemonway en cours de création"
          onClick={() => createWallet({ slug })}
        >
          Créer le compte Lemonway
        </Button>
      </div>
    </>
  );
};
