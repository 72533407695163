import { Action, Subject, Flag } from './types';

import { AbilityBuilder } from '../AbilityBuilder';

const tpExterneBuilder = new AbilityBuilder<Action, Subject, Flag>();

tpExterneBuilder.can('validateAppelDePaiement', 'Chantier');
tpExterneBuilder.can('open', 'Chantier');
tpExterneBuilder.can('close', 'Chantier');

export const tpExterne = tpExterneBuilder.build();
