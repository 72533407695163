import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request, isForbidden } from '@travauxlib/shared/src/utils/request';

export const CHANTIER_KEY = 'chantier';

export type UpdateChantierThresholdsPayload = {
  slug: string;
  leveeReservesThreshold?: number;
  receptionChantierThreshold?: number;
};

const handleUpdateChantierThresholds = async (
  payload: UpdateChantierThresholdsPayload,
): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${payload.slug}/thresholds`;

  await request(requestURL, {
    method: 'PATCH',
    body: payload,
  });
};

export const useUpdateChantierThresholds = (): {
  updateChantierThresholds: typeof handleUpdateChantierThresholds;
} => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateChantierThresholds } = useMutation({
    mutationFn: handleUpdateChantierThresholds,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CHANTIER_KEY] });
      toast.success('Les seuils du chantier ont bien été mis à jour');
    },
    onError: err => {
      if (isForbidden(err)) {
        toast.error("Impossible de baisser ou d'ajouter un seuil déjà atteint");
      } else {
        toast.error(`Une erreur est survenue lors de la mise à jour des seuils`);
      }
    },
  });

  return {
    updateChantierThresholds,
  };
};
