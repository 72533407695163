import React from 'react';

import { CounterField } from '@travauxlib/shared/src/components/DesignSystem/components/Counter/Field';
import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { getLocationLabel } from '@travauxlib/shared/src/features/Configurateur/utils/getLocationLabel';
import { locationsPicto } from '@travauxlib/shared/src/features/DevisDisplay/components/LocalisationIcon';
import { Location } from '@travauxlib/shared/src/types';

import { LocationCard } from 'features/Configurateur/components/LocationCard';

type Props = {
  configuration: Configuration;
  locations: Location[];
  getFields: (locationUuid: string) => {
    label: string;
    name: string;
  }[];
};

export const LocationsCounters: React.FC<Props> = ({ configuration, locations, getFields }) => (
  <>
    {locations.map((location, index) => {
      const Picto = locationsPicto[location.typeLocation];
      return (
        <LocationCard
          key={location.uuid}
          className="mb-md"
          label={getLocationLabel(configuration, location)}
          centerIcon={<Picto />}
          inline={false}
          separator={index !== configuration.locations.length - 1}
        >
          <div className="flex flex-wrap text-left">
            {getFields(location.uuid).map(({ label, name }) => (
              <div
                key={label}
                className="w-1/2 odd:pr-sm even:pl-sm flex items-center mb-md justify-between"
              >
                <div className="text-neutral-600 shrink-1 text-b2 font-medium mb-xxs mr-md">
                  {label}
                </div>
                <CounterField name={name} id={name} />
              </div>
            ))}
          </div>
        </LocationCard>
      );
    })}
  </>
);
