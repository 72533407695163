import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';

import { Person, TeamsLabel } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { AUTH_KEY } from 'api/useAuth';
import { Team } from 'types';

type RequestResult = {
  data: Team[];
};

const fetchTeams = (): Promise<RequestResult> =>
  request(`${APP_CONFIG.apiURL}/admin/employees/teams`);

const handleChangeMembership = async (payload: {
  teamUuid: string;
  personUuid: string;
  add: boolean;
}): Promise<void> => {
  const { teamUuid, personUuid, add } = payload;
  const requestURL = `${APP_CONFIG.apiURL}/admin/employees/teams/${teamUuid}/employees/${personUuid}`;
  await request(requestURL, {
    method: add ? 'POST' : 'DELETE',
  });
};

export const useTeams = (): {
  teams: Team[];
  changeMembership: typeof handleChangeMembership;
  isLoading: boolean;
} => {
  const { data, isLoading } = useQuery<RequestResult>({ queryKey: ['teams'], queryFn: fetchTeams });
  const teams = data?.data;

  const queryClient = useQueryClient();
  const { mutateAsync: changeMembership } = useMutation({
    mutationFn: handleChangeMembership,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teams'] });
      queryClient.invalidateQueries({ queryKey: [AUTH_KEY] });
    },
  });

  return { teams: teams || [], changeMembership, isLoading };
};

export const useTeamMembers = (
  teamLabels: TeamsLabel[],
  withArchived?: boolean,
): { teamMembers: Person[]; isLoading: boolean } => {
  const { teams, isLoading } = useTeams();
  const selectedTeams = teams.filter(team => teamLabels.includes(team.label));

  if (selectedTeams.length === 0) {
    return { teamMembers: [], isLoading };
  }

  const allMembers = selectedTeams.flatMap(team => team.members);
  const filteredMembers = withArchived ? allMembers : allMembers.filter(member => !member.archived);

  return {
    teamMembers: filteredMembers.sort((memberA, memberB) =>
      memberA.firstName > memberB.firstName ? 1 : -1,
    ),
    isLoading,
  };
};

export const useSalesTeamMembers = (): { teamMembers: Person[]; isLoading: boolean } =>
  useTeamMembers([TeamsLabel.Ops, TeamsLabel.CDPL]);
