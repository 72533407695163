import React, { useEffect } from 'react';

import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { DropdownOption } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Option';

import { PartnerAccount, PartnerAgency, PartnerOrganization } from 'types';
import { NavigationTracking } from 'utils/tracking/NavigationTracking';

import { PartnersTabs } from './PartnersTabs';

import { usePartners } from '../api/usePartners';

export const PartnersListContainer: React.FC<{
  component: React.FC<{
    partners: PartnerOrganization[];
    isLoading: boolean;
    agencies: PartnerAgency[];
    accounts: PartnerAccount[];
    accountsByAgencyUUID: { [k: string]: PartnerAccount[] };
    agenciesGrouppedByUuid: { [k: string]: PartnerAgency };
    organisationsByUuid: { [k: string]: PartnerOrganization };
    orgNameOptions: DropdownOption<string>[];
    iacOptions: DropdownOption<string | undefined>[];
  }>;
}> = ({ component }) => {
  useEffect(() => {
    NavigationTracking.onPartenairesViewed();
  }, []);

  const { partners, agencies, accounts, isLoading } = usePartners();

  const Component = component;

  const accountsByAgencyUUID = React.useMemo(() => groupBy(accounts, 'agencyUuid'), [accounts]);

  const agenciesGrouppedByUuid = React.useMemo(
    () => mapValues(groupBy(agencies, 'uuid'), v => v[0]),
    [agencies],
  );

  const organisationsByUuid = React.useMemo(
    () => mapValues(groupBy(partners, 'uuid'), v => v[0]),
    [partners],
  );

  const orgNameOptions = React.useMemo(
    () =>
      uniqBy(
        partners.map(p => ({ label: p.name, value: p.name })),
        'label',
      ),
    [partners],
  );

  const iacOptions = React.useMemo(() => {
    const partnersAccountManagers = partners.map(p => p.internalAccountManager);
    const agenciesAccountManagers = partners
      .flatMap(p => p.agencies)
      .map(a => a.internalAccountManager);

    return uniq(partnersAccountManagers.concat(agenciesAccountManagers))
      .sort((a = '', b = '') => a.localeCompare(b))
      .map(v => ({
        label: v ?? 'Aucun',
        value: v,
      }));
  }, [partners]);

  return (
    <>
      <PartnersTabs />
      <div className="pt-lg">
        <Card>
          <Component
            partners={partners}
            isLoading={isLoading}
            agencies={agencies}
            accounts={accounts}
            accountsByAgencyUUID={accountsByAgencyUUID}
            agenciesGrouppedByUuid={agenciesGrouppedByUuid}
            organisationsByUuid={organisationsByUuid}
            orgNameOptions={orgNameOptions}
            iacOptions={iacOptions}
          />
        </Card>
      </div>
    </>
  );
};
