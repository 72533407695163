import { request } from '@travauxlib/shared/src/utils/request';

import { useChantierQuery } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';

const handleValidateAppelDePaiement = async (payload: {
  chantierSlug: string;
  uuid: string;
}): Promise<void> => {
  const { chantierSlug, uuid } = payload;
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${chantierSlug}/appels-de-paiement/${uuid}/validate`;
  await request(requestURL, { method: 'POST' });
};

export const useValidateAppelDePaiement = (): {
  isLoading: boolean;
  validateAppelDePaiement: typeof handleValidateAppelDePaiement;
} => {
  const { isLoading, query } = useChantierQuery(
    handleValidateAppelDePaiement,
    "de la validation de l'appel de paiement",
    "L'appel de paiement a bien été validé.",
  );
  return { isLoading, validateAppelDePaiement: query };
};
