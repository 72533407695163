import { ProRegistrationAdminView } from '@travauxlib/shared/src/types/api/admin/ProRegistrationAdminView';

import { BaseStep, ExecuteParams } from '../BaseStep';

const fields = [
  {
    name: 'createNewCompany',
    fieldType: 'checkbox',
    initialValue: true,
  },
  {
    name: 'proCompanyEmail',
    hide: ({ createNewCompany }: { createNewCompany: boolean }) => createNewCompany,
  },
  {
    name: 'proCompanyName',
    hide: ({ createNewCompany }: { createNewCompany: boolean }) => !createNewCompany,
  },
  {
    name: 'proCompanyType',
    helperText: 'pro ou architecte',
    hide: ({ createNewCompany }: { createNewCompany: boolean }) => !createNewCompany,
  },
] as const;

export class CreateProLead extends BaseStep<
  {},
  { proLead: ProRegistrationAdminView },
  typeof fields,
  { proLead: ProRegistrationAdminView }
> {
  public name: string = 'Create Pro Lead';

  public fields = fields;

  async execute(
    params: ExecuteParams<{}, typeof fields>,
  ): Promise<{ proLead: ProRegistrationAdminView } | undefined> {
    const { proCompanyEmail, apiURL, proCompanyType, proCompanyName, seed, request } = params;

    if (proCompanyEmail) {
      super.log(`Skipped! proCompanyEmail chosen`);
      return;
    }

    const createdProLead = await request<ProRegistrationAdminView>(
      `${apiURL}/admin/pro-registrations`,
      {
        method: 'POST',
        body: {
          siret: '81377641600022',
          statutEntreprise: 'SARL',
          companyType: proCompanyType || 'pro',
          chiffreAffairesDeclare: '100_400K',
          firstName: 'Jean',
          lastName: 'Miche',
          email: `${seed}@pro.example.hemea.com`,
          phoneNumber: '0235353535',
          source: 'acces_direct',
          godModeOverrideName: proCompanyName,
          password: 'azerty',
        },
      },
    );

    super.log(`Created pro lead email : ${createdProLead.email}`);

    super.setCreatedEntities({ proLead: createdProLead });

    return { ...params, proLead: createdProLead };
  }
}
