import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { Project } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { CONFIGURATEUR_CONFIGURATION_KEY } from 'features/Configurateur/api/useConfiguration';

import { PROJECT_KEY } from './useProjectContext';

const handleUpdateProject = async (project: Project): Promise<Project> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/projects/${project.uuid}`;
  await request(requestURL, {
    method: 'PUT',
    body: project,
  });
  return project;
};

export const useUpdateProject = (): typeof handleUpdateProject => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateProject } = useMutation({
    mutationFn: handleUpdateProject,
    onSuccess: ({ uuid }) => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY, uuid] });
      queryClient.invalidateQueries({ queryKey: [CONFIGURATEUR_CONFIGURATION_KEY, uuid] });
    },
    onError: () => {
      toast.error("Le project n'a pas pu être mis à jour");
    },
  });
  return updateProject;
};
