import { Action, Subject, Flag } from './types';

import { AbilityBuilder } from '../AbilityBuilder';

const adminBuilder = new AbilityBuilder<Action, Subject, Flag>();

adminBuilder.can('manage', 'Company');

adminBuilder.can('manage', 'Person');

adminBuilder.can('cancelSignature', 'Chantier');
adminBuilder.can('cancelAppelDePaiement', 'Chantier');
adminBuilder.can('changeAmountAppelDePaiement', 'Chantier');
adminBuilder.can('changeCommission', 'Chantier');
adminBuilder.can('changeInternalAffectation', 'Chantier');
adminBuilder.can('close', 'Chantier');
adminBuilder.can('collectServiceHemea', 'Chantier');
adminBuilder.can('ignoreTransaction', 'Chantier');
adminBuilder.can('open', 'Chantier');
adminBuilder.can('toggleRelance', 'Chantier');
adminBuilder.can('useAdvancedActions', 'Chantier');
adminBuilder.can('validateAppelDePaiement', 'Chantier');
adminBuilder.can('manage', 'Facturation');

adminBuilder.can('manage', 'Lead');
adminBuilder.can('manageAttributionRules', 'Lead');
adminBuilder.can('manageAttribution', 'Lead');

export const admin = adminBuilder.build();
