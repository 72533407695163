import {
  Chauffage,
  EauChaude,
  Plomberie,
} from '@travauxlib/shared/src/features/Configurateur/types/Plomberie';

const MESSAGE_CHAUFFAGE_INCOMPLETE = "Attention, n'oubliez pas de compléter la question chauffage";
const MESSAGE_NON_MATCHING_CHAUFFAGE = 'Attention, l’eau chaude renseignée ne correspond pas';
const MESSAGE_NON_MATCHING_EAU_CHAUDE = 'Attention, le chauffage renseigné ne correspond pas';

const getEauChaudeDependanteDuChauffage = (eauChaude: EauChaude): boolean =>
  eauChaude?.typeModifElectrique === 'Gaz' ||
  eauChaude?.typeModifElectrique === 'PAC' ||
  eauChaude?.typeModifAucune === 'Gaz' ||
  eauChaude?.typeModifAucune === 'PAC';

const isChauffageMissing = (chauffage: Chauffage, eauChaude: EauChaude): boolean => {
  const chauffageMalRenseignee =
    !chauffage?.typeEnergie || chauffage?.typeEnergie === 'Ne sait pas';

  return chauffageMalRenseignee && getEauChaudeDependanteDuChauffage(eauChaude);
};

const isChauffageNonMatching = (chauffage: Chauffage, eauChaude: EauChaude): boolean => {
  const isTypeEnergieEauAucuneOrElectrique =
    eauChaude?.typeEnergie === 'Aucune' || eauChaude?.typeEnergie === 'Électrique';

  const isTypeEnergieEauGazOrFioul =
    eauChaude?.typeEnergie === 'Gaz' || eauChaude?.typeEnergie === 'Fioul';

  const typeEnergieChauffageFioulGazAucune =
    chauffage?.typeEnergie === 'Fioul' ||
    chauffage?.typeEnergie === 'Gaz' ||
    chauffage?.typeEnergie === 'Aucune';

  const eauTypeEnergieAll = isTypeEnergieEauAucuneOrElectrique || isTypeEnergieEauGazOrFioul;

  const typeModifChauffageForElectrique =
    chauffage?.typeModifGaz === 'Electrique' ||
    chauffage?.typeModifFioul === 'Electrique' ||
    chauffage?.typeInstallation === 'Electrique';

  const typeModifChauffageForGaz =
    chauffage?.typeModifGaz === 'Gaz' ||
    chauffage?.typeModifFioul === 'Gaz' ||
    chauffage?.typeInstallation === 'Gaz';

  const typeModifChauffageForPac =
    chauffage?.typeModifGaz === 'PAC' ||
    chauffage?.typeModifFioul === 'PAC' ||
    chauffage?.typeInstallation === 'PAC';

  if (eauChaude && chauffage && typeEnergieChauffageFioulGazAucune) {
    const typeModifEauChaude =
      eauChaude.typeModifGaz ||
      eauChaude.typeModifElectrique ||
      eauChaude.typeModifFioul ||
      eauChaude.typeModifAucune;

    if (typeModifChauffageForGaz && eauTypeEnergieAll && typeModifEauChaude === 'PAC') {
      return true;
    }

    if (typeModifChauffageForPac && eauTypeEnergieAll && typeModifEauChaude === 'Gaz') {
      return true;
    }

    if (
      typeModifChauffageForElectrique &&
      isTypeEnergieEauAucuneOrElectrique &&
      getEauChaudeDependanteDuChauffage(eauChaude)
    ) {
      return true;
    }
  }
  return false;
};

export const getAlertForTypeModifChauffage = (
  chauffage: Chauffage,
  values: Plomberie,
): string | undefined => {
  if (chauffage.isOption) {
    return undefined;
  }
  const eauChaude = values.eauChaudes?.find(e => !e.isOption);
  const shouldShowMessage = eauChaude && isChauffageMissing(chauffage, eauChaude);
  return shouldShowMessage ? MESSAGE_NON_MATCHING_CHAUFFAGE : undefined;
};

export const getAlertForTypeModifEauChaude = (
  eauChaude: EauChaude,
  values: Plomberie,
): string | undefined => {
  if (eauChaude.isOption) {
    return undefined;
  }
  const chauffage = values.chauffages?.find(c => !c.isOption);
  if (!chauffage) {
    return undefined;
  }
  if (isChauffageMissing(chauffage, eauChaude)) {
    return MESSAGE_CHAUFFAGE_INCOMPLETE;
  } else if (isChauffageNonMatching(chauffage, eauChaude)) {
    return MESSAGE_NON_MATCHING_EAU_CHAUDE;
  }
};
