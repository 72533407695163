import React from 'react';

import { Form } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

import { useCreateClientAccount } from 'features/ClientAccounts/api/useCreateClientAccount';
import { CreateClientAccountFormFields } from 'features/ClientAccounts/components/CreateClientAccountFormFields';

type FormProps = {
  firstName: string;
  lastName: string;
  email: string;
};

type Props = {
  onValidate?: (createdClientAccountUuid: string | undefined) => void;
  onClose: () => void;
  displayForm: boolean;
  setDisplayForm: (displayForm: boolean) => void;
};

export const CreateClientAccountForm: React.FC<Props> = ({
  onValidate,
  onClose,
  displayForm,
  setDisplayForm,
}) => {
  const createAccount = useCreateClientAccount();
  const onSubmit = async (values: FormProps): Promise<void> => {
    const createdAccount = await createAccount(values);
    onValidate?.(createdAccount?.uuid);
    onClose();
  };

  return (
    <Form<FormProps> onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <>
          {displayForm ? (
            <form
              onSubmit={e => {
                handleSubmit(e);
              }}
            >
              <div className="text-h4 mb-sm font-bold">Compte client à rattacher</div>
              <CreateClientAccountFormFields />
              <div className="flex justify-end mt-md gap-md">
                <Button variant="secondary" onClick={() => setDisplayForm(false)}>
                  Annuler
                </Button>
                <Button type="submit">Valider</Button>
              </div>
            </form>
          ) : (
            <Button onClick={() => setDisplayForm(true)}>Créer un nouveau compte client</Button>
          )}
        </>
      )}
    </Form>
  );
};
