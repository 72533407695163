import { useMemo } from 'react';

import { ProCompany } from 'types';

export const simpleAndFilter =
  (fieldName: keyof ProCompany) => (values: string[]) => (proCompany: ProCompany) => {
    const proCompanyValue = (proCompany[fieldName] || []) as string[];
    return values.every(value => proCompanyValue.includes(value));
  };

export const simpleOrFilter =
  (fieldName: keyof ProCompany) => (values: string[]) => (proCompany: ProCompany) => {
    const proCompanyValue: string[] = (proCompany[fieldName] || []) as string[];
    return values.length === 0 || values.some(value => proCompanyValue.includes(value));
  };

const filters = {
  tailleChantier: (tailleChantier: number) => (proCompany: ProCompany) =>
    tailleChantier >= (proCompany.tailleChantierMin || 0) &&
    tailleChantier <= (proCompany.tailleChantierMax || Number.MAX_SAFE_INTEGER),
  departements: simpleOrFilter('departements'),
  certifications: simpleAndFilter('certifications'),
  travailEnInterne: simpleAndFilter('travailEnInterne'),
  kyc2: (kyc2: string) => (proCompany: ProCompany) =>
    kyc2 === 'indifferent' ||
    (kyc2 === 'oui' && proCompany.isKycCompliant) ||
    (kyc2 === 'non' && !proCompany.isKycCompliant),
  corpsEtat: (corpsEtat: string[]) => (proCompany: ProCompany) =>
    simpleAndFilter('corpsEtat')(corpsEtat)(proCompany),
  specializations: (specializations: string[]) => (proCompany: ProCompany) =>
    simpleAndFilter('specializations')(specializations)(proCompany),
  gammes: simpleOrFilter('gammes'),
  profile: simpleAndFilter('profile'),
  additionalInformations: simpleAndFilter('additionalInformations'),
  architectAccreditations: simpleOrFilter('architectAccreditations'),
  acceptsProjects: (acceptsProjects: string) => (proCompany: ProCompany) =>
    acceptsProjects === 'indifferent' ||
    (acceptsProjects === 'barnes' && proCompany.acceptsBarnesProjects) ||
    (acceptsProjects === 'hemea' && proCompany.acceptsHemeaProjects),
  enPause: (enPause: string) => (proCompany: ProCompany) =>
    enPause === 'indifferent' ||
    (enPause === 'oui' && proCompany.pauseReason) ||
    (enPause === 'non' && !proCompany.pauseReason),
  validAssurance: (validAssurance: string) => (proCompany: ProCompany) =>
    validAssurance === 'indifferent' ||
    (validAssurance === 'oui' && proCompany.hasValidInsurances) ||
    (validAssurance === 'non' && !proCompany.hasValidInsurances),
  proProfileFlags: simpleAndFilter('proProfileFlags'),
};

export const applyFilters = (proCompanies: ProCompany[], filterCriteria: object): ProCompany[] =>
  Object.keys(filters).reduce((acc, filterKey) => {
    const filterValue = (filterCriteria as any)[filterKey];
    if ((filterValue && filterValue.length !== 0) || typeof filterValue === 'boolean') {
      const filter = (filters as any)[filterKey];
      return acc.filter(filter(filterValue));
    }
    return acc;
  }, proCompanies);

export function useFilterAndSortResults(
  proCompanies: ProCompany[],
  filterCriteria: object,
): ProCompany[] {
  const result = useMemo(
    () => applyFilters(proCompanies, filterCriteria),
    [proCompanies, filterCriteria],
  );

  return filterCriteria ? result : proCompanies;
}
