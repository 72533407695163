import { request } from '@travauxlib/shared/src/utils/request';

import { useChantierQuery } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';

export enum TypePrestationEncaissement {
  TravauxPlanner = 'travaux_planner',
  ArchiInterne = 'archi_interne',
  ServiceHemea = 'service_hemea',
}

const handleEncaisserPrestationInterne = async (payload: {
  slug: string;
  typePrestation: TypePrestationEncaissement;
  montantAEncaisser: number;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${payload.slug}/encaisser-prestation-interne`;

  await request(requestURL, {
    method: 'POST',
    body: JSON.stringify({
      typePrestation: payload.typePrestation,
      montantAEncaisser: payload.montantAEncaisser,
    }),
  });
};

export const useEncaisserPrestationInterne = (): {
  encaisserPrestationInterne: typeof handleEncaisserPrestationInterne;
} => {
  const { query } = useChantierQuery(
    handleEncaisserPrestationInterne,
    "de l'encaissement de la prestation",
    "L'encaissement a bien été effectué.",
  );

  return { encaisserPrestationInterne: query };
};
