import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import {
  ChipListField,
  ChipPictoVerticalListField,
} from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { PorteEntree } from '@travauxlib/shared/src/features/Configurateur/types/MenuiseriesExterieures';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';
import { getLocationOptions } from 'features/Configurateur/utils/getLocationOptions';

type Props = {
  configuration: Configuration;
};

const Locations: React.FC<{ configuration: Configuration; name: string }> = ({
  configuration,
  name,
}) => {
  const locationOptions = getLocationOptions(configuration);
  return (
    <>
      <QuestionTitle prefix="2" title="Pièce concernée ?" />

      <ChipPictoVerticalListField
        className="flex flex-wrap gap-sm mb-md"
        wrapperClassName="w-[7.75rem] h-[7.75rem]"
        size="xmd"
        options={locationOptions}
        name={`${name}.locationUuid`}
      />
    </>
  );
};

export const PorteEntrees: React.FC<Props> = ({ configuration }) => (
  <Accordion
    className="mb-md"
    title="Porte d'entrée à rénover ou remplacer ?"
    prefix="1"
    defaultExpanded
  >
    <FieldArray<PorteEntree> name="porteEntrees">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={name}>
                <div className="flex justify-between">
                  <QuestionTitle
                    prefix="1"
                    title="Type de prestation ?"
                    tooltip="Le blindage consiste à mettre une serrure 3 points et un système anti-pince au niveau du cadre de la porte."
                  />
                  <PrestationDelete onDelete={() => fields.remove(index)} />
                </div>
                <OptionCheckbox fields={fields} value={value} index={index} />
                <ChipListField
                  name={`${name}.typePrestation`}
                  renderingOptions={{
                    containerClassName: 'mb-md flex flex-wrap gap-xs',
                  }}
                  options={[
                    {
                      label: 'Remplacement',
                      value: 'Remplacement',
                    },
                    {
                      label: 'Blindage',
                      value: 'Blindage',
                    },
                  ]}
                />
                {value.typePrestation && <Locations configuration={configuration} name={name} />}
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
