import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { ChipMultipleListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import {
  Remplacement,
  TypeRemplacement,
} from '@travauxlib/shared/src/features/Configurateur/types/Cuisine';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

export const Elements: React.FC = () => (
  <Accordion
    className="mb-md"
    title="Éléments individuels de cuisine à remplacer ?"
    prefix="2"
    defaultExpanded
  >
    <FieldArray<Remplacement> name="remplacements">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={name}>
                <div className="flex justify-between">
                  <QuestionTitle
                    prefix="1"
                    title="Type d'élément à remplacer ?"
                    tooltip={
                      <div>
                        Type de plan de travail selon les gammes :
                        <br />
                        Primo & Confort : Stratifié
                        <br />
                        Premium : Marbre
                        <br />
                        Exclusif : Céramique
                      </div>
                    }
                  />
                  <PrestationDelete onDelete={() => fields.remove(index)} />
                </div>
                <OptionCheckbox fields={fields} value={value} index={index} />
                <ChipMultipleListField
                  renderingOptions={{
                    containerClassName: 'mb-md flex flex-wrap gap-xs',
                  }}
                  options={[
                    {
                      label: 'Plan de travail',
                      value: TypeRemplacement.PlanDeTravail,
                    },
                    {
                      label: 'Évier',
                      value: TypeRemplacement.Evier,
                    },
                  ]}
                  name={`${name}.typeRemplacements`}
                />
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
