import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';

import { TeamsLabel } from '@travauxlib/shared/src/types';

import { useTeamMembers } from 'features/Trombinoscope/api/useTeams';

const defaultOptions = [
  { label: 'Tous', value: 'tous' },
  { label: 'Aucun', value: 'aucun' },
];

export const useCustomerCareAndTravauxPlannerMembers = (): { value: string; label: string }[] => {
  const rawEmployeeOptions = useTeamMembers([
    TeamsLabel.TravauxPlanner,
    TeamsLabel.CustomerCare,
  ]).teamMembers.map(cc => ({
    label: `${cc.firstName} ${cc.lastName}`,
    value: cc.uuid,
  }));
  const employeeOptions = _sortBy(_uniqBy(rawEmployeeOptions, 'value'), 'label');

  return [...defaultOptions, ...employeeOptions];
};
