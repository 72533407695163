import React from 'react';

import { ProCompany } from 'types';

import { Ligne } from './Ligne';

type Props = {
  proCompanies: ProCompany[];
  ActionsComponent?: React.ComponentType<{ proCompany: ProCompany }>;
};

export const TableCompanyDescription: React.FC<Props> = ({
  proCompanies,
  ActionsComponent,
  ...rest
}) => (
  <div className="overflow-x-scroll">
    <table className="table block w-full max-h-[500px] table-bordered table-striped">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Assurée pour</th>
          <th>N&apos;envoyer que sur</th>
          <th>Réalisés en interne</th>
          <th>Départements</th>
          <th>Evaluation / Commentaire</th>
          <th>Validité assurance</th>
          <th>Compte</th>
        </tr>
      </thead>
      <tbody>
        {proCompanies.map(proCompany => (
          <Ligne
            key={proCompany.slug}
            proCompany={proCompany}
            ActionsComponent={ActionsComponent}
            {...rest}
          />
        ))}
      </tbody>
    </table>
  </div>
);
