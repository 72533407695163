import React, { useState } from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { DropdownOption } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Option';
import { DropdownMulti } from '@travauxlib/shared/src/components/DesignSystem/components/DropdownMulti';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { ColumnConfig } from '@travauxlib/shared/src/components/DesignSystem/components/Table/types';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { useAuth } from 'api/useAuth';
import { PageTitle, PageTitleEnum } from 'components/PageTitle';
import { PartnerOrganization } from 'types';

import { FiltersContainer } from './FiltersContainer';

import { NewPartnerModal } from '../NewPartnerModal';

const columns: ColumnConfig<PartnerOrganization>[] = [
  {
    name: 'Nom',
    columnKey: 'name',
  },
  {
    name: 'Logo',
    columnKey: 'logoUrl',
    renderValue: (value: string | undefined) =>
      value ? <img src={value} alt="logo" loading="lazy" className="max-h-[1.5rem]" /> : <></>,
  },
  {
    name: 'Account Manager',
    columnKey: 'internalAccountManager',
  },
  {
    name: "Nombre d'agences",
    hideSort: true,
    renderValue: (_: unknown, entity: PartnerOrganization) => <>{entity.agencies.length}</>,
  },
  {
    name: 'Crée le',
    columnKey: 'createdAt',
    renderValue: (d: string) => <>{formatFrenchDate(d)}</>,
  },
];

type Props = {
  partners: PartnerOrganization[];
  orgNameOptions: DropdownOption<string>[];
  iacOptions: DropdownOption<string | undefined>[];
  isLoading: boolean;
};

export const PartnerListing: React.FC<Props> = ({
  partners,
  isLoading,
  orgNameOptions,
  iacOptions,
}) => {
  const {
    adminUserProfile: { hasPermission },
  } = useAuth();
  const [nameFilter, setNameFilter] = useState<string[]>([]);
  const [iacFilter, setIacFilter] = useState<(string | undefined)[]>([]);
  const [activeFilter, setActiveFilter] = useState<boolean>(true);
  const [isNewPartnerModalOpen, setIsNewPartnerModalOpen] = useState<boolean>(false);
  const [renovEnergetiqueFilter, setRenovEnergetiqueFilter] = useState<boolean>(false);

  const filteredPartners: PartnerOrganization[] = React.useMemo(
    () =>
      partners.length > 0
        ? partners.filter(
            p =>
              (activeFilter === undefined ? true : activeFilter !== p.isArchived) &&
              (nameFilter.length ? nameFilter.includes(p.name) : true) &&
              (iacFilter.length ? iacFilter.includes(p.internalAccountManager) : true) &&
              (renovEnergetiqueFilter === false ? true : p.isRenovationEnergetique),
          )
        : [],
    [partners, nameFilter, iacFilter, activeFilter, renovEnergetiqueFilter],
  );

  if (isLoading) {
    return <LoaderWrapper />;
  }

  return (
    <>
      <NewPartnerModal
        isOpen={isNewPartnerModalOpen}
        closeModal={() => setIsNewPartnerModalOpen(false)}
      />
      <PageTitle title={PageTitleEnum.partners} />
      <FiltersContainer>
        <DropdownMulti<string>
          id="nameFilter"
          options={orgNameOptions}
          onChange={setNameFilter}
          value={nameFilter}
          label="Nom"
          name="Nom"
        />
        <DropdownMulti<string | undefined>
          id="iacFilter"
          options={iacOptions}
          onChange={setIacFilter}
          value={iacFilter}
          label="Account manager"
        />
        <Checkbox
          indeterminate={activeFilter === undefined}
          checked={!!activeFilter}
          onChange={setActiveFilter}
          label="Actif ?"
          name="actif"
          className="w-fit"
        />
        <Checkbox
          checked={renovEnergetiqueFilter}
          label="Partenaire Rénovation Énergétique"
          onChange={setRenovEnergetiqueFilter}
        />
        <Button
          size="sm"
          onClick={() => setIsNewPartnerModalOpen(true)}
          className="w-fit"
          data-testid="add-partner"
          disabledTriggerClassName="w-fit"
          disabled={!hasPermission('CREATE_PARTNER_ORGANIZATION')}
          disabledMessageTooltip="Vous n'avez pas la permission pour effectuer cette action"
        >
          Nouvelle organisation
        </Button>
      </FiltersContainer>
      <Table<PartnerOrganization>
        columnConfigs={columns}
        items={filteredPartners}
        defaultSort={{ columnKey: 'name', order: 'asc' }}
        itemsPerPage={50}
        onRowClick={({ uuid }) => window.open(`/partners/${uuid}`)}
      />
    </>
  );
};
