import { LeadAdminView } from '@travauxlib/shared/src/types/api/admin/LeadAdminView';

import { BaseStep, ExecuteParams } from '../BaseStep';

type Input = { email?: string };

export class SearchLeadByEmail extends BaseStep<Input, { lead?: LeadAdminView }> {
  public name: string = 'Search lead by email';

  public fields = [];

  async execute({
    email,
    apiURL,
    request,
  }: ExecuteParams<Input>): Promise<{ lead: LeadAdminView } | undefined> {
    if (!email) {
      super.error('The email was undefined. Cannot search lead');
      super.error("Avez vous essayé de vous deconnecter de l'espace client ? (bug onboarding)");
      return Promise.reject();
    }
    const leadsFound = await request<{ data: LeadAdminView[] }>(
      `${apiURL}/admin/leads/client-search?email=${email}`,
      {},
    );

    super.log(
      `Leads found (I will pick the first): \n ${leadsFound.data
        .map((lead: LeadAdminView) => lead.email)
        .join('\n')}`,
    );

    if (leadsFound?.data.length === 0) {
      super.error('No lead was found with this email');
      super.error('Es-tu dans la team CDPL ? Si oui, sors toi de cette team!');
    }

    return { lead: leadsFound?.data[0] };
  }
}
