import React from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { useNavigate, Location } from 'react-router-dom';

import ArrowLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ArrowLeft.svg?react';
import ArrowRight from '@travauxlib/shared/src/components/DesignSystem/assets/ArrowRight.svg?react';
import { ButtonGroup } from '@travauxlib/shared/src/components/DesignSystem/components/ButtonGroup';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { DatePicker } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { formatLocalDate } from '@travauxlib/shared/src/utils/time';
import { stringifyParams, getUrlParams } from '@travauxlib/shared/src/utils/urls';

import { affiliateFilters } from 'utils/constants';

type SearchParameters = {
  startDate?: string;
  endDate?: string;
  affiliateFilter: string;
};

type OwnProps = SearchParameters & {
  location: Location;
  bottomSwitcher?: boolean;
};

type DispatchProps = {
  previousDay: (startDate?: string, endDate?: string) => void;
  nextDay: (startDate?: string, endDate?: string) => void;
  newDays: (startDate?: string, endDate?: string) => void;
  setAffiliateFilter: (affiliateFilter: string) => void;
  isTabletOrMobile: boolean;
};

type Props = OwnProps & DispatchProps;

type State = {
  startDate?: string;
  endDate?: string;
};

export class DaySwitcherComponent extends React.Component<Props, State> {
  state = {
    startDate: this.props.startDate,
    endDate: this.props.endDate,
  };

  componentDidUpdate = (prevProps: Props): void => {
    const { startDate, endDate } = this.props;
    if (startDate !== prevProps.startDate || endDate !== prevProps.endDate) {
      this.setState({
        startDate,
        endDate,
      });
    }
  };

  updateStartDate = (startDate: Dayjs): void => {
    this.setState({
      startDate: formatLocalDate(startDate),
    });
  };

  updateEndDate = (endDate: Dayjs): void => {
    this.setState({
      endDate: formatLocalDate(endDate),
    });
  };

  handleSubmit = (event: React.FormEvent): void => {
    this.props.newDays(this.state.startDate, this.state.endDate);
    event.preventDefault();
  };

  handleSelectFilter = (newFilter?: string): void => {
    if (newFilter !== undefined) {
      this.props.setAffiliateFilter(newFilter);
    }
  };

  render(): JSX.Element {
    const { previousDay, nextDay, affiliateFilter, bottomSwitcher, isTabletOrMobile } = this.props;

    const { startDate, endDate } = this.state;

    const ids = bottomSwitcher
      ? {
          start: 'date-debut-bottom',
          end: 'date-fin-bottom',
        }
      : {
          start: 'date-debut',
          end: 'date-fin',
        };

    return (
      <form
        data-testid="submit"
        className="flex items-center !flex-wrap !mb-sm -mx-md"
        onSubmit={this.handleSubmit}
      >
        <div className="sm-desktop:flex">
          <DatePicker
            label="Date de début"
            className="px-md mb-xs sm-desktop:mb-0"
            id={ids.start}
            value={startDate ? dayjs(startDate) : undefined}
            onChange={this.updateStartDate}
          />
          <DatePicker
            className="px-md mb-xs sm-desktop:mb-0"
            label="Date de fin"
            id={ids.end}
            value={endDate ? dayjs(endDate) : undefined}
            onChange={this.updateEndDate}
          />
        </div>
        <div className="flex mb-xs sm-desktop:mb-0">
          <Button type="submit" className="ml-md mr-xs">
            Valider
          </Button>
          <Button
            variant="secondary"
            type="button"
            className="!mr-xs"
            onClick={() => previousDay(startDate, endDate)}
            leftIcon={<ArrowLeft data-testid="left" />}
          />
          <Button
            variant="secondary"
            type="button"
            onClick={() => nextDay(startDate, endDate)}
            leftIcon={<ArrowRight data-testid="right" />}
          />
        </div>
        <div className="ml-md sm-desktop:ml-sm">
          <ButtonGroup
            vertical={isTabletOrMobile}
            options={Object.values(affiliateFilters)}
            value={affiliateFilter}
            onChange={value => this.handleSelectFilter(value as string)}
          />
        </div>
      </form>
    );
  }
}

export function buildNewLocation(
  location: Location,
  query: SearchParameters,
): { pathname: string; search: string } {
  return {
    pathname: location.pathname,
    search: stringifyParams({
      ...getUrlParams(location),
      ...query,
    }),
  };
}

export function buildDatesFromOffset(
  startDate?: string,
  endDate?: string,
  offset: 0 | 1 | -1 = 0,
): { startDate: string | undefined; endDate: string | undefined } {
  const newStartDate = dayjs(startDate, 'YYYY-MM-DD', true).add(offset, 'day');
  const newEndDate = dayjs(endDate, 'YYYY-MM-DD', true).add(offset, 'day');
  return {
    startDate: formatLocalDate(newStartDate),
    endDate: formatLocalDate(newEndDate),
  };
}

// that's a bit dirty, but the refacto is awfully hard
export const DaySwitcher: React.FC<OwnProps> = props => {
  const navigate = useNavigate();
  const { startDate, endDate, affiliateFilter, location } = props;
  const isTabletOrMobile = useIsTabletOrMobile();

  const updateLocation = (oldLocation: Location, query: SearchParameters): void =>
    navigate(buildNewLocation(oldLocation, query));

  const updateLocationFromDateOffset = (
    startDate: string,
    endDate: string,
    offset: 0 | 1 | -1,
  ): void =>
    updateLocation(location, {
      ...buildDatesFromOffset(startDate, endDate, offset),
      affiliateFilter,
    });

  const extraProps = {
    previousDay: (startDate: string, endDate: string) =>
      updateLocationFromDateOffset(startDate, endDate, -1),
    nextDay: (startDate: string, endDate: string) =>
      updateLocationFromDateOffset(startDate, endDate, 1),
    newDays: (startDate: string, endDate: string) =>
      updateLocationFromDateOffset(startDate, endDate, 0),
    setAffiliateFilter: (affiliateFilter: string) => {
      updateLocation(location, {
        startDate,
        endDate,
        affiliateFilter,
      });
    },
    isTabletOrMobile,
  };
  return <DaySwitcherComponent {...props} {...extraProps} />;
};
