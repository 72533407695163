import { useMutation, useQueryClient } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from 'features/Project/api/useProjectContext';
import { ConsultationInfo } from 'types';

const handleUpdateConsultationInfo = (
  consultationInfo: Partial<ConsultationInfo>,
): Promise<ConsultationInfo> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/consultations/${consultationInfo.id}`;
  return request(requestURL, {
    method: 'PUT',
    body: JSON.stringify(consultationInfo),
  });
};

export const useUpdateConsultationInfo = (
  projectUuid: string,
): typeof handleUpdateConsultationInfo => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateConsultationInfo } = useMutation({
    mutationFn: handleUpdateConsultationInfo,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY, projectUuid] });
    },
  });

  return updateConsultationInfo;
};
