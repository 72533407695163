import React from 'react';

import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { DevisComment } from '@travauxlib/shared/src/types';

type Props = {
  comment: DevisComment;
  isDeleted: boolean;
};

export const CommentTag: React.FC<Props> = ({ comment, isDeleted }) => {
  if (!comment.validated && comment.senderType === 'pro' && !isDeleted) {
    return (
      <WithTooltip
        position="left"
        trigger={
          <span>
            <Tag variant="warning" size="sm" label="Nouveau" />
          </span>
        }
      >
        <div className="text-b2 font-normal text-neutral-0">
          L'entreprise a répondu à votre commentaire, vous pouvez lui répondre ou résoudre le
          commentaire.
        </div>
      </WithTooltip>
    );
  }
  return null;
};
