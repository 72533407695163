export enum CDPLFactureStatus {
  Draft = 'draft',
  Sent = 'sent',
  Paid = 'paid',
}

export type CDPLFacture = {
  createdAt: string;
  numero: string;
  designation: string;
  paidAt?: string;
  token: string;
  chantierTitle: string;
  chantierSlug: string;
  montantTTC: number;
  status: CDPLFactureStatus;
};
