import React from 'react';

import { DropdownMultiField } from '@travauxlib/shared/src/components/DesignSystem/components/DropdownMulti/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { corpsEtat, proSpecializations } from '@travauxlib/shared/src/utils/constants';
import { required } from '@travauxlib/shared/src/utils/form';

import { CheckboxGroupedField } from 'components/CheckboxGrouped/Field';
import { additionalInformations, certifications } from 'utils/constants';

type Props = {
  chiffresAffaires: { [K: string]: number };
};

export const FieldsPro: React.FC<Props> = ({ chiffresAffaires }) => (
  <>
    <div data-testid="pro">
      <CheckboxGroupedField name="corpsEtat" label="Assurée pour" options={corpsEtat} />
    </div>
    <div className="flex flex-wrap gap-md mb-md">
      <div className="w-full sm-desktop:w-3/12 relative">
        <DropdownMultiField
          id="specializations"
          label="N'envoyer que sur"
          name="specializations"
          options={proSpecializations.map(value => ({ value, label: value }))}
        />
      </div>
      <div className="w-full sm-desktop:w-3/12 relative">
        <DropdownMultiField
          id="additionalInformations"
          label="Additionnel"
          name="additionalInformations"
          options={additionalInformations.map(value => ({ value, label: value }))}
        />
      </div>
      <div className="w-full sm-desktop:w-3/12 relative">
        <DropdownMultiField
          id="certifications"
          label="Certifications"
          name="certifications"
          options={certifications.map(value => ({ value, label: value }))}
        />
      </div>
    </div>
    <div className="flex flex-wrap gap-md">
      <div className="w-full relative">
        <div>Chiffre d&apos;affaires : </div>
        <ul className="pl-0 list-none">
          {Object.entries(chiffresAffaires)
            .filter(([, value]) => !!value)
            .map(([key, value]) => (
              <li key={key} className="!ml-xs">
                {key} : <EURCurrency amount={value} />
              </li>
            ))}
        </ul>
      </div>
      <div className="w-full sm-desktop:w-4/12 relative">
        <NumberInputField
          id="tailleChantierMin"
          name="tailleChantierMin"
          label="Taille chantier min"
          validate={required}
        />
      </div>
      <div className="w-full sm-desktop:w-4/12 relative">
        <NumberInputField
          id="tailleChantierMax"
          name="tailleChantierMax"
          label="Taille chantier max"
          validate={required}
        />
      </div>
    </div>
  </>
);
