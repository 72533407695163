import { useParams } from 'react-router-dom';

import { PartnerOrganization } from 'types';

import { usePartners } from '../api/usePartners';

export const usePartner = (): PartnerOrganization | undefined => {
  const { uuid } = useParams();
  const { partners } = usePartners();
  return partners.find(p => p.uuid === uuid);
};
