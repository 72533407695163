import React from 'react';

import { Outlet } from 'react-router-dom';

import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

import { usePartner } from 'features/Partners/hooks/usePartner';

export const PartnerDetailsModal: React.FC = () => {
  const partner = usePartner();

  if (!partner) {
    return null;
  }

  return (
    <>
      <ButtonLink to=".." variant="secondary">
        Retour
      </ButtonLink>
      <Outlet />
    </>
  );
};
