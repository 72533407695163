import React from 'react';

import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';
import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { Chantier, ChantierTransaction } from '@travauxlib/shared/src/features/Chantiers/types';

import { useIgnoreChantierTransaction } from '../api/useIgnoreChantierTransaction';

type OwnProps = {
  item: ChantierTransaction;
  chantier: Chantier;
};

type InjectedProps = {
  handleClose: () => void;
};

type Props = OwnProps & InjectedProps;

export const IgnoreTransactionModal: React.FC<Props> = ({ item, chantier, handleClose }) => {
  const ignoreTransaction = useIgnoreChantierTransaction();

  return (
    <FeedbackMessages
      variant="warning"
      validateAction={{
        label: `${item.isIgnored ? 'Prendre en compte' : 'Ignorer'} la transaction`,
        onClick: () => {
          ignoreTransaction({
            chantier,
            ignored: !item.isIgnored,
            transactionId: item.id,
          });
          handleClose();
        },
      }}
      isOpen
      title={`${item.isIgnored ? 'Prendre en compte' : 'Ignorer'} la transaction`}
      message={`En confirmant, la transaction sera ${
        item.isIgnored ? 'prise en compte' : 'ignorée'
      } dans l'historique des mouvements sur ce compte séquestre. Cette action ne doit être appliquée que sur les mouvements inhabituels sur le compte !`}
      handleClose={handleClose}
    />
  );
};

export const useIgnoreTransactionModal = (): ((
  item: ChantierTransaction,
  chantier: Chantier,
) => void) => {
  const openModal = useOpenModal();

  const open = async (item: ChantierTransaction, chantier: Chantier): Promise<void> => {
    openModal(IgnoreTransactionModal, {
      item,
      chantier,
    });
  };

  return open;
};
