import { ProClientView } from '@travauxlib/shared/src/types/api/pro/ProClientView';

import { BaseStep, ExecuteParams } from '../BaseStep';

const fields = [
  {
    name: 'proClientEmail',
    helperText: 'Si tu veux réutiliser un pro client existant, renseigne son email',
  },
] as const;

export class CreateClient extends BaseStep<
  {},
  { dealUuid: string },
  typeof fields,
  { proClient: ProClientView }
> {
  public name: string = 'Create client';

  public fields = fields;

  async execute({
    seed,
    apiURL,
    request,
    proClientEmail,
  }: ExecuteParams<{}, typeof fields>): Promise<{ dealUuid: string } | undefined> {
    if (proClientEmail) {
      super.log(`Skipped! proClientEmail chosen`);
      return;
    }

    super.log('Creating pro client...');

    const client = {
      name: `godmod_client_${seed}`,
      chantierAddress: {
        address: '10 rue du Paradis',
        postalCode: '75010',
        city: 'Paris',
      },
      sameBillingAndChantierAddress: true,
      email: 'plouf-testcafe@hemea.com',
      dealTitle: 'Chantier de folie',
      isArchived: false,
    };

    const createdProClient = await request<ProClientView>(`${apiURL}/pro/clients`, {
      method: 'POST',
      body: client,
    });

    if (!createdProClient) {
      throw new Error('pro client creation failed');
    }

    super.setCreatedEntities({
      proClient: createdProClient,
    });

    super.log('OK');

    return {
      dealUuid: createdProClient.deals[0].uuid,
    };
  }
}
