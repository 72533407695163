import React from 'react';

import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import { ColumnConfig } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { PartnerCommission } from 'types';

import { shouldDisableTableRow } from './shouldDisableTableRow';

export const commonTableColumns: ColumnConfig<PartnerCommission>[] = [
  {
    name: 'Type',
    columnKey: 'typeCommission',
    renderValue: typeCommission =>
      typeCommission === 'cheque' ? <span>Chèque cadeau</span> : <span>Facture</span>,
  },
  {
    name: 'Partenaire',
    columnKey: 'partnerName',
  },
  {
    name: 'Agence',
    columnKey: 'agencyName',
  },
  {
    name: 'Conditions commerciales',
    columnKey: 'conditions',
  },
  {
    name: 'Nom client',
    columnKey: 'customerName',
  },
  {
    name: 'Montant commission hemea HT',
    columnKey: 'montantCommissionHemeaHT',
    renderValue: (amount: number) => <EURCurrency amount={amount} />,
  },
  {
    name: 'Pourcentage commission',
    columnKey: 'pourcentage',
    renderValue: (pourcentage: number) => (pourcentage ? <span>{pourcentage} %</span> : <span />),
  },
  {
    name: 'Montant facture HT',
    renderValue: (_, commission) => (
      <span>
        {commission.pourcentage ? (
          <EURCurrency
            amount={roundToTwoDecimals(
              (commission.montantCommissionHemeaHT * commission.pourcentage) / 100,
            )}
          />
        ) : (
          ''
        )}
      </span>
    ),
  },
  {
    name: 'Destinataire',
    columnKey: 'billingEmail',
    renderValue: (billingEmail: string | undefined, commission: PartnerCommission) => {
      if (!billingEmail && shouldDisableTableRow(commission)) {
        return <InlineAlert level="error">Destinataire manquant</InlineAlert>;
      }
      return <span>{billingEmail}</span>;
    },
  },
];
