import { Deferred } from '@travauxlib/shared/src/utils/deferred';

import { BaseStep, ExecuteParams, StepField } from '../gdm_steps/BaseStep';

type GetCreatedEntitiesFromBaseStep<BS extends BaseStep<any, any, any>> =
  BS extends BaseStep<any, any, any, infer CreatedEntities> ? CreatedEntities : unknown;

export class Flow<Input, CreatedEntities> {
  stack: BaseStep<any, any>[] = [];

  verbose: boolean;

  constructor(stack: BaseStep<any, any>[] = [], verbose: boolean = true) {
    this.stack = stack;
    this.verbose = verbose;
  }

  next<Output, Fields extends readonly StepField[], Step>(
    step: Step extends BaseStep<Input, Output, Fields> ? Step : BaseStep<Input, Output, Fields>,
  ): Flow<Input & Output, CreatedEntities & GetCreatedEntitiesFromBaseStep<typeof step>> {
    return new Flow<Input & Output, CreatedEntities & GetCreatedEntitiesFromBaseStep<typeof step>>([
      ...this.stack,
      step,
    ]);
  }

  nextFlow<Output, NextFlowCreatedEntities>(
    flow: Flow<Output, NextFlowCreatedEntities>,
  ): Flow<Input & Output, CreatedEntities & NextFlowCreatedEntities> {
    return new Flow<Input & Output, CreatedEntities & NextFlowCreatedEntities>([
      ...this.stack,
      ...flow.stack,
    ]);
  }

  executeFlow =
    (
      /* eslint-disable */
      onStepSuccess: (step: BaseStep<any, any, any>) => void = console.log,
      onStepError: (step: BaseStep<any, any, any>) => void = console.log,
      /* eslint-enable */
    ) =>
    async (
      params: ExecuteParams,
      {
        verbose = this.verbose,
        deferredRef,
      }: {
        verbose?: boolean;
        deferredRef?: React.RefObject<Deferred<any>>;
      },
    ): Promise<CreatedEntities> => {
      let stack: (Object | undefined)[] = [];
      let createdEntities = {} as CreatedEntities;
      let flow = this.stack;
      for (let i = 0; i < flow.length; i++) {
        try {
          if (i !== 0) {
            await deferredRef?.current?.promise;
          }
          const previousStack = stack[i - 1] || params;

          stack[i] = { ...previousStack, ...(await flow[i].execute(previousStack)) };
        } catch (e: any) {
          /* eslint-disable */
          if (verbose) {
            console.error('Flow interrupted! Please see the logs of the current step');
            console.error('Flow stack : ', stack);
            console.error('Exception : ', e);
            console.error('Flow : ', flow[i]);
            /* eslint-enable */
            flow[i].error(e?.toString?.());
            onStepError(flow[i]);
          } else {
            /* eslint-disable no-console */
            console.error('\x1b[31m\n[ERROR]\n\x1b[0m');
            console.error('\x1b[31m-----------------------------------------------\n\x1b[0m');
            console.error(`\x1b[31m${flow[i].getName()} - ${flow[i].seed} - ${e}\n\x1b[0m`);
            console.error('\x1b[31m-----------------------------------------------\n\x1b[0m');
          }

          break;
        } finally {
          const [stout, stderr] = flow[i].getOutputs();

          createdEntities = { ...createdEntities, ...flow[i].getCreatedEntities() };

          /* eslint-disable */
          if (verbose) {
            console.log(`[${flow[i].getName()}][OUT] : ${stout}`);
            if (stderr) console.log(`[${flow[i].getName()}][ERR] : ${stderr}`);
            console.log('Stack : ', stack);
            onStepSuccess(flow[i]);
          }
          /* eslint-enable */
        }
      }

      /* eslint-disable */
      if (verbose) {
        console.log('Flow finished !', createdEntities);
      }
      return createdEntities;
    };
}
