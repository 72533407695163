import React, { useState } from 'react';

import dayjs from 'dayjs';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { Lead } from 'types';

export const LeadHistory: React.FC<{ lead: Lead }> = ({ lead }) => {
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);

  return (
    <>
      <Modal
        isOpen={isHistoryOpen}
        title="Historique du lead"
        size="lg"
        handleClose={() => setIsHistoryOpen(false)}
        validateAction={{ label: 'Fermer', onClick: () => setIsHistoryOpen(false) }}
      >
        <ModalContent>
          <table className="table table-bordered">
            <tr className="text-center">
              <th>Date</th>
              <th>Description</th>
              <th>Ancienne valeur</th>
              <th>Nouvelle valeur</th>
            </tr>
            {lead.history.map(h => (
              <tr key={h.date.toString()}>
                <td>{dayjs(h.date).format('DD/MM/YYYY HH:mm')}</td>
                <td>
                  {h.description} <br />
                  {h.reason}
                </td>
                <td>{h.from}</td>
                <td>{h.to}</td>
              </tr>
            ))}
          </table>
        </ModalContent>
      </Modal>
      <div className="text-center">
        <Link
          className="text-sm text-center"
          onClick={() => setIsHistoryOpen(isHistoryOpen => !isHistoryOpen)}
          inline
        >
          Historique du lead ({lead.history.length})
        </Link>
      </div>
    </>
  );
};
