import React, { useState } from 'react';

import { Field, Form } from 'react-final-form';
import { useOutletContext } from 'react-router-dom';

import { Dropdown } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown';
import { InsuranceForm } from '@travauxlib/shared/src/components/InsuranceForm';
import { ProCompanyInsurance } from '@travauxlib/shared/src/types';
import { InsuranceFormType } from '@travauxlib/shared/src/utils/constants';

import { useUploadTempS3Files } from 'api/useUploadTempS3Files';
import { useUpdateProCompany } from 'features/Entreprises/features/Entreprise/api/useUpdateProCompany';
import { useUploadInsurance } from 'features/Entreprises/features/Entreprise/api/useUploadInsurance';
import { ProCompany } from 'types';

import { AssurancesList } from './AssurancesList';

type FormValues = { insurances: ProCompanyInsurance[] };

export const InsurancesComponent: React.FC = () => {
  const [insuranceType, setInsuranceType] = useState<InsuranceFormType>(
    InsuranceFormType.decennaleAndRCPro,
  );
  const { proCompany } = useOutletContext<{ proCompany: ProCompany }>();
  const { slug } = proCompany;
  const { update, deleteInsurance } = useUpdateProCompany();
  const uploadInsurance = useUploadInsurance();
  const { uploadFiles } = useUploadTempS3Files();

  return (
    <div className="!p-lg border border-solid border-gray-300 border-t-0 !rounded !bg-white !shadow-xs">
      <Form<FormValues>
        onSubmit={async ({ insurances }) => {
          await update({ insurances, slug });
        }}
        initialValues={proCompany}
      >
        {({ handleSubmit, invalid }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <h3 className="!mt-xs">Assurances décennales</h3>
              <Field
                name="insurances"
                handleDelete={(insuranceId: number) => deleteInsurance({ insuranceId, slug })}
                component={AssurancesList}
                validate={
                  () => undefined
                  /* This wil make the app crash sadly: https://github.com/final-form/react-final-form/issues/382
                /* insurances && */
                  /* insurances.some(insurance => */
                  /*   dayjs(insurance.dateDebutValidite).isAfter( */
                  /*     insurance.dateFinValidite, */
                  /*   ), */
                  /* ) */
                }
              />
            </div>
            {invalid && (
              <div className="!text-center !text-danger">
                La date de fin de validité est antérieure à la date de début de validité pour une
                des assurances
              </div>
            )}
          </form>
        )}
      </Form>
      <div className="max-w-1/2">
        <div className="mb-md mt-md text-h4">Ajouter une assurance</div>
        <Dropdown
          className="mb-sm"
          id="insuranceType"
          label="Type d'assurance"
          options={Object.values(InsuranceFormType).map(value => ({ value, label: value }))}
          value={insuranceType}
          onChange={setInsuranceType}
        />
        <InsuranceForm
          insuranceType={insuranceType}
          uploadInsurance={uploadInsurance}
          uploadFiles={uploadFiles}
          slug={slug}
        />
      </div>
    </div>
  );
};
