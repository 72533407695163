import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import _groupBy from 'lodash/groupBy';

import { request } from '@travauxlib/shared/src/utils/request';

import { PartnerCommission } from 'types';

export const COMMISSIONS_KEY = 'commissions';

type Result = {
  allCommissions: PartnerCommission[];
  commissionsToSend: PartnerCommission[];
  commissionsToPay: PartnerCommission[];
  commissionsToBill: PartnerCommission[];
  commissionsPaid: PartnerCommission[];
};

export const useCommissions: ({ showArchived }: { showArchived: boolean }) => Result & {
  isLoading: boolean;
} = ({ showArchived }) => {
  const { data: commissions = [], isLoading } = useQuery<PartnerCommission[]>({
    queryKey: [COMMISSIONS_KEY],
    queryFn: async () => {
      const { commissions }: { commissions: PartnerCommission[] } = await request(
        `${APP_CONFIG.apiURL}/admin/partners/commissions`,
      );

      return commissions;
    },
  });

  return useMemo(() => {
    const filteredCommissions = showArchived ? commissions : commissions.filter(c => !c.archived);
    const commissionsByStatus: { [K in PartnerCommission['status']]?: PartnerCommission[] } =
      _groupBy(filteredCommissions, c => c.status);

    return {
      allCommissions: commissions,
      commissionsToSend: commissionsByStatus.to_send || [],
      commissionsToPay: commissionsByStatus.to_pay || [],
      commissionsToBill: commissionsByStatus.to_bill || [],
      commissionsPaid: commissionsByStatus.paid || [],
      isLoading,
    };
  }, [commissions, showArchived, isLoading]);
};
