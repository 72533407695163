import React from 'react';

import { Form } from 'react-final-form';

import CheckSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CheckSymbol.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';

import { useUpdateProCompany } from 'features/Entreprises/features/Entreprise/api/useUpdateProCompany';

const reviewOptions = Array.from({ length: 10 }, (_, i) => ({
  value: i + 1,
  label: `${i + 1}`,
}));

type Props = {
  slug: string;
  initialValues: object;
};

export const ReviewsDetails: React.FC<Props> = ({ initialValues, slug }) => {
  const { update } = useUpdateProCompany();
  return (
    <Form
      initialValues={initialValues}
      onSubmit={async reviewsDetails => {
        await update({ reviewsDetails, slug });
      }}
    >
      {({ handleSubmit }) => (
        <div className="!mt-sm !pt-sm !pb-sm">
          <form onSubmit={handleSubmit}>
            <div className="!flex !justify-between">
              <div className="!container">
                <div className="flex flex-wrap -mx-md">
                  <div className="w-full px-md relative">
                    <DropdownField
                      className="mb-md"
                      id="note-qualite-travaux"
                      label="Note qualité des travaux"
                      name="noteQualiteTravaux"
                      options={reviewOptions}
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-md">
                  <div className="w-full px-md relative">
                    <DropdownField
                      label="Note qualité des finitions"
                      className="mb-md"
                      id="note-qualite-finitions"
                      name="noteQualiteFinitions"
                      options={reviewOptions}
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-md">
                  <div className="px-md relative w-full">
                    <DropdownField
                      className="mb-md"
                      label="Notre prix des travaux"
                      id="note-prix-travaux"
                      name="notePrixTravaux"
                      options={reviewOptions}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="!container">
                <div className="flex flex-wrap -mx-md">
                  <div className="px-md relative w-full">
                    <DropdownField
                      className="mb-md"
                      id="note-respect-delais"
                      label="Note respect des délais"
                      name="noteRespectDelais"
                      options={reviewOptions}
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-md">
                  <div className="px-md relative w-full">
                    <DropdownField
                      className="mb-md"
                      label="Note réactivité, qualité des échanges"
                      id="note-reactivite-qualite-echange"
                      name="noteReactiviteQualiteEchange"
                      options={reviewOptions}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="!flex !flex-row-reverse">
              <Button size="sm" type="submit" leftIcon={<CheckSymbol />}>
                Sauvegarder
              </Button>
            </div>
          </form>
        </div>
      )}
    </Form>
  );
};
