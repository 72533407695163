import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ConfigurationPayload } from '@travauxlib/shared/src/features/Configurateur/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { CONFIGURATEUR_CONFIGURATION_KEY } from 'features/Configurateur/api/useConfiguration';

const handleCreateUpdateConfiguration = async (
  configuration: ConfigurationPayload,
): Promise<ConfigurationPayload> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/configuration/${configuration.projectUuid}`;
  await request(requestURL, {
    method: 'PUT',
    body: configuration,
  });
  return configuration;
};

export const useCreateUpdateConfiguration = (): typeof handleCreateUpdateConfiguration => {
  const queryClient = useQueryClient();
  const { mutateAsync: createUpdate } = useMutation({
    mutationFn: handleCreateUpdateConfiguration,
    onSuccess: ({ projectUuid }) => {
      queryClient.invalidateQueries({ queryKey: [CONFIGURATEUR_CONFIGURATION_KEY, projectUuid] });
    },
    onError: () => {
      toast.error("La configuration n'a pas pu être mise à jour");
    },
  });
  return createUpdate;
};
