import { Devis } from '@travauxlib/shared/src/types';
import { ProClientView } from '@travauxlib/shared/src/types/api/pro/ProClientView';

import { BaseStep, ExecuteParams } from '../BaseStep';

const fields = [
  {
    name: 'devisTemplate',
    helperText: 'token ou api url',
    fieldType: 'input',
  },
] as const;

type Input = { dealUuid: string; proClientEmail?: string };

const getDealUuidFromProClient = async (
  request: ExecuteParams['request'],
  apiURL: string,
  proClientEmail?: string,
): Promise<string | undefined> => {
  if (!proClientEmail) {
    return undefined;
  }

  const proClients = await request<{ data: ProClientView[] }>(`${apiURL}/pro/clients`, {
    method: 'GET',
  });

  return proClients.data.find(proClient => proClient.email === proClientEmail)?.deals[0]?.uuid;
};

export class ProCreateDevis extends BaseStep<
  Input,
  { devis: Devis },
  typeof fields,
  { devis: Devis }
> {
  public name = 'Pro Create Devis';

  public fields = fields;

  async execute({
    apiURL,
    appURL,
    proClientEmail,
    dealUuid: dealUuidFromProps,
    devisTemplate,
    seed,
    request,
  }: ExecuteParams<Input, typeof fields>): Promise<{ devis: Devis }> {
    const dealUuid =
      (await getDealUuidFromProClient(request, apiURL, proClientEmail)) || dealUuidFromProps;

    if (!dealUuid) {
      this.error("Cannot create devis if I don't have a deal uuid");
      return Promise.reject();
    }

    super.log('Creating devis');
    let devisBody = {
      title: `${seed}-godmode`,
      lots: [
        {
          label: 'Installation de chantier - Nettoyage',
          prixTotalHT: 208.72,
          prixTotalTTC: 229.59,
          uuid: 'b92ce0ae-1e45-4cca-b175-034ab8cd4b1f',
          items: [
            {
              description:
                "Mise en place de bâches de protection adhésives (résistantes et sécurisantes), couverture du palier et de l'escalier joignant l'étage inférieur.",
              designation: 'Mise en place de protections pour les parties communes',
              fournitureLignes: [],
              locations: [],
              lotUuid: 'b92ce0ae-1e45-4cca-b175-034ab8cd4b1f',
              marge: 30,
              montantTVA: 20.87,
              normalizedIdentifier: 'protection_parties_communes',
              order: 0,
              priority: 2,
              prixAchat: 160.55,
              prixHT: 208.72,
              prixMainOeuvreHT: 125,
              prixMateriauxHT: 35.55,
              prixPublicFournitureHT: 0,
              prixTTC: 229.59,
              prixUnitaireHT: 208.72,
              quantite: 1,
              tauxTVA: 10,
              type: 'ligne',
              unite: 'unitaire',
              uuid: '1d933edd-b703-4211-88a0-e4cd224a99f9',
            },
          ],
        },
        {
          label: 'Façade',
          prixTotalHT: 23930.4,
          prixTotalTTC: 25246.57,
          uuid: '511c74b3-3ec1-4bb5-a88f-30ac1d9c3a57',
          items: [
            {
              description:
                "Compris : rail de départ, renfort d'angle, sous-enduit, trame de verre, sous-couche et finition par enduit minéral",
              designation:
                "Fourniture et pose d'isolation thermique par l'extérieur en polystyrène expansé collé de 100 mm d'épaisseur et  R = 2,6 m².K/W",
              fournitureLignes: [],
              locations: [],
              lotUuid: '511c74b3-3ec1-4bb5-a88f-30ac1d9c3a57',
              marge: 30,
              montantTVA: 1316.17,
              normalizedIdentifier: 'isolation_exterieur_colle_pse_10',
              order: 0,
              priority: 1,
              prixAchat: 141.6,
              prixHT: 23930.4,
              prixMainOeuvreHT: 67.5,
              prixMateriauxHT: 74.1,
              prixPublicFournitureHT: 0,
              prixTTC: 25246.57,
              prixUnitaireHT: 184.08,
              quantite: 130,
              tauxTVA: 5.5,
              type: 'ligne',
              unite: 'm2',
              uniteLabel: 'm²',
              uuid: 'e2c9638e-4f77-4918-ae8d-f587f54f005f',
            },
          ],
        },
        {
          label: 'Plomberie',
          prixTotalHT: 1681.36,
          prixTotalTTC: 1849.5,
          uuid: '479f4eca-bc82-47b8-ad4c-5d4426872895',
          items: [
            {
              designation:
                "Création d'un nouveau réseau en multicouche pour l'alimentation d'un élément (lavabo, douche,baignoire,…)",
              fournitureLignes: [],
              locations: [],
              lotUuid: '479f4eca-bc82-47b8-ad4c-5d4426872895',
              marge: 30,
              montantTVA: 168.14,
              normalizedIdentifier: 'ec_ef_evacuation_element_multicouche',
              order: 0,
              priority: 6,
              prixAchat: 323.34,
              prixHT: 1681.36,
              prixMainOeuvreHT: 282,
              prixMateriauxHT: 41.34,
              prixPublicFournitureHT: 0,
              prixTTC: 1849.5,
              prixUnitaireHT: 420.34,
              quantite: 4,
              tauxTVA: 10,
              type: 'ligne',
              unite: 'unitaire',
              uuid: '63b6734a-1304-41d0-99e3-f818db0b37cc',
            },
          ],
        },
        {
          label: 'Cuisine',
          prixTotalHT: 1669.2,
          prixTotalTTC: 1836.12,
          uuid: '4be74f5d-78c0-42d8-ac1d-f4fa7a4830b0',
          items: [
            {
              description:
                'Note : en fonction du matériau du plan de travail, la découpe sera faite sur place ou en atelier',
              designation: "Fourniture et pose d'évier de cuisine",
              fournitureLignes: [],
              locations: [],
              lotUuid: '4be74f5d-78c0-42d8-ac1d-f4fa7a4830b0',
              marge: 30,
              montantTVA: 166.92,
              normalizedIdentifier: 'evier',
              order: 0,
              priority: 27,
              prixAchat: 321,
              prixHT: 1669.2,
              prixMainOeuvreHT: 121,
              prixMateriauxHT: 0,
              prixPublicFournitureHT: 200,
              prixTTC: 1836.12,
              prixUnitaireHT: 417.3,
              quantite: 4,
              tauxTVA: 10,
              type: 'ligne',
              unite: 'unitaire',
              uuid: 'f7d97f65-901b-4683-b089-eb8d5d8dd094',
            },
          ],
        },
      ],
      numero: '101',
      defaultTauxTVA: 10,
      defaultMargin: 30,
      projectTitle: `${seed}-godmode`,
    };

    if (devisTemplate) {
      if (devisTemplate.includes('/')) {
        super.log('Using devis template url');
        devisBody = await request(`${apiURL}/admin/cors-bypass`, {
          method: 'POST',
          body: {
            url: devisTemplate,
          },
        });
      } else {
        super.log('Using devis template token');
        devisBody = await request(`${apiURL}/devis-pro/${devisTemplate}`);
      }
    }

    const createdDevis: Devis = await request(`${apiURL}/pro/deals/${dealUuid}/devis`, {
      method: 'POST',
      body: devisBody,
    });

    super.log(`Devis created : ${appURL}/pro/devis/${createdDevis.token}`);

    if (devisTemplate) {
      return { devis: createdDevis };
    }

    // TODO: recreate devis lignes
    // super.log('Creating devis lignes');
    // super.log('OK');

    super.setCreatedEntities({ devis: createdDevis });

    return { devis: createdDevis };
  }
}
