import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Person } from '@travauxlib/shared/src/types';

import { PermissionLabel, PermissionType } from 'utils/constants';

type Props = {
  person: Person;
};

export const PermissionsCard: React.FC<Props> = ({ person }) => {
  const { permissions } = person;

  return (
    <Card className="h-full">
      <h4>Permissions</h4>
      <ul className="pl-md">
        {permissions.length > 0 ? (
          permissions.map((permission: PermissionType) => (
            <li className="my-xs">{PermissionLabel[permission]}</li>
          ))
        ) : (
          <li className="my-xs">Pas de permissions</li>
        )}
      </ul>
    </Card>
  );
};
