import { Commission } from './components/Commission';
import { ModalitesDevis } from './components/ModalitesDevis';
import { ModalitesHemea } from './components/ModalitesHemea';
import { ModalitesPaiement } from './components/ModalitesPaiement';

export const Modalites = {
  ModalitesDevis,
  ModalitesHemea,
  ModalitesPaiement,
  Commission,
};
