import { useMutation, useQueryClient } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { AUTH_KEY } from './useAuth';

const handleLogoutFromProAccount = (): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/pro/users/logout`;
  return request(requestURL, {
    method: 'POST',
  });
};

export const useLogoutFromProAccount = (): typeof handleLogoutFromProAccount => {
  const queryClient = useQueryClient();
  const { mutateAsync: logout } = useMutation({
    mutationFn: handleLogoutFromProAccount,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [AUTH_KEY] });
    },
  });

  return logout;
};
