import React from 'react';

import Eco from '@travauxlib/shared/src/components/DesignSystem/assets/Eco.svg?react';
import { EventCard } from '@travauxlib/shared/src/components/DesignSystem/components/EventCard';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';

import { eventCardClassName } from './constants';

import { useLinkLwAccount } from '../../api/useLinkLwAccount';

type Props = {
  chantier: Chantier;
};
export const RattacherCompteLemonway: React.FC<Props> = ({ chantier }) => {
  const { linkLwAccount, isLoading } = useLinkLwAccount();

  return !chantier.lemonwayAccountUrl ? (
    <EventCard
      className={eventCardClassName}
      icon={<Eco />}
      variant="warning"
      title="Rattacher un compte Lemonway"
      action={{
        label: 'Rattacher un compte',
        onClick: () => linkLwAccount(chantier),
        disabled: isLoading,
        hideIcon: true,
      }}
    >
      <></>
    </EventCard>
  ) : (
    <></>
  );
};
