import React from 'react';

import { FieldRenderProps } from 'react-final-form';

import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbol.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { required } from '@travauxlib/shared/src/utils/form';

import { Contact } from 'types';

type Props = FieldRenderProps<Contact[]>;

export const Contacts: React.FC<Props> = ({ input: { value = [], onChange } }) => (
  <div className="!mb-sm">
    <h2>Contacts</h2>
    {value.length === 0 && <div>Aucun contact</div>}
    <div className="flex flex-col gap-md mb-md">
      {value.map((field, index) => (
        <div
          key={index}
          className="w-full grid gap-xs md-desktop:grid-cols-3 p-xs border items-center"
        >
          <div className="grid gap-xs grid-cols-2">
            <InputField
              id="name"
              label="Nom du contact *"
              name={`contacts.${index}.name`}
              validate={required}
            />
            <InputField id="email" label="Email" name={`contacts.${index}.email`} />
          </div>
          <div className="grid gap-xs grid-cols-2">
            <InputField id="phoneNumber" label="Tél" name={`contacts.${index}.phoneNumber`} />
            <InputField
              id="comment"
              label="Rôle / commentaire"
              name={`contacts.${index}.comment`}
            />
          </div>
          <div className="flex justify-center">
            <IconButton
              variant="black"
              onClick={() => onChange(value.filter((_, contactIndex) => contactIndex !== index))}
            >
              <Delete />
            </IconButton>
          </div>
        </div>
      ))}
    </div>
    <div>
      <Button leftIcon={<PlusSymbol />} size="sm" onClick={() => onChange([...value, {}])}>
        Ajouter un contact
      </Button>
    </div>
  </div>
);
