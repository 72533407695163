import React from 'react';

import Dropzone from 'react-dropzone';
import { Field, FieldRenderProps } from 'react-final-form';

import ArrowLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ArrowLeft.svg?react';
import ArrowRight from '@travauxlib/shared/src/components/DesignSystem/assets/ArrowRight.svg?react';
import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import PlusCircle from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircle.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { swap } from '@travauxlib/shared/src/utils/listMethods';

type Props = {
  uploadPhoto: (files: File[]) => Promise<object[]>;
  onReject: (fileRejections: { file: File }[]) => void;
};

export const Photos: React.FC<Props & FieldRenderProps<object[]>> = ({
  input: { value = [], onChange },
  uploadPhoto,
  onReject,
}) => (
  <div>
    <div className="!text-right !mb-xs">
      <Dropzone
        accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }}
        onDrop={files => uploadPhoto(files).then(newPhotos => onChange([...value, ...newPhotos]))}
        maxSize={5000000}
        onDropRejected={onReject}
      >
        {({ getRootProps, getInputProps }) => {
          const { ref, ...rest } = getRootProps();
          return (
            <Button size="sm" leftIcon={<PlusCircle />} {...rest}>
              <input {...getInputProps()} />
              Ajouter des photos (max. 5 Mo)
            </Button>
          );
        }}
      </Dropzone>
    </div>
    <div className="!flex !flex-wrap">
      {value.map((photo, index) => (
        <div key={index} className="!mx-xs">
          <InputField label="Label" id={`photos.${index}.label`} name={`photos.${index}.label`} />
          <div className="!text-center">
            <Field
              id="url"
              name={`photos.${index}.url`}
              component={({ input: { value } }: { input: { value: string } }) => (
                <ImgLazy
                  imgixParams={{ fit: 'crop', crop: 'top' }}
                  width={150}
                  height={150}
                  url={value}
                />
              )}
            />
          </div>
          <div className="my-xs flex items-center gap-xs">
            <IconButton
              size="sm"
              data-testid="swap-left"
              disabled={index === 0}
              onClick={() => onChange(swap(value, index, -1))}
            >
              <ArrowLeft />
            </IconButton>
            <IconButton
              size="sm"
              data-testid="swap-right"
              disabled={index === value.length - 1}
              onClick={() => onChange(swap(value, index, +1))}
            >
              <ArrowRight />
            </IconButton>
            <IconButton
              data-testid="delete"
              variant="black"
              type="button"
              onClick={() => onChange(value.filter((_, indexPhoto) => indexPhoto !== index))}
            >
              <Delete />
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  </div>
);
