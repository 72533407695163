import React from 'react';

import { Form } from 'react-final-form';

import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { composeValidators, required } from '@travauxlib/shared/src/utils/form';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { useSubmitPaiement } from '../../api/useSubmitPaiement';

type Props = {
  isModalOpen: boolean;
  onClose: () => void;
  resteAAppeler: number;
  proName: string;
  chantierSlug: string;
  hasSuiviChantier: boolean;
};

export type FormDataPaiement = {
  destinataire?: string;
  montant: number;
  chantierSlug: string;
};

export const ModalAppelDePaiement: React.FC<Props> = ({
  isModalOpen,
  onClose,
  resteAAppeler,
  proName,
  chantierSlug,
  hasSuiviChantier,
}) => {
  const { submitPaiement, isLoading } = useSubmitPaiement();

  const validateMontantAppelle = (montantAppelle: number): string | undefined =>
    roundToTwoDecimals(resteAAppeler - montantAppelle) < 0 ? 'Montant invalide' : undefined;

  return (
    <Modal isOpen={isModalOpen} handleClose={onClose} title="Appel de paiement">
      <Form<FormDataPaiement>
        onSubmit={async values => {
          await submitPaiement(values);
          onClose();
        }}
        initialValues={{ chantierSlug: chantierSlug }}
      >
        {({ handleSubmit, invalid, values }) => (
          <ModalContent
            handleSubmit={handleSubmit}
            validateAction={{ label: 'Envoyer', type: 'submit', disabled: invalid || isLoading }}
            cancelAction={{ label: 'Annuler', onClick: onClose }}
          >
            <>
              {hasSuiviChantier && (
                <AlertMessage level="error" className="mb-md">
                  La libération des fonds en fonction de l'avancement du chantier est activée, les
                  appels de paiements arbitraires doivent donc être proscrits au maximum. Ils
                  doivent être impérativement justifiés.
                </AlertMessage>
              )}
              <strong>Avant</strong>
              <div className="!mb-lg">
                Reste à appeler : <EURCurrency amount={resteAAppeler} forceFullPattern />
              </div>
              <NumberInputField
                className="mb-md"
                name="montant"
                id="montant"
                label="Montant"
                min={0.01}
                fractionDigits={2}
                suffix="€"
                validate={composeValidators(required, validateMontantAppelle)}
              />
              <DropdownField
                className="mb-md"
                name="destinataire"
                id="destinataire"
                label="Destinataire"
                validate={required}
                options={[{ label: proName, value: 'pro' }]}
                defaultValue="pro"
                disabled
              />
              <strong>Après</strong>
              <div className="!mb-lg">
                Reste à appeler :{' '}
                <EURCurrency
                  amount={roundToTwoDecimals(resteAAppeler - (values.montant || 0))}
                  forceFullPattern
                />
              </div>
            </>
          </ModalContent>
        )}
      </Form>
    </Modal>
  );
};
