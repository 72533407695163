import React from 'react';

import { Tag, Variant } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';

type Props = {
  level: Variant;
  badges?: string[];
  title?: string;
  sorted?: boolean;
  wrapped?: boolean;
};

export const BadgeList: React.FC<Props> = props => {
  const { badges = [], level, wrapped = false, sorted = false, title = '' } = props;

  const displayedBadges = sorted ? badges.sort() : badges;

  if (wrapped) {
    return (
      <div className="!flex !flex-wrap">
        {title && <strong className="!my-auto">{title}</strong>}
        {displayedBadges.map((badge, index) => (
          <Tag
            size="md"
            variant={level}
            label={badge}
            className="!py-xxs !px-xs !m-xxs"
            key={`${badge}_${index}`}
          />
        ))}
      </div>
    );
  }

  return (
    <div>
      {displayedBadges.map((badge, index) => (
        <Tag
          variant={level}
          size="md"
          label={badge}
          className="!my-xxs !block"
          key={`${badge}_${index}`}
        />
      ))}
    </div>
  );
};
