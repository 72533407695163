import React from 'react';

import { useParams } from 'react-router-dom';

import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';

import { useProCompany } from './api/useProCompany';
import { EditEntreprise } from './components/Edit';

export const Entreprise: React.FC = () => {
  const { slug } = useParams();
  const proCompany = useProCompany(slug!);

  if (!proCompany) {
    return <LoaderWrapper />;
  }

  return (
    <>
      <PageTitle title={`${PageTitleEnum.entreprises} - ${proCompany.name}`} />
      <EditEntreprise proCompany={proCompany} />
    </>
  );
};
