import { useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

export const handleUploadDocument = ({
  file,
  documentType,
  personUuid,
}: {
  file: File;
  documentType: string;
  personUuid: string;
}): Promise<{
  url: string;
}> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/employees/persons/${personUuid}/document-cdpl`;

  const formData = new FormData();
  formData.append('document', file);
  return request(requestURL, {
    method: 'POST',
    body: formData,
    params: {
      documentType,
    },
  });
};

export const useUploadDocumentCdpl = (): typeof handleUploadDocument => {
  const { mutateAsync: uploadDocument } = useMutation({
    mutationFn: handleUploadDocument,
    onError: () => {
      toast.error("Une erreur est survenue, le fichier n'a pas pu être envoyé.");
    },
  });
  return uploadDocument;
};
