import React from 'react';

import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';
import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';

import { useAuth } from 'api/useAuth';
import { FicheChantiersTracking } from 'utils/tracking/FicheChantiersTracking';

import { useValidateAppelDePaiement } from '../api/useValidateAppelDePaiement';

type OwnProps = {
  appelDePaiementUuid: string;
  chantier: Chantier;
};

type InjectedProps = {
  handleClose: () => void;
};

type Props = OwnProps & InjectedProps;

export const ValidateAppelDePaiementModal: React.FC<Props> = ({
  appelDePaiementUuid,
  chantier,
  handleClose,
}) => {
  const { isLoading, validateAppelDePaiement } = useValidateAppelDePaiement();
  const { adminUserProfile } = useAuth();

  return (
    <FeedbackMessages
      size="md"
      variant="warning"
      title="Confirmer la validation"
      handleClose={handleClose}
      isOpen
      message={
        <div>
          Si vous souhaitez valider un appel paiement à la place d'un client, merci de bien vérifier
          que vous avez son accord écrit.
        </div>
      }
      cancelAction={{ label: 'Annuler', onClick: handleClose }}
      validateAction={{
        label: 'Confirmer',
        'data-testid': 'confirm-validate-appel-de-paiement',
        type: 'submit',
        onClick: async () => {
          await validateAppelDePaiement({
            uuid: appelDePaiementUuid,
            chantierSlug: chantier.slug,
          });
          FicheChantiersTracking.onFondsReleased({
            'User Role': adminUserProfile.role,
            'Chantier UUID': chantier.uuid,
          });
          handleClose();
        },
        loading: isLoading,
      }}
    />
  );
};

export const useValidateAppelDePaiementModal = (): ((
  appelDePaiementUuid: string,
  chantier: Chantier,
) => void) => {
  const openModal = useOpenModal();

  const open = async (appelDePaiementUuid: string, chantier: Chantier): Promise<void> => {
    openModal(ValidateAppelDePaiementModal, {
      appelDePaiementUuid,
      chantier,
    });
  };

  return open;
};
