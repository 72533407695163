import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { ProCompany } from 'types';

export const PRO_COMPANY_KEY = 'pro-company';

export const useProCompany = (slug: string): ProCompany | undefined => {
  const { data } = useQuery<ProCompany>({
    queryKey: [PRO_COMPANY_KEY, slug],
    queryFn: async () => {
      const requestURL = `${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/profile`;

      const { company, ...data } = await request(requestURL);
      return {
        ...company,
        ...data,
      };
    },
    refetchOnWindowFocus: false,
  });

  return data;
};
