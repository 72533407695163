import { request } from '@travauxlib/shared/src/utils/request';

import { useChantierQuery } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';

import { FormDataLiberationFonds } from '../components/EventCards/LiberationFondsForm';

const handleProcessAppelDePaiement = async ({
  payload,
  chantierSlug,
  uuid,
}: {
  payload: FormDataLiberationFonds;
  chantierSlug: string;
  uuid: string;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${chantierSlug}/appels-de-paiement/${uuid}/process`;
  await request(requestURL, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const useProcessAppelDePaiement = (): {
  processAppelDePaiement: typeof handleProcessAppelDePaiement;
} => {
  const { query } = useChantierQuery(
    handleProcessAppelDePaiement,
    'de la libération des fonds',
    'La libération de fonds a bien été effectuée.',
  );
  return { processAppelDePaiement: query };
};
