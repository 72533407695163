import { BaseStep, ExecuteParams } from '../BaseStep';

type Input = { clientUuid: string };

export class SignInAsClient extends BaseStep<Input> {
  public name = 'Sign in as client';

  public fields = [];

  async execute({ apiURL, clientUuid, request }: ExecuteParams<Input>): Promise<void> {
    super.log(`Sign in as client`);

    await request(`${apiURL}/admin/client/${clientUuid}/sign-in`, { method: 'POST' });

    super.log('OK');
  }
}
