import { UserTracking } from '@travauxlib/shared/src/api/UserTracking';
import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import { AdminFeatures, TrackingEvents } from '@travauxlib/shared/src/utils/tracking';

import { AdminUserProfile } from 'api/useAuth';

class IdentityTrackingClass extends Tracker<AdminFeatures> {
  identify(profile: AdminUserProfile): void {
    // Since indentify and people method are not considered as tracking, we need to disable
    // them manually during testing
    if (!APP_CONFIG.mixpanel.enabled) {
      UserTracking.disable();
      return;
    }

    if (profile) {
      UserTracking.identify(profile.uuid?.toString());
      UserTracking.people.set({
        $email: profile.adminEmail,
        $name: `${profile.firstName} ${profile.lastName}`,
      });
    }
  }

  onLogout(properties: { source: 'Clique sidebar' }): void {
    this.sendTracking({ event: TrackingEvents.LoggedOut, properties });
  }
}

export const IdentityTracking = new IdentityTrackingClass(AdminFeatures.Identity);
