import { createAdressableLead } from './createAdressableLead';

import { SearchLeadByEmail } from '../gdm_steps/Client/SearchLeadByEmail';
import { UpdateProjectTypologie } from '../gdm_steps/Client/UpdateProjectTypologie';
import { CreateConfigurateur } from '../gdm_steps/Configurateur/CreateConfigurateur';

// eslint-disable-next-line
export const createLeadAndCompleteConfigurateur = () =>
  createAdressableLead()
    .next(new SearchLeadByEmail())
    .next(new CreateConfigurateur())
    .next(new UpdateProjectTypologie());
