import { Flow } from './Flow';

import { CreateProLead } from '../gdm_steps/Pro/CreateProLead';
import { SearchProRegistration } from '../gdm_steps/Pro/SearchProRegistration';
import { SetProCompanyAbleForHemeaProjects } from '../gdm_steps/Pro/SetProCompanyAbleForHemeaProjects';
import { SetProRegistrationLabellisee } from '../gdm_steps/Pro/SetProRegistrationLabellisee';
import { SignInAsPro } from '../gdm_steps/Pro/SignInAsPro';

// eslint-disable-next-line
export const createProWithoutSubscription = () =>
  new Flow()
    .next(new CreateProLead())
    .next(new SearchProRegistration())
    .next(new SetProRegistrationLabellisee())
    .next(new SetProCompanyAbleForHemeaProjects())
    .next(new SignInAsPro());
