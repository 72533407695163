import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from 'features/Project/api/useProjectContext';

const handleTriggerRDV = (consultationOfferUuid: string): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/consultation-offers/${consultationOfferUuid}/rendez-vous`;

  return request(requestURL, { method: 'POST' });
};

export const useTriggerRDV = (): typeof handleTriggerRDV => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: handleTriggerRDV,
    onSuccess: () => {
      toast.success("Une prise de RDV avec les horaires du client vient d'être proposée au pro.");
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY] });
    },
  });

  return mutateAsync;
};
