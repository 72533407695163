import React, { useState } from 'react';

import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { Form, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useParams } from 'react-router-dom';

import PlusCircle from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircle.svg?react';
import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { ModalitePaiement } from '@travauxlib/shared/src/types';
import { computeTotalPourcentageModalites } from '@travauxlib/shared/src/utils/computeTotalPourcentageModalites';

import { ModalitesPaiementItem } from './ModalitesPaiementItem';

import { useDevisModalites } from '../api/useDevisModalites';
import { useDevisModalitesPaymentDefault } from '../api/useDevisModalitesPaymentDefault';
import { useDevisModalitesPaymentUpdate } from '../api/useDevisModalitesPaymentUpdate';

export const ModalitesPaiement: React.FC = () => {
  const { devisToken } = useParams<{ devisToken: string }>();
  const { data: modalitesDevis } = useDevisModalites(devisToken!);
  const updateModalitesPaymentDevis = useDevisModalitesPaymentUpdate();
  const setDevisModalitesPaymentDefault = useDevisModalitesPaymentDefault();
  const [modalitesPaiementIsManual, setModalitesPaiementIsManual] = useState(
    modalitesDevis?.modalitesPaiementManual ?? false,
  );

  if (!modalitesDevis || !devisToken) {
    return <LoaderWrapper />;
  }

  const { modalitesPaiement, prixTotalTTC } = modalitesDevis;

  const fieldsetDisabled = modalitesDevis.devisSigned;

  return (
    <Form<{ modalites: ModalitePaiement[] }>
      onSubmit={({ modalites }) => {
        updateModalitesPaymentDevis({
          devisToken,
          body: {
            modalitesPaiement: modalites,
            modalitesPaiementManual: modalitesPaiementIsManual,
          },
        });
      }}
      initialValues={{
        modalites: modalitesPaiement,
      }}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({ handleSubmit, values: { modalites }, dirty, form }) => {
        const totalPercentage = computeTotalPourcentageModalites(modalites);
        return (
          <form onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ values: true }}
              onChange={() => {
                if (!modalitesPaiementIsManual && dirty) {
                  setModalitesPaiementIsManual(true);
                }
              }}
            />
            <div className="flex">
              <div className="text-h4 font-bold">Modalités de paiement</div>
              <Tag
                className="ml-lg"
                variant={modalitesPaiementIsManual ? 'warning' : 'info'}
                label={
                  modalitesPaiementIsManual ? 'Modalités personnalisées' : 'Modalités par défault'
                }
              />
            </div>
            {fieldsetDisabled && (
              <AlertMessage level="error" className="my-md">
                Le devis est signé, il n'est pas possible de modifier les modalités
              </AlertMessage>
            )}
            <FieldArray<ModalitePaiement> name="modalites">
              {({ fields }) => (
                <div>
                  {fields.map((name, index) => {
                    const value = fields.value[index];
                    return (
                      <ModalitesPaiementItem
                        key={name}
                        value={value}
                        prixTotalTTC={prixTotalTTC}
                        fieldsetDisabled={fieldsetDisabled}
                        name={name}
                        index={index}
                        onDelete={() => fields.remove(index)}
                        isSingleItem={fields.length === 1}
                      />
                    );
                  })}
                  <Button
                    className="font-bold p-xs mb-sm flex justify-center items-center"
                    variant="primary"
                    disabled={fieldsetDisabled}
                    leftIcon={<PlusCircle />}
                    onClick={() => {
                      fields.push({
                        label: 'Versement',
                        pourcentage: 1,
                      });
                      if (!modalitesPaiementIsManual) {
                        setModalitesPaiementIsManual(true);
                      }
                    }}
                  >
                    <span className="ml-xs">Ajouter une échéance</span>
                  </Button>
                </div>
              )}
            </FieldArray>
            <div
              data-testid="total"
              className={classNames('flex', {
                'text-danger': totalPercentage !== 100,
                'text-gray-600': totalPercentage === 100,
              })}
            >
              <div className="text-neutral-800 font-medium w-4/12 text-h5">
                Total des versements{' '}
                <span className="text-neutral-600 text-b1">({totalPercentage}&nbsp;%)</span>
              </div>
              <EURCurrency
                className="text-neutral-800 font-bold text-center"
                amount={(totalPercentage * prixTotalTTC) / 100}
              />
            </div>
            {totalPercentage !== 100 && (
              <AlertMessage level="error" className="my-md w-fit">
                Le total des pourcentages doit être égal à 100%. Veuillez ajuster les pourcentages
                des versements.
              </AlertMessage>
            )}
            <div className="flex my-md gap-md">
              <Button
                variant="secondary"
                disabled={fieldsetDisabled || !modalitesPaiementIsManual}
                onClick={async () => {
                  const modalitesDefault = await setDevisModalitesPaymentDefault(devisToken);
                  form.change('modalites', modalitesDefault);
                  setTimeout(() => {
                    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
                  }, 100);
                  setModalitesPaiementIsManual(false);
                }}
              >
                Rétablir modalités par défault
              </Button>
              <Button
                positionTooltip="top-end"
                disabledMessageTooltip={
                  fieldsetDisabled
                    ? "Le devis est signé, il n'est pas possible de modifier les modalités"
                    : undefined
                }
                data-testid="modalitesPaiment_save"
                disabled={totalPercentage !== 100 || fieldsetDisabled || !dirty}
                type="submit"
              >
                Sauvegarder
              </Button>
            </div>
          </form>
        );
      }}
    </Form>
  );
};
