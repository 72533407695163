import React from 'react';

import classNames from 'classnames';

import CheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';
import CrossCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircle.svg?react';

type Props = {
  value?: string;
  showIcon?: boolean;
};

export const FileLink: React.FC<Props> = ({ value, showIcon = true }) => (
  <div
    className={classNames({
      '!flex': showIcon,
    })}
  >
    {showIcon && (
      <div className="!mr-md !flex !items-center">
        {value ? (
          <CheckCircle data-testid="success" className="w-md h-md text-success" />
        ) : (
          <CrossCircle data-testid="danger" className="w-md h-md text-danger" />
        )}
      </div>
    )}
    <div>
      {value ? (
        <a href={value} target="_blank" rel="noreferrer noopener">
          Voir le fichier
        </a>
      ) : (
        <small>Aucun fichier ajouté.</small>
      )}
    </div>
  </div>
);
