import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { DevisMarketplace } from 'types';

export const NB_MONTHS = 6;

export const useDevisSignes = (slug: string): { data: DevisMarketplace[] } | undefined => {
  const { data: devisSignes } = useQuery<{ data: DevisMarketplace[] }>({
    queryKey: ['devis-signes', slug],
    queryFn: () =>
      request(
        `${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/devis-signes?nbMonths=${NB_MONTHS}`,
      ),
  });
  return devisSignes;
};
