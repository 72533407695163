import { TeamsLabel } from '@travauxlib/shared/src/types';

import { useAuth } from 'api/useAuth';

export const useChantierAdvancedActionAuthorized = (): {
  isAuthorized: boolean;
  isLoading: boolean;
} => {
  const {
    isLoading,
    adminUserProfile: { isMemberOfAny },
  } = useAuth();

  return { isAuthorized: isMemberOfAny([TeamsLabel.Supply, TeamsLabel.Product]), isLoading };
};
