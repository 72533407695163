import React from 'react';

import Euro from '@travauxlib/shared/src/components/DesignSystem/assets/Euro.svg?react';
import Location from '@travauxlib/shared/src/components/DesignSystem/assets/Location.svg?react';
import Person from '@travauxlib/shared/src/components/DesignSystem/assets/Person.svg?react';
import { Avatar } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar';
import { getInitials } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar/getInitials';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

import { ProjectListView } from 'types';

import { ProjectCardTag } from './ProjectCardTag';

type Props = {
  project: ProjectListView;
  actionBlock: JSX.Element;
};

export const ProjectCardContainer: React.FC<Props> = ({
  project: { title, address, budgetTravaux, clientName, statuses, dealOwnerFullName },
  actionBlock,
}) => (
  <Card className="border-0" bodyClassNames="p-md pt-sm h-full" role="group">
    <div className="flex flex-col justify-between h-full">
      <div>
        <div className="flex gap-xxs">
          {statuses.map(status => (
            <ProjectCardTag key={status} projectStatus={status} />
          ))}
        </div>
        <div className="text-neutral-800 text-b1 font-bold mb-xxs truncate">{title} </div>
        <div>
          {clientName && (
            <div className="flex gap-xxs items-center">
              <div className="h-md w-md flex items-center text-neutral-700">
                <Person />
              </div>
              <div className="text-neutral-700 text-b2">{clientName}</div>
            </div>
          )}
          {address && (
            <div className="flex gap-xxs items-center">
              <div className="h-md w-md flex items-center text-neutral-700">
                <Location />
              </div>
              <div className="text-neutral-700 text-b2">{address}</div>
            </div>
          )}
        </div>
        {budgetTravaux && (
          <div className="flex gap-xxs mt-xxs items-center">
            <div className="h-md w-md flex items-center">
              <Euro />
            </div>
            <EURCurrency
              amount={parseFloat(budgetTravaux)}
              className="text-neutral-800 text-b2 font-medium"
            />
          </div>
        )}
        {dealOwnerFullName && (
          <div className="flex items-center gap-xxs mt-xxs">
            <Avatar size="xs" text={getInitials(dealOwnerFullName)} />
            <div className="text-neutral-800 text-b2">{dealOwnerFullName}</div>
          </div>
        )}
      </div>
      {actionBlock}
    </div>
  </Card>
);
