import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { PartnerAccount, PartnerOrganization } from 'types';

export const PARTNERS_KEY = 'partners';

export const usePartners: () => {
  partners: PartnerOrganization[];
  agencies: any[];
  accounts: PartnerAccount[];
  isLoading: boolean;
} = () => {
  const { data, isLoading } = useQuery<{
    partners: PartnerOrganization[];
  }>({
    queryKey: [PARTNERS_KEY],
    queryFn: () => request(`${APP_CONFIG.apiURL}/admin/partners`),
    staleTime: 10 * 1000,
  });

  const partners = data?.partners;
  const agencies = partners?.flatMap(p => p.agencies);

  return {
    partners: partners || [],
    agencies: agencies ?? [],
    accounts: agencies?.flatMap(a => a.accounts) ?? [],
    isLoading,
  };
};
