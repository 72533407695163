import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import CheckSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CheckSymbol.svg?react';
import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeft.svg?react';
import Draw from '@travauxlib/shared/src/components/DesignSystem/assets/Draw.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { Toggles } from '@travauxlib/shared/src/features/Configurateur/components/Toggles';
import {
  ConfigurationStatus,
  QuestionId,
} from '@travauxlib/shared/src/features/Configurateur/types';
import { requiredFieldsForConsultation } from '@travauxlib/shared/src/features/Configurateur/utils/constants';
import { getConfigurationPrixHT } from '@travauxlib/shared/src/features/Configurateur/utils/getConfigurationPrixHT';
import { Summary } from '@travauxlib/shared/src/features/Project/components/Summary';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { Gamme } from '@travauxlib/shared/src/types';
import { requiredValidGoogleMapAddress } from '@travauxlib/shared/src/utils/form';
import { getDevisFromProject } from '@travauxlib/shared/src/utils/getDevisFromProject';

import { useBaseDePrix } from 'api/useBaseDePrix';
import { useConfiguration } from 'features/Configurateur/api/useConfiguration';
import { useCreateUpdateConfiguration } from 'features/Configurateur/api/useCreateUpdateConfiguration';
import { useConfigurationStore } from 'features/Configurateur/hooks/useConfigurationStore';
import { useCreateDevis } from 'features/LogicielDeDevis/api/useCreateDevis';
import { useProjectContext } from 'features/Project/api/useProjectContext';
import { useUpdateProject } from 'features/Project/api/useUpdateProject';
import { ProjectForm } from 'features/Project/features/ProjectPage/features/ProjectInfos/components/ProjectForm';

import { ProjectNameCard } from './components/ProjectNameCard';
import { ResultTable } from './components/ResultTable';

export const ConfigurateurHub: React.FC = () => {
  const navigate = useNavigate();
  const projectUuid = useParams().uuid!;
  const { hubClosed, setHubClosed } = useConfigurationStore();
  const { projectContext } = useProjectContext(projectUuid);
  const [questionId, setQuestionId] = useState<QuestionId>();
  const { baseDePrix } = useBaseDePrix();
  const updateConfiguration = useCreateUpdateConfiguration();
  const updateProject = useUpdateProject();
  const isMobileOrTablet = useIsTabletOrMobile();
  const { configuration } = useConfiguration(projectUuid!);
  const { createDevis: createDevis, isLoading } = useCreateDevis();

  if (!configuration || !projectContext) {
    return <Loader />;
  }

  const { project } = projectContext;

  const prixHT = getConfigurationPrixHT(baseDePrix, configuration);
  const allNonZeroSurfaces =
    configuration.locations.length > 0 && configuration.locations.every(l => l.surface !== 0);
  const isAddressPrecise =
    configuration.address && !requiredValidGoogleMapAddress('route')(configuration.address);

  const isValidConfiguration =
    requiredFieldsForConsultation.every(field => configuration[field] !== undefined) &&
    allNonZeroSurfaces &&
    isAddressPrecise;

  return (
    <div>
      <div className="mb-xxs sm-desktop:mb-xxs">
        <ButtonLink
          size="sm"
          variant="tertiary"
          className="pl-xxs pr-xs"
          to={`/projects/${configuration.projectUuid}`}
        >
          <ChevronLeft className="w-md h-md" />
          Retourner à la fiche projet
        </ButtonLink>
      </div>
      <div className="mb-xl">
        <div className="flex justify-between items-start gap-md">
          <div className="flex flex-col sm-desktop:flex-row items-center">
            <div className="font-bold text-h4 sm-desktop:text-h3 text-neutral-800 sm-desktop:mr-xs">
              Configuration du projet
            </div>
          </div>
          <Button
            className="!p-sm"
            disabledMessageTooltip="Pour terminer le configurateur, complétez les informations manquantes"
            positionTooltip={isMobileOrTablet ? 'top-end' : 'left'}
            widthTooltip={isMobileOrTablet ? '10rem' : '22rem'}
            onClick={async () => {
              const updatedConfiguration = {
                ...configuration,
                prixHT,
                status: ConfigurationStatus.Submitted,
              };
              await updateConfiguration(updatedConfiguration);

              const autoGeneratedDevis = getDevisFromProject({
                typologieProject: updatedConfiguration,
                configurationData: {
                  baseDePrix,
                  configuration: updatedConfiguration,
                },
              });

              await createDevis({
                ...autoGeneratedDevis,
                projectUuid: project.uuid,
              });

              navigate(`/projects/${configuration.projectUuid}/cadrage`);
            }}
            disabled={!isValidConfiguration || isLoading}
            loading={isLoading}
          >
            <CheckSymbol className="sm-desktop:mr-xxs w-lg h-lg" />
            <div className="hidden sm-desktop:block">Terminer le configurateur</div>
          </Button>
        </div>
      </div>
      <ProjectForm project={project} updateProject={updateProject}>
        <>
          <ProjectNameCard
            project={project}
            configuration={configuration}
            baseDePrix={baseDePrix}
          />
          <Card className="!rounded-md mb-xl" state="elevated">
            <Summary
              googleApiKey={APP_CONFIG.googleApiKey}
              project={projectContext.project}
              updateProject={updateProject}
              isLocalisationsRequired
            />
          </Card>
        </>
      </ProjectForm>
      <div className="mb-xl">
        <Toggles
          readOnly={configuration.status === ConfigurationStatus.Sent}
          baseDePrix={baseDePrix}
          configuration={configuration}
          setQuestionId={setQuestionId}
          hubClosed={hubClosed}
          onChangeGamme={(newGamme: Gamme, questionId: QuestionId) => {
            const updatedConfiguration = {
              ...configuration,
              answers: {
                ...configuration.answers,
                [questionId]: {
                  ...configuration.answers?.[questionId],
                  gamme: newGamme,
                },
              },
            };

            updateConfiguration(updatedConfiguration);
          }}
          setHubClosed={setHubClosed}
        />
      </div>
      {project.comments && (
        <div className="mb-xl">
          <div className="flex items-center mb-md">
            <ColoredCircle className="mr-xs" size="sm">
              <Draw />
            </ColoredCircle>
            <h3 className="font-bold text-h4 mb-0">Prise de note</h3>
          </div>
          <div className="font-medium text-neutral-800">
            <MultilineParagraph text={project.comments} paragraphClassName="!text-b1" />
          </div>
        </div>
      )}
      {questionId && (
        <ResultTable
          baseDePrix={baseDePrix}
          questionId={questionId}
          onClose={() => setQuestionId(undefined)}
          configuration={configuration}
        />
      )}
    </div>
  );
};
